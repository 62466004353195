import React, { useEffect } from "react";

function Redirect({ url }: { url: string }) {

    useEffect(() => {
        window.location.replace(url);
    }, [url])
    return null;
}

export default Redirect