import React, { useContext, useMemo } from 'react';

import PageViewContext from './PageViewContext';

export default function PageSpacer({
	position
}: {
	position: 'left' | 'right';
}) {
	const { pages, canvasScale } = useContext(PageViewContext);

	const MediaBox = useMemo(() => pages.find(p => !!p)!.MediaBox, [pages]);

	return (
		<div
			style={{
				width: +MediaBox.width * canvasScale,
				height: +MediaBox.height * canvasScale,
				display: 'inline-block',
				position: 'absolute',
				top: 0,
				[position]: 0
			}}
		></div>
	);
}
