import { useEffect } from "react"
import { FaArrowLeft } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import HeaderBanner from "../../components/HeaderBanner"
import { useAuthState } from "../../store/authSlice"
import JobProcessingSpinner from "../../components/JobProcessingSpinner"
import PreflightSummaryCard from "../../components/PreflightSummaryCard"
import { visitJob } from "../../routes/visit"
import { setJobId, useJob } from "../../store/jobSlice"
import { PagePart } from "../../types/pages.types"
import { JobStatus } from "../../types/types"
import { sumPreflytIssuesForJob } from "../../utils/job"
import { sortParts } from "../../utils/parts"

function PreflightSummary() {
    const { job, loading, pages } = useJob()
    const { jobId } = useParams()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if (!job && jobId) dispatch(setJobId(jobId))
    }, [job, jobId, dispatch])

    const backClickHandler = () => {
        // manual refresh on moving back to Preview
        if (job && job.JobID) {
            dispatch(setJobId(job.JobID))
        }
        navigate(visitJob({}))
    }

    return <>
        {
            job && <HeaderBanner>
                <div className="job-banner has-background-link is-flex">
                    <div onClick={backClickHandler} className="px-3 is-flex p-2 has-text-white is-clickable" style={{ backgroundColor: "#38678f", border: "1px solid #315a7d" }}>
                        <FaArrowLeft size={25} className="is-align-self-center" />
                    </div>
                    <div className="py-3 pr-3 is-flex-grow-1">
                        <div className="has-text-white ml-2 is-flex is-justify-content-space-between">
                            <div className="mb-3 is-size-6 has-text-weight-bold">
                                Preflight Issues {
                                    loading ? <div className="preflight-issues-total is-loading"></div> : `(${sumPreflytIssuesForJob(job, pages)})`
                                } / {job.Title}{' '}
                                {/* TODO - spinner could be better aligned/styled;
                            change as needed (other styles may change) */}
                                {job.Status === JobStatus.Processing && <JobProcessingSpinner addedClassNames="bump-spinner-down-4"
                                />}
                            </div>
                        </div>
                        <div>
                            <div className="columns">
                                <div className="column is-half">
                                    <nav className="level">
                                        <div className="level-left">
                                            <div className="has-text-white mr-0 px-2">
                                                <div>
                                                    <p className="help has-text-grey-light">JOB #</p>
                                                    <p className="is-size-11">{job.Tags?.SystemJobNumber || <i className="is-size-11">No Job Number</i>}</p>
                                                </div>
                                            </div>
                                            <div className="has-text-white mr-0 px-2">
                                                <div>
                                                    <p className="help has-text-grey-light">
                                                        {
                                                            (job.ISBN13 || job.ISBN10) ? 'ISBN' : job.CustomerReference ? 'CR #' : 'ISBN/CR #'
                                                        }
                                                    </p>
                                                    <p className="is-size-11">{job.ISBN13 || job.ISBN10 || job.CustomerReference || <i className="is-size-11">No ISBN or CR #</i>}</p>
                                                </div>
                                            </div>
                                            <div className="has-text-white mr-0 px-2">
                                                <div>
                                                    <p className="help has-text-grey-light">AUTHOR</p>
                                                    <p className="is-size-11">{job.Author || <i className="is-size-11">No Author</i>}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </HeaderBanner>
        }
        <section className="section h-full has-background-dark">
            {
                job &&
                <>
                    {
                        (job && job.Status === JobStatus.Processing) &&
                        <div className="notification is-warning m-0 mb-5 mx-1">
                            <span className="has-text-black has-text-weight-bold">Read-only while pages are being processed.</span>
                        </div>
                    }
                    {
                        (job && job.Status === JobStatus.Deleted) &&
                        <div className="notification is-warning m-0 mb-5 mx-1">
                            <span className="has-text-black has-text-weight-bold">Read-only because job has been deleted.</span>
                        </div>
                    }
                    {
                        (job.Status === JobStatus.Error && job.ErrorReason) &&
                        <div className="notification is-danger m-0 mb-5 mx-1">
                            Error: {job.ErrorReason}
                        </div>
                    }
                </>
            }
            <div className="is-flex is-flex-wrap-wrap is-justify-content-space-between">
                {
                    Object.keys(pages).sort(sortParts).filter(part => part !== PagePart.UnassignedCorrections).map((part: string) => <PreflightSummaryCard key={part} part={part} />)
                }
            </div>
        </section>
    </>
}

export default PreflightSummary
