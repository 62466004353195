import { PayloadAction } from "@reduxjs/toolkit";
import { put, select } from "redux-saga/effects";
import JobApi from "../lib/job";
import PagesApi from "../lib/pages";
import { MqttLabels } from "../mqtt/mqtt-redux";
import { mqttActions } from "../mqtt/mqttSlice";
import { RootState } from "../store";
import { setJobData, setJobDataError, setJobId, setPages } from "../store/jobSlice";
import { Job } from "../types/job.types";
import { Page, PagePart } from "../types/pages.types";
import { AuthState, JobState } from "../types/store.types";
import { AccountTypes } from "../types/user.types";

export function* getJobData({ payload }: PayloadAction<string>) {
    console.log("fetching job data...");
    if (!payload) {
        yield put(setJobData(undefined));
        return;
    }
    try {
        const { data: jobData }: { data: Job } = yield JobApi.getJob(payload, false, [
            "AssociatedISBNs", "Author", "BODPage", "Created", "CustomerReference", "ErrorReason", "ISBN10", "ISBN13",
            "JobID", "Workflow", "Type", "Title", "Tags", "Status", "Stage", "Publisher", "PartPages", "PartsList", "Owner"
        ]);
        const res: Record<PagePart, Page[]> = yield PagesApi.getPartsPages(jobData.JobID, jobData.PartsList);
        const currJob: JobState = yield select((state: RootState) => state.job);
        if (currJob.jobId !== jobData.JobID) return;
        yield put(setJobData(jobData));
        yield put(setPages(res))
    } catch (e) {
        yield put(setJobDataError("Error getting job"));
    }
}

export function* getJobUpdate({ payload }: PayloadAction<Job | undefined>) {
    if (!payload) return;
    const userData: AuthState = yield select((state: RootState) => state.auth);
    const jobData: JobState = yield select((state: RootState) => state.job);
    const jobId = jobData.jobId;
    const accountId = userData.user?.AccountType === AccountTypes.Combined ? jobData.job?.Account.AccountID : userData.user?.Account;
    const subString = `/account/${accountId}/${jobId}/na`;

    yield put(mqttActions.unsubscribeChannel({ label: MqttLabels.ACTIVE_JOB }));
    yield put(mqttActions.subscribeChannel({ topic: subString, label: MqttLabels.ACTIVE_JOB }));
}

/** 
 * Fetches job, 
 * and if state job's `Modified` timestamp does not match fetched job's timestamp,
 * dispatches a `setJobData` reducer action (which runs the normal `getJobData` saga function.)  
 * This will refetch and reset the job in state only if the aforementioned Modified times are different from each other. 
 * 
 * */ 
export function* getJobDataAndRefetchIfModified({ payload }: PayloadAction<string>) {
    try {
        const { data: jobData }: { data: Job } = yield JobApi.getJob(payload, false, [ "Modified" ]);
        const currJob: JobState = yield select((state: RootState) => state.job);
        if (currJob.jobId !== jobData.JobID) return;

        // if the current state job and the fetched job have the same ID,
        // and both jobs have Modified fields defined, and the Modified fields are the same value -
        // do not re-set the job ID/refetch.
        if (currJob.job?.Modified && jobData.Modified) {
            if (currJob.job.Modified === jobData.Modified) {
                // console.log('The state job has the same Modified value as the fetched job;')
                // console.log('not updating the job.')
                return;
            }

            // console.log('Modified times are different - setting job ID and refetching...')
            yield put(setJobId(payload));
        }        
    } catch (e) {
        yield put(setJobDataError("Error getting job"));
    }
}