import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';

function isPage(obj: any): obj is PDFPageProxy {
    return obj.pageInfo !== undefined;
}

function isDocument(obj: any): obj is PDFDocumentProxy {
    return obj._pdfInfo !== undefined;
}

/**
 * Generate a preview image from a page or document.
 *
 * @param input
 * @param scale
 */
export async function generatePreview(
    input: PDFPageProxy | PDFDocumentProxy,
    scale?: number
): Promise<HTMLCanvasElement> {
    let pdf;
    let page;

    if (isDocument(input)) {
        pdf = input;
        page = await pdf.getPage(1);
    } else if (isPage(input)) {
        page = input;
    } else
        throw new Error(
            'input parameter is neither PDFPageProxy or PDFDocumentProxy?'
        );

    const viewport = page.getViewport({ scale: scale === undefined ? 1 : scale });

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context === null) throw new Error('failed to get 2d rendering context');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderContext = {
        canvasContext: context,
        viewport: viewport
    };

    await page.render(renderContext);

    return canvas;
}

/**
 * Get a PDFJS document object from a file.
 *
 * @param file
 * @param progressCallback
 */
export async function getPDFJSDocumentFromFile(
    file: File,
    progressCallback?: (progress: number) => void
) {
    const [pdfjs, readFile] = await Promise.all([
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        import('pdfjs-dist'),
        new Promise<Uint8Array>((resolve, reject) => {
            const fr = new FileReader();
            fr.addEventListener(
                'progress',
                data =>
                    progressCallback &&
                    progressCallback((data.loaded / data.total) * 0.5)
            );
            fr.addEventListener('load', () => resolve(fr.result as Uint8Array));
            fr.addEventListener('error', reject);
            fr.readAsArrayBuffer(file);
        })
    ]);

    const task = (pdfjs as any).getDocument(
        readFile,
        undefined,
        undefined,
        console.log
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - types are wrong
    task.onProgress = (data: { loaded: number; total: number }) =>
        progressCallback &&
        progressCallback((data.loaded / data.total) * 0.5 + 0.5);

    return task;
}