import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import AddComponentCard from "../../components/AddComponentCard"
import ComponentCard from "../../components/ComponentCard"
import HeaderBanner from "../../components/HeaderBanner"
import JobProcessingSpinner from "../../components/JobProcessingSpinner"
import PreviewLoader from "../../components/PreviewLoader"
import PreviewOptions from "../../components/PreviewOptions"
import { visitJob } from "../../routes/visit"
import { setJobId, useJob } from "../../store/jobSlice"
import { JobTypes } from "../../types/job.types"
import { PagePart } from "../../types/pages.types"
import { userHasAccountType } from "../../utils/user"
import { AccountTypes } from "../../types/user.types"
import { useAuthState } from "../../store/authSlice"
import { sumPreflytIssuesForJob } from "../../utils/job"
import { JobStatus } from "../../types/types"
import { sortParts } from "../../utils/parts"

function Preview() {

    const { job, loading, pages, jobId, changeId, jobIsActive } = useJob()
    const { jobId: paramJobId } = useParams()

    const { user } = useAuthState()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (!paramJobId) return;
        if (jobId === paramJobId) return;
        if (loading && jobId === paramJobId) return;
        dispatch(setJobId(paramJobId));
    }, [paramJobId, dispatch, jobId, loading])

    useEffect(() => {
        if (changeId) navigate(visitJob({ jobId: changeId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeId]);

    const preflightClickHandler = () => {
        navigate(visitJob({ preflight: true }))
    }

    return <section className="h-full is-relative pb-6" style={{ minHeight: '100vh' }} data-testid="preview-component">
        {loading && <PreviewLoader />}
        {
            job &&
            <>
                <HeaderBanner>
                    <div className="job-banner has-background-link p-3" style={{ position: "relative", top: 0, zIndex: 3 }}>
                        <div className="is-flex is-justify-content-space-between">
                            <div className="title has-min-width-125 has-text-light is-size-6">
                                {job.Title}
                                {job.Status === JobStatus.Processing && <JobProcessingSpinner addedClassNames='bump-spinner-down-4' />}
                            </div>
                            <PreviewOptions />
                        </div>
                        <div>
                            <div className="columns is-flex is-flex-wrap-wrap">
                                <div className="column is-two-thirds">
                                    <div className="level-left is-flex is-flex-wrap-wrap is-justify-content-flex-start is-align-items-stretch">
                                        <div className="has-text-white mr-0 px-2">
                                            <div>
                                                <p className="help has-text-grey-light">JOB #</p>
                                                <p className="is-size-11">{job.Tags?.SystemJobNumber || <i className="is-size-11">No Job Number</i>}</p>
                                            </div>
                                        </div>
                                        <div className="has-text-white mr-0 px-2">
                                            <div>
                                                <p className="help has-text-grey-light">
                                                    {
                                                        (job.ISBN13 || job.ISBN10) ? 'ISBN' : job.CustomerReference ? 'CR #' : 'ISBN/CR #'
                                                    }
                                                </p>
                                                <p className="is-size-11">{job.ISBN13 || job.ISBN10 || job.CustomerReference || <i className="is-size-11">No ISBN or CR #</i>}</p>
                                            </div>
                                        </div>
                                        <div className="has-text-white mr-0 px-2">
                                            <div>
                                                <p className="help has-text-grey-light">AUTHOR</p>
                                                <p className="is-size-11">{job.Author || <i className="is-size-11">No Author</i>}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-flex-end">
                                    {
                                        job.Type === JobTypes.Book &&
                                        <div onClick={preflightClickHandler} className="preflight-issues-component has-text-right has-text-white is-size-5 is-clickable mt-2 is-flex is-flex-direction-row is-flex-wrap-nowrap is-justify-content-flex-end is-align-items-flex-start mb-1">
                                            <div className='is-size-5 pf-issues-label'>
                                                Preflight Summary&nbsp;
                                                <>
                                                    {loading && <div className="preflight-issues-total is-loading"></div>}
                                                    {!loading && (job.Status !== JobStatus.Error ? <span>{'('}{sumPreflytIssuesForJob(job, pages)}{')'}</span> : <></>)}
                                                </>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </HeaderBanner>
                <section className="section">
                    {
                        (job && job.Status === JobStatus.Processing) &&
                        <div className="notification is-warning m-0 mb-5 mx-1">
                            <span className="has-text-black has-text-weight-bold">Read-only while pages are being processed.</span>
                        </div>
                    }
                    {
                        (job && job.Status === JobStatus.Deleted) &&
                        <div className="notification is-warning m-0 mb-5 mx-1">
                            <span className="has-text-black has-text-weight-bold">Read-only because job has been deleted.</span>
                        </div>
                    }
                    {
                        (job.Status === JobStatus.Error && job.ErrorReason) &&
                        <div className="notification is-danger m-0 mb-5 mx-1">
                            Error: {job.ErrorReason}
                        </div>
                    }
                    <div className="is-flex is-flex-wrap-wrap">
                        {
                            Object.keys(pages).sort(sortParts).filter(part => part !== PagePart.UnassignedCorrections).map((part: string) => <ComponentCard key={part} part={part} />)
                        }
                        {(!loading && jobIsActive && !userHasAccountType(AccountTypes.TEP, user)) && <AddComponentCard />}
                    </div>
                </section>
            </>
        }
    </section >
}

export default Preview
