import classNames from 'classnames';
import React, { PropsWithChildren, useCallback } from 'react';
import { FaPaperclip } from 'react-icons/fa';

import styles from './ChatBubble.module.scss';

interface ChatBubbleProps {
	mine?: boolean;
	name?: string;
	attachment?: string;
	areaHovered?: (hovered: boolean) => void;
}

export default function ChatBubble({
	name,
	children,
	mine,
	attachment,
	areaHovered
}: PropsWithChildren<ChatBubbleProps>) {
	const mouseEnterHandler = useCallback(() => {
		if (areaHovered) areaHovered(true);
	}, [areaHovered]);

	const mouseLeaveHandler = useCallback(() => {
		if (areaHovered) areaHovered(false);
	}, [areaHovered]);

	return (
		<div
			className={classNames(styles.BubbleWrapper, {
				[styles.Mine]: mine
			})}
		>
			<div
				className={classNames(styles.Bubble, {
					[styles.Mine]: mine
				})}
			>
				<span className={styles.Name}>{name}</span>
				{children}
				{attachment && (
					<div
						className={classNames({
							[styles.Attachment]: !!attachment
						})}
						onMouseEnter={mouseEnterHandler}
						onMouseLeave={mouseLeaveHandler}
					>
						<FaPaperclip /> {attachment}
					</div>
				)}
			</div>
		</div>
	);
}
