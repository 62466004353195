import classNames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import Foco from "react-foco";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Page } from "../components/PageView/_types/LSCScout.type";
import customFetch from "../lib/axiosInstance";
import JobApi from "../lib/job";
import PagesApi from "../lib/pages";
import { visitJob } from "../routes/visit";
import { setJobData, setPartPageAtOrdinal, updatePartPageAtOrdinal, useJob } from "../store/jobSlice";
import { deleteSelectedPages, duplicateSelectedPages, moveToPart, replacePage, setSelectedPages, updateSelectedPagesApproval, usePages } from "../store/pagesSlice";
import { Preflyt1_IssueTypes } from "../types/job.types";
import { HoldingKeys, PagePart, PageReviewStatus, PartPageThumbnailType } from "../types/pages.types";
import { useViewPort } from "../utils/hooks";
import { sumPreflytIssuesForPage } from "../utils/page";
import { getPart, getPartContextOptions } from "../utils/parts";
import { dispatchCustomEvent } from "../utils/utils";
import ContextMenu from "./ContextMenu";
import MultiFolioModal from "./MultiFolioModal";
import PagePreview from "./PageView/_components/PagePreview";
import { useAuthState } from "../store/authSlice";

const PAGE_SCALE_THRESHOLD = 0.002;

function PartPageThumbnail({ page, visible, onClick, selected = false, size = 1, partVal }: PartPageThumbnailType) {

    const { inView, setRef } = useViewPort()
    const [isVisible, setIsVisible] = useState(false);
    const [pos, setPos] = useState({ x: 0, y: 0 });
    const [contextOn, setContextOn] = useState(false);
    const [multiFolioOn, setMultiFolioOn] = useState(false);
    const [imageURL, setImageURL] = useState("");

    const { pages, jobId, job, jobIsActive } = useJob();
    const { part } = useParams();
    const { selectedPages } = usePages();
    const dispatch = useDispatch();
    const { loading, shelfLoading } = usePages();

    const navigate = useNavigate()

    const isUnavailable = useMemo(() => {
        return page.Status === "new" || page.Status === "processing"
    }, [page.Status])

    const partValue = useMemo(() => {
        return partVal || part
    }, [part, partVal])

    const partIsLocked = useMemo(() => (partValue && job?.Stage?.[partValue]?.Stage === "Locked"), [job, partValue]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (inView) setIsVisible(true);
        }, 1500)
        return () => clearTimeout(timer);
    }, [inView])

    // useEffect(() => {
    //     if (!isVisible) return;
    //     const controller = new AbortController();
    //     customFetch.get(page.Thumb, { responseType: "blob", signal: controller.signal }).then(res => {
    //         const base64Image = URL.createObjectURL(res.data);
    //         setImageURL(base64Image);
    //     })
    //     return () => controller.abort();
    // }, [isVisible, page.Thumb]);

    const pageWarnings = useMemo(() => sumPreflytIssuesForPage(job, part, page, Preflyt1_IssueTypes.Warnings), [job, part, page])

    const pageErrors = useMemo(() => sumPreflytIssuesForPage(job, part, page, Preflyt1_IssueTypes.Errors), [job, part, page])

    const onReplacePage = useCallback((from: number, fromPart: PagePart | string) => {
        if (!jobIsActive || partIsLocked) {
            return;
        }
        if (!partValue) return;
        if (from === page.Ordinal && fromPart === page.Part) {
            return;
        }
        dispatch(replacePage({ fromOrdinal: from, fromPart, destinationOrdinal: page.Ordinal, destinationPart: partValue }))
    }, [page.Ordinal, partValue, dispatch, jobIsActive, page.Part, partIsLocked])

    const [, dragRef] = useDrag<{ index: number }, any, any>(() => ({
        canDrag: jobIsActive && !partIsLocked,
        type: "PartPageThumbnail",
        item: { index: page.Ordinal, part: partValue },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        })
    }), [page.Ordinal, jobIsActive, partValue])

    const [{ isOver }, drop] = useDrop<{ index: number, part: PagePart }, any, any>(
        () => ({
            accept: "PartPageThumbnail",
            drop: (item, monitor) => {
                const isOver = !!monitor.isOver() &&
                    partValue !== PagePart.UnassignedCorrections &&
                    (item.part === PagePart.UnassignedCorrections || item.part === partValue)
                if (!isOver) return;
                onReplacePage(item.index, item.part);
            },
            collect: (monitor) => {
                const draggedItem: any = monitor.getItem();
                return ({
                    isOver: !!monitor.isOver() &&
                        partValue !== PagePart.UnassignedCorrections &&
                        (draggedItem.part === PagePart.UnassignedCorrections || draggedItem.part === partValue) &&
                        !(draggedItem.part === partValue && draggedItem.index === page.Ordinal)
                })
            }
        }), [page.Ordinal, partValue]
    )

    const selectHandler = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!onClick) return;
        if (e.ctrlKey || e.metaKey) {
            onClick(page.Ordinal, HoldingKeys.Ctrl);
            return;
        }
        if (e.shiftKey) {
            onClick(page.Ordinal, HoldingKeys.Shift);
            return;
        }
        onClick(page.Ordinal);
    }

    const gotoPageView = useCallback(() => {
        if (page.Part === PagePart.UnassignedCorrections) {
            return;
        }
        navigate(visitJob({jobId, part: partValue, ordinal: page.Ordinal}));
    }, [jobId, navigate, page.Ordinal, partValue, page.Part])

    const openContextMenuHandler = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        setPos({ x: e.clientX, y: e.clientY });
        setContextOn(true);
        if (!onClick) return;
        if (!selected) onClick(page.Ordinal);
    }

    const contextDownloadHandler = useCallback(async (highRes = false) => {
        const sortedOrdinals = cloneDeep(selectedPages).sort((a, b) => a - b);
        const first = sortedOrdinals[0];
        const last = sortedOrdinals[sortedOrdinals.length - 1];
        try {
            if (!partValue) return;
            Swal.fire({
                title: "Requesting download",
                didOpen: () => {
                    Swal.showLoading();
                }
            })
            await PagesApi.downloadProof(jobId, partValue, first, last, highRes);
            Swal.close();
            Swal.fire({
                icon: "success",
                title: "Proof Requested",
                text: "Your proof has been requested, A link to the proof downloads will be sent via your email address when the proof is ready for download."
            })
        } catch (e) {
            Swal.fire({
                icon: "error",
                title: "Error requesting download"
            })
        }
    }, [jobId, partValue, selectedPages])

    const contextMenuHandler = useCallback(async (contextId: string) => {
        if (!jobIsActive || partIsLocked) {
            return;
        }
        setContextOn(false);
        const isMultiple = selectedPages.length > 1;
        const isShelf = partValue === PagePart.UnassignedCorrections;
        switch (contextId) {
            case "select_all": {
                const allPages = partValue ? pages[partValue] : [];
                const allOrdinals = allPages.map(p => p.Ordinal);
                if (isShelf) dispatch(setSelectedPages({ ordinals: allOrdinals, isShelf: true }));
                else dispatch(setSelectedPages({ ordinals: allOrdinals }));
                break;
            }
            case "select_to_last": {
                const allPages = partValue ? pages[partValue] : [];
                const allOrdinals = allPages.filter(p => p.Ordinal >= page.Ordinal).map(p => p.Ordinal);
                const noDuplicateOrdinals = Array.from(new Set([...allOrdinals, ...selectedPages]));
                if (isShelf) dispatch(setSelectedPages({ ordinals: noDuplicateOrdinals, isShelf: true }));
                else dispatch(setSelectedPages({ ordinals: noDuplicateOrdinals }));
                break;
            }
            case "folio": {
                if (!partValue) return;
                const { value, isConfirmed } = await Swal.fire({
                    title: "Enter a folio",
                    input: "text",
                    inputValue: page.Folio,
                    confirmButtonText: "Save",
                    showCancelButton: true,
                    reverseButtons: true
                });
                if (!isConfirmed) return;
                dispatch(updatePartPageAtOrdinal({ part: partValue, ordinal: page.Ordinal, page: { Folio: value } }))
                Promise.all([
                    customFetch.patch(`/page/page/${jobId}/${page.PageID}`, { Folio: value }),
                    customFetch.post(`/page/page/${jobId}/${page.PageID}/annotations`, { Body: `set Folio to ${value}` })
                ]).catch(() => {
                    // set ordinal to previous value when server side update fails. 
                    dispatch(updatePartPageAtOrdinal({ part: partValue, ordinal: page.Ordinal, page: { Ordinal: page.Ordinal } }))
                })
                break;
            }
            case "folios": {
                let isContiguous = true;
                const sortedPages = cloneDeep(selectedPages).sort((a, b) => a - b);
                for (let i = 1; i < sortedPages.length; i++) {
                    if (sortedPages[i] - 1 !== sortedPages[i - 1]) {
                        isContiguous = false;
                        break;
                    }
                }
                if (!isContiguous) {
                    Swal.fire({
                        title: "Can't refolio selected pages",
                        text: "Re-folioing discontiguous blocks of pages is not supported."
                    })
                    return;
                }
                setMultiFolioOn(true);
                break;
            }
            case "delete": {
                const { isConfirmed } = await Swal.fire({
                    icon: "warning",
                    title: "Please Confirm",
                    text: `Are you sure you want to delete ${isMultiple ? "these" : "this"} ${isMultiple ? selectedPages.length : ""} page${isMultiple ? "s" : ""}?`,
                    confirmButtonText: `Yes, delete ${isMultiple ? "them" : "it"}!`,
                    showCancelButton: true,
                    cancelButtonText: "No",
                    reverseButtons: true
                })
                if (isConfirmed && partValue) dispatch(deleteSelectedPages(partValue));
                break;
            }
            case "crop": {
                gotoPageView();
                break;
            }
            case "download_low_res": {
                contextDownloadHandler();
                break;
            }
            case "download_high_res": {
                contextDownloadHandler(true);
                break;
            }
            case "insert_blank_before": {
                if (!partValue) return;
                const newOrdinal = page.Ordinal;
                await PagesApi.insertBlank(jobId, partValue, newOrdinal);
                const response = await PagesApi.getPartPages(jobId, partValue, { first: newOrdinal, last: newOrdinal });
                dispatch(setPartPageAtOrdinal({ ordinal: newOrdinal, part: partValue, pages: response.data }));
                break;
            }
            case "insert_blank_after": {
                if (!partValue) return;
                const newOrdinal = page.Ordinal + 1;
                await PagesApi.insertBlank(jobId, partValue, newOrdinal);
                const response = await PagesApi.getPartPages(jobId, partValue, { first: newOrdinal, last: newOrdinal });
                dispatch(setPartPageAtOrdinal({ ordinal: newOrdinal, part: partValue, pages: response.data }));
                break;
            }
            case PageReviewStatus.NOT_REVIEWED:
            case PageReviewStatus.APPROVED:
            case PageReviewStatus.REJECTED: {
                if (!partValue) return;
                const { isConfirmed } = await Swal.fire({
                    icon: "warning",
                    title: "Please Confirm",
                    text: `Are you sure you want to change the approval status of ${isMultiple ? "these pages" : "this page"}?`,
                    showCancelButton: true,
                    cancelButtonText: "No",
                    confirmButtonText: `Yes, change the approval status!`,
                    reverseButtons: true
                })
                if (!isConfirmed) return;
                dispatch(updateSelectedPagesApproval({ part: partValue, value: contextId }));
                break;
            }
            case "duplicate": {
                if (!partValue) return;
                dispatch(duplicateSelectedPages(partValue))
                break;
            }
            case "bod_page": {
                if (!partValue) return;
                const { isConfirmed } = await Swal.fire({
                    icon: "warning",
                    title: "Please Confirm",
                    // TODO - confirm *ordinal* is the value that needs to be shown here below. 
                    // and confirm I am obtaining it right.
                    // There are times here where the used value looks kind of odd. (i.e. select page labelled 18(folio?) on the UI and 'are you sure you want to set page 20 as BOD?', etc.)
                    // I would've thought folio was preferred here, but maybe not.
                    // Regarding what the original implementation does:
                    // Look at angular code, "setBODPage(selectedPageIndices[0] + 1);" (in app\scripts\Organize.ts)
                    // - looks like it's the ordinal page number, since its (${page index} + 1). 

                    // TODO - the 'Unset' language is displayed, but the UI doesn't currently support a direct 'unset' - the dialog fires the same for 'set' and 'unset'. 
                    // change one or the other to correct this.
                    text: `Are you sure you want to set page ${page.Ordinal} as the BOD page?`,
                    showCancelButton: true,
                    cancelButtonText: "No",
                    confirmButtonText: "Yes",
                    reverseButtons: true
                })
                if (!isConfirmed) return;
                const isBOD = (page.Ordinal === job?.BODPage && partValue === "text");
                const response = await JobApi.patch(jobId, { BODPage: isBOD ? null : page.Ordinal });
                dispatch(setJobData(response.data))
                break;
            }
            case "upload": {
                dispatchCustomEvent("upload-modal-open", { part: partValue })
                break;
            }
            default: {
                if (!partValue) return;
                const partItem = getPart(contextId);
                if (partItem) {
                    dispatch(moveToPart({ from: partValue, to: partItem.part }))
                }
            }
        }
    }, [jobIsActive, selectedPages, partValue, page.Ordinal, page.PageID, jobId, job?.BODPage, contextDownloadHandler, dispatch, gotoPageView, page.Folio, pages, partIsLocked])

    const contextMenuOptions = useMemo(() => {
        const isMultiple = selectedPages.length > 1;
        const issueExists = (pageErrors + pageWarnings) > 0;
        const isBOD = (page.Ordinal === job?.BODPage && partValue === "text");
        const isShelf = partValue === PagePart.UnassignedCorrections;
        return [
            { title: "Part", id: "part", children: getPartContextOptions([partValue as string]) },
            {
                title: "Approval", id: "approval", children: [
                    { title: `Pending ${(!isMultiple && `${page.Tags.ReviewStatus}` === PageReviewStatus.NOT_REVIEWED) ? "✔" : ""}`, id: PageReviewStatus.NOT_REVIEWED },
                    { title: `Approved ${(!isMultiple && `${page.Tags.ReviewStatus}` === PageReviewStatus.APPROVED) ? "✔" : ""}`, id: PageReviewStatus.APPROVED, disabled: issueExists },
                    { title: `Rejected ${(!isMultiple && `${page.Tags.ReviewStatus}` === PageReviewStatus.REJECTED) ? "✔" : ""}`, id: PageReviewStatus.REJECTED },
                ],
                hidden: isShelf
            },
            { title: "Delete", id: "delete" },
            { title: "Crop", id: "crop", disabled: isMultiple, hidden: isShelf },
            {
                title: "Insert blank", id: "insert_blank", children: [
                    { title: "Before selected page", id: "insert_blank_before" },
                    { title: "After selected page", id: "insert_blank_after" }
                ],
                hidden: isShelf
            },
            { title: `Folio${isMultiple ? "s" : ""}`, id: `folio${isMultiple ? "s" : ""}` },
            { title: "Duplicate", id: "duplicate", hidden: isShelf },
            { title: "Upload", id: "upload" },
            {
                title: "Download", id: "download", children: [
                    { title: "Low-res", id: "download_low_res" },
                    { title: "High-res", id: "download_high_res", disabled: issueExists }
                ],
                hidden: isShelf
            },
            {
                title: "Select", id: "select", children: [
                    { title: "Select all", id: "select_all" },
                    { title: "Select to last", id: "select_to_last" }
                ]
            },
            { title: `${isBOD ? "Unset" : "Set"} as BOD page`, id: "bod_page", disabled: partValue !== "text", hidden: isShelf }
        ]
    }, [partValue, selectedPages, pageErrors, pageWarnings, page.Ordinal, job?.BODPage, page.Tags.ReviewStatus])

    const pageWidth = useMemo(() => {
        // return (size ? size * 0.66 : 0) + 66
        return size;
    }, [size])

    const fontSize = useMemo(() => {
        const maxFontSize = 13;
        const val = 9 + (size ? size * 0.01 * 9 : 0)
        return val > maxFontSize ? maxFontSize : val;
    }, [size])

    const canDragThumbCheck = (event: React.DragEvent<HTMLImageElement>) => {
        // Reject drag operations if job is not active:
        if (!jobIsActive || partIsLocked) {
            event.preventDefault();
        }
    }

    return <>
        {multiFolioOn ? <MultiFolioModal state={multiFolioOn} setState={setMultiFolioOn} /> : null}
        {
            contextOn ?
                <Foco onClickOutside={() => setContextOn(false)} className={classNames({ "is-hidden": !contextOn })}>
                    <ContextMenu pos={pos} items={contextMenuOptions} contextMenuClick={contextMenuHandler} />
                </Foco> : null
        }
        <div title={page.Folio} onContextMenu={openContextMenuHandler} onDoubleClick={gotoPageView} onClick={selectHandler} ref={(el) => { setRef(el); drop(el) }} className={classNames("is-flex is-flex-direction-column mb-1", { "is-hidden": !visible })} style={{ width: "auto", zIndex: 99 }}>
            <div className="is-flex is-flex-direction-row is-justify-content-center m-1 is-flex-grow-1">
                {
                    (isVisible && !isUnavailable) ?
                        <div onDragStart={canDragThumbCheck} ref={dragRef} className={classNames("is-align-self-center", {
                            "outline-3-link": selected,
                            "outline-3-danger": (selected && (partValue === PagePart.UnassignedCorrections ? shelfLoading : loading)),
                            "darken": isOver
                        })}>
                            <PagePreview page={page as Page} scale={size * PAGE_SCALE_THRESHOLD} />
                        </div>
                        :
                        <img src="/spinner.svg" alt="" draggable={false} width={size} />
                }
            </div>
            <div className="has-text-centered" style={{ overflow: "hidden", width: "100%", //pageWidth,
            textOverflow: "ellipsis" }}>
                <span style={{ overflow: "hidden", whiteSpace: "pre", fontSize }}>
                    {page.Folio}
                </span>
            </div>
            <div className="review-tags-wrapper constrain-tag-height-to-align-page-tops has-text-centered">
                &zwj;
                {/* &nbsp; */}
                {
                    page.Tags.ReviewStatus === PageReviewStatus.APPROVED && <div className="tag is-success has-text-white is-small is-rounded" style={{ fontSize }}><FaCheck size={fontSize} /></div>
                }
                {
                    page.Tags.ReviewStatus === PageReviewStatus.REJECTED && <div className="tag is-danger has-text-white is-small is-rounded" style={{ fontSize }}><FaTimes size={fontSize} /></div>
                }
            </div>
            {
                partValue !== PagePart.UnassignedCorrections &&
                <div className="issue-tags-wrapper constrain-tag-height-to-align-page-tops is-flex is-justify-content-center">
                    {/* &nbsp; */}
                    &zwj;
                    {
                        (page.Ordinal === (job && job.BODPage) && partValue === "text") &&
                        <div className="tag has-background-grey-light" style={{ paddingLeft: 6, paddingRight: 6, fontSize }}>
                            BOD
                        </div>
                    }
                    {
                        (pageWarnings > 0) &&
                        <div className="tag is-warning is-rounded" style={{ paddingLeft: 6, paddingRight: 6, fontSize }}>
                            {pageWarnings}W
                        </div>
                    }
                    {
                        (pageErrors > 0) &&
                        <div className="tag is-danger is-rounded" style={{ paddingLeft: 6, paddingRight: 6, fontSize }}>
                            {pageErrors}E
                        </div>
                    }
                </div>
            }

        </div>
    </>
}

function areEqual(prevProps: PartPageThumbnailType, nextProps: PartPageThumbnailType) {
    return isEqual(prevProps, nextProps);
}

export default React.memo(PartPageThumbnail, areEqual)
