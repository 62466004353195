import Ruler, { Line, snapLine } from '@apagoinc/react-ruler';
import { Area, Box, Page } from '../_types/LSCScout.type';
import React, {
	CSSProperties,
	Dispatch,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';
import classNames from 'classnames';
import rectToBox from '../_utils/rectToBox';
import drawAnnotationOnCanvas from '../_utils/drawAnnotationOnCanvas';
import parseBox from '../_utils/parseBox';
import convertUnits, { unitShortNames } from '../_utils/convertUnits';
import useBoxDraw from '../_hooks/useBoxDraw';
import useDraw from '../_hooks/useDraw';
import useScaleAmt from '../_hooks/useScaleAmt';
import usePageGroupDimensions from '../_hooks/usePageGroupDimensions';
import styled from 'styled-components';
import useBoxes from '../_hooks/useBoxes';
import outset from '../_utils/outsetBox';
import inset from '../_utils/insetBox';
import lineRoundToGrid from '../_utils/lineToGrid';

import PageDataContext from './PageViewContext';
import { PanZoomContext } from './PanZoom';
import PagePreview from './PagePreview';
import styles from './PreflytAnnotations.module.scss';

interface PreflytAnnotationsProps {
	page: Page;
	showWarnings: boolean;
	showErrors: boolean;
	showBounds: boolean;
	openWarnings: string[];
	openErrors: string[];
	highlightedArea?: Area;
	selectedArea?: Area;
	setSelectedArea: Dispatch<SetStateAction<Area | undefined>>;
	className?: string;
	position: 'left' | 'right';
}

const VertLine = styled.div<{ x: number; color: string; borderWidth: number }>`
	position: absolute;
	left: ${props => props.x - props.borderWidth / 2}px;
	top: 0;
	width: 0px;
	height: 100%;
	box-sizing: border-box;
	border-left: ${props => props.borderWidth}px dashed ${props => props.color};
`;

const HorizLine = styled.div<{ y: number; color: string; borderWidth: number }>`
	position: absolute;
	left: 0;
	top: ${props => props.y - props.borderWidth / 2}px;
	width: 100%;
	height: 0px;
	box-sizing: border-box;
	border-top: ${props => props.borderWidth}px dashed ${props => props.color};
`;

const TrimBoxContainer = styled.div<{ margin: number; padding: number }>`
	position: absolute;
	width: calc(100% - ${props => props.margin * 2}px);
	height: calc(100% - ${props => props.margin * 2}px);
	left: 0;
	top: 0;
	margin: ${props => props.margin}px;
	padding: ${props => props.padding}px;
`;

const TrimBoxContext = React.createContext<{ padding: number }>(
	undefined as any
);

function useBoxDimensions(box: Box) {
	const { canvasScale, bumpOffset } = useContext(PageDataContext);
	const { padding } = useContext(TrimBoxContext);
	const { current } = useBoxes();

	const [bumpX, bumpY] = bumpOffset;

	const x = (-current.x + box.x + padding + bumpX) * canvasScale;
	const y = -(current.y + -(box.y + padding) + bumpY) * canvasScale;
	const width = box.width * canvasScale;
	const height = box.height * canvasScale;

	return { x, y, width, height };
}

function CrossHatchBox({ box, color }: { box: Box; color: string }) {
	const { x, y, width, height } = useBoxDimensions(box);

	return (
		<>
			<VertLine borderWidth={5} x={x} color={color} />
			<HorizLine borderWidth={5} y={y} color={color} />
			<VertLine borderWidth={5} x={x + width} color={color} />
			<HorizLine borderWidth={5} y={y + height} color={color} />
		</>
	);
}

const Rect = styled.div<{
	x: number;
	y: number;
	width: number;
	height: number;
	borderWidth: number;
	color: string;
}>`
	position: absolute;
	left: ${props => props.x}px;
	top: ${props => props.y}px;
	width: ${props => props.width}px;
	height: ${props => props.height}px;
	box-sizing: border-box;
	outline: ${props => props.borderWidth}px dashed ${props => props.color};
`;

function RectBox({ box, color }: { box: Box; color: string }) {
	const dims = useBoxDimensions(box);

	return <Rect {...dims} borderWidth={5} color={color} />;
}

function TrimBoxes({ padding, margin }: { padding: number; margin: number }) {
	const { current } = useBoxes();
	const { canvasScale } = useContext(PageDataContext);

	const bleed = outset(current, convertUnits('inches', 'points', 0.125));
	const safety = inset(current, convertUnits('inches', 'points', 0.25));

	return (
		<TrimBoxContext.Provider value={{ padding }}>
			<TrimBoxContainer
				padding={padding * canvasScale}
				margin={margin * canvasScale}
			>
				<CrossHatchBox box={current} color="green" />
				<CrossHatchBox box={bleed} color="blue" />
				<RectBox box={safety} color="orange" />
			</TrimBoxContainer>
		</TrimBoxContext.Provider>
	);
}

export default function PreflytAnnotations(props: PreflytAnnotationsProps) {
	const boundsCanvasRef = useRef<HTMLCanvasElement>(null);
	const annotationsCanvasRef = useRef<HTMLCanvasElement>(null);
	const newRulerDrawDiv = useRef<HTMLDivElement>(null);

	const { scale: zoomScale } = useContext(PanZoomContext);

	const {
		page,
		showBounds,
		showWarnings,
		showErrors,
		openWarnings,
		openErrors,
		highlightedArea,
		selectedArea,
		setSelectedArea,
		position
	} = props;

	const { width, height } = page.MediaBox;

	const {
		bumpOffset,
		lines,
		setLines,
		setAddRulerMode,
		selectedUnits,
		addRulerMode,
		showMeasurements,
		selectedTab,
		spreadMode
	} = useContext(PageDataContext);

	const { job, canvasScale } = useContext(PageDataContext);

	const bleedSize = useMemo(
		() => convertUnits('inches', 'points', 0.125),
		[]
	);

	const safetySize = useMemo(
		() => convertUnits('inches', 'points', 0.25),
		[]
	);

	const { current: trim } = useBoxes();

	useEffect(() => {
		const { current } = boundsCanvasRef;

		if (!current) return;

		const ctx = current.getContext('2d');

		if (!ctx) return;

		ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

		if (!page.Scale) throw new Error('Scale is undefined');
		else if (!page.Scale.TargetDimensions)
			throw new Error('Scale.TargetDimensions is undefined');

		if (showWarnings) {
			const warnings = page?.Workflow?.Preflyt1?.Warnings || [];

			for (const warning of warnings) {
				if (!openWarnings.includes(warning.RuleID)) continue;
				for (const hit of warning.Hits || []) {
					const { Location } = hit;
					if (Location) {
						const box: Box = {
							x: +Location.llx,
							y: +Location.lly,
							width: +Location.urx - +Location.llx,
							height: +Location.ury - +Location.lly
						};
						drawAnnotationOnCanvas(ctx, box, canvasScale, {
							x: -trim.x,
							y: -trim.y
						});
					} else {
						drawAnnotationOnCanvas(
							ctx,
							parseBox(page.MediaBox),
							canvasScale
						);
					}
				}
			}
		}

		if (showErrors) {
			const errors = page?.Workflow?.Preflyt1?.Errors || [];

			for (const error of errors) {
				if (!openErrors.includes(error.RuleID)) continue;
				for (const hit of error.Hits || []) {
					const { Location } = hit;
					if (Location) {
						const box: Box = {
							x: +Location.llx,
							y: +Location.lly,
							width: +Location.urx - +Location.llx,
							height: +Location.ury - +Location.lly
						};
						drawAnnotationOnCanvas(ctx, box, canvasScale, {
							x: -trim.x,
							y: -trim.y
						});
					}
				}
			}
		}

		if (highlightedArea) {
			const box = rectToBox(highlightedArea);
			drawAnnotationOnCanvas(
				ctx,
				box,
				canvasScale,
				undefined,
				'orange',
				true
			);
		}
	}, [
		page,
		showBounds,
		showWarnings,
		showErrors,
		canvasScale,
		openWarnings,
		openErrors,
		highlightedArea,
		bumpOffset,
		bleedSize,
		trim.x,
		trim.y
	]);

	const drawnArea = useBoxDraw(annotationsCanvasRef, canvasScale);

	useEffect(() => setSelectedArea(drawnArea), [drawnArea, setSelectedArea]);

	useEffect(() => {
		const { current } = annotationsCanvasRef;

		if (!current) return;

		const ctx = current.getContext('2d');

		if (!ctx) return;

		ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

		if (!selectedArea) return;

		drawAnnotationOnCanvas(
			ctx,
			rectToBox(selectedArea),
			canvasScale,
			undefined,
			'orange',
			true
		);
	}, [canvasScale, selectedArea]);

	const setByIndex: (
		i: number
	) => Dispatch<SetStateAction<Line>> = useCallback(
		(i: number) => (action: SetStateAction<Line>) => {
			setLines(l => {
				if (typeof action === 'function') l[i] = action(l[i]);
				else l[i] = action;
				return [...l];
			});
		},
		[setLines]
	);

	const scaleAmt = useScaleAmt();

	// multiple of points - used to determine grid
	// not used currently, may be used in the future
	const [snapMultiplier] = useState(1);

	const displayScale = zoomScale * scaleAmt;

	const totalScale = displayScale * canvasScale;

	const grid: [number, number] = useMemo(
		() => [totalScale * snapMultiplier, totalScale * snapMultiplier],
		[snapMultiplier, totalScale]
	);

	const [{ current, final, shiftKey }, clearNewRulers] = useDraw(
		newRulerDrawDiv,
		{
			x: totalScale,
			y: totalScale
		}
	);

	const correctedCurrent = useMemo(() => {
		if (!current) return current;
		else {
			const [start, end] = current;
			if (shiftKey) {
				const snapped = snapLine({ start, end }, 'end');

				const rounded = lineRoundToGrid(snapped, [1, 1]);

				return rounded;
			} else return { start, end };
		}
	}, [current, shiftKey]);

	const correctedFinal = useMemo(() => {
		if (!final) return final;
		else {
			const [start, end] = final;
			if (shiftKey) {
				const snapped = snapLine({ start, end }, 'end');

				const rounded = lineRoundToGrid(snapped, [1, 1]);

				return rounded;
			} else return { start, end };
		}
	}, [final, shiftKey]);

	useEffect(() => {
		if (correctedFinal) {
			setLines(l => [...l, correctedFinal]);
			clearNewRulers();
			setAddRulerMode(false);
		}
	}, [clearNewRulers, correctedFinal, final, setAddRulerMode, setLines]);

	return (
		<div
			style={{
				top: 0,
				[position]: 0
			}}
			className={styles.PageContainer}
		>
			<PagePreview
				alt={`${job.Title} page ${page.Folio}`}
				page={page}
				scale={canvasScale}
			/>
			<canvas
				style={{
					bottom: `${bleedSize * canvasScale}px`,
					left: `${bleedSize * canvasScale}px`,
					width: +width * canvasScale,
					height: +height * canvasScale
				}}
				width={+width * canvasScale}
				height={+height * canvasScale}
				className={styles.Canvas}
				ref={boundsCanvasRef}
			/>
			<canvas
				style={{
					bottom: `${bleedSize * canvasScale}px`,
					left: `${bleedSize * canvasScale}px`,
					width: +width * canvasScale,
					height: +height * canvasScale
				}}
				width={+width * canvasScale}
				height={+height * canvasScale}
				className={styles.Canvas}
				ref={annotationsCanvasRef}
			/>
			<TrimBoxes padding={bleedSize * 2} margin={-bleedSize} />
			<div
				style={{
					display: !showMeasurements || spreadMode ? 'none' : 'block'
				}}
				className={styles.RulerContainer}
			>
				{lines.map((line, i) => (
					<Ruler
						grid={grid}
						dragHandleClassName={classNames(
							styles.InvisibleDragHandle,
							'panzoom-exclude'
						)}
						scaleFactor={canvasScale}
						onChange={setByIndex(i)}
						handleClassName={classNames(
							styles.DragHandle,
							'panzoom-exclude'
						)}
						lineClassName={styles.Line}
						labelClassName={classNames(
							styles.Label,
							'panzoom-exclude'
						)}
						key={i}
						transformScale={displayScale}
						label={ptWidth =>
							`${parseFloat(
								convertUnits(
									'points',
									selectedUnits,
									ptWidth
								).toFixed(4)
							)}${unitShortNames[selectedUnits]}`
						}
						{...line}
					/>
				))}
				<div
					style={{
						pointerEvents: 'none',
						display:
							!showMeasurements || spreadMode ? 'none' : 'block'
					}}
				>
					{correctedCurrent && (
						<Ruler
							grid={grid}
							dragHandleClassName={classNames(
								styles.InvisibleDragHandle,
								'panzoom-exclude'
							)}
							scaleFactor={canvasScale}
							handleClassName={classNames(
								styles.DragHandle,
								'panzoom-exclude'
							)}
							lineClassName={styles.Line}
							labelClassName={classNames(
								styles.Label,
								'panzoom-exclude'
							)}
							transformScale={zoomScale * scaleAmt}
							label={ptWidth =>
								`${parseFloat(
									convertUnits(
										'points',
										selectedUnits,
										ptWidth
									).toFixed(4)
								)}${unitShortNames[selectedUnits]}`
							}
							{...correctedCurrent}
						/>
					)}
				</div>
			</div>
			<div
				style={{
					display:
						selectedTab === 'Scale/Crop' &&
						addRulerMode &&
						showMeasurements &&
						!spreadMode
							? 'block'
							: 'none'
				}}
				ref={newRulerDrawDiv}
				className={classNames(
					styles.NewRulerDrawArea,
					'panzoom-exclude'
				)}
			>
				<h3 className={styles.Hint}>Click and drag</h3>
			</div>
		</div>
	);
}

interface PanZoomWrapProps {
	className?: string;
	mouseEvents?: boolean;
	cursor?: CSSProperties['cursor'];
}

export function PanzoomWrap(props: React.PropsWithChildren<PanZoomWrapProps>) {
	const { className, mouseEvents, cursor, children } = props;

	const { canvasScale } = useContext(PageDataContext);

	const scaleAmt = useScaleAmt();

	const { width, height } = usePageGroupDimensions();

	return (
		<div
			className={classNames(styles.PageGroupWrap, className, {
				'panzoom-exclude': !mouseEvents
			})}
			style={{
				cursor,
				width: width * canvasScale,
				height: height * canvasScale,
				transform: `translate(-50%, -50%) scale(${scaleAmt})`
			}}
		>
			{children}
		</div>
	);
}
