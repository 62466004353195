import applyAction from '../_utils/applyAction';
import { RefObject, SetStateAction, useCallback } from 'react';

interface VendoredFullscreenableElement extends HTMLElement {
	mozRequestFullscreen: Element['requestFullscreen'];
	msRequestFullscreen: Element['requestFullscreen'];
	webkitRequestFullscreen: Element['requestFullscreen'];
}

export default function useFullscreen<T extends HTMLElement>(
	ref: RefObject<T>
) {
	const isFullscreen = !!document.fullscreenElement;

	const setFullscreen = useCallback(
		(action: SetStateAction<boolean>) => {
			const targetState = applyAction(isFullscreen, action);

			const current = ref.current as VendoredFullscreenableElement | null;

			if (!current) return;

			current.requestFullscreen =
				// eslint-disable-next-line @typescript-eslint/unbound-method
				current.requestFullscreen ||
				current.mozRequestFullscreen ||
				current.msRequestFullscreen ||
				current.webkitRequestFullscreen;

			if (!isFullscreen && targetState) void current.requestFullscreen();
			else if (isFullscreen && !targetState)
				void document.exitFullscreen();
		},
		[isFullscreen, ref]
	);

	return { isFullscreen, setFullscreen };
}
