import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../saga";
import authReducer from "./authSlice"
import searchReducer from "./searchSlice"
import jobReducer from "./jobSlice"
import pagesReducer from "./pagesSlice"
import adminReducer from "./adminSlice";
import { createMQTTMiddleware, ReduxMqttReducer } from "../mqtt"
import ReduxMqttHandler from "../mqtt/mqtt-redux";


const sagaMiddleware = createSagaMiddleware()
const mqttMiddleware = createMQTTMiddleware(ReduxMqttHandler, { debug: true });

export const store = configureStore({
    reducer: {
        auth: authReducer,
        search: searchReducer,
        job: jobReducer,
        pages: pagesReducer,
        mqtt: ReduxMqttReducer,
        admin: adminReducer
    },
    middleware: (getDefaultMiddlewares) => {
        return getDefaultMiddlewares().concat([mqttMiddleware, sagaMiddleware])
    }
})
sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch