import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IClientOptions, IDisconnectPacket } from "mqtt/dist/mqtt";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { cleanTopic, cleanTopics } from "./mqtt";

export type mqttState = {
    connected: boolean,
    error: string,
    subscriptions: Record<string, string[]>,
    sessionTakenOver: boolean // boolean flag to set true if this app instance's connection is lost due to another connection with the same 
    // client ID "taking over" the MQTT server connection for the given client ID
    userNotified: boolean // flag to set true after user is first notified of an MQTT connection change
}

const ALL_SUBS = "subs";

const initialState: mqttState = {
    connected: false,
    error: "",
    subscriptions: { subs: [] },
    sessionTakenOver: false,
    userNotified: false
}

const mqttSlice = createSlice({
    name: 'mqtt',
    initialState,
    reducers: {
        connectMQTT: (state, { payload }: PayloadAction<IClientOptions>) => {
            return;
        },
        disconnectMQTT: (state) => {
            return;
        },
        connectionSuccess: (state) => {
            state.connected = true;
            state.sessionTakenOver = false;
        },
        connectionLost: (state, { payload }: PayloadAction<number | undefined>) => {
            state.userNotified = false;
            const disconnectReasonCode = payload;
            state.connected = false;
            if (disconnectReasonCode === 142) {
                state.sessionTakenOver = true;
            }
        },
        setUserNotifiedOfConnectionChange: (state, { payload }: PayloadAction<boolean>) => {
            state.userNotified = payload;
        },
        connectionError: (state, payload) => {
            return;
        },
        subscribeChannel: (state, { payload }: PayloadAction<{ topic: string | string[], label?: string }>) => {
            const { topic, label } = payload;
            const topicLabel = label || ALL_SUBS;
            const topicVal = topic
            if (!state.subscriptions[topicLabel]) state.subscriptions[topicLabel] = [];
            if (Array.isArray(topicVal)) state.subscriptions[topicLabel].push(...topicVal);
            else state.subscriptions[topicLabel].push(topicVal);
        },
        unsubscribeChannel: (state, { payload }: PayloadAction<{ topic?: string, label?: string }>) => {
            const { topic, label } = payload;
            if (!topic && !label) return;
            if (topic) {
                state.subscriptions[ALL_SUBS] = state.subscriptions[ALL_SUBS].filter(t => t !== topic);
            } else if (label) {
                state.subscriptions[label] = [];
            }
        }

    }
})

export const useMqttState = () => useSelector((state: RootState) => state.mqtt)

export const mqttActions = mqttSlice.actions;
export const ReduxMqttReducer = mqttSlice.reducer;