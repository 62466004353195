import classNames from 'classnames';
import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

import styles from './FullscreenSpinner.module.scss';

interface FullscreenSpinnerProps {
	active: boolean;
}

export default function FullscreenSpinner({ active }: FullscreenSpinnerProps) {
	return (
		<div
			className={classNames(styles.FullscreenSpinner, {
				[styles.Active]: active
			})}
		>
			<div className={styles.Spinner}>
				<PulseLoader color="white" />
			</div>
		</div>
	);
}
