import { ContextMenuItemType } from "../components/ContextMenu";
import customFetch from "../lib/axiosInstance";
import { store } from "../store";
import { Job } from "../types/job.types";
import { PagePart, Part, PartTree, TopLevelPart } from "../types/pages.types"
import { pagesUnapproved } from "./page";

let localPartTree: PartTree = []
const partMap: Map<string, Part> = new Map();
const partOrdinal: Map<string, number> = new Map();

export const setPartTree = (partTree: PartTree) => {
    localPartTree = partTree;
    partTree.forEach((part, index) => {
        partOrdinal.set(part.part, index)
        partMap.set(part.part, part);
        if (part.variants) {
            part.variants.forEach((variant, i) => {
                partOrdinal.set(variant.part, Number(`${index}.${i + 1}`))
                partMap.set(variant.part, variant)
            })
        }
    })
}

export const getPartTree = () => localPartTree;

export const initPartTree = async () => {
    try {
        const res = await customFetch.get("/job/containerspec")
        setPartTree(res.data)
    } catch (err) {
        console.error(err);
    }
}

export const getPart = (part: string) => {
    return partMap.get(part);
}

export const sortParts = (a: PagePart | string, b: PagePart | string) => {
    const ordinalA = partOrdinal.get(a);
    const ordinalB = partOrdinal.get(b);
    if (typeof ordinalA === "number" && typeof ordinalB == "number") return ordinalA - ordinalB;
    return 0;
}

/**
 * Recursively creates context items for a part context menu.
 * @param partArr - array of parts to generate context items from.
 * @param excludeArr - array of part short names - parts with a part short name in this list will be excluded from the context menu.
 * @param isVariant - flag that will be true whenever this method is called recursively for variants/treatments.
 * @returns - an array of context menu items. Menu items that have a `children` property may include nested menu items.
 */
const generateContextItemFromPart: any = (partArr: TopLevelPart[], excludeArr?: string[], isVariant?: boolean) => {
    return partArr
        .filter(part => {
            return ((isVariant && excludeArr) ? !excludeArr.includes(part.part) : true);
        })
        .map(part => {
            return {
                title: part.name,
                id: part.part,
                children: (part.variants ? generateContextItemFromPart(part.variants, excludeArr, true) : undefined)
            }
        });
}

export const getPartContextOptions = (exclude?: string[]) => {
        
    const partTree = getPartTree()
    
    // first, filter out any "top-level" parts that need to be excluded from `partOptions`:
    const topLevelFilteredPartTree = partTree.filter(part => !(exclude && exclude.includes(part.part)))
    const partOptions: ContextMenuItemType[] = topLevelFilteredPartTree.map(part => ({ title: part.name, id: part.part }))
    
    // then, create context options for part "variants"/treatments, and pass down the exclude array to 
    // exclude individual variants as needed
    const variantOptions: ContextMenuItemType[] = generateContextItemFromPart(partTree.filter(part => part.variants), exclude)

    const contextMenuOptions = [
        { title: "PRINT COMPONENTS", id: "print-components", divider: true },
        ...partOptions,
        { title: "TREATMENTS", id: "treatments", divider: true },
        ...variantOptions
    ]
    return contextMenuOptions;
}

/** Get "Tags" data for a given part of a job based on the part's current Stage, as obtained
 * from `job.Stage[part].Stage`.  
 * Can be used to report on a part's progress in a given processing "Stage".
 */
export const getPartTagsDataByCurrentStage = (job: Job, part: PagePart | string): partTagsDataByStage | undefined => {
    const partCurrentStage: string | undefined = job.Stage?.[part]?.Stage
    if (partCurrentStage) {
        const partCurrentTagsDataForStage: partTagsDataByStage | undefined = job.Tags?.[partCurrentStage]?.[part]
        return partCurrentTagsDataForStage;
    }
    return undefined;
}

type partTagsDataByStage = {
    Progress?: {
        percent?: number,
        stage?: string, // Different from the top-level job "Stage" property
    },
    Status?: string,
}