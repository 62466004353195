import { SearchState } from "../types/store.types";
import { IsbnTypes, SearchSortBy, SearchStages, SearchStatus, SearchTitleTypes } from "../types/types";
import isbn3 from "isbn3"
import JobApi from "../lib/job";
import parseLefDate from "./parseLefDate";
import { emailRegex } from "./constants";
import { PdfFileType } from "../components/Upload/UploadModal";
import { dummyIsbn10_RegEx, dummyIsbn13_RegEx } from "./constants";
import { Job as LegacyJobType } from "../components/PageView/_types/LSCScout.type"



export const generateStatusCheckQuery = (...values: string[]) => {
    const obj: any = { $or: [] };
    for (const value of values) {
        obj.$or.push({ Status: { $eq: value } })
    }
    return obj;
}

export const generateQueryQuery = (query: string, ...values: string[]) => {
    const obj: any = { $or: [] };
    for (const value of values) {
        obj.$or.push({ [value]: { $regex: query, $options: "i" } })
    }
    return obj;
}


export const generateCondition = (searchData: SearchState) => {
    const base: Record<string, any> = {
        conditions: {}
    };
    // generate title type query
    base.myjobs = searchData.titleType === SearchTitleTypes.MY_TITLES ? true : false;

    // generate sortBy query
    if (searchData.sortBy === SearchSortBy.ISBN) {
        base.conditions.$orderby = { ISBN10: 1, ISBN13: 1 }
    } else {
        base.conditions.$orderby = {
            [searchData.sortBy]: searchData.sortBy === SearchSortBy.TITLE ? 1 : -1
        }
    }

    // generate status | stage query
    const queries = []

    if (searchData.query) {
        let query = {}
        query = generateQueryQuery(searchData.query, "Title", "Author", "ISBN10", "ISBN13", "CustomerReference", "Tags.SystemJobNumber")
        queries.push(query);
    }

    let statusQuery = {}
    let isStageQuery = false;
    switch (searchData.status) {
        case SearchStatus.ACTIVE:
            statusQuery = generateStatusCheckQuery("active", "processing", "error", "finalized");
            break;
        case SearchStatus.DELETED:
            statusQuery = generateStatusCheckQuery("deleted", "deleting");
            break;
        case SearchStatus.ERROR:
            statusQuery = generateStatusCheckQuery("error");
            break;
        case SearchStatus.LOCKED:
            statusQuery = generateStatusCheckQuery("locked");
            break;
        case SearchStatus.REQUESTED:
            statusQuery = generateStatusCheckQuery("requested");
            break;
        case SearchStages.PREFLIGHT_ISSUES:
        case SearchStages.COMPONENT_COMPLETE:
        case SearchStages.FINAL_APPROVAL_REQUIRED:
        case SearchStages.PROOF_APPROVAL_REQUIRED:
        case SearchStages.REJECTED_PAGES:
        case SearchStages.TITLE_APPROVED_TO_PRINT:
            statusQuery = searchData.status;
            isStageQuery = true;
            break;
        default:
            statusQuery = {}
    }
    queries.push(statusQuery);

    base.conditions.$query = isStageQuery ? statusQuery : { $and: queries };
    base.keys = [
        "Title",
        "CustomerReference",
        "Status",
        "JobID",
        "Pages",
        "PartsList",
        "Created",
        "Modified",
        "Stage",
        "Tags",
        "Progress",
        "Type",
        "Account",
        "ISBN10",
        "ISBN13",
        "CustomerReference",
        "Author"
    ]

    base.first = (searchData.pageNumber - 1) * searchData.pageSize;
    base.last = base.first + searchData.pageSize;
    return base;
}

export const capitalizeText = (txt: string) => {
    const lowerTxt = txt.toLowerCase();
    const caplizedTxt = [];
    for (const str of lowerTxt.split(" ")) {
        caplizedTxt.push(str.charAt(0).toUpperCase() + str.slice(1));
    }
    return caplizedTxt.join(" ")
}

export const snakeCaseToNormal = (txt: string) => {
    return txt.replace(/_/g, " ");
}

export const isbnTrim = (isbn: string) => {
    return isbn.replace(/\s/g, "");
}

export const isbnValidator = async (isbn: string, type: IsbnTypes, existence = true) => {
    const existsMsg = "ISBN already exists in the database.";

    let dummy = false;
    let dummyIsbnType: IsbnTypes | undefined;

    const isbnString = `${isbn}`;
    if (!isbnString) return false;

    if ((/\s/).test(isbnString)) return "ISBN can not contain spaces";

    if (dummyIsbn10_RegEx.test(isbnString)) {
        dummy = true;
        dummyIsbnType = IsbnTypes.ISBN10
    }
    if (dummyIsbn13_RegEx.test(isbnString)) {
        dummy = true;
        dummyIsbnType = IsbnTypes.ISBN13
    }

    const isbnObj = !dummy ? isbn3.parse(isbn) : dummyIsbnObjFactory(dummyIsbnType)

    if (!isbnObj) return "Invalid ISBN";
    else if (type === IsbnTypes.ISBN10 && isbnObj.isIsbn10) {
        if (!existence) return true;
        const exists = await JobApi.isbnExists(isbn, type);
        return exists ? existsMsg : true;
    }
    else if (type === IsbnTypes.ISBN13 && isbnObj.isIsbn13) {
        if (!existence) return true;
        const exists = await JobApi.isbnExists(isbn, type);
        return exists ? existsMsg : true;
    }
    return `Invalid ${type}`
}

/** Function using an adapted form of the isbnValidator logic that only confirms whether or not the passed value is an ISBN.  
 *  I.e. the function returns true for both valid and dummy isbns, and false for all other inputs.  
 *  Unlike isbnValidator, it runs synchronously since it does not call the backend to check if the isbn exists. */
export const isAnIsbn = (isbn: string) => {
    const isbnString = `${isbn}`;
    if (!isbnString) return false;
    if (dummyIsbn10_RegEx.test(isbnString) || dummyIsbn13_RegEx.test(isbnString)) {
        return true;
    }
    return isbn3.parse(isbn) ? true : false;
}

/**
 * Method that checks to see if a string is an accepted (valid or dummy) ISBN, and then tries to infer the type of an ISBN.
 * @param {string} potentialIsbn - value to test for whether or not it is an ISBN
 * @returns {IsbnTypes|undefined}
 * `IsbnTypes` - enum value for the inferred type of a valid ISBN.  
 * `undefined` - for an invalid(not dummy or valid) ISBN - or for if somehow, the valid ISBN is not of length 10 or 13 (should be impossible)
 */
export const tryInferIsbnOfType = (potentialIsbn: string): IsbnTypes | undefined => {
    const isbn = isbnTrim(potentialIsbn);
    if (isAnIsbn(isbn)) {
        // isbn is confirmed valid at this point - just need length to 'type' it:
        const isbnLen = isbn.length
        if (isbnLen === 13) return IsbnTypes.ISBN13;
        else if (isbnLen === 10) return IsbnTypes.ISBN10;
    }
    return undefined;
}

export const customerReferenceValidator = (reference: string) => {
    const customerReferenceRE = /^[a-zA-Z0-9\s-]+$/;
    if (customerReferenceRE.test(reference)) return true;
    return "Input must be alpha-numeric"
}

export const getReadableDate = (date: string | Date) => {
    try {
        const dateObj = parseLefDate(date);
        return dateObj.toString()
    } catch (e) {
        return ""
    }
}

export const sortByOrdinal = (a: PdfFileType, b: PdfFileType) => a.ordinal - b.ordinal

export const sortObject = <T>(prop: keyof T, desc = false) => (a: T, b: T) => {
    const first = a[prop];
    const second = b[prop];
    if (typeof first === "number" && typeof second === "number") return desc ? second - first : first - second;
    else return `${first}`.localeCompare(`${second}`);
}

export const sortObjectByDeepProp = <T>(prop: string, desc = false) => (a: T, b: T) => {
    const props = prop.split(".") as (keyof T)[];
    let first: any = a;
    let second: any = b;
    for (const p of props) {
        if (first[p]) first = first[p];
        if (second[p]) second = second[p];
    }
    if (typeof first === "number" && typeof second === "number") return desc ? second - first : first - second;
    else return `${first}`.localeCompare(`${second}`);
}

export const dispatchCustomEvent = (eventName: string, data?: any) => {
    const event = new CustomEvent(eventName, { detail: data });
    dispatchEvent(event);
}

export const emailValidator = (emailStr: string): boolean => {
    return emailRegex.test(emailStr)
}

export const getKeyOfType = <T extends { [k: string]: any }>(obj: T, key: string): keyof T | undefined => {

    type objKeys = keyof typeof obj

    const keyLookup = key as objKeys

    if (keyLookup) return keyLookup;

    throw Error(`key ${key} could not be found in the object's keys: ${Object.keys(obj)}`)
}

// Note that this can only set one of 3 primitive values (number|string|boolean) on an object.
export const setValueOfKeyForType = <T extends { [k: string]: any }>(obj: T, key: string, val: (number | string | boolean)) => {

    const objKey = getKeyOfType<T>(obj, key)
    if (objKey) {
        obj[objKey] = val as T[keyof T]
    }
}

export const dummyIsbnObjFactory = (dummyIsbnType: IsbnTypes | undefined): ISBNObject => {

    if (dummyIsbnType === undefined) {
        throw new Error('Cannot create dummy ISBN without specifying ISBN type.');
    }

    const dummyIsbnObj: ISBNObject = {
        source: 'dummy',
        isValid: true, // as confirmed by dummy regex
        isIsbn10: (dummyIsbnType === IsbnTypes.ISBN10),
        isIsbn13: (dummyIsbnType === IsbnTypes.ISBN13),
        prefix: 'dummy',
        group: 'dummy',
        publisher: 'dummy',
        article: 'dummy',
        check: 'dummy',
        isbn13: 'dummy',
        isbn13h: 'dummy',
        check10: 'dummy',
        check13: 'dummy',
        groupname: 'dummy',
        isbn10: 'dummy',
        isbn10h: 'dummy'
    }
    return dummyIsbnObj;
}

export const validateIsbnFormInput = async (isbnFormEntry: string, checkIfIsbnAlreadyExists: boolean): Promise<string | boolean> => {

    const isbn13ValidationResult = await isbnValidator(isbnFormEntry, IsbnTypes.ISBN13, checkIfIsbnAlreadyExists)
        .then((result) => {
            if (typeof result !== 'boolean') {
                return (result === "ISBN already exists in the database." ? result : false);
            }
            return result;
        })
        .catch(error => console.log(error))

    // The below case is when isbnValidator returns "ISBN already exists in the database"
    if (isbn13ValidationResult) return isbn13ValidationResult;

    if (!isbn13ValidationResult) {
        const isbn10ValidationResult = await isbnValidator(isbnFormEntry, IsbnTypes.ISBN10, checkIfIsbnAlreadyExists)
            .then((result) => {
                if (typeof result !== 'boolean') {
                    return (result === "ISBN already exists in the database." ? result : false);
                }
                return result;
            })
            .catch(error => console.log(error))

        if (isbn10ValidationResult) return isbn10ValidationResult;

        if (!isbn10ValidationResult) {
            return 'Value must be either a valid ISBN-13 or ISBN-10.';
        }
    }

    return true;

}

// Have to access deepest "Stage" property this way because job object in legacy PageView code is 
// typed with the legacy job type
export const checkLegacyJobTypePartLock = (legacyJobObj: LegacyJobType, part: string) => {
    const nestedPartProperty = legacyJobObj?.Stage?.[part]
    if (nestedPartProperty && typeof nestedPartProperty !== 'string') {
        if (nestedPartProperty.Stage === "Locked") {
            return true;
        }
    }
    return false;
}

// This same as checking if the website is running inside an iframe
export const isKeyCloak = () => {
    const params = new URLSearchParams(window.location.search);
    let keycloak = params.get('autolaunch')?.trim().toLowerCase() === 'true';
    if (!keycloak) {
        // check for IFRAME
        keycloak = window.location !== window.parent.location;
    }
    return keycloak;
}

export const stringToRegExp = (str: string, i = true) => {
    const regexpString = str.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&");
    return new RegExp(regexpString, `${i ? "i" : ""}`);
};

/**
 * Tries to obtain a reference to the div containing the groove widget iframe.
 * @returns Groove widget div if found, or undefined if not found.
 */
export const getGrooveDivReference = () => {
    try {
        const pageBodyChildren = document.body.children
        for (const child of Array.from(pageBodyChildren)) {
            if (child.id.startsWith('groove-container')) {
                return child;
            }
        }
    }
    catch (err) {
        console.log(err)
        return undefined;
    }
}
