import customFetch from "./axiosInstance";

const getUserNameById = async (userId: string): Promise<string|void> => {
    // encode userid string (if needed)
    const encodedId = encodeURIComponent(userId)
    return await customFetch.get(`admin/user/${encodedId}`)
    .then((res) => {
        const { DisplayName: userDisplayName } = res.data
        return userDisplayName;
    })
    .catch(err => {
        console.log(`Error: Could not retrieve user on history record's name by ID (ID '${userId}'). The user might not exist in the DB, or the active user viewing history might not have the required permissions to make this call. Contents of error message: "${err}"`)
    })
}

const getAllUsers = async (options?: {
    // Confines results returned to the supplied account ID, if one is passed
    accountId?: string
}): Promise<any[]> => {
    const encodedId = options?.accountId ? encodeURIComponent(options.accountId) : ''
    return await customFetch.get(`/admin/users${options?.accountId ? '/?account='+encodedId : ''}`)
    .then((res) => {
        return res.data;
    })
    .catch(err => {
        console.log((err.response ? err.response : err));
    })
}

const userDataApi = {
    getAllUsers,
    getUserNameById
}

export default userDataApi;