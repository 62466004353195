import classNames from 'classnames';
import React from 'react';
import {
	FaAngleDown,
	FaAngleLeft,
	FaAngleRight,
	FaAngleUp
} from 'react-icons/fa';

import styles from './Compass.module.scss';

export enum Direction {
	North,
	West,
	South,
	East
}

interface CompassProps {
	disabled?: boolean;
	onClick: (
		direction: Direction,
		e: React.MouseEvent<HTMLButtonElement>
	) => void;
	className?: string;
}

export default function Compass(props: CompassProps) {
	const { disabled, onClick } = props;
	return (
		<div className={classNames(styles.Compass, props.className)}>
			<button
				className={styles.up}
				onClick={e => onClick(Direction.North, e)}
				disabled={disabled}
			>
				<FaAngleUp size={28} />
			</button>
			<button
				className={styles.down}
				onClick={e => onClick(Direction.South, e)}
				disabled={disabled}
			>
				<FaAngleDown size={28} />
			</button>
			<button
				className={styles.left}
				onClick={e => onClick(Direction.West, e)}
				disabled={disabled}
			>
				<FaAngleLeft size={28} />
			</button>
			<button
				className={styles.right}
				onClick={e => onClick(Direction.East, e)}
				disabled={disabled}
			>
				<FaAngleRight size={28} />
			</button>
		</div>
	);
}
