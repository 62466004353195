import classNames from "classnames"
import { useDrop } from "react-dnd"

type UploadItemDropZoneType = {
    ordinal: number,
    onPosChange: (from: number, to: number) => void
}

function UploadItemDropZone({ ordinal, onPosChange }: UploadItemDropZoneType) {

    const [{ isOver, isDragging, canDrop }, drop] = useDrop<{ index: number }, any, any>(
        () => ({
            accept: "UploadItem",
            drop: (item) => {
                onPosChange(item.index, ordinal);
            },
            collect: monitor => {
                const draggedItem: any = monitor.getItem();
                return {
                    isOver: !!monitor.isOver(),
                    isDragging: !!monitor.getItem(),
                    canDrop: !!monitor.canDrop() && (draggedItem.index !== (ordinal - 1) && draggedItem.index !== (ordinal)),
                }
            }
        }), [ordinal])

    return <tr>
        <td colSpan={5} style={{
            height: isOver ? 100 : 6,
            position: "relative"
        }} className={classNames("p-0", { "dropspace": isOver })}>
            <div ref={drop} className={classNames("cannot-currently-drop", { "can-drop": canDrop })} style={{ position: "absolute", top: -37, bottom: -37, left: 0, right: 0, display: isDragging ? "block" : "none" }}></div>
        </td>
    </tr>
}

export default UploadItemDropZone