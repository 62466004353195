import { store } from "../store";
import { Job } from "../types/job.types";
import { Page, PagePart } from "../types/pages.types";

/**
 * Calculates if provided warning has been fixed in job, part or page. Useful for easily calculating sums by iterating over pages.
 *
 * @param job
 * @param part
 * @param page
 * @param rule
 */
export function atAll(
    job: Job,
    part: PagePart | string,
    page: Page,
    rule: string
) {
    if (inJob(job, rule)) return 'job';
    else if (inPart(job, part, rule)) return 'part';
    else if (inPage(page, rule)) return 'page';
    else return false;
}

/**
 * Calculates if provided warning has been fixed in the provided job.
 *
 * @param job
 * @param rule
 */
function inJob(job: Job | any, rule: string): boolean {
    if (
        !job.Workflow ||
        !job.Workflow.Preflyt1 ||
        !job.Workflow.Preflyt1.Warnings
    )
        return false;
    else
        return job.Workflow.Preflyt1.Warnings.some(
            (w: any) => w.RuleID === rule && w.AutoFix === true
        );
}

/**
 * Calculates if provided warning has been fixed in the provided part of the provided job.
 *
 * @param job
 * @param part
 * @param rule
 */
function inPart(
    job: Pick<Job, 'Workflow'> | any,
    part: PagePart | string,
    rule: string
) {
    if (
        !job.Workflow ||
        !job.Workflow.Preflyt1 ||
        !job.Workflow.Preflyt1[part] ||
        !job.Workflow.Preflyt1[part].Warnings
    )
        return false;
    else
        return (job.Workflow.Preflyt1[part]?.Warnings) ? job.Workflow.Preflyt1[part]?.Warnings?.some(
            (w: any) => w.RuleID === rule && w.AutoFix === true
        ) : false;
}

/**
 * Calculates if provided warning has been fixed in the provided page.
 *
 * @param page
 * @param rule
 * @returns - a value that is one of two possible return types:  
 *          -- `boolean` - if the rule exists on the page's warnings, a boolean will be returned indicating whether or not the warning has been fixed.  
 *          -- `undefined` - if the rule does not exist on the page's warnings, undefined will be returned as this check does not apply to this page.  
 *              (`undefined` will also be returned if the job is missing any of the necessary nested properties to drill down through to check for the warning.)
 */
function inPage(page: Page | any, rule: string): boolean | undefined {
    const warningRuleOnPage = page.Workflow?.Preflyt1?.Warnings?.find((w: any) => w.RuleID === rule)
    // if the warning rule is on the page:
    if (warningRuleOnPage) {
        // if the warning has a valid AutoFix value:
        if (warningRuleOnPage.AutoFix && typeof warningRuleOnPage.AutoFix === 'boolean') {
            return warningRuleOnPage.AutoFix;
        }
        // if the warning exists but AutoFix is not defined at all or not defined properly on it:
        return false;
    }
    // if the warning rule is not on the page:
    return undefined;
}

function completelyFixed(part: PagePart | string, rule: string): boolean {
    const job = store.getState().job.job;
    const pages = store.getState().job.pages;
    if (!job) return false;
    if (hasBeenFixed.inJob(job, rule)) return true;
    else if (hasBeenFixed.inPart(job, part, rule)) return true;
    else {
        if (!pages[part])
            throw new Error(`part ${part} is undefined`);
        else
            return pages[part].every(page => {                
                const warningFixedOnPage: boolean | undefined = hasBeenFixed.inPage(page, rule)
                if (warningFixedOnPage !== undefined) {
                    return warningFixedOnPage;
                }
                else {
                    // when warningFixedOnPage is undefined, the page doesn't have the warning -
                    // so return true, bc otherwise the check for "every()" will fail since not "every" page has this warning.
                    return true;
                }
            }
            );
    }
}

/**
 * Sum all of the warnings and/or errors in a page.
 *
 * @param job
 * @param part
 * @param page
 * @param type
 */
export function countReports(
    job: Job | any,
    part: PagePart | string,
    page: Page | any,
    type: 'Warnings' | 'Errors'
): number {
    if (!page.Tags) return 0;
    else if (!page.Tags.Preflyt1) return 0;
    else if (!page.Tags.Preflyt1[type]) return 0;
    else if (type === 'Errors')
        return Object.keys(page.Tags.Preflyt1[type]!).reduce(
            (sum, ruleid) => sum + page.Tags!.Preflyt1![type]![ruleid],
            0
        );
    else {
        let sum = 0;
        for (const ruleid in page.Tags.Preflyt1[type]!)
            if (!atAll(job, part, page, ruleid))
                sum += page.Tags.Preflyt1[type]![ruleid];

        return sum;
    }
}

const hasBeenFixed = {
    atAll,
    inJob,
    inPage,
    inPart,
    completelyFixed,
    countReports
}

export default hasBeenFixed