export default {
	prefixes: [
		'None',
		'Miss',
		'Mr',
		'Mrs',
		'Ms',
		'Dr',
		'Prof',
		'Rev',
		'Rabbi',
		'Sister',
		'Brother',
		'Father'
	],
	languages: {
		'en-US': 'English (en-US)'
	},
	phone_types: {
		office: 'Office',
		cell: 'Cell',
		fax: 'Fax',
		direct: 'Direct',
		home: 'Home'
	},
	account_statuses: {
		active: 'Active',
		disabled: 'Disabled',
		deleted: 'Deleted',
		interm: 'Interm'
	},
	job_statuses: {
		active: 'Active',
		disabled: 'Disabled',
		deleted: 'Deleted',
		requested: 'Requested'
	},
	user_statuses: {
		active: 'Active',
		disabled: 'Disabled',
		deleted: 'Deleted',
		unverified: 'Unverified',
		interm: 'Interm',
		kicked: 'Kicked',
		banned: 'Banned'
	},
	states: {
		AL: 'Alabama',
		AK: 'Alaska',
		AS: 'American Samoa',
		AZ: 'Arizona',
		AR: 'Arkansas',
		CA: 'California',
		CO: 'Colorado',
		CT: 'Connecticut',
		DE: 'Delaware',
		DC: 'District Of Columbia',
		FM: 'Federated States Of Micronesia',
		FL: 'Florida',
		GA: 'Georgia',
		GU: 'Guam',
		HI: 'Hawaii',
		ID: 'Idaho',
		IL: 'Illinois',
		IN: 'Indiana',
		IA: 'Iowa',
		KS: 'Kansas',
		KY: 'Kentucky',
		LA: 'Louisiana',
		ME: 'Maine',
		MH: 'Marshall Islands',
		MD: 'Maryland',
		MA: 'Massachusetts',
		MI: 'Michigan',
		MN: 'Minnesota',
		MS: 'Mississippi',
		MO: 'Missouri',
		MT: 'Montana',
		NE: 'Nebraska',
		NV: 'Nevada',
		NH: 'New Hampshire',
		NJ: 'New Jersey',
		NM: 'New Mexico',
		NY: 'New York',
		NC: 'North Carolina',
		ND: 'North Dakota',
		MP: 'Northern Mariana Islands',
		OH: 'Ohio',
		OK: 'Oklahoma',
		OR: 'Oregon',
		PW: 'Palau',
		PA: 'Pennsylvania',
		PR: 'Puerto Rico',
		RI: 'Rhode Island',
		SC: 'South Carolina',
		SD: 'South Dakota',
		TN: 'Tennessee',
		TX: 'Texas',
		UT: 'Utah',
		VT: 'Vermont',
		VI: 'Virgin Islands',
		VA: 'Virginia',
		WA: 'Washington',
		WV: 'West Virginia',
		WI: 'Wisconsin',
		WY: 'Wyoming'
	},
	countries: {
		US: 'United States',
		AD: 'AndorrA',
		AE: 'United Arab Emirates',
		AF: 'Afghanistan',
		AG: 'Antigua and Barbuda',
		AI: 'Anguilla',
		AL: 'Albania',
		AM: 'Armenia',
		AN: 'Netherlands Antilles',
		AO: 'Angola',
		AQ: 'Antarctica',
		AR: 'Argentina',
		AS: 'American Samoa',
		AT: 'Austria',
		AU: 'Australia',
		AW: 'Aruba',
		AX: 'Åland Islands',
		AZ: 'Azerbaijan',
		BA: 'Bosnia and Herzegovina',
		BB: 'Barbados',
		BD: 'Bangladesh',
		BE: 'Belgium',
		BF: 'Burkina Faso',
		BG: 'Bulgaria',
		BH: 'Bahrain',
		BI: 'Burundi',
		BJ: 'Benin',
		BM: 'Bermuda',
		BN: 'Brunei Darussalam',
		BO: 'Bolivia',
		BR: 'Brazil',
		BS: 'Bahamas',
		BT: 'Bhutan',
		BV: 'Bouvet Island',
		BW: 'Botswana',
		BY: 'Belarus',
		BZ: 'Belize',
		CA: 'Canada',
		CC: 'Cocos (Keeling) Islands',
		CD: 'Congo, The Democratic Republic of the',
		CF: 'Central African Republic',
		CG: 'Congo',
		CH: 'Switzerland',
		CI: "Cote D'Ivoire",
		CK: 'Cook Islands',
		CL: 'Chile',
		CM: 'Cameroon',
		CN: 'China',
		CO: 'Colombia',
		CR: 'Costa Rica',
		CS: 'Serbia and Montenegro',
		CU: 'Cuba',
		CV: 'Cape Verde',
		CX: 'Christmas Island',
		CY: 'Cyprus',
		CZ: 'Czech Republic',
		DE: 'Germany',
		DJ: 'Djibouti',
		DK: 'Denmark',
		DM: 'Dominica',
		DO: 'Dominican Republic',
		DZ: 'Algeria',
		EC: 'Ecuador',
		EE: 'Estonia',
		EG: 'Egypt',
		EH: 'Western Sahara',
		ER: 'Eritrea',
		ES: 'Spain',
		ET: 'Ethiopia',
		FI: 'Finland',
		FJ: 'Fiji',
		FK: 'Falkland Islands (Malvinas)',
		FM: 'Micronesia, Federated States of',
		FO: 'Faroe Islands',
		FR: 'France',
		GA: 'Gabon',
		GB: 'United Kingdom',
		GD: 'Grenada',
		GE: 'Georgia',
		GF: 'French Guiana',
		GG: 'Guernsey',
		GH: 'Ghana',
		GI: 'Gibraltar',
		GL: 'Greenland',
		GM: 'Gambia',
		GN: 'Guinea',
		GP: 'Guadeloupe',
		GQ: 'Equatorial Guinea',
		GR: 'Greece',
		GS: 'South Georgia and the South Sandwich Islands',
		GT: 'Guatemala',
		GU: 'Guam',
		GW: 'Guinea-Bissau',
		GY: 'Guyana',
		HK: 'Hong Kong',
		HM: 'Heard Island and Mcdonald Islands',
		HN: 'Honduras',
		HR: 'Croatia',
		HT: 'Haiti',
		HU: 'Hungary',
		ID: 'Indonesia',
		IE: 'Ireland',
		IL: 'Israel',
		IM: 'Isle of Man',
		IN: 'India',
		IO: 'British Indian Ocean Territory',
		IQ: 'Iraq',
		IR: 'Iran, Islamic Republic Of',
		IS: 'Iceland',
		IT: 'Italy',
		JE: 'Jersey',
		JM: 'Jamaica',
		JO: 'Jordan',
		JP: 'Japan',
		KE: 'Kenya',
		KG: 'Kyrgyzstan',
		KH: 'Cambodia',
		KI: 'Kiribati',
		KM: 'Comoros',
		KN: 'Saint Kitts and Nevis',
		KP: "Korea, Democratic People'S Republic of",
		KR: 'Korea, Republic of',
		KW: 'Kuwait',
		KY: 'Cayman Islands',
		KZ: 'Kazakhstan',
		LA: "Lao People'S Democratic Republic",
		LB: 'Lebanon',
		LC: 'Saint Lucia',
		LI: 'Liechtenstein',
		LK: 'Sri Lanka',
		LR: 'Liberia',
		LS: 'Lesotho',
		LT: 'Lithuania',
		LU: 'Luxembourg',
		LV: 'Latvia',
		LY: 'Libyan Arab Jamahiriya',
		MA: 'Morocco',
		MC: 'Monaco',
		MD: 'Moldova, Republic of',
		MG: 'Madagascar',
		MH: 'Marshall Islands',
		MK: 'Macedonia, The Former Yugoslav Republic of',
		ML: 'Mali',
		MM: 'Myanmar',
		MN: 'Mongolia',
		MO: 'Macao',
		MP: 'Northern Mariana Islands',
		MQ: 'Martinique',
		MR: 'Mauritania',
		MS: 'Montserrat',
		MT: 'Malta',
		MU: 'Mauritius',
		MV: 'Maldives',
		MW: 'Malawi',
		MX: 'Mexico',
		MY: 'Malaysia',
		MZ: 'Mozambique',
		NA: 'Namibia',
		NC: 'New Caledonia',
		NE: 'Niger',
		NF: 'Norfolk Island',
		NG: 'Nigeria',
		NI: 'Nicaragua',
		NL: 'Netherlands',
		NO: 'Norway',
		NP: 'Nepal',
		NR: 'Nauru',
		NU: 'Niue',
		NZ: 'New Zealand',
		OM: 'Oman',
		PA: 'Panama',
		PE: 'Peru',
		PF: 'French Polynesia',
		PG: 'Papua New Guinea',
		PH: 'Philippines',
		PK: 'Pakistan',
		PL: 'Poland',
		PM: 'Saint Pierre and Miquelon',
		PN: 'Pitcairn',
		PR: 'Puerto Rico',
		PS: 'Palestinian Territory, Occupied',
		PT: 'Portugal',
		PW: 'Palau',
		PY: 'Paraguay',
		QA: 'Qatar',
		RE: 'Reunion',
		RO: 'Romania',
		RU: 'Russian Federation',
		RW: 'RWANDA',
		SA: 'Saudi Arabia',
		SB: 'Solomon Islands',
		SC: 'Seychelles',
		SD: 'Sudan',
		SE: 'Sweden',
		SG: 'Singapore',
		SH: 'Saint Helena',
		SI: 'Slovenia',
		SJ: 'Svalbard and Jan Mayen',
		SK: 'Slovakia',
		SL: 'Sierra Leone',
		SM: 'San Marino',
		SN: 'Senegal',
		SO: 'Somalia',
		SR: 'Suriname',
		ST: 'Sao Tome and Principe',
		SV: 'El Salvador',
		SY: 'Syrian Arab Republic',
		SZ: 'Swaziland',
		TC: 'Turks and Caicos Islands',
		TD: 'Chad',
		TF: 'French Southern Territories',
		TG: 'Togo',
		TH: 'Thailand',
		TJ: 'Tajikistan',
		TK: 'Tokelau',
		TL: 'Timor-Leste',
		TM: 'Turkmenistan',
		TN: 'Tunisia',
		TO: 'Tonga',
		TR: 'Turkey',
		TT: 'Trinidad and Tobago',
		TV: 'Tuvalu',
		TW: 'Taiwan, Province of China',
		TZ: 'Tanzania, United Republic of',
		UA: 'Ukraine',
		UG: 'Uganda',
		UM: 'United States Minor Outlying Islands',
		UY: 'Uruguay',
		UZ: 'Uzbekistan',
		VA: 'Holy See (Vatican City State)',
		VC: 'Saint Vincent and the Grenadines',
		VE: 'Venezuela',
		VG: 'Virgin Islands, British',
		VI: 'Virgin Islands, U.S.',
		VN: 'Viet Nam',
		VU: 'Vanuatu',
		WF: 'Wallis and Futuna',
		WS: 'Samoa',
		YE: 'Yemen',
		YT: 'Mayotte',
		ZA: 'South Africa',
		ZM: 'Zambia',
		ZW: 'Zimbabwe'
	},

	// https://github.com/mailcheck/mailcheck/wiki/List-of-Popular-Domains
	common_domains: [
		/* Default domains included */
		'aol.com',
		'att.net',
		'comcast.net',
		'facebook.com',
		'gmail.com',
		'gmx.com',
		'googlemail.com',
		'google.com',
		'hotmail.com',
		'hotmail.co.uk',
		'mac.com',
		'me.com',
		'mail.com',
		'msn.com',
		'live.com',
		'sbcglobal.net',
		'verizon.net',
		'yahoo.com',
		'yahoo.co.uk',

		/* Other global domains */
		'email.com',
		'fastmail.fm',
		'games.com' /* AOL */,
		'gmx.net',
		'hush.com',
		'hushmail.com',
		'icloud.com',
		'iname.com',
		'inbox.com',
		'lavabit.com',
		'love.com' /* AOL */,
		'outlook.com',
		'pobox.com',
		'protonmail.com',
		'rocketmail.com' /* Yahoo */,
		'safe-mail.net',
		'wow.com' /* AOL */,
		'ygm.com' /* AOL */,
		'ymail.com' /* Yahoo */,
		'zoho.com',
		'yandex.com',

		/* United States ISP domains */
		'bellsouth.net',
		'charter.net',
		'cox.net',
		'earthlink.net',
		'juno.com',

		/* British ISP domains */
		'btinternet.com',
		'virginmedia.com',
		'blueyonder.co.uk',
		'freeserve.co.uk',
		'live.co.uk',
		'ntlworld.com',
		'o2.co.uk',
		'orange.net',
		'sky.com',
		'talktalk.co.uk',
		'tiscali.co.uk',
		'virgin.net',
		'wanadoo.co.uk',
		'bt.com',

		/* Domains used in Asia */
		'sina.com',
		'qq.com',
		'naver.com',
		'hanmail.net',
		'daum.net',
		'nate.com',
		'yahoo.co.jp',
		'yahoo.co.kr',
		'yahoo.co.id',
		'yahoo.co.in',
		'yahoo.com.sg',
		'yahoo.com.ph',

		/* French ISP domains */
		'hotmail.fr',
		'live.fr',
		'laposte.net',
		'yahoo.fr',
		'wanadoo.fr',
		'orange.fr',
		'gmx.fr',
		'sfr.fr',
		'neuf.fr',
		'free.fr',

		/* German ISP domains */
		'gmx.de',
		'hotmail.de',
		'live.de',
		'online.de',
		't-online.de' /* T-Mobile */,
		'web.de',
		'yahoo.de',

		/* Italian ISP domains */
		'libero.it',
		'virgilio.it',
		'hotmail.it',
		'aol.it',
		'tiscali.it',
		'alice.it',
		'live.it',
		'yahoo.it',
		'email.it',
		'tin.it',
		'poste.it',
		'teletu.it',

		/* Russian ISP domains */
		'mail.ru',
		'rambler.ru',
		'yandex.ru',
		'ya.ru',
		'list.ru',

		/* Belgian ISP domains */
		'hotmail.be',
		'live.be',
		'skynet.be',
		'voo.be',
		'tvcablenet.be',
		'telenet.be',

		/* Argentinian ISP domains */
		'hotmail.com.ar',
		'live.com.ar',
		'yahoo.com.ar',
		'fibertel.com.ar',
		'speedy.com.ar',
		'arnet.com.ar',

		/* Domains used in Mexico */
		'yahoo.com.mx',
		'live.com.mx',
		'hotmail.es',
		'hotmail.com.mx',
		'prodigy.net.mx',

		/* Domains used in Brazil */
		'yahoo.com.br',
		'hotmail.com.br',
		'outlook.com.br',
		'uol.com.br',
		'bol.com.br',
		'terra.com.br',
		'ig.com.br',
		'itelefonica.com.br',
		'r7.com',
		'zipmail.com.br',
		'globo.com',
		'globomail.com',
		'oi.com.br'
	]
};
