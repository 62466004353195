import { useQuery } from 'react-query';
import { useCallback, useContext } from 'react';
import { Annotation, Page } from '../_types/LSCScout.type';
import PageViewContext from '../_components/PageViewContext';

import AnnotationApi from '../../../lib/annotation';

export default function useReviews() {

	const { job, page } = useContext(PageViewContext);

	const { data: reviews, refetch } = useQuery<Page['Annots']>(
		['Reviews', job.JobID, page!.PageID],
		() => AnnotationApi.getAnnotation(job.JobID, page ? page.PageID : ""),
		{ initialData: [], enabled: !!page }
	);

	const add = useCallback(
		async (review: Pick<Annotation, 'Body' | 'Area'>) => {
			await AnnotationApi.addAnnotation(review, job.JobID, page ? page.PageID : "");
		},
		[job.JobID, page]
	);

	return { reviews, add, refetch };
}
