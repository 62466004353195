import classNames from "classnames";
import { cloneDeep } from "lodash";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FaArrowLeft, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate, Navigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { v4 } from "uuid";
import customFetch from "../lib/axiosInstance";
import userDataApi from "../lib/userdata";
import { visitUsersAdmin } from "../routes/visit";
import { setEditUserDirty, setEditUserId, setUserList, useAdmin } from "../store/adminSlice";
import { setActiveAccount, useAuthState } from "../store/authSlice";
import { UnitsTypes } from "../types/pages.types";
import { Account, User, UserPermission, UserRoles } from "../types/user.types";
import { adminRoleNames, excludedRoleNames, swalButtonColorsPalette as palette } from "../utils/constants";
import { userHasPermission } from "../utils/user";
import Basedata from "./PageView/_types/Basedata";
import PreviewLoader from "./PreviewLoader";
import AccountDataApi from "../lib/accountdata";

type EditUserProps = {
    isNew?: boolean
}

const PasswordRE = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/

const EditUser: React.FC<EditUserProps> = ({ isNew }) => {
    const { register, reset, handleSubmit, watch, formState: { isSubmitting, errors, isDirty } } = useForm();
    const { activeAccountId, user } = useAuthState()

    // All accounts:
    const [allAccounts, setAllAccounts] = useState<Account[]>([]);

    // Data for accounts that the user currently has added:
    const [accountData, setAccountData] = useState<{ name: string, id: string, role: string }[]>([])
    
    // List of all account IDs selected from the account select on the top-level view (the view that allows Add, Delete, and Make Default)
    const [selectedAcctIds, setSelectedAcctIds] = useState<string[]>([])
    // Note: there are some operations on this component that require only a single account to be selected - 
    // in those cases, the above array is confirmed to be of length 1
    
    // type to receive roles data from API
    type Roles = {
        [roleName: string]: {
            Permissions: string[],
            DisplayName?: string,
            Description: {
                [locale: string]: string
            }
        }
    }

    // State object to hold the roles as obtained from the API for a currently selected account on the top-level view -
    // only applies whenever only one account is selected
    const [rolesForCurrentAcct, setRolesForCurrentAcct] = useState<Roles>({})

    const [rolesError, setRolesError] = useState(false)
    const [acctRolesLoading, setAcctRolesLoading] = useState<boolean>(false)


    /* State data for the Add Accounts modal */
    // Accounts that can be added for the user (ones that the user does not yet have):
    const [addableAccounts, setAddableAccounts] = useState<Account[]>([])
    // Account IDs currently selected to be added for the user (selected on the select in the add accounts modal)
    const [acctIdsSelectedForAdd, setAcctIdsSelectedForAdd] = useState<string[]>([])
    /* */


    const [loading, setLoading] = useState(false);
    const { userId } = useParams();

    const [accountModalOpen, setAccountModalOpen] = useState(false)

    const dispatch = useDispatch()

    const { editUserId, editUserDirty } = useAdmin()

    // callback to be fired by beforeunload listener
    const unloadListener = (event: any) => {
        event.preventDefault();
        // Not sure if the below property setting is necessary, but:
        event.returnValue = ''
    }

    useEffect(() => {
        dispatch(setEditUserDirty(isDirty))
    }, [isDirty])

    useEffect(() => {
        window.addEventListener('beforeunload', unloadListener)
        return (
            () => {
                window.removeEventListener('beforeunload', unloadListener)
                // clear redux edit user id (also clears form dirty status)
                dispatch(setEditUserId(''))
            }
        )
    }, [])
    
    useEffect(() => {
        dispatch(setEditUserId(userId));
        // clear selected accts on user change
        setSelectedAcctIds([])
        // clear errors on user change
        setRolesError(false)
    }, [userId])


    useEffect(() => {

        let cancelled = false;        

        async function getRolesetForAccount() {
            setRolesError(false)
            setAcctRolesLoading(true)
            return await AccountDataApi.getAccount(selectedAcctIds[0])
            .then(res => {
                if (cancelled) {
                    // console.log('component unmounted before roleset call returned. Not setting new Roles.')
                    return;
                }
                if (res.data) {
                    if (res.data.Roleset?.Roles) {
                        return res.data.Roleset.Roles;
                    }
                }
            })
            .then(rolesData => {
                // console.log('setting roles data in state obtained from the API account call...')
                if (rolesData) {
                    setRolesForCurrentAcct(rolesData)
                }
                else {
                    setRolesError(true)
                }
                return;
            })
            .catch(err => {
                console.log('Error trying to get the roleset for the selected account in the account list:')
                console.log(err)
                setRolesError(true)
            })
            .finally(() => setAcctRolesLoading(false))
        }
        
        // If one account is selected,
        // fetch its roleset and place the roles in the select dropdown
        if (selectedAcctIds.length === 1) {
            getRolesetForAccount()
        }

        // cleanup hook on useEffect unmount, to cancel async return if this component unmounts before it returns
        return (() => {
            // console.log('useEffect hook unmounting. Setting cancelled to `true`.')
            cancelled = true;
        })
    }, [selectedAcctIds])

    const navigate = useNavigate()

    /**
     * Method to generate and sort the data that will be set for `allAccounts`.
     * Supports two *exclusive* types of input data:
     * - 3 params - (`accountNames`, `accountIds`, `roles`) --  
     * associative arrays of the account names, IDs, and roles, respectively,  
     * 
     * OR  
     * 
     * - 1 param - (`acctData`) --    
     * the updated account data list with the `name`, `id`, and `role` fields already contained in objects, instead
     * @param accountNames 
     * @param accountIds 
     * @param roles 
     * @param acctData
     */
    const generateAccountData = (accountNames?: string[], accountIds?: string[], roles?: [],
        acctData?: { name: string, id: string, role: string }[]) => {
        // Throws if the method is used incorrectly (if params of both exclusive types of input data are passed) -
        if (acctData && (accountNames || accountIds || roles)) {
            throw Error("Method can only be called with either the 3 assoc. arrays of fields, or a single array of account data objects. The two types of input to this function are exclusive.");
        }
        const data = [];
        // If the input data is the first type (the 3 associative arrays of fields) -
        if (accountNames && accountIds && roles) {
            for (let i = 0; i < accountNames.length; i++) {
                if (accountNames[i] && accountIds[i] && roles[i]) data.push({
                    name: accountNames[i],
                    id: accountIds[i],
                    role: roles[i]
                })
            }
        }
        else if (acctData) {
            // if the input data is of the second type (already a list of acct data objects)
            data.push(...acctData);
        }
        // sort the account data in place by the 'name' property -
        // but leave the first entry in the array at index 0,
        // as being at index 0 sets the 'default' account for the user.
        // TODO - work with @lef to resolve this issue:
        // >      https://github.com/ApagoInc/LSCPortalBE/issues/155
        // As a result of closing this issue, the way Default accounts are set and obtained may change.
        // Make appropriate changes here if the default account process changes and affects this.
        let sortedData: any[] | undefined;
        if (data.length > 1) {
            // get acct at index 0
            const defaultAcct = data[0]
            // and get arr of accts at index 1 through the end of the list
            const allOthersInList = data.slice(1)
            // sort all accts after index 0 alphabetically
            allOthersInList.sort((a, b) => {
                const acctNameA = a.name.toLowerCase()
                const acctNameB = b.name.toLowerCase()
                if (acctNameA > acctNameB) {
                    return 1;
                }
                if (acctNameA < acctNameB) {
                    return -1;
                }
                return 0;
            }) 
            sortedData = [defaultAcct].concat(allOthersInList)
        }        
        setAccountData(sortedData || data);
    }

    const preferencesDefaults = {
        "units": "inches"
    }

    const contactDefaults = {
        "office": "",
        "cell": "",
        "fax": "",
        "direct": "",
        "home": "",
        "altemail": ""
    }

    useEffect(() => {
        if (isNew) {
            setAccountData([])
            reset({
                DisplayName: "",
                FirstName: "",
                LastName: "",
                Prefix: Basedata.prefixes[0],
                Title: "",
                Preferences: preferencesDefaults,
                Contact: contactDefaults,
                Language: Object.keys(Basedata.languages)[0],
                Status: Object.keys(Basedata.user_statuses)[0],
                Email: "",
                Password: "",
                ConfirmPassword: ""
            });
        }
        setLoading(true);
        Promise.all([
            isNew ? Promise.resolve() : customFetch.get(`/admin/user/${userId}`),
            customFetch.get<Account[]>(`/admin/accounts`)
        ]).then(([accountRes, accountsRes]) => {
            setLoading(false);
            setAllAccounts(accountsRes.data || []);
            if (!accountRes) return;
            const data = accountRes.data;
            generateAccountData(data.AccountNames || [], data.Accounts || [], data.Roles || [])
            reset({
                DisplayName: data.DisplayName,
                FirstName: data.FirstName,
                LastName: data.LastName,
                Prefix: data.Prefix || Basedata.prefixes[0],
                Title: data.Title || "",
                Preferences: data.Preferences || preferencesDefaults,
                Contact: data.Contact || contactDefaults,
                Language: data.Language || Object.keys(Basedata.languages)[0],
                Status: data.Status || Object.keys(Basedata.user_statuses)[0],
                Email: data.Email || "",
                Password: "",
                ConfirmPassword: ""
            })
        }).catch(err => {
            setLoading(false);
            console.error(err);
        });
    }, [isNew, userId, reset])

    const deleteUserAccount = useCallback(async () => {
        if (isNew) return;
        if (!userHasPermission(UserPermission.User_Delete, user)) return;
        const { isConfirmed } = await Swal.fire({
            title: "Confirmation",
            text: "Are you sure you want to delete this user account?",
            showCancelButton: true,
            confirmButtonText: "Yes, delete",
            confirmButtonColor: palette.red,
            focusCancel: true
        })
        if (!isConfirmed) return;
        Swal.fire({
            title: "Deleting user account...",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })
        customFetch.delete(`/admin/user/${userId}`).then(() => {
            Swal.close();
            Swal.fire({
                icon: "success",
                title: "User deleted successfully.",
                timer: 4000,
                toast: true,
                position: 'top',
                showClass: {
                    popup: 'swal-popover-in'
                },
                hideClass: {
                    popup: 'swal-popover-out'
                },
                showCloseButton: true,
                showConfirmButton: false,
                grow: 'row'
            })                
            return userDataApi.getAllUsers({ accountId: activeAccountId });
        })
        .then(((refetchedUserList: User[]) => {
            // navigate off of the now-deleted user's param id
            navigate(visitUsersAdmin())
            dispatch(setUserList(refetchedUserList))
        })).catch(err => {
            Swal.close();
            console.error(err);
        })
    }, [navigate, userId, isNew])

    const deleteSelectedAccounts = useCallback(async () => {
        // if the currently-logged in user attempts to remove the account that they are currently using:
        // Note: the below branch should never fire, as the button is set to be disabled under these conditions:
        if (selectedAcctIds.includes(activeAccountId) && user?.UserID === editUserId) {
            return await Swal.fire({
                "icon": "error",
                "title": "Error",
                "text": "Cannot delete the currently-active account from the currently logged-in user. If you want to remove this account from the user's list, change your active account in this session to a different one and then remove the account."
            });
        }
        const { isConfirmed } = await Swal.fire({
            title: "Confirmation",
            text: `Are you sure you want to remove ${selectedAcctIds.length > 1 ? `the ${selectedAcctIds.length} selected accounts` : 'this account'}?`,
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: palette.red
        })
        if (!isConfirmed) return;
        dispatch(setEditUserDirty(true))
        setAccountData(prev => prev.filter(acct => !selectedAcctIds.includes(acct.id)))
        setSelectedAcctIds([])
    }, [selectedAcctIds, activeAccountId])

    // A memoized list of all accts that the user already "has" in order to remove them from the Add Account select options list -
    // recomputes when user's acct data is changed
    const usersAccountIds: Set<string> = useMemo(() => {
        if (accountData) {
            return new Set(accountData.map(usersAcct => usersAcct.id));
        }
        // if for some reason user has no account data, return an empty set:
        return new Set();
    }, [accountData])

    const openAddAccounts = async () => {
        // reset selected accounts for add in state:
        setAcctIdsSelectedForAdd([])

        // Get a list of all accounts that can be added for the user:
        // Copy allAccounts, filter out all entries found in `usersAccountIds`, and sort the remainder
        // (only show those accounts that the user does not already "have")
        const allAddableAccts = cloneDeep(allAccounts)
        .filter(account => !usersAccountIds.has(account.AccountID))
        .sort((a, b) => {
            const acctNameA = a.AccountName.toLowerCase()
            const acctNameB = b.AccountName.toLowerCase()
            if (acctNameA > acctNameB) {
                return 1;
            }
            if (acctNameA < acctNameB) {
                return -1;
            }
            return 0;
        })
        if (allAddableAccts.length < 1) {
            return await Swal.fire({
                title: "All accounts already added",
                text: "All existing accounts have already been added for this user."
            })
        }
        setAddableAccounts(allAddableAccts)
        setAccountModalOpen(true)        
    }

    // This method is called on the UI with `acctIdsSelectedForAdd` from state as the param:
    const addAccounts = async (accountIds: string[]) => {
        // TODO - revisit typing for user roles with @lsp-types if needed
        const acctDataToAdd: { name: string; id: string; role: string; }[] = []
        // given the list of selected acct IDs to add for the user, 
        // get each account's object by ID and then add the data to the user's account list
        for (const acctId of accountIds) {
            const acctById: Account | undefined = allAccounts.find(acct => acct.AccountID === acctId)
            // This should never happen, but just in case something got out of sync somehow:
            if (!acctById) {
                return await Swal.fire({
                    title: "Cannot add account",
                    text: `Cannot add the account under ID ${acctId} - the account could not be found in the list of all accounts.`
                });                
            }        
            // This validation check should also never fail - but again, just in case:
            if (usersAccountIds.has(acctId)) {
                return await Swal.fire({
                    title: "Cannot add account",
                    text: `Cannot add account "${acctById.AccountName}" - the user already has this account added.`
                });
            }
            // add acct data to the list of accts to add for the user
            acctDataToAdd.push({
                name: acctById.AccountName, 
                id: acctById.AccountID, 
                role: UserRoles.NoRoleAssigned
            })
        }
        dispatch(setEditUserDirty(true))

        // clear the selected acct IDs list
        setAcctIdsSelectedForAdd([])        

        // prepare the updated data to set for the user's new accounts list:
        const updatedAcctData = [...accountData, ...acctDataToAdd]

        // set the account data in state within this method call, as this method will also re-sort the updated data list:
        generateAccountData(undefined, undefined, undefined, updatedAcctData)       
        setAccountModalOpen(false)
    }

    const selectedAcctsChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        // clear any prior selected account IDs:
        setSelectedAcctIds([])

        // get the values (acct IDs) of all selected opts
        const selectOpts: HTMLOptionElement[] = Array.from(e.currentTarget.options)
        const selectedAcctIds = []
        for (const opt of selectOpts) {
            if (opt.selected) {
                selectedAcctIds.push(opt.value)
            }
        }
        // set all selected options' values (acct IDs) in state:
        setSelectedAcctIds(selectedAcctIds)
    }

    const updateAccountRole = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        if (selectedAcctIds.length > 1) {
            // Can only update role for a single account at a time
            return;
        }
        const singleSelectedAcctId = selectedAcctIds[0]
        const role = e.target.value;
        dispatch(setEditUserDirty(true))
        setAccountData(prev => prev.map(acc => {
            if (acc.id === singleSelectedAcctId) return { ...acc, role };
            return acc;
        }))
    }, [selectedAcctIds])

    const getAccountRole = useCallback(() => {
        if (selectedAcctIds.length > 1) {
            // Can only get role for a single account at a time
            return;
        }
        const singleSelectedAcctId = selectedAcctIds[0]
        const account = accountData.find(acc => acc.id === singleSelectedAcctId);
        if (account) return account.role;
        return UserRoles.NoRoleAssigned;
    }, [selectedAcctIds, accountData])

    const makeDefault = useCallback(() => {
        if (selectedAcctIds.length > 1) {
            // Can only make one account default at a time
            return;
        }
        const singleSelectedAcctId = selectedAcctIds[0]
        dispatch(setEditUserDirty(true))
        setAccountData(prev => [...prev].sort((a, b) => {
            if (a.id === singleSelectedAcctId) return -1;
            else if (b.id === singleSelectedAcctId) return 1;
            return 0;
        }))
        setSelectedAcctIds([]);
        setSelectedAcctIds([singleSelectedAcctId]);
    }, [selectedAcctIds])

    // TODO - possibly debounce the swal loading alerts for 'creating', 'updating', 'deleting' -
    // if the API returns fast, the transition is a bit choppy as the loading alert just opens and immediately closes
    // --- Could also use a better close transition for the loading alerts, as they currently seem to just disappear on close, 
    // instead of transitioning out like most of the other Swal dialogs in the app.
    const submitHandler = handleSubmit((data) => {
        const submitData: any = {
            ...data,
            AccountNames: accountData.map(acc => acc.name),
            Accounts: accountData.map(acc => acc.id),
            Roles: accountData.map(acc => acc.role)
        }
        if (!isPasswordMatching) {
            Swal.fire("Passwords do not match.");
            return;
        }
        if (!isPasswordValid) {
            Swal.fire({
                title: "Invalid password", 
                text: "Password must have a mininum of eight characters, at least one letter, one number and one special character."
            })
            return;
        }
        if (submitData.Accounts.length < 1) {
            Swal.fire({
                title: "Must add account(s) for user",
                text: "A user must have at least one account."
            })
            return;
        }
        if (!submitData.Password && !isNew) {
            delete submitData.Password;
            delete submitData.ConfirmPassword;
        }
        if (isNew && !submitData.Password) {
            Swal.fire({ title: "Password is required", text: "A password is required for a new account." });
            return;
        }

        Swal.fire({
            title: isNew ? "Creating new user..." : "Updating user...",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        })
        if (isNew) {
            return customFetch.put(`/admin/user`, submitData).then(() => {
                Swal.close();
                Swal.fire({
                    icon: "success",
                    title: "New user created successfully.",
                    timer: 4000,
                    toast: true,
                    position: 'top',
                    showClass: {
                        popup: 'swal-popover-in'
                    },
                    hideClass: {
                        popup: 'swal-popover-out'
                    },
                    showCloseButton: true,
                    showConfirmButton: false,
                    grow: 'row'
                })                
                navigate(visitUsersAdmin());
                return userDataApi.getAllUsers({ accountId: activeAccountId }); 
            })
            .then(updatedUserData => {
                dispatch(setUserList(updatedUserData))
                dispatch(setEditUserDirty(false))
            })
            .catch(err => {
                const errMsg: string | undefined = err.response?.data?.Error
                Swal.close();
                Swal.fire({
                    title: "An error occurred",
                    text: errMsg
                });
                console.error(err);
            })
        } else {
            return customFetch.post(`/admin/user/${userId}`, submitData).then(() => {
                Swal.close();
                Swal.fire({
                    icon: "success",
                    title: "User updated successfully.",
                    timer: 4000,
                    toast: true,
                    position: 'top',
                    showClass: {
                        popup: 'swal-popover-in'
                    },
                    hideClass: {
                        popup: 'swal-popover-out'
                    },
                    showCloseButton: true,
                    showConfirmButton: false,
                    grow: 'row'
                })
                // if the user edited their own currently-active account, re-dispatch the updated user account to the active session here
                if (user?.UserID && user.UserID === userId) {
                    dispatch(setActiveAccount(activeAccountId))                
                }
                return userDataApi.getAllUsers({ accountId: activeAccountId });
            })
            .then(updatedUserData => {
                dispatch(setUserList(updatedUserData))
                dispatch(setEditUserDirty(false))
            })
            .catch(err => {
                const errMsg: string | undefined = err.response?.data?.Error
                Swal.close();
                Swal.fire({
                    title: "An error occurred",
                    text: errMsg
                });
                console.error(err);
            })
        }
    });

    const cancelHandler = async () => {
        // confirm cancel if form dirty
        if (editUserDirty) {
            const { isConfirmed } = await Swal.fire({
                icon: "warning",
                title: "Unsaved changes",
                text: "Are you sure you want to cancel with unsaved changes?",
                showCancelButton: true,
                focusCancel: true,
                confirmButtonText: "Yes, discard changes",
                cancelButtonText: "No, don't cancel",
                reverseButtons: true
            })
            if (isConfirmed) {
                navigate(visitUsersAdmin());    
                dispatch(setEditUserId(''))
            }
        }
        else {
                navigate(visitUsersAdmin());
                dispatch(setEditUserId(''))
        }
    }

    const passwordVal = watch("Password");

    const isPasswordMatching = passwordVal === watch("ConfirmPassword");
    const isPasswordValid = passwordVal !== "" ? PasswordRE.test(watch("Password")) : true;

    const addAcctSelectChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        // clear any prior selected account IDs:
        setAcctIdsSelectedForAdd([])
        
        // get the values (acct IDs) of all selected opts
        const selectOpts: HTMLOptionElement[] = Array.from(e.currentTarget.options)
        const acctIdsUpForAdd = []
        for (const opt of selectOpts) {
            if (opt.selected) {
                acctIdsUpForAdd.push(opt.value)
            }
        }

        // the below commented-out line is another way to go about this, but it seems to have just recently obtained browser support:
        // const selectedAcctIds = Array.from(e.currentTarget.selectedOptions, option => option.value)

        // set all selected options' values (acct IDs) in state:
        setAcctIdsSelectedForAdd(acctIdsUpForAdd)
    }

    const userIsLscAdminOrGreater = useMemo(() => {
        if (user) {
            return adminRoleNames.includes(user.Role);
        }
        return false;
    }, [user]);

    return (
        userHasPermission(UserPermission.User_Edit, user) ?
        <>
        <div className={classNames('modal', {"is-active": accountModalOpen})}>
            <div onClick={() => setAccountModalOpen(false)} className='modal-background'></div>
            {/* TODO - get fonts from classes (for $font-family-secondary), etc. */}
            <div style={{fontFamily: "Avenir Book", height: 400, position: 'relative', bottom: '15vh', display: 'flex', flexFlow: 'column nowrap', alignItems: 'center'}} className={classNames('modal-content', 'box', 'p-5', 'has-background-grey-dark')}>
                <h2 style={{fontFamily: "Avenir Book"}} className="is-size-4 has-text-light mb-4">Add Accounts</h2>
                {/* TODO - factor styles into classes...? */}
                <div style={{width: "95%", height: "80%"}}>
                    <select value={acctIdsSelectedForAdd} className="add-acct-select" onChange={addAcctSelectChangeHandler} style={{width: "100%", height: "100%"}} multiple={true}>
                        {
                            addableAccounts.map(acct => <option style={{fontFamily: "Avenir Book"}} key={v4()} value={acct.AccountID}>{acct.AccountName}</option>)
                        }
                    </select> 
                </div>
                <div className="acct-select-buttons mt-5">
                    <button style={{minWidth: 120}} onClick={async () => await addAccounts(acctIdsSelectedForAdd)} type="button" disabled={addableAccounts.length < 1 || acctIdsSelectedForAdd.length < 1} className="mx-2 p-2 button is-success">Add Account{acctIdsSelectedForAdd.length > 1 && `s (${acctIdsSelectedForAdd.length})`}</button>
                    <button onClick={() => setAccountModalOpen(false)} type="button" className="mx-2 p-2 button">Cancel</button>
                </div>
            </div>
        </div>
        {/* redirect from '/new' for users lacking permission,
        if '/new' is entered in the URL path manually */}
        {isNew && !userHasPermission(UserPermission.User_Create, user) &&
        <Navigate to={visitUsersAdmin()} replace={true}/>}
        {loading ? <PreviewLoader /> : null}
        <div className="job-banner has-background-link is-flex mb-3">         
            <div onClick={cancelHandler} className="px-3 is-flex p-2 has-text-white is-clickable" style={{ backgroundColor: "#38678f", border: "1px solid #315a7d" }}>
                <FaArrowLeft size={25} className="is-align-self-center" />
            </div>
            <div className="py-3 pr-3 is-flex-grow-1">
                <div className="has-text-white ml-2 is-flex is-justify-content-space-between">
                    <div className="is-size-6 has-text-weight-bold">
                        {
                            isNew ? "New User" : watch("DisplayName")
                        }
                    </div>
                    {
                    userHasPermission(UserPermission.User_Delete, user) &&
                    <div onClick={deleteUserAccount} className={classNames("is-align-self-center is-clickable", { "is-hidden": isNew })}>
                        <FaTrash />  Delete User
                    </div>
                    }
                </div>
                <div>
                </div>
            </div>
        </div>
        <form onSubmit={submitHandler} className="px-4 has-text-white">
            <table width="100%" className="is-fullwidth" style={{ margin: "auto" }}>
                <thead>
                    <tr>
                        <td width="20%"></td>
                        <td width="80%"></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Accounts</div>
                        </td>
                        <td>
                            <div className="columns my-2">
                                <div className="column is-fullwidth">
                                    <select className="active-select" value={selectedAcctIds} style={{ height: 120, width: "100%" }} onChange={selectedAcctsChangeHandler} multiple>
                                        {
                                            accountData.map((account, index) => {
                                                const accountOptionLabel = `${account.name}${account.id === activeAccountId ? " (active)" : ""}${index === 0 ? " (default)" : ""}`
                                                return (
                                                    <option title={accountOptionLabel} key={account.id} value={account.id} className="has-background-white">
                                                        {accountOptionLabel}
                                                    </option>
                                                    );
                                                })
                                        }
                                    </select>
                                </div>
                                <div className="column">
                                    <div className="mb-1" style={{ width: 200 }}>
                                        <button type="button" onClick={openAddAccounts} className="button is-small is-primary is-fullwidth">Add Accounts</button>
                                    </div>
                                    <div className="mb-1" style={{ width: 200 }}>
                                        <button type="button" onClick={deleteSelectedAccounts} className="button is-small is-danger is-fullwidth" disabled={selectedAcctIds.length < 1 || (selectedAcctIds.includes(activeAccountId) && user?.UserID === editUserId)} >Delete Account{selectedAcctIds.length > 1 && <>s {`(${selectedAcctIds.length})`}</>}</button>
                                    </div>
                                    <div className="mb-1" style={{ width: 200 }}>
                                        <button type="button" onClick={makeDefault} className="button is-small is-light is-fullwidth" disabled={!(selectedAcctIds.length === 1) || accountData[0]?.id === selectedAcctIds[0]} >Make Default</button>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Role</div>
                        </td>
                        <td className="user-admin-select-wrapper">
                            <div className={classNames("select is-fullwidth my-2", {"is-loading": acctRolesLoading})}>
                                <select disabled={rolesError || acctRolesLoading || selectedAcctIds.length > 1 || selectedAcctIds.length === 0} value={getAccountRole()} onChange={updateAccountRole} className="input" id="">
                                    {
                                        !rolesError && rolesForCurrentAcct &&
                                        Object.entries(rolesForCurrentAcct)
                                        .filter(([roleName, roleData]) => {
                                            if (userIsLscAdminOrGreater) {
                                                return true;
                                            }
                                            else {
                                                return !excludedRoleNames.includes(roleName);
                                            }
                                        })
                                        .map(([roleName, roleData]) => {
                                            return <option key={v4()} value={roleName}>{roleData.DisplayName || roleName}</option>;
                                        }) 
                                    }
                                </select>
                            </div>
                        </td>
                    </tr>
                    {rolesError && <tr>
                            <td className="has-text-centered" colSpan={2}>
                                <span className="has-text-danger">Something went wrong while trying to fetch the roles for this account.</span>
                            </td>
                        </tr>}
                    <tr>
                        <td colSpan={2}>
                            <hr className="has-background-light" />
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Display Name *</div>
                        </td>
                        <td>
                            <input {...register("DisplayName", { required: "Display Name is required." })} type="text" className="input is-fullwidth my-2" />
                            <p className="help is-danger">{errors.DisplayName?.message}</p>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <hr className="has-background-light" />
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">First Name *</div>
                        </td>
                        <td>
                            <input {...register("FirstName", { required: "First Name is required." })} type="text" className="input is-fullwidth my-2" />
                            <p className="help is-danger">{errors.FirstName?.message}</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Last Name *</div>
                        </td>
                        <td>
                            <input {...register("LastName", { required: "Last Name is required." })} type="text" className="input is-fullwidth my-2" />
                            <p className="help is-danger">{errors.LastName?.message}</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Prefix</div>
                        </td>
                        <td>
                            <div className="select is-fullwidth my-2">
                                <select {...register("Prefix")} className="input" id="">
                                    {
                                        Basedata.prefixes.map(prefix => <option key={prefix} value={prefix}>{prefix}</option>)
                                    }
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Title</div>
                        </td>
                        <td>
                            <input {...register("Title")} type="text" className="input is-fullwidth my-2" />
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Email *</div>
                        </td>
                        <td>
                            <input {...register("Email", { required: "Email is required." })} type="email" className="input is-fullwidth my-2" autoComplete="off" />
                            <p className="help is-danger">{errors.Email?.message}</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Password {isNew ? "*" : ""}</div>
                        </td>
                        <td>
                            <input {...register("Password", { required: isNew })} type="password" className={classNames("input is-fullwidth my-2", { "is-danger": !isPasswordValid })} autoComplete="off" />
                            <div className="help is-danger">{!isPasswordValid ? "Minimum eight characters, at least one letter, one number and one special character" : null}</div>
                            {
                                (isNew && errors.Password?.type === "required") && <p className="help is-danger">Password is required for a new user account.</p>
                            }       
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Confirm Password {isNew ? "*" : ""}</div>
                        </td>
                        <td>
                            <input {...register("ConfirmPassword", { required: isNew })} type="password" className="input is-fullwidth my-2" />
                            <div className="help is-danger">{!isPasswordMatching ? "Passwords do not match" : null}</div>
                            {
                                (isNew && errors.ConfirmPassword?.type === "required") && <p className="help is-danger">Confirm Password is required for a new user account.</p>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Default Measurement</div>
                        </td>
                        <td>
                            <div className="select is-fullwidth my-2">
                                <select {...register("Preferences.units")} className="input">
                                    {
                                        Object.values(UnitsTypes).map(unit => <option key={unit} value={unit}>{unit}</option>)
                                    }
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <hr className="has-background-light" />
                            <div className="title is-size-5 ml-6">Contact</div>
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Office</div>
                        </td>
                        <td>
                            <input {...register("Contact.office")} type="text" className="input is-fullwidth my-2" />
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Cell</div>
                        </td>
                        <td>
                            <input {...register("Contact.cell")} type="text" className="input is-fullwidth my-2" />
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Fax</div>
                        </td>
                        <td>
                            <input {...register("Contact.fax")} type="text" className="input is-fullwidth my-2" />
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Direct</div>
                        </td>
                        <td>
                            <input {...register("Contact.direct")} type="text" className="input is-fullwidth my-2" />
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Home</div>
                        </td>
                        <td>
                            <input {...register("Contact.home")} type="text" className="input is-fullwidth my-2" />
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Alt Email</div>
                        </td>
                        <td>
                            <input {...register("Contact.altemail")} type="text" className="input is-fullwidth my-2" />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <hr className="has-background-light" />
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Language</div>
                        </td>
                        <td>
                            <div className="select is-fullwidth my-2">
                                <select {...register("Language")} className="input">
                                    {
                                        Object.entries(Basedata.languages).map(([key, value]) => <option key={key} value={key}>{value}</option>)
                                    }
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <hr className="has-background-light" />
                        </td>
                    </tr>
                    <tr>
                        <td className="has-text-right">
                            <div className="mt-4 mr-4">Status</div>
                        </td>
                        <td className="user-admin-select-wrapper">
                            <div className="select is-fullwidth my-2">
                                <select disabled={!userIsLscAdminOrGreater} {...register("Status")} className="input">
                                    {
                                        Object.entries(Basedata.user_statuses).map(([key, value]) => <option key={key} value={key}>{value}</option>)
                                    }
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <div className="buttons my-6">
                                <button type="submit" disabled={!editUserDirty || !userHasPermission(UserPermission.User_Edit, user)} className={classNames("button is-flex-grow-1 is-primary", { "is-loading": isSubmitting })}>Save</button>
                                <button onClick={async () => await cancelHandler()} type="button" className="button is-flex-grow-1 is-light">Cancel</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </>
    :
    // only render an empty fragment if user does not have User_Edit permission:
    <></>
    )
}

export default EditUser