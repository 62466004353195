/**
 * As expected - this will go away when \@lsp-types\ is implemented.
 * 
 * In the meantime, however -
 * I want a way to check that a passed part name is valid.
 * 
 * I am not sure how I would set this up to generate as a dynamic type, 
 * so here is a dictionary consisting of every key of the partMap variable in ./utils/parts.ts - once it is populated on app startup.
 * 
 * This one is available immediately as I just copied all of the values. I am not sure how I would facilitate creating a typed enum at runtime.
 * 
 * In the live application, unless new component 'types' can be created in the application at whim - which I don't believe is the case -  
 * it seems like a static index like this could be used to control the types of components.
*/
// {[partName in partNameKeys]: partDisplayNames} 
export const partNameDictionary: {[k: string]: any} = {
    "text": "Text",
    "cover": "Soft Cover",
    "coverFoil": "Soft Cover Foil",
    "coverEmboss": "Soft Cover Emboss",
    "coverDeboss": "Soft Cover Deboss",
    "coverElkote": "Soft Cover Elkote",
    "coverTexture": "Soft Cover Texture",
    "coverEtch": "Soft Cover Etch",
    "coverBlind": "Soft Cover Blindstamp",
    "coverEmbossLo": "Soft Cover Lo Emboss",
    "coverEmbossHi": "Soft Cover Hi Emboss",
    "coverEmbossMed": "Soft Cover Med Emboss",
    "coverEmbossCreate": "Soft Cover Create",
    "coverSpotUV": "Soft Cover SpotUV",
    "caseside": "Caseside",
    "casesideFoil": "Caseside Foil",
    "casesideEmboss": "Caseside Emboss",
    "casesideDeboss": "Caseside Deboss",
    "casesideElkote": "Caseside Elkote",
    "casesideTexture": "Caseside Texture",
    "casesideEtch": "Caseside Etch",
    "casesideBlind": "Caseside Blindstamp",
    "casesideEmbossLo": "Caseside Lo Emboss",
    "casesideEmbossHi": "Caseside Hi Emboss",
    "casesideEmbossMed": "Caseside Med Emboss",
    "casesideEmbossCreate": "Caseside Create",
    "casesideSpotUV": "Caseside SpotUV",
    "frontCover": "Front Cover (Spiral)",
    "backCover": "Back Cover (Spiral)",
    "jacket": "Jacket",
    "jacketFoil": "Jacket Foil",
    "jacketEmboss": "Jacket Emboss",
    "jacketDeboss": "Jacket Deboss",
    "jacketElkote": "Jacket Elkote",
    "jacketTexture": "Jacket Texture",
    "jacketEtch": "Jacket Etch",
    "jacketBlind": "Jacket Blind",
    "jacketEmbossLo": "Jacket EmbossLo",
    "jacketEmbossHi": "Jacket EmbossHi",
    "jacketEmbossMed": "Jacket EmbossMed",
    "jacketEmbossCreate": "Jacket EmbossCreate",
    "jacketSpotUV": "Jacket SpotUV",
    "tab": "Tab",
    "insertBound": "Bound Insert",
    "insertBoundFoil": "Bound Insert Foil",
    "insertBoundEmboss": "Bound Insert Emboss",
    "insertBoundDeboss": "Bound Insert Deboss",
    "insertBoundElkote": "Bound Insert Elkote",
    "insertBoundTexture": "Bound Insert Texture",
    "insertBoundEtch": "Bound Insert Etch",
    "insertBoundBlind": "Bound Insert Blind",
    "insertBoundEmbossLo": "Bound Insert EmbossLo",
    "insertBoundEmbossHi": "Bound Insert EmbossHi",
    "insertBoundEmbossMed": "Bound Insert EmbossMed",
    "insertBoundCreate": "Bound Insert Create",
    "insertBoundSpotUV": "Bound Insert SpotUV",
    "insertLoose": "Loose Insert",
    "insertLooseBoundFoil": "Loose Insert Foil",
    "insertLooseBoundEmboss": "Loose Insert Emboss",
    "insertLooseBoundDeboss": "Loose Insert Deboss",
    "insertLooseBoundElkote": "Loose Insert Elkote",
    "insertLooseBoundTexture": "Loose Insert Texture",
    "insertLooseBoundEtch": "Loose Insert Etch",
    "insertLooseBoundBlind": "Loose Insert Blind",
    "insertLooseBoundEmbossLo": "Loose Insert EmbossLo",
    "insertLooseBoundEmbossHi": "Loose Insert EmbossHi",
    "insertLooseBoundEmbossMed": "Loose Insert EmbossMed",
    "insertLooseBoundCreate": "Loose Insert Create",
    "insertLooseBoundSpotUV": "Loose Insert SpotUV",
    "frontEndsheet": "Endsheet Front",
    "backEndsheet": "Endsheet Back",
    "card": "Card",
    "onlay": "Onlay",
    "bellyBand": "Belly Band",
    "jWrap": "J Wrap",
    "boxWrap": "Box Wrap",
    "die": "Die",
    "poster": "Poster",
    "posterFront": "Poster (Front)",
    "posterBack": "Poster (Back)",
    "sticker": "Sticker",
    "shelf": "Unassigned Corrections"
};

const _keys = [
    "text",
    "cover",
    "coverFoil",
    "coverEmboss",
    "coverDeboss",
    "coverElkote",
    "coverTexture",
    "coverEtch",
    "coverBlind",
    "coverEmbossLo",
    "coverEmbossHi",
    "coverEmbossMed",
    "coverEmbossCreate",
    "coverSpotUV",
    "caseside",
    "casesideFoil",
    "casesideEmboss",
    "casesideDeboss",
    "casesideElkote",
    "casesideTexture",
    "casesideEtch",
    "casesideBlind",
    "casesideEmbossLo",
    "casesideEmbossHi",
    "casesideEmbossMed",
    "casesideEmbossCreate",
    "casesideSpotUV",
    "frontCover",
    "backCover",
    "jacket",
    "jacketFoil",
    "jacketEmboss",
    "jacketDeboss",
    "jacketElkote",
    "jacketTexture",
    "jacketEtch",
    "jacketBlind",
    "jacketEmbossLo",
    "jacketEmbossHi",
    "jacketEmbossMed",
    "jacketEmbossCreate",
    "jacketSpotUV",
    "tab",
    "insertBound",
    "insertBoundFoil",
    "insertBoundEmboss",
    "insertBoundDeboss",
    "insertBoundElkote",
    "insertBoundTexture",
    "insertBoundEtch",
    "insertBoundBlind",
    "insertBoundEmbossLo",
    "insertBoundEmbossHi",
    "insertBoundEmbossMed",
    "insertBoundCreate",
    "insertBoundSpotUV",
    "insertLoose",
    "insertLooseBoundFoil",
    "insertLooseBoundEmboss",
    "insertLooseBoundDeboss",
    "insertLooseBoundElkote",
    "insertLooseBoundTexture",
    "insertLooseBoundEtch",
    "insertLooseBoundBlind",
    "insertLooseBoundEmbossLo",
    "insertLooseBoundEmbossHi",
    "insertLooseBoundEmbossMed",
    "insertLooseBoundCreate",
    "insertLooseBoundSpotUV",
    "frontEndsheet",
    "backEndsheet",
    "card",
    "onlay",
    "bellyBand",
    "jWrap",
    "boxWrap",
    "die",
    "poster",
    "posterFront",
    "posterBack",
    "sticker",
    "shelf"
]

const _values = [
    "Text",
    "Soft Cover",
    "Soft Cover Foil",
    "Soft Cover Emboss",
    "Soft Cover Deboss",
    "Soft Cover Elkote",
    "Soft Cover Texture",
    "Soft Cover Etch",
    "Soft Cover Blindstamp",
    "Soft Cover Lo Emboss",
    "Soft Cover Hi Emboss",
    "Soft Cover Med Emboss",
    "Soft Cover Create",
    "Soft Cover SpotUV",
    "Caseside",
    "Caseside Foil",
    "Caseside Emboss",
    "Caseside Deboss",
    "Caseside Elkote",
    "Caseside Texture",
    "Caseside Etch",
    "Caseside Blindstamp",
    "Caseside Lo Emboss",
    "Caseside Hi Emboss",
    "Caseside Med Emboss",
    "Caseside Create",
    "Caseside SpotUV",
    "Front Cover (Spiral)",
    "Back Cover (Spiral)",
    "Jacket",
    "Jacket Foil",
    "Jacket Emboss",
    "Jacket Deboss",
    "Jacket Elkote",
    "Jacket Texture",
    "Jacket Etch",
    "Jacket Blind",
    "Jacket EmbossLo",
    "Jacket EmbossHi",
    "Jacket EmbossMed",
    "Jacket EmbossCreate",
    "Jacket SpotUV",
    "Tab",
    "Bound Insert",
    "Bound Insert Foil",
    "Bound Insert Emboss",
    "Bound Insert Deboss",
    "Bound Insert Elkote",
    "Bound Insert Texture",
    "Bound Insert Etch",
    "Bound Insert Blind",
    "Bound Insert EmbossLo",
    "Bound Insert EmbossHi",
    "Bound Insert EmbossMed",
    "Bound Insert Create",
    "Bound Insert SpotUV",
    "Loose Insert",
    "Loose Insert Foil",
    "Loose Insert Emboss",
    "Loose Insert Deboss",
    "Loose Insert Elkote",
    "Loose Insert Texture",
    "Loose Insert Etch",
    "Loose Insert Blind",
    "Loose Insert EmbossLo",
    "Loose Insert EmbossHi",
    "Loose Insert EmbossMed",
    "Loose Insert Create",
    "Loose Insert SpotUV",
    "Endsheet Front",
    "Endsheet Back",
    "Card",
    "Onlay",
    "Belly Band",
    "J Wrap",
    "Box Wrap",
    "Die",
    "Poster",
    "Poster (Front)",
    "Poster (Back)",
    "Sticker",
    "Unassigned Corrections"
] 