
import { PagePart } from "../types/pages.types";
import { getPartContextOptions } from "../utils/parts"
import ContextMenu from "./ContextMenu";

type PartSelectorType = {
    pos: { x: number, y: number },
    setSelectedPart: (part: PagePart | string) => void,
    exclude?: PagePart[] | string[]
}

function PartSelector({ pos, setSelectedPart, exclude }: PartSelectorType) {

    const menuItemClickHandler = (part: PagePart | string) => {
        setSelectedPart(part);
    }

    return <ContextMenu contextMenuClick={menuItemClickHandler} items={getPartContextOptions(exclude)} pos={pos}></ContextMenu>
}

export default PartSelector
