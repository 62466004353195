import React, { useCallback, useContext, useEffect, useReducer } from 'react';

function getFullpath() {
	return window.location.pathname + window.location.hash;
}

interface LocationContextProps {
	location: string;
	forceUpdate: () => void;
}

const LocationContext = React.createContext<LocationContextProps>({ location: window.location.href, forceUpdate: () => { console.log("force") } });

export default function useLocation(): [string, (location: string) => void] {
	const { location, forceUpdate } = useContext(LocationContext);

	const setLocation = useCallback(
		(newLocation: string) => {
			history.pushState({}, document.title, newLocation);
			forceUpdate();
		},
		[forceUpdate]
	);

	useEffect(() => {
		const stateChangeHandler = () => forceUpdate();

		window.addEventListener('popstate', stateChangeHandler);
		window.addEventListener('replaceState', stateChangeHandler);
		window.addEventListener('hashchange', stateChangeHandler);

		return () => {
			window.removeEventListener('popstate', stateChangeHandler);
			window.removeEventListener('replaceState', stateChangeHandler);
			window.removeEventListener('hashchange', stateChangeHandler);
		};
	}, [forceUpdate, setLocation]);

	return [location, setLocation];
}

function LocationProvider({ children }: React.PropsWithChildren<any>) {
	const location = getFullpath();

	const [, forceUpdate] = useReducer(x => x + 1, 0);
	return (
		<LocationContext.Provider
			value={{
				location,
				forceUpdate
			}}
		>
			{children}
		</LocationContext.Provider>
	);
}

const LocationConsumer = LocationContext.Consumer;

export { LocationProvider, LocationConsumer, LocationContext };
