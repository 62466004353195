

import mqtt from "mqtt/dist/mqtt";

class MQTTService {
    client?: mqtt.MqttClient;

    constructor() {
        window.addEventListener("focus", () => {
            
            const currTime = new Date().toLocaleTimeString()
            console.log(`[${currTime}] - MQTT client connected status: ${this.client?.connected}`)
                        
            this.reconnect();
        })
    }

    /** Reconnect MQTT client if it is not still connected. */
    async reconnect() {        
        if (this.client?.connected) {            
            return;
        }

        return new Promise((resolve) => {
            console.log('*** MQTT client not connected. Reconnecting...')
                        
            this.client?.once("reconnect", () => {
                console.log('MQTT client reconnected.')
                resolve(null);
            });

            this.client?.reconnect();
        })
    }

    /**
     * Connects the class's internal mqtt.MqttClient object and passes a reference to the client to the middleware. 
     * @param opts - Connection options for connecting the mqtt client.
     * @returns - A connected instance of the class's internal mqtt client.
     */
    connect(opts: mqtt.IClientOptions) {
        this.client = mqtt.connect({ ...opts, protocolVersion: 5, reconnectPeriod: 0 });
        return this.client;
    }
}

export function cleanTopic(topic: string) {
    const match = String(topic).match(/\/.+\//);
    return match ? match[0] : undefined;
}

export function cleanTopics(topics: string[]) {
    return topics.map(topic => cleanTopic(topic)).filter(Boolean) as string[];
}

const mqttService = new MQTTService()
export default mqttService;