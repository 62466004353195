import { put, select } from "redux-saga/effects";
import { SearchState } from "../types/store.types";
import { RootState } from "../store";
import { generateCondition } from "../utils/utils";
import { searchError, searchSuccess, setNumberOfJobs, setPageNumber } from "../store/searchSlice";
import JobApi from "../lib/job";


export function* searchTitles() {
    try {
        const searchData: SearchState = yield select((state: RootState) => state.search)
        // generate condition here
        const searchFilter = generateCondition(searchData)
        // @ts-ignore
        const response = yield JobApi.searchJobs(searchFilter)
        const [jobs, jobsinfo] = response;
        yield put(searchSuccess(jobs.data))
        yield put(setNumberOfJobs(jobsinfo.data.NumberOfJobs))
        const numberOfPages = Math.ceil(jobsinfo.data.NumberOfJobs / searchData.pageSize);
        if (numberOfPages !== 0 && numberOfPages < searchData.pageNumber) yield put(setPageNumber(1))
    } catch (e) {
        console.error(e)
        yield put(searchError("Error fetching data"))
    }
}