import cloneDeep from "lodash/cloneDeep"
import { MouseEvent, useMemo } from "react"
import { FaSearch } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import JobApi from "../lib/job"
import { visitJob } from "../routes/visit"
import { useAuthState } from "../store/authSlice"
import { setJobData, useJob } from "../store/jobSlice"
import { Preflyt1_IssueTypes } from "../types/job.types"
import { PreviousViewState } from "../types/types"
import { UserPermission } from "../types/user.types"
import hasBeenFixed, { atAll } from "../utils/hasBeenFixed"
import { getPart } from "../utils/parts"
import { userHasPermission } from "../utils/user"

type PreflightSummaryCardType = {
    part: string
}

function PreflightSummaryCard({ part }: PreflightSummaryCardType) {

    const { pages, jobIsActive } = useJob()
    const { user } = useAuthState()
    const { job, jobId } = useJob()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const partIsLocked = useMemo(() => (job?.Stage?.[part]?.Stage === "Locked"), [job]);

    const sumIssues = (type: "Warnings" | "Errors") => {
        const ruleCountsMap = new Map<string, { unresolved: number, total: number }>();
        pages[part].forEach(page => {
            Object.entries(page.Tags?.Preflyt1?.[type] || {}).forEach(([key, value]) => {
                const val = ruleCountsMap.get(key) || { unresolved: 0, total: 0 };
                if (typeof value === 'number') {
                    val.total += value;
                    if (job && !atAll(job, part, page, key)) {
                        val.unresolved += value;
                    }
                }
                ruleCountsMap.set(key, val);
                // Note: errors can't be "resolved" without re-uploading the errant page(s).
                // Thus, for an error, 'total' will always equal 'unresolved' - 
                // this does not cause the method to handle summing errors incorrectly.
            })
        })
        const ruleDataMap = new Map<string, { unresolved: number, total: number, fixable: boolean, rule: string }>();
        pages[part].forEach(page => {
            const ruleData = (page.Workflow && page.Workflow.Preflyt1 && page.Workflow.Preflyt1[type]) ? page.Workflow.Preflyt1[type] : [];
            if (ruleData) {
                ruleData.forEach((issue: any) => {
                    const counts = ruleCountsMap.get(issue.RuleID);
                    if (counts) {
                        const [, , fixableString] = issue.RuleID.split(",")
                        const isFixable = (fixableString === 'true')
                        ruleDataMap.set(issue.Name, { unresolved: counts.unresolved, total: counts.total, fixable: isFixable, rule: issue.RuleID });
                    }
                })
            }
        })
        return Object.fromEntries(ruleDataMap)
    }

    const getWarnings = () => Object.entries(sumIssues("Warnings"))
    const getErrors = () => Object.entries(sumIssues("Errors"))


    // Note: errors can't be "resolved" without re-uploading the errant page(s), but this method will still find the first page with a given error.
    const getFirstPageWithUnresolved = (type: "Warnings" | "Errors", rule: string) => {
        const page = pages[part].find(page => {
            return Object.entries(page.Tags.Preflyt1[type] || {}).find(([key, value]) => {
                return (rule === key && (job && !atAll(job, part, page, key)));
            })
        });
        const viewState: PreviousViewState = { from: 'preflightsummary' }
        if (page?.Ordinal) navigate(visitJob({ jobId, part, ordinal: page.Ordinal }), {
            state: viewState
        })
    };

    const resolveProblemHandler = (ruleId: string, type: Preflyt1_IssueTypes) => (e: MouseEvent<HTMLButtonElement>) => {
        if (!job) return;
        if (!jobIsActive || partIsLocked) return;
        const clonedWf = (job.Workflow ? cloneDeep(job.Workflow) : undefined);

        const updatedJobWf = clonedWf || {}

        if (!updatedJobWf.Preflyt1) {
            updatedJobWf.Preflyt1 = {}
        }
        if (!updatedJobWf.Preflyt1[part]) {
            updatedJobWf.Preflyt1[part] = { Warnings: [] }
        }
        // if the part property was defined but doesn't have the Warnings array, define here:
        if (!Object.keys(updatedJobWf.Preflyt1[part]!).includes("Warnings")) {
            updatedJobWf.Preflyt1[part]!.Warnings = []
        }

        // The below [type] will only ever be "Warnings" as only warnings can be resolved this way:
        let warningData = (updatedJobWf.Preflyt1 && updatedJobWf.Preflyt1[part] && updatedJobWf.Preflyt1[part]![type]) || [];

        // avoid adding warning element if it's already on the list -        
        const warningIsInData = warningData.find(w => w.RuleID === ruleId);
        if (warningIsInData) {
            warningData = warningData.map(w => (w.RuleID === ruleId) ? { ...w, AutoFix: true } : w);
        }
        else {
            warningData.push({ RuleID: ruleId, AutoFix: true })
        }

        // assign the updated value to be PATCHED with:
        updatedJobWf.Preflyt1[part]![type] = warningData

        Swal.fire({
            title: "Please wait...",
            text: `Fixing ${part} warnings`,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        })
        JobApi.signOff(jobId, updatedJobWf)
            .then(res => {
                Swal.close()
                dispatch(setJobData(res.data));
            })
            .catch(err => {
                Swal.close();
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "There was an error fixing warnings. Try again"
                })
                console.error(err)
            })
    }

    return <div className="mb-6 is-flex is-flex-direction-column" style={{ flexBasis: "48%" }}>
        <div className="has-background-grey-dark is-flex is-flex-direction-column p-2 is-flex-grow-1">
            <table className="table has-background-grey-dark has-text-white">
                <thead>
                    <tr>
                        <th colSpan={2} className="has-text-white">{getPart(part)?.name}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ border: "none" }} colSpan={2} className="has-text-info">
                            <span className="tag is-warning pf-summary-issue-header">
                                Warnings:
                            </span>
                        </td>
                    </tr>
                    {
                        getWarnings().length > 0 ?
                            getWarnings().map(([key, value]) => {
                                return <tr key={key}>
                                    <td style={{ border: "none" }} className="is-vcentered">
                                        {key}
                                        {' '}
                                        <>
                                            {!hasBeenFixed.completelyFixed(part, value.rule) && <span>{'('}{value.unresolved}/{value.total}{')'}</span>}
                                            {hasBeenFixed.completelyFixed(part, value.rule) && <span>{'('}{value.total}{')'}</span>}
                                        </> {
                                            !hasBeenFixed.completelyFixed(part, value.rule) &&
                                            <span className="is-clickable">
                                                <FaSearch onClick={() => getFirstPageWithUnresolved("Warnings", value.rule)} className="has-text-link" size={12} />
                                            </span>
                                        }</td>
                                    <td className="is-vcentered" style={{ border: "none" }}>
                                        {
                                            userHasPermission(UserPermission.Preflight1_ApplyFix, user) && value.fixable && !hasBeenFixed.completelyFixed(part, value.rule) && <button disabled={!jobIsActive || partIsLocked} onClick={resolveProblemHandler(value.rule, Preflyt1_IssueTypes.Warnings)} className="button is-warning is-small">Fix</button>
                                        }
                                        {
                                            userHasPermission(UserPermission.Preflight1_Signoff, user) && !value.fixable && !hasBeenFixed.completelyFixed(part, value.rule) && <button disabled={!jobIsActive || partIsLocked} onClick={resolveProblemHandler(value.rule, Preflyt1_IssueTypes.Warnings)} className="button is-warning is-small">Sign Off</button>
                                        }
                                        {
                                            hasBeenFixed.completelyFixed(part, value.rule) && <button className="button is-primary is-small" disabled={true}>{value.fixable ? "Fixed" : "Signed off"}</button>
                                        }
                                    </td>
                                </tr>
                            })
                            :
                            <tr>
                                <td colSpan={2} className="has-text-centered is-underlined">No Warnings</td>
                            </tr>
                    }

                    <tr>
                        <td style={{ border: "none" }} colSpan={2}>
                            <span className="tag is-danger pf-summary-issue-header">
                                Errors:
                            </span>
                        </td>
                    </tr>
                    {
                        getErrors().length > 0 ?
                            getErrors().map(([key, value]) => {
                                return <tr key={key}>
                                    <td style={{ border: "none" }} className="is-vcentered">{key} ({value.unresolved})  {
                                        <span className="is-clickable">
                                            <FaSearch onClick={() => getFirstPageWithUnresolved("Errors", value.rule)} className="has-text-link" size={12} />
                                        </span>
                                    }</td>
                                </tr>
                            })
                            :
                            <tr>
                                <td colSpan={2} className="has-text-centered is-underlined">No Errors</td>
                            </tr>
                    }
                </tbody>

            </table>
        </div>
    </div>
}

export default PreflightSummaryCard