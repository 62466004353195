import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthState } from "../store/authSlice";

function PublicRoute({ children }: { children: JSX.Element }) {
    const { isAuthenticated, activeAccountId } = useAuthState()
    const location = useLocation();

    const locationState: any = location.state;


    if (locationState?.from?.pathname && locationState.from.pathname.startsWith('/dashboard')) {

        const origUrlPath = locationState.from.pathname

        // check for a passed account value - will be directly following '/dashboard/',
        // and will either end the string or end before another forward slash.
        const pathPortions = origUrlPath.split("/")

        // where in order to have a possible account ID in the path,
        // the result of splitting the pathname on forward slashes
        // must have at least 3 elements, where the 3rd element is
        // the possible account ID:
        if (pathPortions.length >= 3) {
            const urlAccountId = pathPortions[2]
            sessionStorage.setItem('urlAccountId', urlAccountId)
        }

        sessionStorage.setItem('storedLinkPath', locationState.from.pathname)
    }

    const storedDeepLinkPath: string | null = sessionStorage.getItem('storedLinkPath')

    const from = storedDeepLinkPath || locationState?.from?.pathname || `/dashboard${activeAccountId ? `/${activeAccountId}` : ''}`;

    if (isAuthenticated) {
        sessionStorage.removeItem('storedLinkPath')
        sessionStorage.removeItem('urlAccountId')

        console.log("from:", from)
        console.log(storedDeepLinkPath, locationState?.from?.pathname, activeAccountId)
        return <Navigate to={from} replace />;
    }
    return children
}

export default PublicRoute