import customFetch from "./axiosInstance"

const getAnnotation = (jobId: string, pageId: string, config?: any) => {
    return customFetch.get(`/page/page/${jobId}/${pageId}`, {
        params: { keys: 'Annots' },
        ...config
    }).then(response => response.data.Annots || [])
}

const addAnnotation = (annotation: any, jobId: string, pageId: string, config?: any) => {
    return customFetch.post(`/page/page/${jobId}/${pageId}/annotations`,
        (typeof annotation === "string") ? { Body: annotation } : annotation,
        { errorRedirection: false, ...config }
    )
}

const deleteAnnotation = (index: number, jobId: string, pageId: string, config?: any) => {
    return customFetch.delete(`/page/page/${jobId}/${pageId}/annotations/${index}`, { errorRedirection: false, ...config })
}

const AnnotationApi = {
    getAnnotation,
    addAnnotation,
    deleteAnnotation
}

export default AnnotationApi