import { PagePart } from "./pages.types"

export enum JobTypes {
    Book = "Book",
    TEP = "TEP"
}

export interface JobsInfo {
    Modified: string, // date-string
    MostRecentJob?: string,
    NumberOfJobs: number,
    Oldest?: string,
    OldestJob?: string
}

export enum Preflyt1_IssueTypes {
    Warnings = 'Warnings',
    Errors = 'Errors'
}

export type Preflyt1TagsIssueData = {
    [ruleId: string]: number
}

export type Preflyt1WorkflowIssueData = {
    RuleID: string,
    AutoFix?: boolean
}

export interface Job {
    Account: { AccountID: string, Name: string },
    AssociatedISBNs?: string[],
    Author?: string,
    BODPage?: number,
    Created: string,
    CustomerReference?: string,
    ISBN10?: string,
    ISBN13?: string,
    JobID: string,
    Modified: string,
    Owner: { Email: string, Name: string, UserID: string },
    Pages: number,
    Status?: string,
    Title?: string,
    Type: JobTypes,
    Parts: number,
    PartsList: PagePart[],
    Progress: {
        percent: number
        process: string
        stage: string
    }
    Publisher: string,
    Stage: {
        [x: string]: {
            Stage: string;
            Status: string;
            Reason: string;
            Display: string;
            Summary?: string;
            Progress: any
        }
    },
    IntendedPageCount?: string, // Currently, this is never going to come from the API
    // JobNumber?: string, // Now only using Tags.SystemJobNumber as this property was duplicating it
    PartPages?: {
        [partName in PagePart | string]: number
    },
    ErrorReason?: string,
    GetstreamToken?: string,
    Tags?: {
        Preflyt1?: {
            [partName in PagePart | string]: {
                Warnings?: Preflyt1TagsIssueData,
                Errors?: Preflyt1TagsIssueData,
                [k: string]: any // TODO - refine
            }
        },
        SystemJobNumber?: string,
        [k: string]: any // TODO - fix this to be more specific - just did not want to narrow out any Tags properties
    }
    Workflow?: {
        Preflyt1?:
        {
            Warnings?: Preflyt1WorkflowIssueData[],
            Errors?: Preflyt1WorkflowIssueData[], // TODO An Errors property might never exist here or may not have any utility. Find out
        }
        &
        {
            [partName in PagePart | string]?: {
                Warnings?: Preflyt1WorkflowIssueData[],
                Errors?: Preflyt1WorkflowIssueData[], // TODO An Errors property might never exist here or may not have any utility. Find out
            }
        }
    }
}

export type SearchJob = Pick<Job, "Author" | "CustomerReference" | "JobID" | "Status" | "Type" | "Title" | "ISBN10" | "ISBN13" | "Stage" | "PartsList" | "Progress" | "Account">

export interface NewJob {
    Author: string
    CustomerReference: string
    ISBN10: string
    ISBN13: string
    Publisher: string
    Title: string
}

export interface JobCopyFormData {
    ISBN: string,
    CustomerReference: string,
    Title?: string,
    Author?: string,
    OtherCommonIsbnEntry: string,
    OtherCommonIsbnsList: string[]
}

// Type of small collection of data on a job's unique identifying fields -
// Its primary ID - "JobID", and
// its other 'unique IDs' that it may have: ISBN13, ISBN10, CustomerReference
export type JobIdData = {
    JobID?: string,
    ISBN13?: string,
    ISBN10?: string,
    CustomerReference?: string
}