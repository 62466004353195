import Big, { RoundingMode } from 'big.js';

const scalars = {
	points: new Big('1'),
	inches: new Big('72'),
	millimeters: new Big('72').div('25.4')
};

export const unitShortNames: { [key in Unit]: string } = {
	points: 'pt',
	millimeters: 'mm',
	inches: '"'
};

export type Unit = keyof typeof scalars;

export const units = Object.keys(scalars);

export default function convertUnits<T extends string | number>(
	sourceUnits: Unit,
	targetUnits: Unit,
	source: T,
	dp = 6,
	rm: RoundingMode = Big.roundHalfEven
): T {
	const srcType = typeof source as 'string' | 'number';
	if (sourceUnits === targetUnits) return source;
	else {
		const bigSrc = new Big(source);
		const srcScalar = scalars[sourceUnits];
		const targetScalar = scalars[targetUnits];
		const converted = bigSrc.times(srcScalar).div(targetScalar);

		if (srcType === 'string')
			return converted.round(dp, rm).toString() as T;
		else if (srcType === 'number')
			return converted.round(dp, rm).toNumber() as T;
		else throw new Error('Input type must be string or number');
	}
}
