import { Job, JobTypes, Preflyt1_IssueTypes, SearchJob } from "../types/job.types"
import { Page } from "../types/pages.types";
import { sumPreflytIssuesForPage } from "./page";

export const hasComponents = (jobData: Job): boolean => {
    return jobData.PartsList && jobData.PartsList.length !== 0
}

export const jobHasType = (jobType: JobTypes[] | JobTypes, job?: Job) => {
    if (!job) return false;
    if (Array.isArray(jobType)) return jobType.includes(job.Type);
    else return jobType === job.Type
}

export const sumPreflytIssuesForJob = (job: Job, jobPagesByPart: Record<string, Page[]>) => {
    let sum = 0;
    for (const part of job.PartsList) {
        if (!jobPagesByPart[part]) continue;
        for (const page of jobPagesByPart[part]) {
            const totalUnresolvedIssuesForPage = sumPreflytIssuesForPage(job, part, page, Preflyt1_IssueTypes.Errors) +
                sumPreflytIssuesForPage(job, part, page, Preflyt1_IssueTypes.Warnings)
            sum += totalUnresolvedIssuesForPage;
        }
    }
    return sum;
}

export const hasError = (job: Job | SearchJob) => {
    return job.Status !== undefined && job.Status === 'error';
}

export const isProcessing = (job: Job | SearchJob) => {
    return job.Status === undefined || job.Status === 'processing';
}

export const progressStage = (job: Job | SearchJob) => {
    return job?.Progress?.stage || "";
}

export const progressPercent = (job: Job | SearchJob) => {
    if (!job.Progress) return 0;
    else if (job.Progress.percent !== undefined)
        return job.Progress.percent;
    else return 0;
}

export const hideProgress = (job: Job | SearchJob) => {
    return job.Status === 'active' ||
        !job.Progress ||
        job.Progress.percent === undefined ||
        job.Progress.stage === 'Complete';
}