import { useQuery } from 'react-query';
import PagesApi from '../../../lib/pages';
import { PagePart } from '../../../types/pages.types';
import { Page } from '../_types/LSCScout.type';

export default function usePartPages({
	JobID,
	Part
}: {
	JobID: string;
	Part: string;
}) {
	return useQuery<Page[]>(['all pages', JobID, Part].join(), () =>
		PagesApi.getPartPages(JobID, Part as PagePart).then((res: any) =>
			res.data.map((p: Page) => ({
				Ordinal: p.Ordinal,
				PageID: p.PageID
			}))
		)
	);
}
