import { queryClient } from '../../../pages/PageView'
import { useCallback } from 'react';
import { useQueries, UseQueryResult } from 'react-query';
import { Page as IPage } from '../_types/LSCScout.type';

import usePartPages from './usePartPages';
import customFetch from '../../../lib/axiosInstance';

export default function usePages(
	JobID: string,
	Part: string,
	pageOrdinals: number[]
) {
	const { data: allPages = [] } = usePartPages({ JobID, Part });

	const pageIds = pageOrdinals.map(
		po => allPages.find(p => p.Ordinal === po)?.PageID
	);

	const getPage = useCallback(
		(PageID: string) =>
			customFetch.get(`/page/page/${JobID}/${PageID}`)
				.then((res: any) => res.data)
				.then(async (pageData: any) => {
					// prime browser cache with page image
					await new Promise((resolve, reject) => {
						const img = document.createElement('img');
						img.addEventListener('load', resolve);
						img.addEventListener('error', reject);
						img.src = pageData.Image;
					});

					return pageData;
				}),
		[JobID]
	);

	const prefetch = useCallback(
		async (prefetchedOrdinals: number[]) => {
			const preFetchableOrdinals = prefetchedOrdinals
				.filter(o => o !== 0)
				.filter(o => o <= allPages.length);

			const pages = preFetchableOrdinals.map(po =>
				allPages.find(p => p.Ordinal === po)
			);

			if (pages.some(p => !p))
				throw new Error(
					`Some of page ordinals ${prefetchedOrdinals.join(
						', '
					)} are not cached`
				);
			else
				return Promise.all(
					pages.map(p =>
						queryClient.prefetchQuery(p!.PageID, () =>
							getPage(p!.PageID)
						)
					)
				);
		},
		[allPages, getPage]
	);

	const pages = useQueries(
		pageIds.map(pid => {
			if (pid)
				return {
					queryKey: pid,
					queryFn: (qp: any) => getPage(qp.queryKey[0]),
					enabled: !!pid
				};
			else return { enabled: false };
		})
	) as UseQueryResult<IPage>[];

	return { pages, prefetch };
}
