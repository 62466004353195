import { Job, Page, PageUpdate, partShortname } from '../_types/LSCScout.type';
import React, { Dispatch, SetStateAction } from 'react';
import { Unit } from '../_utils/convertUnits';
import { Line } from '@apagoinc/react-ruler';

interface IPageViewContext {
	page?: Page;
	pages: Array<Page | undefined>;
	ordinal?: number;
	Part: partShortname;
	job: Job;
	refetch: () => Promise<void>;
	processing: boolean;
	batchOp: (op: PageUpdate) => Promise<void>;
	mutateJob: Dispatch<SetStateAction<Partial<Job>>>;
	bumpOffset: [number, number];
	setBumpOffset: Dispatch<SetStateAction<[number, number]>>;
	selectedUnits: Unit;
	clearMeasurements: () => void;
	lines: Line[];
	setLines: Dispatch<SetStateAction<Line[]>>;
	zoomAreaHeight: number;
	zoomAreaWidth: number;
	canvasScale: number;
	addRulerMode: boolean;
	setAddRulerMode: Dispatch<SetStateAction<boolean>>;
	showMeasurements: boolean;
	setShowMeasurements: Dispatch<SetStateAction<boolean>>;
	isFullscreen: boolean;
	setFullscreen: Dispatch<SetStateAction<boolean>>;
	selectedTab: string;
	spreadMode: boolean;
}

const PageViewContext = React.createContext<IPageViewContext>(undefined as any);

export default PageViewContext;
