import { Unit } from '../_utils/convertUnits';
import React, { CSSProperties, useEffect, useState } from 'react';
import swal from 'sweetalert';

import MultiUnitInput from './MultiUnitInput';

const contentStyles: CSSProperties = {};

const dimensionsStyles: CSSProperties = {
	display: 'flex',
	flexDirection: 'row'
};

const dimensionStyles: CSSProperties = {
	flex: '1',
	display: 'flex',
	flexDirection: 'column',
	padding: '10px'
};

const labelStyles: CSSProperties = {};

const valueStyles: CSSProperties = {
	fontSize: '30px',
	border: '1px solid black',
	borderRadius: '5px',
	width: '100%'
};

export default function ScaleCenterPopupContent(props: {
	title?: string;
	width: number;
	height: number;
	units: Unit;
	scale: number;
}) {
	const { title, units, scale } = props;

	const [width, setWidth] = useState(props.width);
	const [height, setHeight] = useState(props.height);

	useEffect(() => {
		if (swal.setActionValue) swal.setActionValue(`${width} ${height}`);
	}, [height, width]);

	return (
		<div style={contentStyles}>
			{title && <h2>{title}</h2>}
			<h2>
				Component trim will be automatically centered to these values
			</h2>
			<div style={dimensionsStyles}>
				<div style={dimensionStyles}>
					<span style={labelStyles}>Component Width ({units})</span>
					<MultiUnitInput
						style={valueStyles}
						value={width}
						modelUnits="points"
						displayUnits={units}
						onChange={v => setWidth(+v)}
					/>
				</div>
				<div style={dimensionStyles}>
					<span style={labelStyles}>Component Height ({units})</span>
					<MultiUnitInput
						style={valueStyles}
						value={height}
						modelUnits="points"
						displayUnits={units}
						onChange={v => setHeight(+v)}
					/>
				</div>
			</div>
			<p>
				Applying these changes will cause the component to check for content too close to the new Trim box.
			</p>
			{scale !== 1 && <p>
				Click <a href="https://lscscout.groovehq.com/help/risks-associated-with-scaling" target="_blank" rel='noreferrer'>here</a> to review risks associated with scaling
			</p>}
		</div>
	);
}
