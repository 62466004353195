import classNames from "classnames"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import Swal from "sweetalert2"
import userDataApi from "../lib/userdata"
import { visitUsersAdmin } from "../routes/visit"
import { setEditUserId, setUserList, useAdmin } from "../store/adminSlice"
import { useAuthState } from "../store/authSlice"
import { User, UserPermission } from "../types/user.types"
import { userHasPermission } from "../utils/user"
import { escapeRegExp } from "lodash"
import { adminRoleNames, emailInExcludedDomain } from "../utils/constants"

enum UserStatus {
    All = "all",
    Active = "active",
    Deleted = "deleted",
    Disabled = "disabled",
    Unverified = "unverified"
}

const UsersList = () => {

    const { user, activeAccountId } = useAuthState()

    const [filteredUsers, setFilteredUsers] = useState<User[]>([])
    const [queryStatus, setQueryStatus] = useState<UserStatus>(UserStatus.All)
    const [query, setQuery] = useState("");

    const navigate = useNavigate();
    const { userId } = useParams();

    const selectedRef = useRef<HTMLSelectElement>(null)

    const dispatch = useDispatch()

    const { userList, editUserDirty } = useAdmin()

    const userIsLscAdminOrGreater = useMemo(() => {
        if (user) {
            return adminRoleNames.includes(user.Role);
        }
        return false;
    }, [user]);

    useEffect(() => {
        try {
            // escape any possible regex characters first in the search query string
            const escapedQuery = escapeRegExp(query)       
            const searchRe = new RegExp(escapedQuery, "i") 

            setFilteredUsers(userList
                .filter(user => {
                    return (`${user.DisplayName}`.search(searchRe) > -1 || `${user.Email}`.search(searchRe) > -1 ) && (queryStatus === UserStatus.All || user.Status === queryStatus)
                })
                .filter((listedUser) => {
                    // filter list based on currently logged in user's role
                    if (userIsLscAdminOrGreater) {
                        return true;
                    }
                    else {
                        return !emailInExcludedDomain(listedUser.Email);
                    }
                })
            );
        }
        catch (err) {
            console.log('Error searching with input string:', query)
            return;
        }
    }, [query, userList, queryStatus])

    useEffect(() => {

        let cancelled = false;

        async function getUserList() {
            return userDataApi.getAllUsers({ accountId: activeAccountId }) 
                .then(users => {
                    if (cancelled) {
                        // console.log('Component unmounted. Users list change will not be dispatched.')
                        return;
                    }
                    
                    dispatch(setUserList(users))
                })
                .catch(error => {
                    console.log('Error calling getAllUsers:')
                    console.log(error)
                })
        }

            getUserList();

        // cleanup hook on useEffect unmount, to cancel an async return if component unmounted
        return (() => {
            // console.log('useEffect hook unmounting. Setting cancelled to `true`.')
            cancelled = true;
        })

    }, [activeAccountId]) 

    const getCount = useCallback((status: string) => {
        return filteredUsers.filter(a => a.Status === status).length
    }, [filteredUsers])

    useEffect(() => {
        if (!selectedRef.current) return;
        const target = selectedRef.current;
        const { selectedIndex } = target;
        for (let i = 0; i < target.options.length; i++) {
            const option = target.options[i];
            option.textContent = option.value;
            if (i === selectedIndex) {
                const count = option.value === UserStatus.All ? filteredUsers.length : getCount(option.value)
                option.textContent = option.value + `(${count})`;
            }
        }
    }, [filteredUsers, queryStatus, getCount])

    const promptOnDirtyForm = async () => {
        return await Swal.fire({
            icon: "warning",
            title: "Unsaved changes",
            text: "Are you sure you want to cancel with unsaved changes?",
            showCancelButton: true,
            focusCancel: true,
            confirmButtonText: "Yes, discard changes",
            cancelButtonText: "No, don't cancel",
            reverseButtons: true
        })
    }

    const userAccountClickHandler = (accountId: string) => async () => {
        if (editUserDirty) {
            const { isConfirmed } = await promptOnDirtyForm();
            if (!isConfirmed) {
                return;
            }
        }
        navigate(visitUsersAdmin({ userId: accountId }));
    }

    const queryStatusFilterChangeHandler = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        // capture the change event value here exactly as it is received, to preserve it since this is running async
        // and since this runs async, the value can apparently be 'lost' if not assigned at the time it is received, like this:
        const valueAsReceived: UserStatus = e.target.value as UserStatus;

        if (editUserDirty) {
            const { isConfirmed } = await promptOnDirtyForm();
            if (!isConfirmed) {
                return;
            }
            setQueryStatus(valueAsReceived)
            dispatch(setEditUserId(''))
            navigate(visitUsersAdmin());
        }
        else {
            setQueryStatus(valueAsReceived)
            dispatch(setEditUserId(''))
            navigate(visitUsersAdmin());
        }
    }

    const addNewUserHandler = async () => {
        if (editUserDirty) {
            const { isConfirmed } = await promptOnDirtyForm();
            if (!isConfirmed) {
                return;
            }
        }
        dispatch(setEditUserId(''))
        navigate(visitUsersAdmin({ new: true }))
    }

    return <aside className="is-flex h-full is-flex-direction-column pb-6">
        <div className="p-3 bb-1-light">
            <div className="is-flex">
                <button onClick={addNewUserHandler} disabled={!userHasPermission(UserPermission.User_Create, user)} className="button is-primary is-small is-flex-grow-1 mr-1">+ User</button>
                <div className="select is-flex-grow-1 ml-1">
                    <select ref={selectedRef} value={queryStatus} onChange={async (e) => await queryStatusFilterChangeHandler(e)} className="input has-text-black is-capitalized" id="" style={{ backgroundColor: "#fef5ea" }}>
                        {
                            Object.values(UserStatus).map(status => <option key={status} value={status}>{status}</option>)
                        }
                    </select>
                </div>
            </div>
            <div className="control mt-3">
                <input value={query} onChange={(e) => setQuery(e.target.value)} className="input is-small white-placeholder" placeholder="Search by name or email" type="text" style={{ backgroundColor: "#3e4247", color: "#fff" }} />
            </div>
        </div>
        <div className="is-flex-grow-1" style={{ overflowY: "auto" }}>
            {
                filteredUsers.sort((a, b) => `${a.DisplayName}`.localeCompare(b.DisplayName)).map((user, i) => <div key={i} className={classNames("bb-1-light px-3 py-2 is-clickable", { "has-background-primary": user.UserID === userId })} onClick={userAccountClickHandler(user.UserID)}>
                    <div className="title mb-1" style={{ fontSize: 13 }}>{user.DisplayName}</div>
                    <div style={{ color: "#d1dade", fontSize: 12 }}>{user.Email}</div>
                    <div style={{ color: "#d1dade", fontSize: 12 }}>{user.Status}</div>
                </div>)
            }
        </div>
    </aside>
}

export default UsersList