import classNames from "classnames"
import cloneDeep from "lodash/cloneDeep"
import React from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import customFetch from "../lib/axiosInstance"
import PagesApi from "../lib/pages"
import { updatePartPageAtOrdinal, useJob } from "../store/jobSlice"
import { usePages } from "../store/pagesSlice"

type MultiFolioModalType = {
    state: boolean,
    setState: (state: boolean) => void
    startingValue?: string
}

enum FolioStyle {
    Arabic = "arabic",
    Roman = "roman",
    Alphabetic = "alphabetic"
}

enum FolioAddon {
    None = "none",
    Prefix = "prefix",
    Suffix = "suffix"
}

const MultiFolioModal: React.FC<MultiFolioModalType> = ({ state, setState, startingValue }) => {

    const { register, handleSubmit, getValues, watch, reset, formState: { errors, isSubmitting } } = useForm({
        defaultValues: {
            folio: startingValue,
            folioStyle: FolioStyle.Arabic,
            folioAddon: FolioAddon.None,
            addonValue: ""
        }
    });

    const { part } = useParams()
    const { selectedPages } = usePages()
    const { jobId } = useJob()
    const dispatch = useDispatch()

    watch(["folioAddon", "folioStyle"]);

    const addonNotNone = getValues("folioAddon") !== FolioAddon.None;

    const submitHandler = handleSubmit(async (data) => {
        const sortedPages = cloneDeep(selectedPages).sort((a, b) => a - b);
        const reqData = {
            "folio-type": "",
            "ordinal": sortedPages[0],
            "folio": data.folio,
            "part": part,
            "count": selectedPages.length,
            "type": "refolio"
        }
        // update folio value with prefix/suffix value
        if (data.folioAddon == FolioAddon.Prefix) reqData.folio = data.addonValue + data.folio;
        else if (data.folioAddon == FolioAddon.Suffix) reqData.folio += data.addonValue;

        const isLower = /[a-z]/.test(data.folio || "");
        if (data.folioStyle === FolioStyle.Arabic) {
            reqData["folio-type"] = "numeric";
            if (data.folioAddon !== FolioAddon.None) reqData["folio-type"] = `${data.folioAddon}-numeric`;
        } else if (data.folioStyle === FolioStyle.Roman) {
            if (isLower) reqData["folio-type"] = "lower-roman";
            else reqData["folio-type"] = "upper-roman";
        } else if (data.folioStyle === FolioStyle.Alphabetic) {
            if (isLower) reqData["folio-type"] = "lower-letter";
            else reqData["folio-type"] = "upper-letter";
        }

        if (!part || typeof reqData.ordinal !== "number") return;
        await customFetch.patch(`/page/page/${jobId}`, reqData);
        const response = await PagesApi.getPartPages(jobId, part, { first: reqData.ordinal, last: reqData.ordinal + (reqData.count - 1) });
        response.data.map(page => dispatch(updatePartPageAtOrdinal({ ordinal: page.Ordinal, part, page })))
        reset();
        setState(false);
    })

    const folioValidator = () => {
        const style = getValues("folioStyle");
        const value = getValues("folio");
        if (!value) return "folio can't be empty";
        if (style === FolioStyle.Arabic) {
            if (/^[0-9]+$/.test(value)) return true;
            return "Arabic folios take only numeric[0-9] values"
        } else if (style === FolioStyle.Alphabetic) {
            if (/^[a-zA-Z]+$/.test(value)) return true;
            return "Alphabetic folios take only alphabetic[a-z] values"
        } else if (style === FolioStyle.Roman) {
            if (/^[IVXLCDMivxlcdm]+$/.test(value)) return true;
            return "Roman folios take only [I,V,X,L,C,D,M] values"
        }
    }

    return <form onSubmit={submitHandler} className={classNames("modal has-text-light-cascading-no-important", { "is-active": state })} style={{ zIndex: 999999 }} onClick={(e) => e.stopPropagation()}>
        <div className="modal-background"></div>
        <div className="modal-card">
            <header className="modal-card-head">
                <p className="has-text-light modal-card-title">Folios</p>
                <button className="delete" aria-label="close" onClick={() => setState(false)}></button>
            </header>
            <section className="modal-card-body has-background-dark">
                <table className="table has-background-dark is-fullwidth" style={{ border: "none" }}>
                    <tbody>
                        <tr>
                            <td width="30%" className="has-text-right is-vcentered">Folio Style:</td>
                            <td>
                                <div className="select is-fullwidth">
                                    <select {...register("folioStyle")} disabled={isSubmitting}>
                                        {
                                            Object.entries(FolioStyle).map(([key, value]) => <option key={value} value={value}>{key}</option>)
                                        }
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="has-text-right is-vcentered">Starting value:</td>
                            <td>
                                <div className="help is-danger">{errors.folio?.message}</div>
                                <input {...register("folio", { validate: folioValidator })} type="text" className="input is-fullwidth" disabled={isSubmitting} />
                            </td>
                        </tr>
                        <tr>
                            <td className="has-text-right is-vcentered">Folio Add-on:</td>
                            <td>
                                <div className="select is-fullwidth">
                                    <select {...register("folioAddon")} disabled={isSubmitting}>
                                        {
                                            Object.entries(FolioAddon).map(([key, value]) => <option key={value} value={value}>{key}</option>)
                                        }
                                    </select>
                                </div>
                            </td>
                        </tr>
                        {
                            addonNotNone &&
                            <tr>
                                <td className="has-text-right is-vcentered is-capitalized">{getValues("folioAddon")}</td>
                                <td>
                                    <input {...register("addonValue", { required: addonNotNone })} type="text" className="input" disabled={isSubmitting} />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </section>
            <footer className="modal-card-foot">
                <button className={classNames("button is-primary", { "is-loading": isSubmitting })} type="submit">Save changes</button>
                <button className="button" onClick={() => setState(false)} type="button" disabled={isSubmitting}>Close</button>
            </footer>
        </div>
    </form>
}

export default MultiFolioModal