import { MouseEvent, useEffect, useMemo, useRef, useState } from "react"
import { FaCaretDown } from "react-icons/fa";
import { PagePart } from "../../types/pages.types";
import { getPart } from "../../utils/parts";
import PartSelector from "../PartSelector";
import { PdfFileType } from "./UploadModal"
import { useDrag } from "react-dnd"
import Swal from "sweetalert2";
import Foco from "react-foco";
import { generatePreview } from "../../utils/pdf";
import { useJob } from "../../store/jobSlice";
import { JobStatus } from "../../types/types";
import { getDocument } from "pdfjs-dist";
import classNames from "classnames";

type UploadItemProps = PdfFileType & {
    index: number,
    onDelete: (index: number) => void,
    onPartChange: (index: number, part: PagePart | string) => void,
    multipleFilesMode: boolean,
    initialPart: string
}

function UploadItem({ file, part, index, doc, numPages, onDelete, onPartChange, multipleFilesMode, initialPart, pdfWorker, failedToLoad, aboveSizeThreshold }: UploadItemProps) {

    const [pos, setPos] = useState({ x: 0, y: 0 });
    const [showPartsSelector, setShowPartsSelector] = useState(false);
    const ref = useRef<HTMLTableCellElement>(null);
    const [fileData, setFileData] = useState<{ thumbnail?: HTMLCanvasElement, preview?: HTMLCanvasElement }>({});
    const { job } = useJob();

    const [, dragRef] = useDrag<{ index: number }, any, any>(() => ({
        type: "UploadItem",
        item: { index },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        })
    }), [index])

    useEffect(() => {
        (async () => {
            if (!doc) return;
            const thumbnail = await generatePreview(doc, 0.1);
            const preview = await generatePreview(doc, 1);
            setFileData({ preview, thumbnail })
        })()
    }, [file, doc])

    useEffect(() => {
        if (!ref.current) return;
        const parent = ref.current;
        if (fileData.thumbnail) ref.current.append(fileData.thumbnail)
        return () => parent && parent.querySelector("canvas")?.remove();
    }, [fileData.thumbnail])

    const partOptionClickHandler = (e: MouseEvent<HTMLTableCellElement>) => {
        if (multipleFilesMode) {
            return;
        }
        setPos({ x: e.clientX, y: e.clientY });
        setShowPartsSelector(true);
    }

    const setSelectedPartHandler = (part: PagePart | string) => {
        setShowPartsSelector(false);
        onPartChange(index, part);
    }

    const imagePreviewHandler = async () => {
        if (aboveSizeThreshold) {
            return Swal.fire({
                title: 'Cannot show preview',
                text: 'The file is too large to show a preview for.',
                icon: 'error'
            });
        }

        let docPreviewEl: HTMLCanvasElement | undefined;

        await getDocument({ url: URL.createObjectURL(file), worker: pdfWorker }).promise
        .then(async doc => {
            return await generatePreview(doc, 1);
        })
        .then(preview => {
            docPreviewEl = preview;
        })
        .catch(err => {
            console.log('error loading file preview:')
            console.log(err)
        })

        if (!docPreviewEl) {
            Swal.fire("Preview unavailable!");
            return;
        }

        docPreviewEl.style.border = "1px solid #333";
        Swal.fire({
            title: "Page Preview",
            html: docPreviewEl,
            width: docPreviewEl.width + 60,
            heightAuto: true,
            confirmButtonText: "Close Preview"
        })
    }

    const disabledParts = useMemo(() => {
        const lockedParts = Object.entries(job?.Stage || {})
            .filter(([a, b]) => `${b.Stage}`.toLocaleLowerCase() === JobStatus.Locked)
            .map(([a, b]) => a);
        return lockedParts
    }, [job])

    const canDragItemCheck = (event: React.DragEvent<HTMLDivElement>) => {
        // Reject drag operations if the parent modal is in multiple files mode:
        if (multipleFilesMode) {
            event.preventDefault();
        }
    }

    return <tr onDragStart={canDragItemCheck} ref={dragRef} className="is-size-7" onClick={(e) => e.stopPropagation()}>
        <td className="is-vcentered">{file.name}</td>
        <td className="has-text-centered is-vcentered">
            {/* show no page number in multiple files mode */}
            {multipleFilesMode ? '' :
                <>
                    {/* In normal mode, three possible exclusive cases for the value shown in the Pages column */}
                    {numPages && numPages >= 0 ? numPages : ''}
                    {numPages && numPages < 0 &&
                        <>
                            {failedToLoad ? <span className="has-text-danger">Corrupt or encrypted</span> : ''}
                            {aboveSizeThreshold ? '(Large file)' : ''}
                        </>
                    }
                </>
            }
        </td>
        <td className={classNames("has-text-centered is-vcentered upload-pages-modal-select",
        {"disabled-custom-control no-not-allowed-icon": multipleFilesMode})}>
            <div onClick={partOptionClickHandler} className="button is-small">
                {multipleFilesMode && getPart(initialPart)?.name}
                {!multipleFilesMode && getPart(part)?.name}
                <FaCaretDown className="ml-1" />
            </div>
            {
                showPartsSelector &&
                <Foco onClickOutside={() => setShowPartsSelector(false)}>
                    <PartSelector pos={pos} setSelectedPart={setSelectedPartHandler} exclude={disabledParts} />
                </Foco>
            }
        </td>
        <td className="has-text-centered is-vcentered">
            <div className="level">
                {!multipleFilesMode && <button className="button is-small level-item is-link" disabled={failedToLoad} onClick={imagePreviewHandler}>Preview</button>}
                <button className="button is-small ml-1 level-item is-danger" onClick={() => onDelete(index)}>Delete</button>
            </div>
        </td>
    </tr>
}

export default UploadItem
