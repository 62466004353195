import { Job } from '../_types/LSCScout.type';
import omit from 'lodash/omit';

import useResource, { ResourceDefinition } from './useResource';
import customFetch from '../../../lib/axiosInstance';

export interface JobParams {
	API_HOST: string;
	jobId: string;
}

const jobDef: ResourceDefinition<Job, JobParams> = {
	name: 'page',
	get: params =>
		customFetch
			.get(`/job/job/${params.jobId}`)
			.then(res => res.data) as Promise<Job>,
	update: (patch, params) =>
		customFetch
			.patch(
				`/job/job/${params.jobId}`,
				omit(patch, ['Logs', 'IFList'])
			)
			.then(res => res.data) as Promise<Job>,
	isProcessing: p => p.Status === 'processing'
};

function useJob(params: JobParams, seed: Job) {
	const { data, ...rest } = useResource(jobDef, params, seed);

	const spreadData: Job = { ...data };

	return { data: spreadData, ...rest };
}

export default useJob;
