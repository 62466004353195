import classNames from "classnames"
import React, { useMemo, useState } from "react"
import Swal from "sweetalert2"
import JobApi from "../lib/job"
import { emailValidator } from "../utils/utils"
import { getKeyOfType } from "../utils/utils"
import { InviteFormData, MessageNames, MessageContents } from "../types/types"
import { useForm } from "react-hook-form" 
import { useJob } from "../store/jobSlice"

type InviteUserProps = {
isOpen: boolean,
toggleOpen: (isOpen: boolean) => void,
toggleSending: (isSending: boolean) => void
};


const InviteUser = (props: InviteUserProps) => {

    const { register, handleSubmit, watch } = useForm<Omit<InviteFormData, "email">>({
        mode: 'onChange',
        defaultValues: {
            notes: '',
            preflyt: false,
            proofs: false,
            approve: false
        }
    })

    const { job } = useJob()

    type InviteEmail = {
        email: string,
        valid: boolean
    }
    
    // Field to contain emails, expected to be a comma-separated list of emails
    const [emailsField, setEmailsField] = useState<string>('')

    // state object to hold email strings and their valid statuses, as parsed from emailsField
    const [emails, setEmails] = useState<InviteEmail[]>([])

    // error string to show on submit if emailField is blank or any of the supplied emails are invalid
    const [emailSubmitError, setEmailSubmitError] = useState<string>('')

    const emailsValid = useMemo(() => {
        return emails.every(email => {
            // ignore 'blank' emails (although none should make it to here due to the logic in parseEmailsFromList())
            if (email.email === "") {
                return true;
            }
            return email.valid;
        })
    }, [emails])

    const messageSuggestedNotes: Record<MessageNames, MessageContents> = {
            preflyt: 'Please review preflight issues.\n\n',
            proofs: 'Please review and approve proofs.\n\n',
            approve: 'Please give final approval to print.\n\n'
    }

    const inviteSubmitHandler = handleSubmit(async (data) => {
        setEmailSubmitError('')

        let messageText = ''

        if (emails.length === 0) {
            setEmailSubmitError('Please include at least one user email.')
            return;
        }

        if (!emailsValid) {
            setEmailSubmitError('One or more of the emails supplied are invalid.')
            return;
        }
        
        // build clean comma-separated string out of the valid emails, 
        // with no extra white space or commas (if they were pasted into the field adjacent to one another, for some reason)
        const validEmails = []
        for (const email of emails) {
            validEmails.push(email.email)
        }        
        const validEmailsStr = validEmails.join(',')
        

        for (const [noteName, noteText] of Object.entries(messageSuggestedNotes)) {

            const inviteFormKey = getKeyOfType<Pick<InviteFormData, "preflyt" | "proofs" | "approve">>(data, noteName)

            if (inviteFormKey) {
                const inviteFormVal = Boolean(data[inviteFormKey])
                if (inviteFormVal) messageText += noteText;
            }

        }

        messageText += data.notes

        if (job && job.JobID) {
            props.toggleOpen(false)
            props.toggleSending(true)
            await JobApi.inviteToJob(job.JobID, validEmailsStr, messageText) 
            .then((res) => {
                props.toggleSending(false)
                Swal.fire({
                    icon: "success",
                    title: `Invite sent successfully.`,
                    timer: 4000,
                    toast: true,
                    position: 'top',
                    showClass: {
                        popup: 'swal-popover-in'
                    },
                    hideClass: {
                        popup: 'swal-popover-out'
    
                    },
                    showCloseButton: true,
                    showConfirmButton: false,
                    grow: 'row'
                })
            })
            .catch((err) => {
                if ((err.response && err.response.status === 404)) {
                    Swal.fire({
                        icon: "error",
                        title: "Error sending invite",
                        // TODO - do we want to try and list the specific emails not found in 
                        // a message from the back end?
                        text: `A user could not be found that had one or more of the supplied emails.` 
                    })
                    return;
                }
                Swal.fire({
                    icon: "error",
                    title: "Error sending invite",
                    text: "An error occurred while trying to send the invite."
                })
            }).finally(() => props.toggleSending(false))
        }
    })

    /** function to parse each of the (non-blank) emails from the emailsField comma-separated email list, validate them,
    and return them as an array of InviteEmail objects
    */
    const parseEmailsFromList = (commaSeparatedList: string): InviteEmail[] => {
         
        const splitEmailsList = commaSeparatedList.split(',')

        const splitTrimmedEmailsList = [];
        for (const el of splitEmailsList) {
            splitTrimmedEmailsList.push(el.trim())
        }

        const newEmailsObj: InviteEmail[] = []
        for (const emailStr of splitTrimmedEmailsList) {
            // skip 'blank' emails
            if (emailStr !== "") {
                newEmailsObj.push({
                    email: emailStr,
                    valid: emailValidator(emailStr)
                })
            }   
        }

        return newEmailsObj;
    }

    const emailsFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailSubmitError('')

        const { value } = event.currentTarget

        setEmailsField(value)

        const updatedEmailsObj = parseEmailsFromList(value)
        setEmails(updatedEmailsObj)
    }

    return (
    <form className={classNames("dropdown-menu", "invite-user-popover has-text-light-cascading-no-important", {"popover-active": props.isOpen})} role="form">
        <div className={classNames("has-background-dark dropdown-content popover-border")}>
            <section className="dropdown-item">
                <p className="mt-3 has-text-centered">Invite users</p>
            </section>
            <div className="dropdown-divider"></div>
            <fieldset> 
            <div className="field dropdown-item">
                <label className="ml-3">Email</label>
                <div className="control">
                    <input name="email" value={emailsField} onChange={emailsFieldChangeHandler} required className={classNames("input", {"is-danger": (emailSubmitError)})} type="email" multiple />
                </div>
            {emailSubmitError &&
            (
                <p className="help is-danger email-val-help">{emailSubmitError}</p>
            )}
            </div>
            <div className="field dropdown-item">
                <label className="ml-3">Notes</label>
                <div className="control">
                    <textarea {...register("notes")} name="notes" className="textarea"></textarea>
                </div>
            </div>

            <div className="field dropdown-item">
                <section>
                <p className="is-size-6 mb-1 ml-3">Suggested notes:</p>
                <hr className="m-0 p-0 mb-3" />
                    <fieldset className="popover-fieldset">
                        <div className="field popover-control-field">
                            <div className="control">
                                <label className="checkbox is-unselectable">
                                <input {...register("preflyt")} name="preflyt" className="mr-2" id="suggest-preflyt" type="checkbox" checked={watch("preflyt")}/>
                                Please review preflight issues.
                                </label>
                            </div>
                        </div>
                        <div className="field popover-control-field">
                            <div className="control">
                                <label className="checkbox is-unselectable">
                                <input {...register("proofs")} className="mr-2" name="proofs" id="suggest-proofs" type="checkbox" checked={watch("proofs")}/>
                                Please review and approve proofs.
                                </label>
                            </div>
                        </div>
                        <div className="field popover-control-field">
                            <div className="control">
                                <label className="checkbox is-unselectable">
                                <input {...register("approve")} className="mr-2" name="approve" id="suggest-approve" type="checkbox" checked={watch("approve")}/>
                                Please give final approval to print.
                                </label>
                            </div>
                        </div>
                    </fieldset>
                </section>
            </div>

            <div className="field dropdown-item">
                <div className="control popover-option-buttons">
                    <button onClick={inviteSubmitHandler} className="button is-primary" type="submit">Send invite</button>
                    <button onClick={() => props.toggleOpen(false)} className="button is-popover-cancel" type="button">Cancel</button>
                </div>
            </div>
            </fieldset>
        </div>
    </form>
    )
}

export default InviteUser;
