// visit Job
import {} from "react-router-dom";
import { store } from "../store";

export const visitHome = () => {
  const activeAccountId = store.getState().auth.activeAccountId
  return `/dashboard/${activeAccountId}`;
};

interface visitJobArg {
  new?: boolean;
  edit?: boolean;
  jobId?: string;
  accountId?: string;
  preflight?: boolean;
  part?: string;
  history?: boolean;
  ordinal?: number | number[];
}

export const visitJob = (args: visitJobArg) => {
  
  const state = store.getState()
  const activeAccountId = state.auth.activeAccountId

  // We don't have to validate the job as being part of the passed active account ID,
  // because a user will only ever be shown the jobs for the account they are active on.
  
  if (args.new) return `/dashboard/${activeAccountId}/add-title`;

  const jobId = args.jobId || store.getState().job.job?.JobID;
  const accountId = args.accountId || activeAccountId || store.getState().job.job?.Account.AccountID;

  let path = `/dashboard/${accountId}/${jobId}/`;
  if (args.part) path += `${args.part}/`;

  if (args.ordinal) path += `${args.ordinal}`;
  else if (args.preflight) path += `preflight`;
  else if (args.edit) path += `edit`;
  
  else if (args.history) path += `history`;
  return path;
};

// Navigation string helper for users Admin
interface visitUsersAdminArg {
  new?: boolean;
  userId?: string;
}

export const visitUsersAdmin = (args?: visitUsersAdminArg) => {
  
  let argStr = ''
  if (args) {
    if (args.new) {
      argStr = 'new'
    }
    else if (args.userId) {
      argStr = args.userId
    }
  }

  return `/dashboard/users` + (argStr ? `/${argStr}` : '');
};


