type UserAccount = Pick<
  Account,
  "AccountID" | "AccountName" | "Type" | "AllowFewerPages" | "AllowMorePages"
>;

// NOTE - only including one user role here as rolesets are subject to change.
// This role is used as a fallback if no other role is assigned on the UI.
export enum UserRoles {
    NoRoleAssigned = "LoginOnly"
}
export interface User {
  _id: string;
  Account: string;
  Accounts: UserAccount[];
  AccountType: AccountTypes;
  AllowFewerPages?: boolean;
  AllowMorePages?: boolean;
  DisplayName: string;
  Email: string;
  FirstName: string;
  GetstreamToken?: string;
  JobsList: { [accountName: string]: string[] };
  LastName: string;
  Language: string;
  LoggedIn: string;
  LoginMethod: string;
  LoginTime: string;
  Modified: string;
  MQTT: MQTTDetails;
  Permissions: UserPermission[];
  Prefix: string;
  Preferences: {
    units: string;
  };
  Role: string;
  Roles: string[];
  Status: string;
  sessiontoken: string;
  Title: string;
  UserID: string;
  getStreamTokens: {
    token: any;
    feeds: {
      user: {
        feedSlug: string;
        userId: string;
        token: string;
      };
      notification: {
        feedSlug: string;
        userId: string;
        token: string;
      };
    };
  };
}

export interface MQTTDetails {
  URL: string;
  User: string;
  Password: string;
  Path: string;
  Port: number;
}

export interface Account {
  AccountName: string;
  AccountID: string;
  AccountRoles: string;
  CustomerNumber: number;
  AccountAdmins: string[];
  Representatives: string[];
  Status: string;
  State: any;
  Roleset: any;
  Type: AccountTypes;
  AddBOD: boolean;
  BODPage: number;
  BODPosition: [number, number];
  BODOrientation: string; // ???
  AutoDeliverNewTitles: boolean;
  AllowFewerPages: boolean;
  AllowMorePages?: boolean;
  SubAccounts?: string[];
}

// TODO - we have Account.Type (in the Account interface above) as a string literal union,
// and we also have the enum AccountTypes, here below.
// These seem to be duplicate typings,
// but this will likely not be an issue any more once \@lsp-types is adopted and these objects are re-typed.
export enum AccountTypes {
  Book = "Book",
  TEP = "TEP",
  Combined = "TEPScout",
}

export enum UserPermission {
  Account_Create = "Account_Create",
  Account_Delete = "Account_Delete",
  Account_Edit = "Account_Edit",
  Account_List = "Account_List",
  Account_Prefs = "Account_Prefs",
  Job_Chat = "Job_Chat",
  Job_Copy = "Job_Copy",
  Job_Create = "Job_Create",
  Job_DeleteAny = "Job_DeleteAny",
  Job_DeleteLocked = "Job_DeleteLocked",
  Job_DeleteMine = "Job_DeleteMine",
  Job_Edit = "Job_Edit",
  Job_Invite = "Job_Invite",
  Job_ListAll = "Job_ListAll",
  Job_List = "Job_List",
  Job_Proof = "Job_Proof",
  Job_HiRes = "Job_HiRes",
  Job_Download = "Job_Download",
  Page_Add = "Page_Add",
  Page_Comment = "Page_Comment",
  Page_Delete = "Page_Delete",
  Page_Reorder = "Page_Reorder",
  Page_Edit = "Page_Edit",
  Page_List = "Page_List",
  Page_Proof = "Page_Proof",
  Preflight1_ApplyFix = "Preflight1_ApplyFix",
  Preflight1_Signoff = "Preflight1_Signoff",
  Preflight1_SignoffOverride = "Preflight1_SignoffOverride",
  Preflight2_Signoff = "Preflight2_Signoff",
  Preflight2_SignoffOverride = "Preflight2_SignoffOverride",
  System_Rolesets = "System_Rolesets",
  System_BackupRestore = "System_BackupRestore",
  System_DBConfig = "System_DBConfig",
  System_EmailConfig = "System_EmailConfig",
  System_FileStore = "System_FileStore",
  System_LoadBalance = "System_LoadBalance",
  System_Logs = "System_Logs",
  System_Reset = "System_Reset",
  System_WebConfig = "System_WebConfig",
  TEP_Upload = "TEP_Upload",
  Transmit = "Transmit",
  User_Create = "User_Create",
  User_Delete = "User_Delete",
  User_Edit = "User_Edit",
  User_List = "User_List",
}
