import classNames from "classnames"
import { useNavigate, useParams } from "react-router-dom"
import { visitJob } from "../routes/visit"
import { useJob } from "../store/jobSlice"
import { PagePart } from "../types/pages.types"
import { getPart } from "../utils/parts"

function OrganizerSidebar() {
    const { part: paramPart } = useParams()

    const navigate = useNavigate()
    const { job } = useJob()

    const partClickHandler = (part: PagePart | string) => () => {        
        navigate(visitJob({ part: part }))
    }

    return <table className="table has-background-dark has-text-white">
        <tbody>
            {
                Object.entries(job?.PartPages || {}).filter(([part, count]) => part !== PagePart.UnassignedCorrections).map(([part, count]) => {
                    return <tr key={part}>
                        <td onClick={partClickHandler(part)} className={classNames("is-size-7 is-clickable", { "has-background-primary": part === paramPart })}>
                            {getPart(part)?.name} ({count})
                        </td>
                    </tr>
                })
            }
        </tbody>
    </table>
}

export default OrganizerSidebar