import { Link } from "react-router-dom"

function Notfound() {
    return <section className="has-background-primary" data-testid="notfound-page">
        <div className="is-size-3 has-text-centered has-text-white">
            404 <br />
            There&apos;s nothing here! <br />
            {":("}
        </div>
        <div className="has-text-centered">
            <Link to={"/"} className="button is-link mt-3">Go back home</Link>
        </div>
    </section>
}

export default Notfound
