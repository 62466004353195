import { Middleware, MiddlewareAPI } from "@reduxjs/toolkit"
import { MqttClient } from "mqtt/dist/mqtt";
import mqttService, { cleanTopic } from "./mqtt";
import { mqttActions, mqttState } from "./mqttSlice";

type ReduxMQTTCallbackInfo = {
    topic: string,
    label?: string
}

type ReduxMQTTCallback<T = any> = (info: ReduxMQTTCallbackInfo, data: T, storeApi: MiddlewareAPI) => void

type ReduxMQTTOptions = {
    debug: boolean
}

function createMQTTMiddleware<T = any>(callback: ReduxMQTTCallback<T>, options?: ReduxMQTTOptions) {
    const { debug } = options || {};
    const mqttMiddleware: Middleware<unknown, { mqtt: mqttState }> = storeApi => {
        let mqttClient: MqttClient;
        return next => action => {

            const isConnected = mqttClient && storeApi.getState().mqtt.connected;
            
            if (mqttActions.connectMQTT.match(action)) {
                mqttClient = mqttService.connect(action.payload);
                if (debug) console.debug("middleware: connecting to mqtt", action.payload);

                mqttClient.on("connect", () => {
                    if (debug) console.debug("middleware: connection created");
                    storeApi.dispatch(mqttActions.connectionSuccess())
                })

                mqttClient.on("disconnect", (disconnPacket) => {
                    if (debug) {
                        console.debug("middleware: connection lost");
                        if (disconnPacket.reasonCode === 0) {
                            console.debug("middleware: reason for connection loss - MQTT disconnected normally.")
                        }
                        if (disconnPacket.reasonCode === 142) {
                            console.debug('middleware: reason for connection loss - Another connection was opened using the same client ID.')
                        }
                    }
                    storeApi.dispatch(mqttActions.connectionLost(disconnPacket.reasonCode || undefined))
                })

                mqttClient.on("message", (topic, payload, packet) => {
                    if (debug) console.debug("middleware: message in");
                    const topicVal = topic;
                    const data = JSON.parse(payload.toString());
                    const labels = storeApi.getState().mqtt.subscriptions;
                    const matchingSub = topicVal ? Object.entries(labels).find(([label, values]) => values.includes(topicVal)) : undefined;
                    const label = matchingSub ? matchingSub[0] : undefined;
                    callback({ topic, label }, data, storeApi);
                })

            } else if (mqttActions.subscribeChannel.match(action)) {
                if (debug) console.debug("middleware: subscribing to channel", action.payload)
                mqttClient.subscribe(action.payload.topic, { qos: 0 });
            } else if (mqttActions.unsubscribeChannel.match(action)) {
                if (debug) console.debug("middleware: unsubscribing to channel", action.payload)
                const { topic, label } = action.payload;
                const unsubTopic = label ? storeApi.getState().mqtt.subscriptions[label] : topic;
                if (unsubTopic) mqttClient.unsubscribe(unsubTopic);
            } else if (mqttActions.disconnectMQTT.match(action)) {
                if (mqttClient.connected) {
                    console.debug('middleware: ending MQTT connection')
                    mqttClient.end();
                }
            }

            next(action);
        }
    }
    return mqttMiddleware;
}

export {
    createMQTTMiddleware,
    ReduxMQTTCallback
}