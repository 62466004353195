import { put } from "redux-saga/effects";
import { LoginPayload } from "../types/store.types";
import {
  loginError,
  loginSuccess,
  logoutError,
  logoutSuccess,
  setActiveAccount,
} from "../store/authSlice";
import AuthApi from "../lib/auth";
import { PayloadAction } from "@reduxjs/toolkit";
import { search, setPageNumber } from "../store/searchSlice";
import { AccountTypes, MQTTDetails, User } from "../types/user.types";
import { mqttActions } from "../mqtt";
import { MqttLabels } from "../mqtt/mqtt-redux";
import { setGetStreamTokens } from "../utils/getstream";
import { AxiosResponse } from "axios";

function getMQTTConnectionData(mqttOptions?: MQTTDetails, clientId?: string) {
  const mqttOptionString = localStorage.getItem("mqtt-options");
  const localMqttOptions = mqttOptionString
    ? JSON.parse(mqttOptionString)
    : null;
  try {
    const connectionOption = mqttOptions
      ? {
        host: new URL(mqttOptions.URL).host,
        hostname: new URL(mqttOptions.URL).host,
        protocol: "wss",
        port: mqttOptions.Port,
        clientId,
        path: mqttOptions.Path,
        username: mqttOptions.User,
        password: mqttOptions.Password,
        protocolVersion: 5,
      }
      : localMqttOptions;
    localStorage.setItem("mqtt-options", JSON.stringify(connectionOption));

    return connectionOption;
  } catch (e) {
    console.log(e);
    console.log("couldn't establish connection");
  }
}

export function* loginUser({ payload }: PayloadAction<LoginPayload>) {
  const { email, password, isIframe } = payload;
  try {
    const { data }: { data: User } = isIframe
      ? yield AuthApi.keycloakLogin()
      : yield AuthApi.login(email, password);
    setGetStreamTokens(data.getStreamTokens);
    const connectionData = getMQTTConnectionData(data.MQTT, data.UserID);
    yield put(mqttActions.connectMQTT(connectionData));

    // We will always first log a user in to their default account, as
    // we can't make any reliable assumptions about a session.
    yield put(setActiveAccount(data.Accounts[0].AccountID));
  } catch (error: any) {
    const errorMsg = error.response?.data?.Error || "Error logging in";
    yield put(loginError(errorMsg));
  }
}

export function* loginUserSession() {
  try {
    // We will fetch the user's "preferences" endpoint, which will contain any session token that they previously acquired for the active session.
    // If a session token is not there, this will just return the UI to the login page.
    const { data }: { data: User; } = yield AuthApi.getPreference();

    setGetStreamTokens(data.getStreamTokens);
    const connectionData = getMQTTConnectionData(data.MQTT);
    yield put(mqttActions.connectMQTT(connectionData));

    // From a successful '/preferences' fetch, the API will hand back the current active session account ID under "Account";
    // As a result, we can easily sync the front-end account's state with the state of the API.
    // If for some reason `data.Account` is not defined, we will then fall back to the default account ID for the user.
    const account = data.Account || data.Accounts[0].AccountID;
    // The below should be virtually impossible:
    if (!account) {
      throw new Error('Could not get user\'s account from /preferences call in loginUserSession.');
    }
    if (account) yield put(setActiveAccount(account));
  } catch (error) {
    console.error(error);
    yield put(loginError("Your login session has expired."));
  }
}

export function* setUserAccount({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse = yield AuthApi.setAccount(payload, true);

    const { data, status }: {
      data: User,
      status: number
    } = res

    // if status is 404, call /setaccount again with the default account ID
    if (status === 404) {
      yield put(setActiveAccount(data.Accounts[0].AccountID));
      return;
    }


    let accountsSub;
    if (data.AccountType === AccountTypes.Combined) {
      accountsSub = data.Accounts.filter(
        (account) =>
          account.Type === AccountTypes.TEP || account.Type == AccountTypes.Book
      ).map((account) => `/account/${account.AccountID}`);
    } else {
      accountsSub = `/account/${data.Account}`;
    }

    yield put(
      mqttActions.unsubscribeChannel({ label: MqttLabels.ACTIVE_ACCOUNT })
    );
    yield put(
      mqttActions.subscribeChannel({
        topic: accountsSub,
        label: MqttLabels.ACTIVE_ACCOUNT,
      })
    );
    yield put(loginSuccess(data));
    yield put(setPageNumber(1));
    yield put(search());
    localStorage.setItem("account", payload);
  } catch (error) {
    console.error(error);
  }
}

export function* logoutUser() {
  try {
    yield AuthApi.logout();
    yield put(logoutSuccess());
    yield put(mqttActions.disconnectMQTT());
    localStorage.removeItem("account");
  } catch (error) {
    yield put(logoutError());
  }
}
