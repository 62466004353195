import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { FaLink, FaPlus, FaTrash } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import Swal from "sweetalert2"
import { v4 } from "uuid"
import JobApi from "../lib/job"
import { visitJob } from "../routes/visit"
import { useAuthState } from "../store/authSlice"
import { setJobId, useJob } from "../store/jobSlice"
import { Job, JobIdData } from "../types/job.types"
import { CommonTitlesPatchData } from "../types/jobApi.types"
import { User, UserPermission } from "../types/user.types"
import { userHasAllPermissionsInList } from "../utils/user"
import { customerReferenceValidator, isAnIsbn, validateIsbnFormInput } from "../utils/utils"


type CommonTitlesProps = {
    isOpen: boolean,
    toggleOpen: (value: boolean) => void,
}


const CommonTitles = (props: CommonTitlesProps) => {

    const navigate = useNavigate()

    const { job, jobIsActive } = useJob()
    const { user } = useAuthState()
    const dispatch = useDispatch()

    const [commonIsbnsList, setCommonIsbnsList] = useState<Job["AssociatedISBNs"]>(job && job.AssociatedISBNs ? job.AssociatedISBNs : [])
    const [commonIsbnEntryField, setCommonIsbnEntryField] = useState("")

    // ref is used to detect input focus on form submit
    // on enter/return key press, if input is focused: 
    // 'add isbn' events will be fired instead of form submit events
    const isbnEntryFieldInputRef = useRef<HTMLInputElement>(null)

    const [isbnEntryFieldError, setIsbnEntryFieldError] = useState("")
    const [listModified, setListModified] = useState(false)

    const fetchJobLinksAndIdData = async () => {
        if (commonIsbnsList) {
            // only fetch the Job ID link & data if it's not already present from a prior fetch:
            commonIsbnsList.map(async (isbnOrCr) => {
                if (!titleLinkFetchResults[isbnOrCr]) {
                    return await JobApi.getJobIdDataByIsbnOrCr(isbnOrCr)
                        .then((foundJobIdData) => {
                            const commonTitleJobId = foundJobIdData?.JobID
                            if (commonTitleJobId && !commonTitlesJobIdRecord[commonTitleJobId]) {
                                setCommonTitleJobIdRecord((oldRecord) => {
                                    const updatedIdRecord = { ...oldRecord }
                                    updatedIdRecord[commonTitleJobId] = foundJobIdData;
                                    return updatedIdRecord;
                                })
                            }
                            return setTitleLinkFetchResults((oldRecord) => {
                                const updatedRecord = { ...oldRecord }
                                updatedRecord[isbnOrCr] = foundJobIdData;
                                return updatedRecord;
                            })
                        })
                        .catch(err => console.log(err))
                }
            })
        }
    }

    const findAndLinkExistingJobs = async () => {
        return await fetchJobLinksAndIdData();
    }

    const [titleLinkFetchResults, setTitleLinkFetchResults] = useState<Record<string, JobIdData>>({})
    const [linkedTitles, setLinkedTitles] = useState<Record<string, JobIdData>>({})

    const [commonTitlesJobIdRecord, setCommonTitleJobIdRecord] = useState<Record<string, JobIdData>>({})

    const initialIsbnListLength = job?.AssociatedISBNs?.length || 0;

    useEffect(() => {
        findAndLinkExistingJobs();
    }, [commonIsbnsList])

    useEffect(() => {
        return setLinkedTitles(titleLinkFetchResults)
    }, [titleLinkFetchResults])

    useEffect(() => {
        if (!listModified && commonIsbnsList?.length !== initialIsbnListLength) setListModified(true);
    }, [commonIsbnsList])

    const commonIsbnEntryFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget
        if (value && value.indexOf("-") >= 0) {
            setIsbnEntryFieldError('Please do not include hyphens in the ISBN input.')
        }
        else {
            setIsbnEntryFieldError('')
        }
        setCommonIsbnEntryField(value)
    }

    const userCanEditCommonTitles = (user?: User): boolean => {
        if (user) {
            return userHasAllPermissionsInList([
                UserPermission.Job_Edit,
                UserPermission.Preflight1_Signoff,
                UserPermission.Preflight1_ApplyFix,
                UserPermission.Preflight2_Signoff
            ], user);
        }
        return false;
    }

    const submitHandler = async (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
            // if the submit event was fired on the form while the isbn entry input field has focus:
            // (i.e. if the enter/return key was pressed while the field has focus)
            if (isbnEntryFieldInputRef?.current && isbnEntryFieldInputRef.current === document.activeElement) {
                // catch the event and pass it to the 'Add' button's event handler:
                return await addCommonIsbnHandler();
            }
        }
        if (!listModified) return;
        if (!userCanEditCommonTitles(user)) {
            await Swal.fire({
                icon: "info",
                title: "Insufficient permissions",
                text: "Your user account doesn't have the required permissions to update a job's common titles."
            })
            return;
        }
        const updatedCommonTitlesData: CommonTitlesPatchData = {
            AssociatedISBNs: commonIsbnsList
        }
        if (job && job.JobID) {
            await JobApi.updateJobCommonTitles(job.JobID, updatedCommonTitlesData)
                .then((res) => {
                    props.toggleOpen(false);
                    // The below dispatch acts as a 'soft' refresh to make the saved changes render to the UI
                    if (job?.JobID) dispatch(setJobId(job.JobID));

                    return Swal.fire({
                        icon: "success",
                        title: `Common titles updated successfully.`,
                        timer: 4000,
                        toast: true,
                        position: 'top',
                        showClass: {
                            popup: 'swal-popover-in'
                        },
                        hideClass: {
                            popup: 'swal-popover-out'

                        },
                        showCloseButton: true,
                        showConfirmButton: false,
                        grow: 'row'
                    })
                })
                .catch(err => {
                    let errorMsg;
                    if (err.response) {
                        if (err.response.data["Error"]) {
                            errorMsg = err.response.data["Error"]
                        }
                    }
                    return Swal.fire({
                        icon: "error",
                        title: "Error updating common titles",
                        text: errorMsg || "Something went wrong while trying to update the job's common titles.",
                    })
                })
        }
    }

    const addCommonIsbnHandler = async () => {
        if (!jobIsActive) {
            return;
        }

        const validAdditionToList: boolean = await validateAddIsbnToList(commonIsbnEntryField).then(isbnValidOnList => isbnValidOnList)

        if (!validAdditionToList) {
            return;
        }

        setCommonIsbnsList(oldVal => {
            const updatedList = oldVal ? [...oldVal] : [];
            updatedList.push(commonIsbnEntryField)
            return updatedList;
        })
    }

    const isOwnJobsIdentifier = (job: Job, valueToCheckFor: string) => {
        if (job) {
            const jobIdentifierMatchesValue = ([job.ISBN10, job.ISBN13, job.CustomerReference].some((jobExistingValue) => (jobExistingValue === valueToCheckFor)))
            return jobIdentifierMatchesValue;
        }
    }

    /**
     * @returns {Promise<string|false|undefined>}  
     * - string - returns the job's other unique id that was found on the list
     * - false - when none of the job's other unique ids were found on the list
     * - undefined - if somehow the job was not available in state (should never occur)
     */
    const oneOfJobsUniqueIdsAlreadyOnList = async (initialUniqueId: string, job: Job, jobIdDataRecord: Record<string, JobIdData>): Promise<string | false | undefined> => {
        if (job) {
            // If the new isbn value's fetched JobID *already* exists for an item on the list,
            // the job is on the list under a different 'secondary' unique identifier - either ISBN13, ISBN10, or CR#.
            return await JobApi.getJobIdDataByIsbnOrCr(initialUniqueId)
                .then(foundJobIdData => {
                    if (foundJobIdData.JobID) {
                        const jobIdForNewListVal = foundJobIdData.JobID
                        if (jobIdDataRecord[jobIdForNewListVal]) {
                            const existingJobIdData = jobIdDataRecord[jobIdForNewListVal]
                            const fieldAlreadyOnList = Object.values(existingJobIdData).find(jobIdentifyingField => commonIsbnsList?.includes(jobIdentifyingField));
                            if (fieldAlreadyOnList) {
                                return fieldAlreadyOnList;
                            }
                        }
                    }
                    else return false;
                })
                .catch(err => {
                    console.log(err)
                    return false; // on error, choosing to 'fail' in a way that should not break other runtime behavior
                })
        }
    }

    const validateAddIsbnToList = async (value: string): Promise<boolean> => {

        // if the below call returns a string, it has returned a custom error message to be shown on the UI.
        const isbnIsValid: string | boolean = await validateIsbnFormInput(value, false)

        if (!isbnIsValid) {
            setIsbnEntryFieldError('Value must be a valid ISBN-13.')
            return false;
        }

        if (typeof isbnIsValid === 'string') {
            setIsbnEntryFieldError(isbnIsValid)
            return false;
        }

        if (commonIsbnsList && commonIsbnsList.length === 10) {
            setCommonIsbnEntryField('Max number of common titles reached. (10/10)')
            return false;
        }

        const isbnIsAlreadyOnList = commonIsbnsList?.some(entry => entry === commonIsbnEntryField) ? true : false;

        if (isbnIsAlreadyOnList) {
            setIsbnEntryFieldError('The entered value is already on the list.')
            return false;
        }

        if (job && isOwnJobsIdentifier(job, value)) {
            setIsbnEntryFieldError(`Cannot add a title to its own common title list.`)
            return false;
        }

        // This will catch the special case of when a common title is already listed under a unique ID(isbn13/isbn10/cr#), 
        // and a user attempts to add the common title under one of its other unique IDs 
        if (job && Object.keys(linkedTitles).length > 0) {
            const otherUniqueIdOnList = await oneOfJobsUniqueIdsAlreadyOnList(value, job, commonTitlesJobIdRecord)
            if (typeof otherUniqueIdOnList === 'string') {
                setIsbnEntryFieldError(`The common title is already on the list under "${otherUniqueIdOnList}."`)
                return false;
            }
        }

        return true;

    }

    const confirmLeaveWithUnsavedChanges = async () => {
        const { isConfirmed } = await Swal.fire({
            icon: "warning",
            title: "Unsaved changes",
            text: "The job's common titles list has been modified, but the changes are not saved. Are you sure you want to close the Common Titles dialog?",
            showCancelButton: true,
            focusCancel: true,
            confirmButtonText: "Yes, close anyways",
            cancelButtonText: "Cancel",
            reverseButtons: true
        })
        return isConfirmed;
    }

    const cancelHandler = async () => {
        if (listModified) {
            return await confirmLeaveWithUnsavedChanges()
                .then(confirmed => {
                    if (confirmed) {
                        props.toggleOpen(false)
                    }
                })
                .catch(err => {
                    console.log(err)
                    props.toggleOpen(false)
                })
        }
        else {
            props.toggleOpen(false)
        }
    }

    const deleteCommonIsbnHandler = (event: React.MouseEvent<HTMLButtonElement>) => {

        if (event && event.currentTarget && event.currentTarget.dataset && event.currentTarget.dataset.rowid) {

            setIsbnEntryFieldError('')

            const deletedItemId = event.currentTarget.dataset.rowid;

            const itemIndex = event.currentTarget.dataset.itemindex || undefined;
            if (itemIndex) {
                const indexVal = Number(itemIndex)
                setCommonIsbnsList(oldVal => {
                    const updatedList = oldVal ? [...oldVal] : [];
                    updatedList.splice(indexVal, 1)
                    return updatedList;
                })
            }
        }
    }

    const isCustomerReference = (listItem: string): boolean => {
        // Note: using a 'true' literal here since this method can also return strings that could evaluate as truthy -
        // in this instance, a boolean literal of value "true" is what the conditional is checking for in the conditions below
        if (!isAnIsbn(listItem)) {
            if (customerReferenceValidator(listItem) === true) {
                return true;
            }
        }
        return false;
    }

    const navigateToJobWithIsbnHandler = async (event: React.MouseEvent<HTMLInputElement>) => {
        if (event) {
            const { dataset } = event.currentTarget
            if (dataset) {
                if (typeof dataset.linkedjobid === 'string' && dataset.linkedjobid !== '') {
                    if (listModified) {
                        const confirmLeaveOnModified = await confirmLeaveWithUnsavedChanges()
                        if (!confirmLeaveOnModified) return;
                    }
                    props.toggleOpen(false)
                    navigate(visitJob({ jobId: dataset.linkedjobid }), { replace: true })
                }
            }
        }
    }



    return (
        <div className={classNames('modal has-text-light-cascading-no-important', { "is-active": props.isOpen })}>
            <div onClick={async () => await cancelHandler()} className='modal-background'></div>
            {/* todo - styles to be placed in scss w/ css restyle */}
            <div style={{ width: 400 }} className={classNames('modal-content', 'box', 'copy-modal', 'p-5', 'has-background-grey')}>
                <header>
                    {/* todo - styles for both p elements - css restyle */}
                    <p style={{ fontWeight: 'bold', fontSize: '20px' }}>{(job && job.Title) ? (<span className="title-to-copy-name">{job.Title.length > 100 ? job.Title.substring(0, 100) + '...' : job.Title}</span>) : 'Currently selected job'}</p>
                    {job && (
                        <div>
                            {/* The back end adds AssociatedISBNs values for a jobcopy operation in the following order of fallbacks:
                    ISBN13, ISBN10, CR#
                Here, the title's identifiers are shown in the same order of fallbacks: */}
                            <p>
                                {
                                    (job.ISBN13 || job.ISBN10) ? 'ISBN' : job.CustomerReference ? 'CR #' : 'ISBN/CR #'
                                }
                                {': '}
                                <span style={{ fontStyle: 'normal', color: 'black !important' }} className="is-size-7 has-text-weight-bold">{job.ISBN13 || job.ISBN10 || job.CustomerReference || "No ISBN or CR #"}</span>
                            </p>
                        </div>
                    )
                    }
                    <p className="has-text-weight-bold is-size-7" >Common titles by ISBN/C.R.</p>
                </header>
                {/* todo - css restyle on hr */}
                <hr style={{ margin: '5px 0 35px 0' }} />
                {/* TODO - copy-modal-form classname needs renamed or other solution to unclear naming for this other use */}
                <form onSubmit={submitHandler} className="copy-modal-form is-flex is-flex-direction-row is-justify-content-space-around is-align-items-flex-start">
                    {/* todo - styles to be placed in scss w/ css restyle */}
                    <div style={{ width: '90%' }}>
                        <div className='control is-text-control is-flex is-flex-direction-column end-of-column'>
                            <label className="is-text-input-label" htmlFor='common-isbn-list-input'>Common title ISBN</label>
                            <div className="add-other-isbn-field-wrapper field is-grouped has-addons">
                                <input ref={isbnEntryFieldInputRef} disabled={!jobIsActive} value={commonIsbnEntryField} placeholder="Add a common title by ISBN" onChange={commonIsbnEntryFieldChangeHandler} className={classNames('input', { "is-danger": isbnEntryFieldError })}
                                    id="common-isbn-list-input" type='text' maxLength={13} />
                                <button disabled={!jobIsActive} onClick={async () => await addCommonIsbnHandler()} type="button" className={classNames("button is-info", { "disabled-custom-control": !jobIsActive })}>
                                    <span className="icon is-small"><FaPlus /></span>
                                    <span>Add</span>
                                </button>
                            </div>
                        </div>
                        {/* todo - add styles to scss as part of restyle - */}
                        <div style={{ height: 37 }} className="error-field-text isbn-list-entry-error-text">
                            <p className="has-text-danger">{isbnEntryFieldError ? isbnEntryFieldError : ''}</p>
                        </div>
                        <div className={classNames("table-container-and-message-wrapper", { "show-no-isbns-message": (commonIsbnsList?.length === 0) })} >
                            {(commonIsbnsList?.length === 0) && <span className="no-isbns">{'(No common ISBNs)'}</span>}
                            <div className="table-container">
                                <table className='isbn-table table is-fullwidth'>
                                    {/*  is-striped is-hoverable */}
                                    <tbody>
                                        {commonIsbnsList && commonIsbnsList.length > 0 && commonIsbnsList.map((isbn, index) => {
                                            const uniqueListItemId = v4();
                                            const linkedJobIdResult = titleLinkFetchResults?.[isbn]?.JobID
                                            const linkedJobId = linkedTitles?.[isbn]?.JobID
                                            return (
                                                <tr key={uniqueListItemId} data-itemindex={index} data-rowid={uniqueListItemId} className="tr" >
                                                    <td className={classNames("td common-title-flex-cell", { "has-text-link": linkedJobId })}>
                                                        {/* todo - css styles refactor */}
                                                        {isCustomerReference(isbn) && <span style={{ position: 'absolute', fontSize: '7px', fontStyle: 'italic', left: 2, top: 0, zIndex: 999 }}>(C.R.)</span>}

                                                        {/* todo - styles to add to CSS */}
                                                        <div data-linkedjobid={linkedJobIdResult} className={classNames("job-link commonTitleListItem", { "common-title-link": linkedJobId })} onClick={navigateToJobWithIsbnHandler}>
                                                            <span style={{ fontSize: '0.8em' }} className='link-container'>
                                                                {(linkedJobId) && <span style={{ zIndex: 9999 }} className='positioned-link-badge'><FaLink /></span>}
                                                            </span>
                                                            <span title={isbn} data-linkedjobid={linkedJobIdResult}>{isbn}</span>
                                                        </div>
                                                        {/* todo CSS refactor */}
                                                        <div style={{ top: 0 }} className="other-isbn-context-buttons field is-grouped">
                                                            <button disabled={!jobIsActive} data-rowid={uniqueListItemId} data-itemindex={index} type="button" onClick={deleteCommonIsbnHandler} className="button other-isbn other-isbn-delete">
                                                                <span>
                                                                    <FaTrash size={20} />
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="mt-5 field is-grouped modal-button-options px-6 is-flex is-flex-direction-row is-justify-content-space-around is-align-items-center">
                    <button type="button" onClick={async () => await cancelHandler()} className="button is-dark is-secondary">{!listModified ? 'Close' : 'Cancel'}</button>
                    <button disabled={!listModified} type="submit" onClick={submitHandler} className="button is-primary">Save changes</button>
                </div>
            </div>
        </div>
    );
}

export default CommonTitles;