import { PdfFileType } from "../components/Upload/UploadModal"
import { Preflyt1TagsIssueData } from "./job.types"

export enum PagePart {
    Text = "text",
    Cover = "cover",
    Caseside = "caseside",
    FrontCover = "frontCover",
    BackCover = "backCover",
    Jacket = "jacket",
    Tab = "tab",
    InsertBound = "insertBound",
    InsertLoose = "insertLoose",
    FrontEndsheet = "frontEndsheet",
    BackEndsheet = "backEndsheet",
    Card = "card",
    Onlay = "onlay",
    BellyBand = "bellyBand",
    JWrap = "jWrap",
    BoxWrap = "boxWrap",
    Die = "die",
    Sticker = "sticker",
    UnassignedCorrections = "shelf"
}

export type PagePartKeys = keyof typeof PagePart

export type PagePartKeys_LowerFirstLetter = Uncapitalize<PagePart>
export type PagePartKeys_UpperFirstLetter = Capitalize<PagePartKeys_LowerFirstLetter>

export interface Page {
    Folio: string,
    Image: string,
    Ordinal: number,
    PageID: string,
    Part: PagePart,
    RegenThumb: boolean,
    Status: string, // TODO there has to be an enum of status
    Thumb: string,
    Tags: {
        Preflyt1: {
            Warnings: Preflyt1TagsIssueData,
            Errors: Preflyt1TagsIssueData,
            [k: string]: any // TODO add more detail
        }
        [k: string]: any // TODO - add more detail 
    }
    Workflow?: {
        Preflyt1?: {
            Warnings?: Preflyt1WorkflowIssueReport[],
            Errors?: Preflyt1WorkflowIssueReport[]
        }
    }
}



// TODO right now, this typing is trivial - just for semantic and logical separation.
export type Preflyt1TagsWarningData = Preflyt1TagsIssueData
export type Preflyt1TagsErrorData = Preflyt1TagsIssueData

export interface Preflyt1WorkflowIssueReport {
    RuleID?: string,
    Name?: string,
    Description?: string,
    Hits?: Preflyt1Hit[],
    AutoFix?: boolean
}

export type Preflyt1Hit = {
    Location?: { [k: string]: any } // location obj - needs better typing,
    Type?: string // needs better typing, again
}

// TODO right now, this typing is trivial - but it could matter for a logical program structure.
// \@lsp-types\ will probably have a better solution.
export type Preflyt1WorkflowWarningData = Preflyt1WorkflowIssueReport;
export type Preflyt1WorkflowErrorData = Preflyt1WorkflowIssueReport;

export enum TagStatuses {
    "error",
    "failed",
    "invalidated",
    "passed",
    "Complete",
    "Processing",
    "reset",
    "successful",
    "N/A"
}

export interface Part {
    part: string;
    name: string;
    suffix: string;
    disposition?: string;
}

interface VariantPart extends Part {
    parent: string;
}

export interface TopLevelPart extends Part {
    variants?: VariantPart[];
}

export type PartTree = TopLevelPart[];

export enum StageStatus {
    COMPLETE = "Complete",
    ERROR = "error",
    FAILED = "Failed",
    IN_PRODUCTION = "In Production",
    IN_PROGRESS = "In Progress",
    PROCESSING = "Processing",
    WAITING = "Waiting",
    WAITING_APPROVAL = "Waiting On Approval"
}

export enum PageReviewStatus {
    APPROVED = "approved",
    NOT_REVIEWED = "Not Reviewed",
    REJECTED = "rejected"
}

export enum PageBox {
    CROP = "Crop",
    CUSTOM = "Custom",
    MEDIA = "Media",
    TRIM = "Trim"
}

export enum UnitsTypes {
    INCHES = "inches",
    MILLIMETERS = "millimeters",
    POINTS = "points"
}

export enum CustomBoxOperationType {
    ASCALECENTER = "ascalecenter",
    BUMP = "bump",
    CENTER = "center",
    UNDOSCALE = "undoscale"
}

export type UploadPageData = {
    pdfFile: PdfFileType,
    last: boolean,
    key?: string
}

export type UploadPageProgress = {
    total: number,
    loaded: number,
    percent: number,
    key?: string
}

export enum HoldingKeys {
    Ctrl,
    Shift
}

export type PartPageThumbnailType = {
    page: Page,
    visible: boolean,
    onClick?: (pageOrdinal: number, held?: HoldingKeys) => void,
    selected?: boolean,
    size?: number,
    partVal?: string
}
