import classNames from "classnames";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux";
import AuthApi from "../lib/auth";
import Redirect from "../routes/Redirect";
import { login, loginSession, useAuthState } from "../store/authSlice";
import { emailValidator, isKeyCloak } from "../utils/utils";
import { hiddenLoginErrors } from "./Login.utils";

function Login() {
    const [hasSession, setHasSession] = useState(false);
    const [disableLogin, setDisableLogin] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm<{ email: string, password: string }>();
    const { loading, error } = useAuthState()
    const dispatch = useDispatch();
    const autolaunch = (new URLSearchParams(window.location.search)).get("autolaunch");
    const redirectURL = process.env?.REACT_APP_REDIRECT_TARGET as string;

    const submitHandler = handleSubmit(({ email, password }) => {
        dispatch(login({ email, password }))
    })

    useEffect(() => {
        if (isKeyCloak()) {
            // do a keycloak login here
            setHasSession(true);
            dispatch(login({ isIframe: true }));
            return;
        }

        if (!error) {
        // Here, at first when no login errors have occurred - 
        // we are going to dispatch loginSession regardless of if we know the user is logged into the API or not.
        // If they have a valid session, it will be returned. If not, the login screen will still be here.
        // We will always initially try once to set a session, since we can't check whether to set a session or not based on the cookie's presence or absence. (We cannot necessarily access it programmatically)
            setHasSession(true);
            dispatch(loginSession())
        } else setHasSession(false);
    }, [dispatch])

    useEffect(() => {
        if (error && hasSession) setHasSession(false);
    }, [error, hasSession])

    useEffect(() => {
        let cancelled = false;

        AuthApi.checkServerHealth()
            .then(() => {
                if (cancelled) {
                    // avoid setting state since it will throw a React error
                    return;
                }
                setDisableLogin(false);
            })
            .catch(() => {
                if (cancelled) {
                    return;
                }
                setDisableLogin(true);
            })

        return () => {
            // console.log('useEffect cleanup hook - setting cancelled to true, to avoid setting state when fetch is complete.')
            cancelled = true;
        }
    }, [])

    if (!isKeyCloak() && redirectURL && autolaunch !== "false") {
        // console.log('redirecting to redirectURL:')
        // console.log(redirectURL)
        return <Redirect url={redirectURL as string} />
    }

    return <section className={classNames('login-screen', (hasSession ? "has-background-light" : "has-background-primary"))} data-testid="login-page">
        {
            !hasSession &&
            <div className="columns is-centered">
                <form onSubmit={submitHandler} className="column card p-6">
                    <div className="has-text-centered">
                        <img src="/lakeside-logo.png" alt="" />
                    </div>
                    <div className="has-text-dark has-text-centered">
                        Sign in
                    </div>
                    <div className="help is-danger has-text-centered mb-5">
                            {!hiddenLoginErrors.has(error) ? error : null}
                    </div>
                    <div className="field">
                        <div className="control">
                            <input className="input" {...register("email", { required: true, validate: (v) => emailValidator(v) })} type="text" placeholder="Email" />
                            <p className="help is-danger">{errors.email?.message}</p>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <input className="input" {...register("password", { required: true })} type="password" placeholder="Password" />
                        </div>
                    </div>
                    <div className="buttons mb-0">
                        <button type="submit" className={classNames("button is-primary is-fullwidth", { "is-loading": loading })} disabled={disableLogin}>Login</button>
                    </div>
                    <div className="help has-text-primary has-text-centered">Forgot Password?</div>
                    <footer className="has-text-centered is-size-7 mt-6">
                        <small className="has-text-dark">
                            Interactive Preflight is a trademark of Lakeside Book Company <br />
                            Copyright © 2017-2023 LSC Communications US, LLC., doing business as Lakeside Book Company <br />
                            All rights reserved
                        </small>
                    </footer>
                </form>
            </div>
        }
    </section>
}

export default Login
