import React from "react";

function HeaderBanner({ children }: { children: JSX.Element }) {
    return <header style={{
        position: "sticky",
        top: 0,
        zIndex: 9998
    }}>
        {children}
    </header>
}

export default HeaderBanner;