import { Job } from "./job.types"

export interface JobCopyApiReqData {
    // NOTE: newid is one of three values: either a new ISBN-13, ISBN-10, or Customer Reference #. The new job being created as a copy must have at least one of these.
    newid: string,
    isbnlist?: string[],
    author?: string,
    title?: string
}

export type CommonTitlesPatchData = {
    // Typing AssociatedISBNs as Job["AssociatedISBNs"] is not 'necessary' as Job["AssociatedISBNs"] is string|undefined.
    // The typing is just added to better logically organize/present this operation. 
    AssociatedISBNs: Job["AssociatedISBNs"]
}

export type JobIsbnOrCrPostBody = {
    first?: number,
    last?: number,
    keys?: [keyof Job],
    conditions: {
        $query: JobIsbnOrCrPostQuery
    }
}

export type JobIsbnOrCrPostQuery = 
{ISBN10?: {$eq: string}} 
& 
{ISBN13?: {$eq: string}} 
& 
{CustomerReference?: {$eq: string}}

export enum PreflytProcessName {
    Preflyt1 = 'Preflyt1',
    Preflyt2 = 'Preflyt2',
    ApprovalCheck = 'ApprovalCheck',
    FinalUpload = 'FinalUpload',
    TEP2Scout = 'TEP2Scout'
}

export type PreflytProcessOnJobPostData = {
    part?: string, // TODO - this should be typed to only support lowercase, valid part names - 
    // ^^^ with the adoption of @lsp-types (or before), refactor 'part' property typing to be more narrow than 'string'
    processname: PreflytProcessName,
    ignorepagecount?: boolean 
}
