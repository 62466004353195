import React, {
	useRef,
	useLayoutEffect,
	ReactNode,
	useCallback,
	useEffect,
	ForwardedRef,
	useState,
	useContext,
	useMemo
} from 'react';
import panzoom, { PanzoomObject } from '@panzoom/panzoom';
import {
	FaCompress,
	FaExpand,
	FaFile,
	FaMinus,
	FaPlus,
	FaWindowMaximize
} from 'react-icons/fa';
import usePageViewNavigation from '../_hooks/usePageViewNavigation';

import styles from './PanZoom.module.scss';
import PageDataContext from './PageViewContext';
import { useParams } from 'react-router-dom';

interface PanZoomProps {
	children: ReactNode;
	className?: string;
	enabled?: boolean;
}

interface IPanZoomContext {
	scale: number;
}

const PanZoomContext = React.createContext<IPanZoomContext>(undefined as any);

function PanZoom(props: PanZoomProps, ref: ForwardedRef<HTMLDivElement>) {
	const elementRef = useRef<HTMLDivElement>(null);
	const panzoomRef = useRef<PanzoomObject | null>(null);
	const { enabled = true } = props;
	const [scale, setScale] = useState(1);

	// Set up panzoom on mount, and dispose on unmount
	useLayoutEffect(() => {
		const { current } = elementRef;

		if (!current) return;

		const pz = panzoom(current, {
			minZoom: 0.25,
			maxZoom: 4,
			transformOrigin: { x: 0.5, y: 0.5 }
		});

		current.addEventListener('wheel', e => {
			pz.zoomWithWheel(e);
			setScale(pz.getScale());
		});

		panzoomRef.current = pz;

		return () => {
			current.removeEventListener('wheel', pz.zoomWithWheel);
			pz.destroy();
		};
	}, []);

	const safePz: <T>(
		fn: (pz: PanzoomObject) => T
	) => T | undefined = useCallback(fn => {
		const { current: pz } = panzoomRef;

		if (!pz) return;

		const returnValue = fn(pz);
		setScale(pz.getScale());
		return returnValue;
	}, []);

	useEffect(
		() =>
			safePz(pz => {
				pz.reset();
				pz.setOptions({ disableZoom: !enabled, disablePan: !enabled });
			}),
		[enabled, safePz]
	);

	const { isFullscreen, setFullscreen, Part, job } = useContext(
		PageDataContext
	);

	const { navigateToPages } = usePageViewNavigation();
	const { ordinal } = useParams();

	const PageOrdinals = useMemo(() => {
		return ordinal ? ordinal.split(",").map(o => parseInt(o)) : [];
	}, [ordinal])

	const zoomIn = useCallback(() => safePz(pz => pz.zoomIn()), [safePz]);
	const zoomOut = useCallback(() => safePz(pz => pz.zoomOut()), [safePz]);
	const reset = useCallback(() => safePz(pz => pz.reset()), [safePz]);

	const toggleSpread = useCallback(() => {
		if (PageOrdinals.length === 1) {
			const [ordinal] = PageOrdinals;

			if (ordinal % 2 === 0) navigateToPages([ordinal, ordinal + 1]);
			else navigateToPages([ordinal - 1, ordinal]);
		} else {
			const evenPage = PageOrdinals.filter(o => o % 2 === 0)[0];
			if (evenPage === 0) navigateToPages([1]);
			else navigateToPages([evenPage]);
		}
	}, [navigateToPages, PageOrdinals]);

	const partPages = useMemo(() => job.PartPages[Part], [Part, job.PartPages]);

	return (
		<PanZoomContext.Provider value={{ scale }}>
			<div ref={ref} className={styles.ZoomWrapper}>
				{enabled && (
					<div className={styles.ZoomControls}>
						<button
							type="button"
							className={styles.Button}
							onClick={zoomOut}
						>
							<FaMinus />
						</button>
						<button
							type="button"
							className={styles.Button}
							onClick={zoomIn}
						>
							<FaPlus />
						</button>
						{partPages > 1 && (
							<button
								type="button"
								className={styles.Button}
								onClick={toggleSpread}
							>
								{PageOrdinals.length > 1 ? (
									<FaFile />
								) : (
									<>
										<FaFile
											style={{ transform: 'scaleX(-1)' }}
										/>
										<FaFile />
									</>
								)}
							</button>
						)}
						<button
							type="button"
							className={styles.Button}
							onClick={reset}
						>
							<FaWindowMaximize />
						</button>
						<button
							type="button"
							className={styles.Button}
							onClick={() => setFullscreen(f => !f)}
						>
							{isFullscreen ? <FaCompress /> : <FaExpand />}
						</button>
					</div>
				)}
				<div className={styles.ZoomContents} ref={elementRef}>
					{props.children}
				</div>
			</div>{' '}
		</PanZoomContext.Provider>
	);
}

export default React.forwardRef(PanZoom);

export { PanZoomContext };
