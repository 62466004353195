import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthState } from "../store/authSlice";

function PrivateRoute({ children }: { children: JSX.Element }) {
    const { isAuthenticated } = useAuthState()
    const location = useLocation();
    
    if (!isAuthenticated) return <Navigate to="/" state={{ from: location }} replace />
    return children
}
export default PrivateRoute
