import classNames from "classnames"
import { ChangeEvent, KeyboardEvent, useEffect, useRef } from "react"
import { FaSearch } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { visitJob } from "../routes/visit"
import { useAuthState } from "../store/authSlice"
import { search, setQuery, setSortBy, setStatus, setTitleType, useSearch } from "../store/searchSlice"
import { ALL_SEARCH_STATUS_AND_STAGES, SearchSortBy, SearchStages, SearchStatus, SearchTitleTypes } from "../types/types"
import { AccountTypes, UserPermission } from "../types/user.types"
import { userHasPermission } from "../utils/user"
import { capitalizeText, snakeCaseToNormal } from "../utils/utils"
import usePreferences from "./PageView/_hooks/usePreferences"

function Search() {
    const { query, sortBy, titleType, status, loading, numberOfJobs } = useSearch()
    const { user } = useAuthState()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [prefs, setPrefs] = usePreferences()
    const selectRef = useRef<HTMLSelectElement>(null);


    useEffect(() => {
        if (!selectRef.current) return;
        const target = selectRef.current
        const { selectedIndex } = target.options;
        for (let index = 0; index < target.options.length; index++) {
            const option = target.options[index];
            const displayText = option.getAttribute("data-value") || option.value;
            option.textContent = displayText;
            if (index === selectedIndex) option.textContent += ` (${numberOfJobs})`
        }
    }, [numberOfJobs])

    useEffect(() => {
        dispatch(setTitleType(prefs.activeTitleType));
    }, [prefs.activeTitleType, dispatch])

    const searchHandler = () => {
        dispatch(search())
    }

    const queryChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        // TODO debounce setQuery call from input update to reduce calls.
        dispatch(setQuery(e.target.value))
    }

    const sortByChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        dispatch(setSortBy(e.target.value))
    }

    const titleTypeChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        dispatch(setTitleType(e.target.value as SearchTitleTypes))
        setPrefs(prev => ({ ...prev, activeTitleType: e.target.value as SearchTitleTypes }))
    }

    const statusChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
        dispatch(setStatus(e.target.value))
    }

    const enterKeyHandler = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") searchHandler();
    }

    const addTitleClickHandler = () => {
        navigate(visitJob({ new: true }))
    }

    return (
        <div className="px-1">
            <div className="nav-control-row titles-control-row px-0 pt-2">
                <div className="control col-left">
                    <button onClick={addTitleClickHandler} className="button rounded-4 is-small is-primary" disabled={user?.AccountType === AccountTypes.TEP || !userHasPermission(UserPermission.Job_Create, user)}>
                        <span>{' + '}Title</span>
                    </button>
                </div>
                <div className="field is-flex col-right">
                    <div className="custom-select-dark control">
                        <div className="select is-small is-fullwidth">
                            <select value={titleType} onChange={titleTypeChangeHandler}>
                                {
                                    Object.values(SearchTitleTypes).map(val => {
                                        return <option key={val} value={val}>{val}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>

                </div>
            </div>
            <div className="nav-control-row sort-by-and-search-control-row py-2">
                <div className="wrapped-search-component field has-addons custom-addons-rounded-4 is-flex" >
                    <div className="control search-field">
                        <input value={query} onChange={queryChangeHandler} onKeyUp={enterKeyHandler} className="input is-small" type="text" title="Search ISBN, Author, Title, CR# or Job#" placeholder="Search ISBN, Author, Title, CR# or Job#" />
                    </div>
                    <div className="control search-button-control">
                        <button type="button" className={classNames("button is-fullwidth is-primary is-small")} onClick={searchHandler}>
                            <span className={classNames("icon is-small", { "disabled-loading-control": loading })}><FaSearch /></span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="nav-control-row stage-status-control-row custom-select-dark has-text-light">
                <div className="col-left">
                    <div className="custom-select-dark sort-by-control">
                        <label className="inset-dropdown-label">Sort By</label>
                        <div className="select is-small">
                            <select value={sortBy} onChange={sortByChangeHandler}>
                                {
                                    Object.values(SearchSortBy).map(val => {
                                        return <option key={val} value={val}>{val}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-right stage-status-filter-control-wrapper">
                    <label className="inset-dropdown-label">Filter by</label>
                    <div className="select is-small is-fullwidth">
                        <select ref={selectRef} value={status} onChange={statusChangeHandler} style={{ textTransform: "capitalize" }}>
                            <option value={ALL_SEARCH_STATUS_AND_STAGES}>{ALL_SEARCH_STATUS_AND_STAGES}</option>
                            <optgroup label="Status">
                                {
                                    Object.values(SearchStatus).map(val => {
                                        return <option key={val} value={val}>{val}</option>
                                    })
                                }
                            </optgroup>
                            <optgroup label="Stage">
                                {
                                    Object.entries(SearchStages).filter(([, value]) => {
                                        if (value === SearchStages.FINAL_APPROVAL_REQUIRED)
                                            return user?.AccountType !== AccountTypes.TEP
                                        return true;
                                    }).map(([key, value]) => {
                                        const displayText = capitalizeText(snakeCaseToNormal(key));
                                        return <option key={value} value={value} data-value={displayText}>{displayText}</option>
                                    })
                                }
                            </optgroup>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search