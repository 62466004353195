import classNames from "classnames";
import { FaSpinner } from "react-icons/fa";

type JobProcessingSpinnerProps = {
addedClassNames?: string // comma-separated string to support use in the classNames() function below
}

const JobProcessingSpinner = (props: JobProcessingSpinnerProps) => {

    const { addedClassNames } = props;
    return (
        <span className={classNames("job-processing-spinner", addedClassNames)}>
            <FaSpinner size={20}/>
        </span>
    );
}

export default JobProcessingSpinner;