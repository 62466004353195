import deepSet from '@apagoinc/deep-set';
import { Line } from '@apagoinc/react-ruler';
import classNames from 'classnames';
import * as pt from 'prop-types';
import {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react';
import useDimensions from 'react-cool-dimensions';
import {
	FaAngleLeft,
	FaAngleRight,
	FaArrowLeft,
	FaReply
} from 'react-icons/fa';
import { VscGripper } from 'react-icons/vsc';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ResizableBox } from 'react-resizable';
import { PulseLoader } from 'react-spinners';
import PageDataContext from '../components/PageView/_components/PageDataContext';
import useFullscreen from '../components/PageView/_hooks/useFullscreen';
import useJob from '../components/PageView/_hooks/useJob';
import usePages from '../components/PageView/_hooks/usePages';
import usePreferences from '../components/PageView/_hooks/usePreferences';
import useSet from '../components/PageView/_hooks/useSet';
import { Area, Job, PageUpdate } from '../components/PageView/_types/LSCScout.type';

import FullscreenSpinner from '../components/PageView/_components/FullscreenSpinner';
import InksUsed from '../components/PageView/_components/InksUsed';
import { LocationConsumer, LocationProvider } from '../components/PageView/_components/LocationProvider';
import PageSpacer from '../components/PageView/_components/PageSpacer';
import ScaleCropContext from '../components/PageView/_components/PageViewContext';
import PanZoom from '../components/PageView/_components/PanZoom';
import PreflightTree from '../components/PageView/_components/PreflightTree';
import PreflytAnnotations, { PanzoomWrap } from '../components/PageView/_components/PreflytAnnotations';
import Review from '../components/PageView/_components/Review';
import ScaleCropPane from '../components/PageView/_components/ScaleCrop';
import Tabber from '../components/PageView/_components/Tabber';
import styles from './PageView.module.scss';

import { useDispatch } from 'react-redux';
import 'react-resizable/css/styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import JobProcessingSpinner from '../components/JobProcessingSpinner';
import customFetch from '../lib/axiosInstance';
import { setJobData, updatePartPageAtOrdinal, useJob as useReduxJob } from '../store/jobSlice';
import { Page } from "../types/pages.types";

import { useLocation as useReactRouterLocation } from 'react-router-dom';
import { useAuthState } from '../store/authSlice';
import { visitJob } from '../routes/visit';
import { PreviousViewState } from "../types/types";
import { getGrooveDivReference } from '../utils/utils';

// these map to state uirouter state resolvers and defining them makes uirouter inject
const propTypes = {
	job: pt.object
};

// these are the better type definitions we'll be using for typescript checking
interface PageViewProps extends pt.InferProps<typeof propTypes> {
	job: Job | any;
}

// ^ make sure these are in sync!

export default function PageView(props: PageViewProps) {
	const dispatch = useDispatch();
	const API_HOST = process.env.REACT_APP_API_HOST as string;

	// this legacy PageView code has its own hook named "useLocation", so the react-router useLocation hook is aliased here:
	const { state: prevPageState } = useReactRouterLocation()
	const previousPageState = (prevPageState ? prevPageState as PreviousViewState : { from: 'preflightsummary' });

	const { jobId: JobID, part: Part, ordinal } = useParams()

	// if (!JobID || !Part) return null;

	// top-level state variables for open/closed state of warning and error accordions on Preflight tab,
	// (whenever the tab is active)
	const [warningsAccordionOpen, setWarningsAccordionOpen] = useState(false)
	const [errorsAccordionOpen, setErrorsAccordionOpen] = useState(false)

	const PageOrdinals = useMemo(() => {
		return ordinal ? ordinal.split(",").map(o => parseInt(o)) : [];
	}, [ordinal])

	const spreadMode = useMemo(() => PageOrdinals.length > 1, [
		PageOrdinals.length
	]);

	const [prefs, setPrefs] = usePreferences();

	const { units } = prefs;
	const setUnits = deepSet(setPrefs, 'units');

	const { pages: pageResults, prefetch } = usePages(
		JobID!,
		Part!,
		PageOrdinals
	);

	const pages = useMemo(() => pageResults.map(pr => pr.data), [pageResults]);

	const firstPage = useMemo(() => pages[0], [pages]);

	const refetchPages = useCallback(
		() => Promise.all(pageResults.map(pr => pr.refetch())),
		[pageResults]
	);

	const pagesProcessing = useMemo(
		() => pages.some(p => p?.Status === 'processing'),
		[pages]
	);

	const {
		data: job,
		processing: jobProcessing,
		refetch: refetchJob,
		mutate: mutateJob
	} = useJob(
		{
			API_HOST,
			jobId: JobID!
		},
		props.job
	);

	const processing = pagesProcessing || jobProcessing;
	const [selectedTab, setSelectedTab] = useState<string>(
		(previousPageState?.from === 'preflightsummary' ? 'Preflight' : 'Scale/Crop')
	);

	const [displayedWarnings, setWarningOpen] = useSet<string>();
	const [displayedErrors, setErrorOpen] = useSet<string>();

	const [highlightedArea, setHighlightedArea] = useState<Area>();

	const refetch = useCallback(async () => {
		const [pagesData, jobData] = await Promise.all([refetchPages(), refetchJob()]);
		if (jobData.isSuccess) dispatch(setJobData(jobData.data as any));
		// pages data needs to be updated here after an operation in PageView

		// if the change made was a preflight warning sign-off/fix:
		if (pagesData[0].isSuccess && pagesData.length === 1 && selectedTab === 'Preflight') {
			const ordinalNum = (ordinal && typeof ordinal === 'string') ? Number(ordinal) : undefined
			// could add undef. checking and Workflow addition if its undefined on the page?
			// The method is currently written expecting pages to already have a Workflow property.
			const pageWfUpdate: Partial<Page> | undefined = { Workflow: pagesData[0].data.Workflow } || undefined
			if (!pageWfUpdate || !pageWfUpdate.Workflow) {
				throw new Error('The page to sign-off/fix a warning for does not have the required Workflow property')
			}
			if (ordinalNum && Part && pageWfUpdate.Workflow) {
				dispatch(updatePartPageAtOrdinal({ ordinal: ordinalNum, part: Part, page: pageWfUpdate }))
			}
		}
	}, [refetchJob, refetchPages, dispatch]);

	const [selectedArea, setSelectedArea] = useState<Area>();

	const navigate = useNavigate()

	const backClickHandler = () => {
		navigate(visitJob({ jobId: JobID, part: Part }));
	}

	const preflightClickHandler = () => {
		navigate(visitJob({ jobId: JobID, preflight: true }))
	}

	const canPrevious = useMemo(() => {
		const lastPageOrdinal = PageOrdinals[PageOrdinals.length - 1];

		return lastPageOrdinal > 1;
	}, [PageOrdinals]);

	const canNext = useMemo(() => {
		const [firstPageOrdinal, secondPageOrdinal] = PageOrdinals;
		if (secondPageOrdinal) return secondPageOrdinal < job.PartPages[Part!];
		else return firstPageOrdinal < job.PartPages[Part!];
	}, [PageOrdinals, Part, job.PartPages]);

	const gotoPreviousPage = useCallback(async () => {		
		const count = PageOrdinals.length;

		const nextPageGroup = PageOrdinals.map(po => po - count);
		await prefetch(nextPageGroup);

		navigate(visitJob({ part: Part, ordinal: nextPageGroup }))
	}, [PageOrdinals, prefetch, Part, navigate]);

	const gotoNextPage = useCallback(async () => {
		const count = PageOrdinals.length;
		const nextPageGroup = PageOrdinals.map(po => po + count);
		await prefetch(nextPageGroup);

		navigate(visitJob({part: Part, ordinal: nextPageGroup}));
	}, [PageOrdinals, prefetch, Part, navigate]);

	const [bumpOffset, setBumpOffset] = useState<[number, number]>([0, 0]);

	const [canvasScale] = useState(3);

	const [lines, setLines] = useState<Line[]>([]);

	const clearMeasurements = useCallback(() => setLines([]), []);

	const contentRef = useRef<HTMLDivElement>(null);

	const { observe, height: zoomAreaHeight, width: zoomAreaWidth } = useDimensions<
		HTMLDivElement
	>();

	const [addRulerMode, setAddRulerMode] = useState(false);
	const [showMeasurements, setShowMeasurements] = useState(true);

	const { isFullscreen, setFullscreen } = useFullscreen(contentRef);

	const batchOp = useCallback(
		async (op: PageUpdate) => {
			await customFetch.patch(`/page/page/${JobID}`, op);
		},
		[JobID]
	);

	const [sidebarSize, setSidebarSize] = useState(335);

	const pageFolioText = useMemo(() => {
		const realPages = PageOrdinals.map(o =>
			o > 0 && o <= job.PartPages[Part!] ? o : null
		);

		if (realPages.filter(o => o !== null).length === 1) {
			const realPageIndex = realPages.findIndex(o => o !== null);
			return `page ${pages[realPageIndex]?.Folio || PageOrdinals[realPageIndex]
				}`;
		} else
			return (
				'pages ' +
				pages
					.map(
						(p, i) =>
							p?.Folio ||
							(PageOrdinals[i] === 0 ? '0' : PageOrdinals[i])
					)
					.join(', ')
			);
	}, [PageOrdinals, Part, job.PartPages, pages]);

	// Conditionally add and remove a class to move the groove widget div on PageView mount and unmount
	useEffect(() => {

		// add class on component mount
		const grooveDiv = getGrooveDivReference()
		if (grooveDiv) {
			try {
				grooveDiv.classList.add('move-up-groove-widget')
			}
			catch (err) {
				console.log(err);
			}
		}

		// remove class on component unmount
		return () => {
			const grooveDiv = getGrooveDivReference()
			if (grooveDiv) {
				try {
					grooveDiv.classList.remove('move-up-groove-widget')
				}
				catch (err) {
					console.log(err);
				}
			}
		}
	}, [])
	
	return (
		<ScaleCropContext.Provider
			value={{
				job,
				page: firstPage,
				Part: Part!,
				refetch,
				processing,
				batchOp,
				mutateJob,
				bumpOffset,
				setBumpOffset,
				ordinal: firstPage?.Ordinal,
				selectedUnits: units,
				clearMeasurements,
				lines,
				setLines,
				zoomAreaHeight,
				zoomAreaWidth,
				canvasScale,
				addRulerMode,
				setAddRulerMode,
				showMeasurements,
				setShowMeasurements,
				isFullscreen,
				setFullscreen,
				selectedTab,
				pages,
				spreadMode
			}}
		>
			<div className={styles.PageView}>
				<div className="force-to-front-of-ui has-background-link is-flex">
					<div onClick={backClickHandler} className="px-3 is-flex p-2 has-text-white is-clickable" style={{ backgroundColor: "#38678f", border: "1px solid #315a7d" }}>
						<FaArrowLeft size={25} className="is-align-self-center" />
					</div>
					<div className="py-3 pr-3 is-flex-grow-1">
						<div className="has-text-white ml-2 is-flex is-justify-content-space-between is-size-5">
							<div className="is-align-self-center is-size-6">
								{job.Title} {pageFolioText}
								{processing && <JobProcessingSpinner addedClassNames='is-size-5 bump-spinner-down-2' />}
							</div>
							<div onClick={preflightClickHandler} className="is-flex is-clickable">
								<FaReply size={20} className="is-align-self-center mr-2" />
								<div className="is-align-self-center is-size-5">Preflight Summary</div>
							</div>
						</div>
					</div>
				</div>
				<div ref={contentRef} className={classNames(styles.Content)}>
					<div className={styles.PreviewColumn}>
						<button
							onClick={gotoPreviousPage}
							disabled={!canPrevious}
							className={styles.Previous}
						>
							<FaAngleLeft />
						</button>

						<button
							onClick={gotoNextPage}
							disabled={!canNext}
							className={styles.Next}
						>
							<FaAngleRight />
						</button>
						<PanZoom ref={observe}>
							{pages.every(p => !p) ? (
								<PulseLoader
									css={`
										position: absolute;
										top: 50%;
										left: 50%;
										transform: translate(-50%, -50%);
									`}
									color="white"
								/>
							) : (
								<PanzoomWrap
									cursor={
										selectedTab === 'Review'
											? 'crosshair'
											: 'move'
									}
									mouseEvents={selectedTab !== 'Review'}
								>
									{pages.map((p, i) =>
										p ? (
											<PreflytAnnotations
												key={p.PageID}
												selectedArea={selectedArea}
												setSelectedArea={
													setSelectedArea
												}
												openErrors={displayedErrors}
												openWarnings={displayedWarnings}
												showBounds={true}
												showErrors={
													selectedTab === 'Preflight'
												}
												showWarnings={
													selectedTab === 'Preflight'
												}
												page={p}
												highlightedArea={
													highlightedArea
												}
												position={
													i === 0 ? 'left' : 'right'
												}
											/>
										) : (
											<PageSpacer
												key={i}
												position={
													i === 0 ? 'left' : 'right'
												}
											/>
										)
									)}
								</PanzoomWrap>
							)}
						</PanZoom>
					</div>
					{!spreadMode && (
						<ResizableBox
							axis="x"
							height={Infinity}
							resizeHandles={['w']}
							width={335}
							minConstraints={[300, Infinity]}
							className={styles.Sidebar}
							onResize={(_, { size }) =>
								setSidebarSize(size.width)
							}
							handle={
								<div className={styles.Handle}>
									<VscGripper />
								</div>
							}
						>
							<Tabber
								currentTab={selectedTab}
								setCurrentTab={setSelectedTab}
							>
								{[
									{
										name: 'Preflight',
										contents: (
											<>
												{!firstPage ? (
													<PulseLoader
														css={`
															position: absolute;
															top: 50%;
															left: 50%;
															transform: translate(
																-50%,
																-50%
															);
														`}
														color="white"
													/>
												) : (
													<PageDataContext.Provider
														value={firstPage}
													>
														<PreflightTree
															openErrors={displayedErrors}
															setErrorOpen={setErrorOpen}
															openWarnings={displayedWarnings}
															setWarningOpen={setWarningOpen}
															warningsExpanded={warningsAccordionOpen}
															errorsExpanded={errorsAccordionOpen}
															onToggleWarningsExpanded={setWarningsAccordionOpen}
															onToggleErrorsExpanded={setErrorsAccordionOpen}
														/>
														<InksUsed />
													</PageDataContext.Provider>
												)}
											</>
										)
									},
									{
										name: 'Scale/Crop',
										contents: (
											<>
												{!firstPage ? (
													<PulseLoader
														css={`
															position: absolute;
															top: 50%;
															left: 50%;
															transform: translate(
																-50%,
																-50%
															);
														`}
														color="white"
													/>
												) : (
													<PageDataContext.Provider
														value={firstPage}
													>
														<ScaleCropPane
															selectedUnit={units}
															setSelectedUnit={
																setUnits
															}
														/>
													</PageDataContext.Provider>
												)}
											</>
										)
									},
									{
										name: 'Review',
										contents: (
											<>
												{!firstPage ? (
													<PulseLoader
														css={`
															position: absolute;
															top: 50%;
															left: 50%;
															transform: translate(
																-50%,
																-50%
															);
														`}
														color="white"
													/>
												) : (
													<PageDataContext.Provider
														value={firstPage}
													>
														<Review
															selectedArea={
																selectedArea
															}
															setSelectedArea={
																setSelectedArea
															}
															hilightArea={
																setHighlightedArea
															}
															part={Part}
														/>
													</PageDataContext.Provider>
												)}
											</>
										)
									}
								]}
							</Tabber>
						</ResizableBox>
					)}
				</div>
			</div>
			<FullscreenSpinner active={processing} />
		</ScaleCropContext.Provider>
	);
}

const queryClient = new QueryClient();

export function ProviderWrap(props: PageViewProps) {
	/*
		in the crop view, we have disabled ui-router's path change listeners with
		the "dynamic" flag and re-implemented state navigation using the HTML5
		history api, in order to not trigger a fullscreen exit and cache discard
		on state change.

		when transitioning from organize into the new state there is a brief period
		where the PageView component is being rendered, but the location is still
		that of the old state.

		this causes the usePageViewNavigation hook to attempt to parse the organize
		state's url as that of the crop state, which causes the hook to return
		bad data, which then triggers a variety of bugs.

		we work around this by using this parent component to test the current url,
		and conditionally render the PageView component only if the url is correct.

		this bug and therefore this workaround are an artifact of angularjs/react
		interoperability, and the ultimately correct solution is to discard angular
		altogether, which hopefully will happen at a future date.
	 */

	return (
		<QueryClientProvider client={queryClient}>
			<LocationProvider>
				<LocationConsumer>
					{({ location, forceUpdate }) => {
						const stillOnOrganize = location.includes('organize');
						const stillOnPreflyt = location.includes('preflyt');
						if (stillOnOrganize || stillOnPreflyt) {
							setTimeout(forceUpdate, 0);
							return null;
						} else return <PageView {...props} />;
					}}
				</LocationConsumer>
			</LocationProvider>
		</QueryClientProvider>
	);
}

ProviderWrap.propTypes = propTypes;

PageView.propTypes = propTypes;

export { queryClient };
