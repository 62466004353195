import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useContext,
	useLayoutEffect,
	useMemo,
	useRef,
	useState
} from 'react';
import { FaCheck, FaPaperPlane, FaTimes } from 'react-icons/fa';
import classNames from 'classnames';
import Textarea from 'react-expanding-textarea';
import { Area } from '../_types/LSCScout.type';
import useReviews from '../_hooks/useReviews';
import usePage from '../_hooks/usePage';
import useReportCounts from '../_hooks/useReportCounts';

import ChatBubble from './ChatBubble';
import styles from './Review.module.scss';
import PageViewContext from './PageViewContext';
import { useAuthState } from '../../../store/authSlice';
import { useJob } from '../../../store/jobSlice';
import { userHasPermission } from '../../../utils/user';
import { UserPermission } from '../../../types/user.types';
import { JobStatus } from '../../../types/types';

import { setJobId } from "../../../store/jobSlice"
import { useDispatch } from "react-redux"
import { PageReviewStatus } from '../../../types/pages.types';
import { checkLegacyJobTypePartLock } from '../../../utils/utils';

interface ReviewProps {
	hilightArea: Dispatch<SetStateAction<Area | undefined>>;
	selectedArea?: Area;
	setSelectedArea: Dispatch<SetStateAction<Area | undefined>>;
	part?: string | undefined
}

export default function Review({
	hilightArea,
	selectedArea,
	setSelectedArea,
	part
}: ReviewProps) {

	const dispatch = useDispatch()

	const { page: externalPage } = useContext(PageViewContext);
	const { user } = useAuthState()

	// 
	const { job: jobFromPageViewCtx } = useContext(PageViewContext);
	// 

	// Note: this hook is different from the useJob hook in this legacy PageView code:
	const { job } = useJob()

	const contextJobIsActive: boolean | undefined = useMemo(() => (jobFromPageViewCtx && jobFromPageViewCtx.Status === JobStatus.Active), [jobFromPageViewCtx])

	const partIsLocked = useMemo(() => {
		return (part ? checkLegacyJobTypePartLock(jobFromPageViewCtx, part) : false);
	}, [jobFromPageViewCtx, part]);

	const me = user;
	// if (!job || !user) return null;
	const { reviews, add, refetch } = useReviews();

	const canApprove = useMemo(() => userHasPermission(UserPermission.Preflight1_Signoff, user), [user, userHasPermission]);
	const [commentBody, setCommentBody] = useState<string>('');

	const { unfixedWarnings, errors } = useReportCounts();

	const enableApprove = useMemo(() => unfixedWarnings + errors === 0, [
		errors,
		unfixedWarnings
	]);

	const { data: page, mutate } = usePage(
		{
			jobId: job!.JobID,
			pageId: externalPage!.PageID
		},
		externalPage!
	);

	const setApprovalStatus = useCallback(
		async (status: PageReviewStatus) =>
			await mutate(() => {
				const data = {
					Tags: {
						ReviewStatus: status
					}
				}
				return data;
			})
				.then(() => {
					if (job) dispatch(setJobId(job.JobID))
				}),
		[mutate, job, dispatch]
	);

	const approvalStatus = useMemo(
		() => page?.Tags?.ReviewStatus || PageReviewStatus.NOT_REVIEWED,
		[page?.Tags?.ReviewStatus]
	);

	const approveBtnClickHandler = useCallback(() => {
		if (!contextJobIsActive || partIsLocked) {
			return;
		}
		if (approvalStatus !== PageReviewStatus.APPROVED) setApprovalStatus(PageReviewStatus.APPROVED);
		else setApprovalStatus(PageReviewStatus.NOT_REVIEWED);
	}, [approvalStatus, setApprovalStatus, contextJobIsActive, partIsLocked]);

	const rejectBtnClickHandler = useCallback(() => {
		if (!contextJobIsActive || partIsLocked) {
			return;
		}
		if (approvalStatus !== PageReviewStatus.REJECTED) setApprovalStatus(PageReviewStatus.REJECTED);
		else setApprovalStatus(PageReviewStatus.NOT_REVIEWED);
	}, [approvalStatus, setApprovalStatus, contextJobIsActive, partIsLocked]);

	const scrollBottom = useCallback(() => {
		const { current } = comments;

		if (!current) return;

		current.scrollTop = current.scrollHeight - current.clientHeight;
	}, []);

	const clearArea = useCallback(() => setSelectedArea(undefined), [
		setSelectedArea
	]);

	const send = useCallback(async () => {
		if (!contextJobIsActive || partIsLocked) {
			return;
		}
		if (!commentBody) return;
		await add({ Body: commentBody, Area: selectedArea });
		setCommentBody('');
		clearArea();
		await refetch();
		scrollBottom();
	}, [add, clearArea, commentBody, refetch, scrollBottom, selectedArea, contextJobIsActive, partIsLocked]);

	const submitBtnHandler = useCallback(() => send(), [send]);

	const textareaKeyboardEvtHandler = useCallback(
		(e: React.KeyboardEvent) => {
			if (e.ctrlKey && e.key === 'Enter') void send();
		},
		[send]
	);

	const canSubmit = useMemo(() => !!commentBody, [commentBody]);

	const comments = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => scrollBottom(), [scrollBottom]);

	return (
		<div className={styles.Review}>
			{canApprove && (
				<div className={styles.ApproveRejectButtons}>
					<button
						className={classNames(styles.Button, styles.Approve)}
						type="button"
						onClick={approveBtnClickHandler}
						disabled={!contextJobIsActive || partIsLocked || !enableApprove}
					>
						{approvalStatus === PageReviewStatus.APPROVED ? (
							<>
								<FaCheck /> Approved
							</>
						) : (
							'Approve'
						)}
					</button>
					<button disabled={!contextJobIsActive || partIsLocked}
						className={classNames(styles.Button, styles.Reject)}
						type="button"
						onClick={rejectBtnClickHandler}
					>
						{approvalStatus === 'rejected' ? (
							<>
								<FaTimes /> Rejected
							</>
						) : (
							'Reject'
						)}
					</button>
				</div>
			)}
			<div ref={comments} className={styles.Comments}>
				{reviews?.map((r: any) => (
					<ChatBubble
						name={r.User.Name}
						mine={me && r.User.UserID === me.UserID}
						key={`${r.Body} ${r.DateTime}`}
						attachment={r.Area ? 'Attached Area' : undefined}
						areaHovered={hovered =>
							hilightArea(hovered ? r.Area : undefined)
						}
					>
						{r.Body}
					</ChatBubble>
				))}
			</div>
			<div className={styles.NewCommentArea}>
				<div className={styles.Left}>
					<Textarea disabled={!contextJobIsActive || partIsLocked}
						onKeyPress={textareaKeyboardEvtHandler}
						rows={1}
						className={styles.TextArea}
						value={commentBody}
						placeholder="Add a comment..."
						onChange={(e: any) => setCommentBody(e.currentTarget.value)}
					></Textarea>
					{selectedArea && (
						<span className={styles.AttachedArea}>
							<FaTimes
								onClick={clearArea}
								className={styles.ClearArea}
							/>{' '}
							Area Attached
						</span>
					)}
				</div>
				<button
					className={styles.Button}
					type="submit"
					disabled={!contextJobIsActive || partIsLocked || !canSubmit}
					onClick={submitBtnHandler}
				>
					<FaPaperPlane />
				</button>
			</div>
		</div>
	);
}
