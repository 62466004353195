import { PdfFileType } from "../components/Upload/UploadModal"
import { Page, PagePart, UploadPageData, UploadPageProgress } from "../types/pages.types"
import { BatchPageUpdateOptions } from "../types/pagesApi.types"
import { sortByOrdinal } from "../utils/utils"
import customFetch from "./axiosInstance"


const getPartsPages = async (jobId: string, parts: PagePart[]): Promise<Partial<Record<PagePart, Page[]>>> => {
    const pages = await Promise.all(parts.map(part => getPartPages(jobId, part)))
    const results: Partial<Record<PagePart, Page[]>> = {};
    for (const part of parts) {
        results[part] = pages[parts.indexOf(part)].data
    }
    return results
}

const getPartPages = (jobId: string, part: PagePart | string, range?: { first: number, last: number }) => {
    const rangeString = (range) ? `&first=${range.first}&last=${range.last}` : "";
    return customFetch.get<Page[]>(`/page/pages/${jobId}?part=${part}${rangeString}`)
}

const batchUpdatePages = async (jobId: string, options: BatchPageUpdateOptions) => {
    return customFetch.patch(`/page/page/${jobId}`, options)
}

const requestProof = (jobId: string, part?: PagePart | string, quality?: boolean) => {
    const partQuery = `part=${part}`;
    const qualityQuery = `&quality=high`
    return customFetch.get(`/page/pages/${jobId}/proof?${part ? partQuery : ""}${quality ? qualityQuery : ""}`)
}

const uploadPage = (jobId: string, data: UploadPageData, progressCallback?: (progress: UploadPageProgress) => void) => {
    const formData = new FormData();
    formData.append("file", data.pdfFile.file);
    return customFetch.post(`/page/page/${jobId}?pagecount=${data.pdfFile.numPages}&part=${data.pdfFile.part}${data.last ? "&last=true" : ""}`, formData, {
        onUploadProgress: (progressEv: ProgressEvent) => {
            const percentDone = (progressEv.loaded / progressEv.total) * 100;
            progressCallback && progressCallback({ total: progressEv.total, loaded: progressEv.loaded, percent: percentDone, key: data.key })
        }
    });
}

const uploadPages = async (jobId: string, pagesData: PdfFileType[], progressCallback?: (progress: UploadPageProgress) => void) => {
    const totalProgress = (new Array(pagesData.length)).fill(0);
    const orderedPages = pagesData.sort(sortByOrdinal);
    for (let i = 0; i < orderedPages.length; i++) {
        const pdfFile = orderedPages[i];
        const last = i === orderedPages.length - 1;
        await PagesApi.uploadPage(jobId, { pdfFile, last, key: `${i}` }, (progress) => {
            if (progress.key) totalProgress[parseInt(progress.key)] = progress.percent;
            const total = 100 * totalProgress.length;
            const loaded = totalProgress.reduce((a, b) => a + b);
            const percent = ((loaded / total) * 100)
            progressCallback && progressCallback({ loaded: totalProgress.filter(p => p === 100).length, total: totalProgress.length, percent })
        })
    }
}

const multiUploadSinglePages = async (jobId: string, part: string, pagesData: PdfFileType[], progressCallback?: (progress: UploadPageProgress) => void) => {


    // The pages should already be coming sorted by their `ordinal` property, though.
    const orderedPages = pagesData.sort(sortByOrdinal);

    // do other request sizes, like headers, really need to be factored in here? Maybe not for this implementation.
    /** Helper function that gets total size (in bytes) of all current files in a FormData form.  
     * 
    */
    const getFdSize = (form: FormData) => {

        const fdEntriesArr = Array.from(form.entries())

        let totalSize = 0;
        for (const fdEnt of fdEntriesArr) {
            const file = fdEnt[1]
            if (typeof file !== 'string') {
                totalSize = totalSize + file.size 
            }
        }
        return totalSize;
    }

    // max amount of files to send in one fd PUT
    // TODO - set to 200 files for now
    const maxFormLength = 200; 

    // max total file size (in bytes) for one form 
    // (if a single file exceeds this size, it will be sent in its own form) 
    // TODO - set to 1 GB for now
    const maxFormSize = 1000000000  

    // Is it bad to just go ahead and hold all of the prepared formData forms in state?
    // This is currently done in order to provide upload progress.

    // Array to hold all FormData forms that will be PUT to the API
    const putForms: FormData[] = []

    /** Prepares and sends each FormData in a PUT to the API based on the max form data constraints,
     * until all data has been sent to the API.
     */
    const prepareAndPUTFiles = async (startIndex: number): Promise<void> => {

        const fd = new FormData();

        let last: boolean | undefined;

        // Starting index to be set and used for the next PUT request, if any;
        // (if this is undefined at the end of this function, the forms have all been prepared)
        let nextPutStartIndex: number | undefined;

        // console.log('in prepareAndPUTFiles - with start index of -', startIndex)
        
        for (let i = startIndex; i < orderedPages.length; i++) {
            // console.log('in fd prepare loop - iteration', i)

            const currFdSize = getFdSize(fd)


            // console.log('current fd size is', currFdSize)

            // number of files currently added to fd at the time of this iteration
            const currFdNumFiles = Array.from(fd.entries()).length

            // if the form is already at its max length
            if (currFdNumFiles === maxFormLength) {
                // console.log('broke out of fd preparing loop - at max files length of', currFdNumFiles)

                nextPutStartIndex = i;
                break;
            }

            // The file to be appended to the form
            const pdfFile = orderedPages[i]

            // if the next file to add will put this form over the max form size:
            if (currFdSize + pdfFile.file.size > maxFormSize) {

                // In the special case that the current form is empty and this one single file is greater than the maxFormSize:
                if (currFdNumFiles === 0) {
                    // console.log('The form is empty, and the current file is larger than the total size limit.')
                    // console.log('This large file will be the only file sent in this PUT form.')
                    
                    // append the large file that will be sent alone
                    fd.append("page", pdfFile.file);
                    
                    // move to next file since this large one has been appended
                    nextPutStartIndex = i + 1;
                    break;
                }

                // console.log('broke out of fd preparing loop - next file would exceed max request size;')
                // console.log(`[It would push the request size to ${currFdSize + pdfFile.file.size} bytes (over byte limit of ${maxFormSize})]`)
                // console.log('final form files size:', currFdSize, 'bytes')
                
                nextPutStartIndex = i;
                break;
            }

            // if the file won't make the form too long or too large,
            // append the file
            fd.append("page", pdfFile.file)
        }

        // add the form to the list of forms to PUT, if it has had any files appended to it
        if (Array.from(fd.entries()).length > 0) {
            // console.log('Form prepared:')
            // console.log(fd)

            // add each new formdata to the array
            putForms.push(fd)
        }


        // if there is a 'next put start index' set:
        if (nextPutStartIndex) {
            // continue to prepare forms until all files have been added to forms
            return prepareAndPUTFiles(nextPutStartIndex);
        }

        // If no nextPutStartIndex, all the PUT forms are ready to send.

        // console.log('no nextPutStartIndex, so the PUT forms must all be ready.')
        // console.log("putForms:")
        // console.log(putForms)
        // console.log('contents of each fd:')
        // putForms.map((fd, index) => {
        //     console.log('index', index, '-')
        //     console.log(Array.from(fd.entries()))
        // })

        const totalPutsToMake = putForms.length
  
        const totalPagesToUpload = orderedPages.length;

        // for calculating progress and reporting to UI
        let totalPagesUploaded = 0;

        for (let i = 0; i < putForms.length; i++) {

            // The form to be sent in this iteration
            const currentPutFormData = putForms[i] 

            // current form data length, to calculate progress
            const currentPutFormLength = Array.from(currentPutFormData.entries()).length

            // the current PUT request being made, starting from 1
            const currentPutNo = i + 1;

            // if the 'last' PUT request with files is being made:
            const last = (currentPutNo === totalPutsToMake)
            
            await customFetch.put(`/page/page/${jobId}?part=${part}${last ? '&last=true' : ''}`, currentPutFormData, {
                onUploadProgress: (progressEv: ProgressEvent) => {
                    // The percent that this particular PUT is done
                    const percentDone = (progressEv.loaded / progressEv.total) * 100;
                    // The approx. amount of pages uploaded so far in this particular PUT
                    const approxLoadedPages = Math.floor((progressEv.loaded / progressEv.total) * currentPutFormLength)
                  
                    // Report progress to the UI as the total progress so far in submitting all of the forms
                    progressCallback && progressCallback({
                        total: totalPagesToUpload,
                        loaded: totalPagesUploaded + approxLoadedPages,
                        percent: ((totalPagesUploaded/totalPagesToUpload) * 100) + (percentDone * currentPutFormLength / totalPagesToUpload)                        
                    })
                },
            })
                .then((res: any) => {
                    // add the set of files that just finished uploading to the total:
                    totalPagesUploaded += currentPutFormLength;

                    // console.log('response to PUT:')
                    // console.log(res);
                })
                .catch(err => {
                    console.log('(in a PUT request) - error making this form PUT:')
                    console.log(err)
                    throw err;
                })
        }
    }

    // Calls the above to prepare all forms and PUT them to the API
    try {
        return await prepareAndPUTFiles(0);
    }
    catch (err) {
        console.log('error while preparing and PUTting files to API:')
        console.log(err);
        throw err;
    }
}

const patchPage = (jobId: string, pageId: string, patch: Partial<Page> | any) => {
    return customFetch.patch(`/page/page/${jobId}/${pageId}`, patch);
}

const deletePage = (jobId: string, deleteGroup: any) => {
    return customFetch.patch(`/page/page/${jobId}`, deleteGroup)
}

const downloadProof = (jobId: string, part: PagePart | string, first: number, last: number, highRes = false) => {
    return customFetch.head(`/page/pages/${jobId}/proof?first=${first}&last=${last}&part=${part}${highRes ? "&quality=high" : ""}`)
}

const insertBlank = (jobId: string, part: PagePart | string, ordinal: number) => {
    return customFetch.post(`/page/page/${jobId}`, {
        count: 1,
        ordinal: ordinal,
        part
    })
}

const patch = (jobId: string, approvalUpdateData: any) => {
    return customFetch.patch(`/page/page/${jobId}`, approvalUpdateData)
}

const PagesApi = {
    getPartPages,
    getPartsPages,
    batchUpdatePages,
    requestProof,
    uploadPage,
    uploadPages,
    multiUploadSinglePages,
    deletePage,
    patchPage,
    downloadProof,
    insertBlank,
    patch
}

export default PagesApi