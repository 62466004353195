import classNames from "classnames"
import { useEffect, useRef } from "react"
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleRight, FaArrowCircleLeft, FaArrowCircleRight, FaExclamationTriangle, FaLock, FaTrash } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import JobApi from "../lib/job"
import { visitJob } from "../routes/visit"
import { useJob } from "../store/jobSlice"
import { search, setPageNumber, useSearch } from "../store/searchSlice"
import { JobTypes, SearchJob } from "../types/job.types"
import { JobStatus } from "../types/types"
import { hideProgress, progressPercent, progressStage } from "../utils/job"
import { useAuthState } from "../store/authSlice"

function SearchList() {
    const { numberOfPages, pageNumber, results, loading, error, query } = useSearch()
    const { jobId } = useJob()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const titlesListRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (loading) return;
        if (results.length > 0 && titlesListRef.current) titlesListRef.current.scroll(0, 0)
    }, [loading, results.length])

    const setPageNumberHandler = (pageNumber: number) => {
        if (pageNumber < 1 || pageNumber > numberOfPages) return;
        dispatch(setPageNumber(pageNumber));
    }

    const jobItemClickHandler = (accountId: string, clickJobId: string) => {
        navigate(visitJob({ accountId, jobId: clickJobId }))
    }

    const trySearchAgainHandler = () => {
        dispatch(search())
    }

    const isJobCheckingStatus = (job: SearchJob, status: "CHECKED_IN" | "CHECKED_OUT") => {
        if (job.Status !== JobStatus.Active || !job.PartsList || !job.Stage) return false;
        // const filteredStage = Object.entries(job.Stage).filter(([key,]) => job.PartsList.includes(key as PagePart));
        if (status === "CHECKED_IN" && job.Type === JobTypes.TEP) {
            return true;
        } else if (status === "CHECKED_OUT" && job.Type === JobTypes.Book) {
            return true;
        }
        return false;
    }

    const requestFileHandler = async () => {
        const { value, isConfirmed } = await Swal.fire({
            icon: 'info',
            text: "Please enter the ISBN, SKU, Customer Code or other title identifier for the title that you would like to request. Though we strive to complete your request as quickly as possible, please allow up to 24 hours.",
            confirmButtonText: "Request files",
            input: "text",
            inputValue: query,
            inputValidator: val => !`${val}`.trim() ? "input can't be empty" : null,
            inputPlaceholder: "",
            showCancelButton: true,
            reverseButtons: true
        });

        if (!isConfirmed) return;

        JobApi.requestJob(value)
            .then(() => {
                Swal.fire({
                    icon: "success",
                    // TODO -
                    // Find out why this toast renders the text positioned differently, and 
                    // apply the required styling to make it render like the others.
                    text: "Your request for files has been submitted.",
                    timer: 4000,
                    toast: true,
                    position: 'top',
                    showClass: {
                        popup: 'swal-popover-in'
                    },
                    hideClass: {
                        popup: 'swal-popover-out'
                    },
                    showCloseButton: true,
                    grow: 'row'
                })
            })
            .catch(() => {
                Swal.fire({
                    icon: "error",
                    text: "An error occurred while making the request for files."
                })
            })
    }

    return (
        <>
            <div className="px-0" style={{ borderBottom: "1px solid #a3a3a321" }}>
                <div className="pagination-control py-2 is-flex is-justify-content-center px-1 py-1">
                    <button onClick={() => setPageNumberHandler(pageNumber - 1)} className="button is-primary px-5" disabled={loading || (!results.length && !error)}>
                        <span className="icon">
                            <FaAngleDoubleLeft />
                        </span>
                    </button>
                    <div className="is-align-self-center has-text-centered mx-2 has-text-light is-flex-grow-1">
                        {
                            !results.length ? <span className="is-size-8"></span> : <span className="is-size-8">Page {pageNumber}{' / '}{numberOfPages}</span>
                        }
                    </div>
                    <button onClick={() => setPageNumberHandler(pageNumber + 1)} className="button is-primary px-5" disabled={loading || (!results.length && !error)}>
                        <span className="icon">
                            <FaAngleDoubleRight />
                        </span>
                    </button>
                </div>
            </div>
            <div ref={titlesListRef} data-testid="titles-component" className="titles-list" >
                {
                    (!results.length && !error) &&
                    <div className="my-5">
                        <p className="mb-5 has-text-centered has-text-light">Title wasn&apos;t found.</p>
                        <p className="mb-5 has-text-centered has-text-light">The title may still exist in Lakeside Book archives.</p>
                        <div className="buttons is-centered">
                            <button onClick={requestFileHandler} className="button is-primary">Request files</button>
                        </div>
                    </div>
                }
                {
                    error &&
                    <div className="mt-3">
                        <p className="has-text-centered has-text-light">
                            There was an error fetching search data.
                        </p>
                        <div className="buttons is-centered">
                            <button onClick={trySearchAgainHandler} className="button is-primary">Try again</button>
                        </div>
                    </div>
                }
                {
                    !error && results.map(result => {
                        const isCheckedOut = isJobCheckingStatus(result, "CHECKED_OUT");
                        const isCheckedIn = isJobCheckingStatus(result, "CHECKED_IN");
                        return <div key={result.JobID} onClick={() => jobItemClickHandler(result.Account.AccountID, result.JobID)} className={classNames("title-item-bg", { "active-title": result.JobID === jobId })}>
                            <div className="title-item is-flex is-justify-content-space-between px-4 pt-2">
                                <div className="title-list-item-text">
                                    <div className="title has-text-light is-size-7">
                                        {
                                            result.Status === JobStatus.Locked ? <span className="icon fill-svg-warning">
                                                <FaLock />
                                            </span> :
                                                (
                                                    result.Status === JobStatus.Deleted ? <span className="icon fill-svg-warning">
                                                        <FaTrash />
                                                    </span> :
                                                        (
                                                            result.Status === JobStatus.Error &&
                                                            <span className="icon fill-svg-danger">
                                                                <FaExclamationTriangle />
                                                            </span>
                                                        )
                                                )
                                        }
                                        {result.Title || "No Title"}
                                    </div>
                                    <div className="subtitle is-size-7 mb-0 has-text-grey-light">
                                        {result.Author || <i>No Author</i>}
                                    </div>
                                    <div className="subtitle is-size-7 has-text-grey-lighter">
                                        {
                                            result.CustomerReference ? `C.R. ${result.CustomerReference}` :
                                                (result.ISBN13 || result.ISBN10) ? `ISBN ${result.ISBN13 || result.ISBN10}` :
                                                    'No C.R. or ISBN'
                                        }
                                    </div>
                                </div>
                                <div className="icon arrow is-flex is-align-self-center" style={{ marginTop: 30 }}>
                                    <span>
                                        <FaAngleRight size={39} className="is-align-self-center" />
                                    </span>
                                </div>

                            </div>
                            <div className={classNames("px-4 is-flex pb-2", { "is-justify-content-flex-end": isCheckedIn })}>
                                {
                                    isCheckedOut ? <div className="icon fill-svg-warning "> <FaArrowCircleLeft size={17} style={{ backgroundColor: "white", borderRadius: "50%" }} /> </div> : null
                                }
                                {
                                    isCheckedIn ? <div className="icon fill-svg-success"> <FaArrowCircleRight size={17} style={{ backgroundColor: "white", borderRadius: "50%" }} /> </div> : null
                                }
                            </div>
                            {
                                !hideProgress(result) ?
                                    <div className="px-4 mb-2">
                                        <div className="progress-bar-and-label-wrapper">
                                            <progress className="progress is-small is-success" value={progressPercent(result)} max={100} style={{ width: "100%", backgroundColor: "#008b55", border: "1px solid #ffffffa1" }}></progress>
                                            <span>{progressStage(result) !== "" && `${progressStage(result)}: `}
                                                {progressPercent(result)}%</span>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    })
                }
            </div>
        </>
    )
}

export default SearchList
