import { SetStateAction, useCallback, useState } from 'react';
import applyAction from '../_utils/applyAction';

export default function useSet<T>(
	initialValue: T[] = []
): [T[], (value: T, action: SetStateAction<boolean>) => void] {
	const [setContents, setSetContents] = useState(initialValue);

	const include = useCallback(
		(value: T, action: React.SetStateAction<boolean>) => {
			setSetContents(contents => {
				const setIncludesValue = contents.includes(value);

				const newState = applyAction(setIncludesValue, action);

				if (newState === true) {
					if (!contents.includes(value)) return [...contents, value];
					else return contents;
				} else {
					if (contents.includes(value))
						return contents.filter(v => v !== value);
					else return contents;
				}
			});
		},
		[]
	);

	return [setContents, include];
}
