import { MouseEvent, useState } from "react"
import Foco from "react-foco";
import { FaPlus } from "react-icons/fa"
import { PagePart } from "../types/pages.types";
import { JobStatus } from "../types/types";
import { dispatchCustomEvent } from "../utils/utils";
import { useJob } from "../store/jobSlice"
import PartSelector from "./PartSelector";
import classNames from "classnames";

import styles from "./AddComponentCard.module.scss"


function AddComponentCard() {

    const { job } = useJob()

    const [showParts, setShowParts] = useState(false);
    const [pos, setPos] = useState({ x: 0, y: 0 });

    const addComponentClickHandler = (e: MouseEvent<HTMLDivElement>) => {
        if (job?.Status === JobStatus.Active) {
            setPos({ x: e.clientX, y: e.clientY });
            setShowParts(true);
        }
    }

    const setSelectedPartHandler = (part: PagePart | string) => {
        dispatchCustomEvent("upload-modal-open", { part })
    }

    return <Foco className="is-flex is-flex-direction-column px-1" onClickOutside={() => setShowParts(false)}>
        <div className="title is-size-6 mb-3 mt-5 is-invisible">Add component</div>
        <div onClick={addComponentClickHandler} className={classNames({"disabled-custom-control": job && job.Status !== JobStatus.Active}, styles.Card, 'is-clickable rounded-1 add-component-card-fixed-dims')} > 
            <div className="mb-6 has-text-centered">
                <div className="icon">
                    <FaPlus size={50} />
                </div>
                <div className="is-size-4">
                    Add Component
                </div>
            </div>
            {
                showParts &&
                <PartSelector pos={pos} setSelectedPart={setSelectedPartHandler} exclude={job?.PartsList} />
            }
        </div>
    </Foco>
}

export default AddComponentCard