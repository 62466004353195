export interface RuleDef {
	fixable: boolean;
	icon: string;
	number: number;
	category: RuleCategory;
	malformed?: boolean;
}

export const categoryFaIcons = {
	page: 'page',
	image: 'image',
	color: 'paint-brush',
	text: 'font',
	format: 'file-o',
	vector: 'pie-chart',
	undefined: 'exclamation-triangle'
};

export type RuleCategory = keyof typeof categoryFaIcons;
const categories = Object.keys(categoryFaIcons) as RuleCategory[];
function isCategory(input: string | undefined): input is RuleCategory {
	if (!input) return false;
	else return categories.includes(input as RuleCategory);
}

/**
 * Returns an object definition for a rule identifier string of the format number,category,fixability.
 *
 * @param ruleIdentifier
 */
export default function extract(ruleIdentifier: string): RuleDef {
	if (ruleIdentifier.toLowerCase() === 'trimsize')
		return {
			category: 'format',
			icon: 'file-o',
			fixable: false,
			number: 0
		};

	const split = ruleIdentifier.split(',');

	const [numberStr, category, fixability = 'true'] = split;
	const number = parseInt(numberStr, 10);
	// tslint:disable-next-line no-magic-numbers
	if (!isCategory(category)) {
		console.error(
			`${category} is not a defined rule category, "${ruleIdentifier}" is not in correct format`
		);

		return {
			icon: 'undefined',
			category: 'undefined',
			fixable: false,
			number: 0,
			malformed: true
		};
	}
	const icon = number < 100 ? 'info' : categoryFaIcons[category];

	const def = {
		icon,
		category,
		number: parseInt(numberStr, 10),
		fixable: fixability.toLowerCase() === 'true'
	};

	return def;
}
