import classNames from "classnames"
import capitalize from 'lodash/capitalize'
import React, { useEffect, useState } from "react"
import { FaArrowLeft } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import historyApi from "../../lib/history"
import { visitJob } from "../../routes/visit"
import { setJobId, useJob } from '../../store/jobSlice'
import { Job } from "../../types/job.types"
import { sortParts } from "../../utils/parts"
import HeaderBanner from "../HeaderBanner"
import { useAuthState } from "../../store/authSlice"
import { applyPreRenderTransformsForEntry, approvalStreamActivities, DerivedRenderConditions, filterJobLevelHistoryEntries, HistoryFilters, objectStreamActivityVerbs, pageStreamActivityVerbs, partStreamActivityVerbs, tepStreamActivityVerbs, transformGsIsoTimeToLocale, transformObject, transformPartName, transformVerb } from "./historyEntryTransforms"

export interface HistoryEntry {
    actor?: string,
    verb?: string,
    object?: string,
    part?: string,
    account?: string,
    foreign_id?: string,
    id?: string,
    origin?: string | null,
    target?: string,
    time?: string,
    unifiedid?: string,
    renderData: { // renderData does not come from getStream - it is an added property used to contain optional extra entry data that will be rendered to the UI 
        jobTitle?: string,
        origCopiedJobTitle?: string,
    },
    renderOn?: DerivedRenderConditions, // same for this property - contains derived data used in rendering to UI
    count?: number,
    ordinal?: number,
    folio?: string,
    type?: string, //type of reset - possibly other props depending on the activity
    quality?: string, // quality of 'pageproof' downloaded
    stage?: string, // stage when certain approvals are made - pf1, pf2, etc.
    [anyOtherActivityPropertyKey: string]: any // accept all, because any not needed can be dropped - this API response is picked from to create a HistoryEntry anyways
}

const ViewHistory = () => {

    const { part: paramPart, jobId: paramJobId } = useParams()

    // boolean state flag to only "directly" run the history filter change handler on the first render of this component
    const [initialRenderComplete, setInitialRenderComplete] = useState<boolean>(false)

    // Array to hold the "base" job history entries in state; these will only be fetched once and stored here for a given instance of this component.
    // (<= 100 entries)
    const [baseHistoryEntries, setBaseHistoryEntries] = useState<HistoryEntry[]>()

    // The current history entries that are displayed on the UI - after being filtered and transformed as needed
    const [filteredHistoryEntries, setFilteredHistoryEntries] = useState<HistoryEntry[]>([])

    // Note: part history entries are not fetched once and stored in state like the "baseHistoryEntries" -
    // they are instead fetched any time a part is selected and then set under filteredHistoryEntries until the selection changes.

    const [historyLoading, setHistoryLoading] = useState<boolean>(true)
    const [historyError, setHistoryError] = useState<string>('')

    const dispatch = useDispatch()

    const { job, jobId, pages } = useJob()

    const navigate = useNavigate()

    // Hook to unmount ViewHistory component on page load after browser refresh - because the redux store selector hooks lose their values after a refresh.
    // this works - but it would be better to go ahead and stop ViewHistory ever rendering after a browser refresh.
    useEffect(() => {
        if (!job && paramJobId) {
            // navigate(`/dashboard/${paramJobId}`)
            // The below re-obtaining of the job that was in state by param should work for giving the View History component the data it needs after a browser refresh.
            dispatch(setJobId(paramJobId))
        }
    }, [job, navigate, paramJobId])

    // The `pages` state store data should already be available when this component loads, so this initial value works well:
    const [jobHistoryParts, setJobHistoryParts] = useState<string[]>(Object.keys(pages).sort(sortParts))

    // useEffect hook to ensure jobHistoryParts is set to its initial value even if the job or the job's pages change in state:
    // resets state data if browser is refreshed while ViewHistory is active
    useEffect(() => {
        if (jobHistoryParts !== Object.keys(pages).sort(sortParts)) {
            // console.log('setting missing jobHistoryParts value in state:')
            setJobHistoryParts(Object.keys(pages).sort(sortParts))
        }
    }, [job, pages])

    const backClickHandler = () => {
        navigate(visitJob({}))
    }

    // currently-selected history filter option from the select dropdown
    const [historyFilterSelection, setHistoryFilterSelection] = useState<string | undefined>()

    /**
     * Gets the "base" history for a job, to set in state and show for any job-level history filter selections.  
     * (Retrieves up to 100 of the most recent history entries for the job and its parts)
     */
    const getBaseHistoryForJob = async (currentJob?: Job) => {
        if (currentJob) {
            return await historyApi.getFeedForJob(currentJob)
                .then(res => {
                    if (res?.results) {
                        return res.results as HistoryEntry[];
                    }
                })
        }
        else {
            console.error('Either currentJob was not passed to this function, or it is not defined.')
            return undefined;
        }
    }

    // Hook to run async logic to fetch and set data for the first history filter on component load
    useEffect(() => {
        if (!job) {
            // console.log('Not running initial history filter render without state job.')
            return;
        }
        if (job && paramPart && !initialRenderComplete) {
            // Call historyFilterChangeHandler for the special case of component init -
            // Use the below state variable to record the first render, to only run this once when the component first mounts.
            setInitialRenderComplete(true)
            // console.log('Performing first and only direct historyFilterChangeHandler() call')
            historyFilterChangeHandler(undefined, paramPart)
        }
    }, [job, pages])

    // Pre-filters to remove unwanted/trivial history entries before the data transforms and initial selected filter are applied
    async function preFilterHistoryData(historyEntries: HistoryEntry[]): Promise<HistoryEntry[]> {

        // Filters out any 'update' entries that are not informative
        // Most (if not all) 'update' activity entries seem trivial and not useful - 
        // They are often devoid of any definite information other than 'update', and they often occur repeatedly within milliseconds of one another
        historyEntries = historyEntries.filter(entry => entry.verb !== 'update')

        return historyEntries;
    }

    // Adds relevant data and transforms some text values to more human-readable forms where applicable for history entries
    async function applyPreRenderTransforms(entries: HistoryEntry[]): Promise<HistoryEntry[] | void> {
        const transformedEntries: HistoryEntry[] = []
        for (const entry of entries) {
            await applyPreRenderTransformsForEntry(entry, job).then((transformedEntry) => {
                transformedEntries.push(transformedEntry)
            })
        }
        return transformedEntries;
    }

    // Runs both the prefiltering and data-transforming methods on a set of entries, before they are set on the UI
    const prepareEntriesForUi = async (entries: HistoryEntry[]) => {
        return await preFilterHistoryData(entries)
            .then(prefilteredEntries => {
                return applyPreRenderTransforms(prefilteredEntries);
            })
    }

    // Applies dropdown selection filters that are "job-level" options and not parts of the job ("All Components", "Deleted Components")
    const filterJobLevelEntries = (filter: string, entries: HistoryEntry[]) => {
        if (filter === 'all') {
            return entries;
        }
        if (filter === 'deleted') {
            if (job) {
                return filterJobLevelHistoryEntries(entries, { filter: HistoryFilters.deleted, job: job })
            }
        }
    }

    /** Method to get new selection data for a part of a job, when the part is selected on the history dropdown.     
     * For a part, the data will be fetched from the API recursively each time to get and show up to 100 entries.  
     */
    const getNewPartSelectionData = async (job: Job, part: string) => {
        return await historyApi.getFeedEntriesForPart(job, part);
    }

    /** change handler that runs when the history filter <select> option is changed.  
     * Note: this only ever runs without a change event on initial history component load - this is called once in a small useEffect() hook.  
     * in that case, it runs with `initialValue` set to the URL part parameter that the history component is being loaded for. 
     * ** The method can only run with either an `event` param or an `initialValue` param - not both.  
     * @param event - the change event from the <select> option change
     * @param initialValue - the initial URL part parameter, passed only whenever the history component first loads.
     * 
     * */
    const historyFilterChangeHandler = async (event?: React.ChangeEvent<HTMLSelectElement>, initialValue?: string) => {

        if (event && initialValue) {
            throw new Error('Method can only run with either the event or the initialValue param, not both.');
        }

        setHistoryError('')
        setHistoryLoading(true)
        setFilteredHistoryEntries([])

        const value = event?.currentTarget?.value || initialValue;
        if (value) {
            // When the below state variable is set,
            // a useEffect hook will fire to fetch and/or set the new data for the new filter selection:
            setHistoryFilterSelection(value)
        }
        else {
            console.error('No event value was received for the history filter change.')
            setHistoryError('Something went wrong while trying to retrieve the history for this job.');
        }
    }

    // useEffect hook to fetch and/or set data for a given historyFilterSelection value - any time a new select dropdown option is chosen
    useEffect(() => {
        // do not even try to fetch if the state store job is not available - it has to be reset in state first:
        if (!job) {
            // console.log('Not attempting history filter change fetch since there is no redux job in state.')
            return;
        }
        // also do not try to fetch data if no jobHistoryParts are present - they have to be reset in state first:
        if (jobHistoryParts.length < 1) {
            // console.log('Not attempting history change since no job history parts are set in state.')
            return;
        }

        // flag to be set to true if component unmounts - to conditionally abort attempting a state update on an unmounted component
        let cancelled = false;

        /** Async method to handle the history filter change */
        async function handleHistoryFilterChange() {
            // if the newly selected value is a part:
            if (job && historyFilterSelection && jobHistoryParts.includes(historyFilterSelection)) {
                return await getNewPartSelectionData(job, historyFilterSelection)
                    .then((data: HistoryEntry[] | undefined) => {
                        if (data) {
                            return data;
                        }
                        else {
                            throw new Error('Part data could not be obtained for the selected filter.')
                        }
                    })
                    .then(entries => {
                        if (entries) {
                            return prepareEntriesForUi(entries);
                        }
                        else {
                            throw new Error('No part entries were supplied to prepare for display.')
                        }
                    })
                    .then(preparedEntries => {
                        if (cancelled) {
                            // console.log('async return set state was aborted. (cancelled was set to true on unmount.)')
                            return;
                        }
                        if (preparedEntries) {
                            setFilteredHistoryEntries(preparedEntries)
                            setHistoryLoading(false)
                        }
                        else {
                            throw new Error('The prepared part entries could not be set for display.')
                        }
                    })
                    .catch((err) => {
                        console.log(`Error while setting history filter to a part filter (for part "${historyFilterSelection}"):`)
                        console.log(err)
                        setHistoryError('Something went wrong while trying to retrieve the history for this job.')
                        setHistoryLoading(false)
                    })
            }
            else if (job && historyFilterSelection) {
                // if the newly selected value is a job-level filter ("All Components", "Deleted Components", etc.)                

                // if the baseHistoryEntries data has already been fetched, prepared, and set:
                if (baseHistoryEntries) {
                    // just filter the base history entries to the new selected filter and display these
                    const entriesToDisplay = filterJobLevelEntries(historyFilterSelection, baseHistoryEntries)
                    if (entriesToDisplay) {
                        setFilteredHistoryEntries(entriesToDisplay)
                    }
                    else {
                        setHistoryError('Something went wrong while trying to retrieve the history for this job.');
                    }
                    setHistoryLoading(false)
                }
                else {
                    // If the baseHistoryEntries data still needs to be fetched, prepared, and set in state for the first time:
                    return await getBaseHistoryForJob(job)
                        .then((res: HistoryEntry[] | undefined) => {
                            if (res) {
                                return prepareEntriesForUi(res);
                            }
                            else {
                                throw new Error('No base job history returned.')
                            }
                        })
                        .then(preparedEntries => {
                            if (preparedEntries) {
                                // also set the fetched base job history entries in state for next time they are requested:
                                setBaseHistoryEntries(preparedEntries)
                                return filterJobLevelEntries(historyFilterSelection, preparedEntries)
                            }
                            else {
                                throw new Error('Base job history entries could not be prepared.')
                            }
                        })
                        .then((displayEntries) => {
                            if (displayEntries) {
                                if (cancelled) {
                                    // console.log('async return state set was aborted. (cancelled was set to true on unmount.)')
                                    return;
                                }
                                setFilteredHistoryEntries(displayEntries)
                                setHistoryLoading(false)
                            }
                            else {
                                throw new Error('Job history entries could not be filtered for display.')
                            }
                        })
                        .catch(err => {
                            console.log('Error while setting history filter to a job-level filter:')
                            console.log(err)
                            setHistoryError('Something went wrong while trying to retrieve the history for this job.');
                            setHistoryLoading(false)
                        })
                }
            }
        }

        handleHistoryFilterChange();

        return (() => {
            // console.log('Component unmounting. Setting cancelled to true, to abort any async fetch returns.')
            cancelled = true;
        })

    }, [jobHistoryParts, historyFilterSelection])

    return (
        <div className="column p-0 dashboard-main scrollable">
            <HeaderBanner>
                <div className="job-banner has-background-link p-3">
                    {job && <>
                        <div className="is-flex is-justify-content-space-between">
                            <div className="title has-text-light is-size-6">{job.Title}</div>
                            <div className="is-flex is-flex-wrap-no-wrap"></div>
                        </div>
                        <div>
                            <div className="columns">
                                <div className="column is-half">
                                    <nav className="level">
                                        <div className="level-left">
                                            <div className="has-text-white mr-0 px-2">
                                                <div>
                                                    <p className="help has-text-grey-light">JOB #</p>
                                                    <p className="is-size-11">{job.Tags?.SystemJobNumber || <i className="is-size-11">No Job Number</i>}</p>
                                                </div>
                                            </div>
                                            <div className="has-text-white mr-0 px-2">
                                                <div>
                                                    <p className="help has-text-grey-light">
                                                        {
                                                            (job.ISBN13 || job.ISBN10) ? 'ISBN' : job.CustomerReference ? 'CR #' : 'ISBN/CR #'
                                                        }
                                                    </p>
                                                    <p className="is-size-11">{job.ISBN13 || job.ISBN10 || job.CustomerReference || <i className="is-size-11">No ISBN or CR #</i>}</p>
                                                </div>
                                            </div>
                                            <div className="has-text-white mr-0 px-2">
                                                <div>
                                                    <p className="help has-text-grey-light">AUTHOR</p>
                                                    <p className="is-size-11">{job.Author || <i className="is-size-11">No Author</i>}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                                <div className="column is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-flex-end">
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </div>
            </HeaderBanner>
            <div className="view-history-box has-text-light-cascading-no-important has-background-grey box m-4 p-5 has-border">
                <div onClick={backClickHandler} className="button p-4 mb-5 has-text-light is-link is-clickable">
                    <FaArrowLeft className="is-align-self-center" />
                    <div className="is-align-self-center ml-3">Back</div>
                </div>
                <header>
                    <h2 className="text is-size-5 mb-0 view-history-header">
                        History
                    </h2>
                    <div className='columns mb-0'>
                        <div className='column is-narrow is-flex is-justify-content-stretch is-align-items-flex-start'>

                            <div className='mt-3 history-select-filter-wrapper ml-2'>
                                <h3 className='text is-size-6'>Showing history for:</h3>
                                <div className="mt-1 select history-filter-select">
                                    <select value={historyFilterSelection} disabled={historyLoading} onChange={async (e) => await historyFilterChangeHandler(e)} className='history-filter-select'>
                                        {jobHistoryParts.map((part, index) => {
                                            return <option key={part + index} value={part}>{transformPartName(part) || part}</option>
                                        })}
                                        {jobHistoryParts && (
                                            <>
                                                <option key={'all-option-react-key'} value='all'>All components</option>
                                                <option key={'deleted-option-react-key'} value='deleted'>Deleted components</option>
                                            </>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='column is-flex is-justify-content-flex-end is-align-items-flex-start'>
                        </div>
                    </div>
                    <hr className="my-1 p-0" />
                </header>
                <div className={classNames("history-record", { "is-loading": historyLoading }, { "no-entries-found": (!historyLoading && !historyError && filteredHistoryEntries?.length === 0) }, { "errorred": (!historyLoading && historyError) })}>
                    <table className="table has-background-dark is-rounded is-fullwidth has-text-centered">
                        <tbody>
                            {
                                historyLoading ?
                                    <tr className='history-message-tr'>
                                        <td><p>Loading...</p><div className='history-loading-spinner'></div></td>
                                    </tr> : null
                            }
                            {
                                (!historyLoading && filteredHistoryEntries && filteredHistoryEntries.length > 0) ? filteredHistoryEntries.map((entry, index) => { // TODO -> make sure entry.id is a guaranteed return value and is always unique - check Getstream documentation */
                                    const { actor, verb } = entry

                                    // TODO eliminate the need to make this odd check - enforce renderOn as guaranteed to be on 'entry' so that this never evaluates to {}
                                    const { renderOn } = entry || {}
                                    const { part, object } = renderOn || {}
                                    const { objType, objValue } = object || {}

                                    const isApprovalActivity: boolean | undefined = (verb ? verb in approvalStreamActivities : undefined)

                                    // console.log(`(${index})   (${part ? part : `no part`})`, actor, verb, `${objType || 'no objType'}`, `'${objValue?.substring(0,10)}...'`)
                                    return (
                                        <tr key={entry.id} className="tr has-text-left">
                                            <td className='history-activity-column-entry'><span className="history-event-tag button is-static has-background-grey-dark has-text-light is-unselectable">{entry.verb === 'created' ? 'create' : entry.verb}</span></td>
                                            <td className="history-activity-column-contents">
                                                <span>
                                                    {/* ({index})&emsp; */}
                                                    {(historyFilterSelection && ['all', 'deleted'].includes(historyFilterSelection) &&
                                                        entry.part && entry.part !== 'all')
                                                        && (
                                                            <span className='is-overline'>{transformPartName(entry.part)}</span>
                                                        )}
                                                    <span>&nbsp;</span>
                                                    {entry.actor && (entry.actor + (entry.verb === 'downloadfailed' ? "'s" : ''))}
                                                    <span>&nbsp;</span>
                                                    {entry.verb && entry.verb === 'jobcopy' &&
                                                        (<span>created </span>)
                                                    }
                                                    {
                                                        (entry.verb === 'reset' && (entry.type && ['preflyt1', 'preflyt2', 'Preflyt1', 'Preflyt2'].includes(entry.type))) &&
                                                        <span>reset {capitalize(entry.type)} for</span>
                                                    }
                                                    {
                                                        (entry.verb === 'reset' && !(entry.type && ['preflyt1', 'preflyt2', 'Preflyt1', 'Preflyt2'].includes(entry.type))) &&
                                                        <span>performed a {(entry.type && ['soft', 'hard'].includes(entry.type)) && <span>{entry.type}{' '}</span>}reset of </span>
                                                    }
                                                    {entry.verb === 'resubmit' && <span>checked in </span>}
                                                    {entry.verb === 'resubmit' && entry.object?.startsWith('PDF:') &&
                                                        <span><i> {transformObject(entry.object)}</i> on </span>}
                                                    {entry.verb && entry.verb !== 'reset' && entry.verb !== 'jobcopy' && entry.verb !== 'not-reviewed' && entry.verb !== 'pageproof' && entry.verb !== 'resubmit' && transformVerb(entry.verb)}
                                                    {entry.verb &&
                                                        entry.verb in objectStreamActivityVerbs &&
                                                        (entry.object?.startsWith('Job:') && (entry.part === 'all' || !entry.part) && entry.renderData?.jobTitle) &&
                                                        <strong> {entry.renderData.jobTitle}</strong>
                                                        ||
                                                        (entry.object?.startsWith('Component:') && entry.part) &&
                                                        <span> the <strong>{transformPartName(entry.part)}</strong> component</span>
                                                    }
                                                    {entry.verb && entry.verb === 'jobcopy' && <span> by making a copy of {entry.renderData?.origCopiedJobTitle ? `the title "${entry.renderData.origCopiedJobTitle}"` : entry.target?.startsWith('Job:') ? `the title under ID number ${entry.target.replace('Job:', '')}` : 'another title'}</span>}
                                                    {
                                                        ((isApprovalActivity && (entry.type || entry.stage)) &&
                                                            (
                                                                (([entry.type, entry.stage].includes('preflyt1') || [entry.type, entry.stage].includes('Preflyt1') && isApprovalActivity) &&
                                                                    <></>)
                                                                ||
                                                                (([entry.type, entry.stage].includes('preflyt2') || [entry.type, entry.stage].includes('Preflyt2')) && <span> Preflyt2 for </span>)
                                                            )
                                                        )
                                                    }
                                                    {entry.verb &&
                                                        entry.verb in partStreamActivityVerbs &&
                                                        entry.object?.startsWith('PDF:') && (<span><i> {transformObject(entry.object)}</i> to </span>)}
                                                    {(entry.verb && entry.part !== 'all' && (entry.verb in partStreamActivityVerbs || (isApprovalActivity && !entry.object?.startsWith('Page:'))) &&
                                                        (entry.part && entry.part !== 'all' && entry.verb !== 'created' && entry.verb !== 'delete' && (<span> the <strong>{transformPartName(entry.part)}</strong> component</span>))
                                                    )
                                                    }
                                                    {(entry.verb && entry.target && entry.verb === 'delivered') && <span>{' '}to {entry.target}</span>}
                                                    {isApprovalActivity && ([entry.type, entry.stage].includes('preflyt1') || [entry.type, entry.stage].includes('Preflyt1'))
                                                        && (
                                                            <span> to print</span>
                                                        )
                                                    }
                                                    {(entry.verb && entry.verb in tepStreamActivityVerbs &&
                                                        entry.part && (<span> the <strong>{transformPartName(entry.part)}</strong> component</span>))}
                                                    {entry.verb && entry.verb === 'pageproof' && (
                                                        <span>requested a download for {entry.count === 1 ? 'the proof' : 'proofs'} of </span>
                                                    )}
                                                    {entry.verb && entry.verb in pageStreamActivityVerbs && (entry.object?.startsWith('Page:') || entry.verb === 'pageproof') &&
                                                        (
                                                            <span>
                                                                {entry.verb && entry.verb === 'not-reviewed' && (
                                                                    <span>unapproved </span>
                                                                    // replaced 'made previous changes that moved ' with 'unapproved '
                                                                )}
                                                                {/* {isApprovalActivity ? <span> content for </span> : <span>{' '}</span>} */}
                                                                {' '}
                                                                {(entry.count && entry.count !== 0 && entry.count !== 1) ? `${isApprovalActivity ? '' : ' '}${entry.count} ` : ''}
                                                                {entry.count && entry.count > 1 && <span>pages </span>}
                                                                {/* {isApprovalActivity && (',')} */}
                                                                {((entry.count === 1 || !entry.count) && entry.part !== 'all' && (<span>{(entry.folio ? <span>page {'"'}{entry.folio}{'"'}&nbsp;</span> : <span> a page </span>)}</span>))}
                                                                {(entry.count && entry.count > 1 && entry.folio) && <span>{isApprovalActivity ? '' : ''}starting at page number &quot;{entry.folio}&quot; </span>}
                                                                {entry.part !== 'all' && (entry.verb === 'delete' ? 'from ' : 'of ')}
                                                                {/* entry.verb !== 'not-reviewed' && <--- removed from above render of 'from' : 'of' */}
                                                                {/* : isApprovalActivity ? 'on '  */}
                                                                {/* {entry.verb === 'not-reviewed' && <span>back to an unapproved state on </span>} */}
                                                                {/* removed the above as part of changing the verbiage to 'unapproved' whenever a 'not-reviewed' verb is passed */}
                                                                <span>{entry.part === 'all' ? entry.object?.startsWith("Job:") ? <strong>{entry.renderData?.jobTitle}</strong> : <span>the title</span> : <span>the <strong>{entry.part && transformPartName(entry.part)}</strong> component</span>}</span>
                                                            </span>
                                                        )}
                                                    <span>&nbsp;</span>
                                                    {entry.time ? `on ${transformGsIsoTimeToLocale(entry.time)}` : ''}</span></td>
                                        </tr>)
                                }) :
                                    (!historyLoading && !historyError) &&
                                    <tr className="history-message-tr">
                                        <td>
                                            No history records were found for this {(historyFilterSelection && /(all|deleted)/.test(historyFilterSelection)) ? 'job filter' : 'part'}.
                                        </td>
                                    </tr>
                            }
                            {
                                (!historyLoading && historyError) ? <tr className="history-message-tr"><td className="has-text-danger">{historyError}</td></tr> : null
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )

}

export default ViewHistory;