import { SetStateAction } from 'react';

function actionIsFn<T>(action: SetStateAction<T>): action is (state: T) => T {
	return typeof action === 'function';
}

export default function applyAction<T>(state: T, action: SetStateAction<T>) {
	if (actionIsFn(action)) return action(state);
	else return state;
}
