import { changeActiveJob, getJobById } from "../store/jobSlice";
import { ReduxMQTTCallback } from "../mqtt"
import { generateCondition } from "../utils/utils";
import JobApi from "../lib/job";
import { search, searchSuccess, setNumberOfJobs } from "../store/searchSlice";
import { RootState } from "../store";
import { AccountTypes } from "../types/user.types";
import { SearchStatus } from "../types/types";
type mqttJobType = {
    Job: {
        JobID: string
    },
    DestinationAccount?: string,
    DestinationJobID?: string,
    Subject: string,
    Status: string
};

export enum MqttLabels {
    ACTIVE_JOB = "ACTIVE_JOB",
    ACTIVE_ACCOUNT = "ACTIVE_ACCOUNT"
}

const ReduxMqttHandler: ReduxMQTTCallback<mqttJobType> = ({ topic, label }, data, storeApi) => {
    const storeData: RootState = storeApi.getState();
    const authData = storeData.auth;
    const jobData = storeData.job;

    console.log(`[${label}] message in`, data);
    if (label === MqttLabels.ACTIVE_JOB) {
        // update activeJob
        storeApi.dispatch(getJobById(jobData.jobId))

        // check if job in searchlist and update
        const isActiveInList = storeData.search.results.some(job => job.JobID === data.Job.JobID);
        if (isActiveInList && data.Subject === "Progress") {
            storeApi.dispatch(search());
        }
    } else if (label === MqttLabels.ACTIVE_ACCOUNT) {
        const searchData = storeData.search;
        const { status } = searchData;
        if (data.Subject === "Created") {
            // do something here
            if (data.Status.toLocaleLowerCase() !== `${status}`.toLocaleLowerCase()) return;
            const searchFilter = generateCondition(searchData);
            JobApi.searchJobs(searchFilter).then(([job, jobsinfo]) => {
                storeApi.dispatch(searchSuccess(job.data))
                storeApi.dispatch(setNumberOfJobs(jobsinfo.data.NumberOfJobs))
            })
        } else if (data.Subject === "StatusChange") {
            // if active is the activeJob then
            if (jobData.jobId === data.Job.JobID) {
                storeApi.dispatch(getJobById(jobData.jobId));
                setTimeout(() => {
                    storeApi.dispatch(getJobById(jobData.jobId));
                }, 2 * 60 * 1000)
            }
            // if didn't status change to a status under SearchStatus. do nothing
            if (!Object.values(SearchStatus).map(s => s.toLocaleLowerCase()).includes(data.Status.toLocaleLowerCase())) return;


            if (data.Status.toLocaleLowerCase() !== `${status}`.toLocaleLowerCase()) {
                // dispatch another search
                storeApi.dispatch(search())
                // if active job is same as one with state change
                const activeJob = storeData.job.jobId;
                if (activeJob === data.Job.JobID) {
                    storeApi.dispatch(changeActiveJob(""));
                }
            }
        } else if (data.Subject === "Checkin") {
            if (authData.user?.AccountType === AccountTypes.TEP || authData.user?.AccountType === AccountTypes.Combined) {
                const activeJob = jobData.job;
                storeApi.dispatch(search())
                if (data.Job.JobID === activeJob?.JobID || !activeJob?.JobID) {
                    if (data.DestinationJobID) storeApi.dispatch(changeActiveJob(data.DestinationJobID));
                }
            }
        } else if (data.Subject === "Checkout") {
            if (authData.user?.AccountType === AccountTypes.Book || authData.user?.AccountType === AccountTypes.Combined) {
                const activeJob = jobData.job;
                storeApi.dispatch(search())
                if (data.Job.JobID === activeJob?.JobID || !activeJob?.JobID) {
                    if (data.DestinationJobID) storeApi.dispatch(changeActiveJob(data.DestinationJobID));
                }
            }
        } else if (data.Subject === "Progress") {
            // check if jobId inside searchList and update searchList
            storeApi.dispatch(search());
            console.log("progress in");
        }
    }
}

export default ReduxMqttHandler;