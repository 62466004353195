import classNames from 'classnames';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import styles from './Tabber.module.scss';

interface TabberProps {
	children: Array<{ name: string; contents: ReactNode }>;
	currentTab: string;
	setCurrentTab: Dispatch<SetStateAction<string>>;
}
export default function Tabber({
	children,
	currentTab,
	setCurrentTab
}: TabberProps) {
	return (
		<div className={styles.Tabber}>
			<div className={styles.Tabs}>
				{children.map(({ name }) => (
					<div
						key={name}
						className={classNames(styles.Tab, {
							[styles.Active]: name === currentTab
						})}
						onClick={() => setCurrentTab(name)}
					>
						<span className={styles.TabName}>{name}</span>
					</div>
				))}
			</div>
			<div className={styles.Content}>
				{children.map(({ name, contents }) => (
					<div
						key={name}
						className={classNames(styles.Pane, {
							[styles.Inactive]: currentTab !== name
						})}
					>
						{contents}
					</div>
				))}
			</div>
		</div>
	);
}
