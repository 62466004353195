// API object for accessing an Account or data on an Account object
import customFetch from "./axiosInstance"
import { User } from "../types/user.types";


/** Get a Lakeside account by account ID */
const getAccount = (accountId: string) => {
    return customFetch.get(`/admin/account/${accountId}`);
}


/** Get an account's roleset by the roleset ID under the Account's "AccountRoles" property. ("default", etc.)
*/
const getRolesetById = (rolesetId: string) => {
    return customFetch.get(`/admin/roleset/${rolesetId}`);
}


/**
 * Attempts to fetch corrections account display name for displaying on the UI.  
 * This is called whenever either a single component or all components of a job are 
 * moved back to Corrections (by a user selecting 'Make Corrections' on the UI.)
 * @param {string} combinedAccountId - the current active account ID. Must be a Combined/'umbrella' account ID. Expected to be available in the Redux store.
 * @param {User} activeUser - required to check user's 'Accounts' to confirm 'combinedAccountId' is indeed for an account of type 'Combined'. Prevents accidental misuse of this method.
 * @returns {string|false}  
 * - string - the account display name if found
 * - *false* - instead returns *false* literal if attempt to get account display name fails
 * 
 */
const getCorrectionsAcctDispNameByCombinedAcctId = async (combinedAccountId: string, activeUser: User): Promise<string|false> => {
    // check to confirm the id is a valid combined account id that the current user has access to:

    for (const account of activeUser.Accounts) {
        if (combinedAccountId === account.AccountID && account.Type === 'TEPScout') { 
            // call the API to get the 'SubAccounts' property associated with the combined account,
            // look up the Corrections 'subaccount' for the given combined account,
            // & return the Corrections 'subaccount' display name
            // TODO - 
            // This API call is how the angular FE implemented this feature.
            // In our new app structure, I think it might be possible to obtain this acct. display name without an API call.
            // It would probably require adding the 'SubAccounts' property for the 'active' Account somewhere in the state store.
            // We can look back at this in order to reduce unnecessary network activity.
            // At the least:
            // I would appreciate another set of eyes to assess the feasibility of getting this display name without an API call.
            // It just feels to me like I could be missing an easy change here.
            // I want to make sure I am utilizing Redux and the available data where it's helpful to use it, 
            // instead of always reaching for something across the network again. 
            // (Especially if the needed data is not likely to go 'stale', per se - like a 'SubAccounts' list.)
            // Anyways - this API call is not the most 'expensive' thing - not make-or-break.

            const acctDisplayNameFetchAttempt = await getAccount(combinedAccountId)
            .then((res) => {
                if (res.data) {
                    if (res.data['SubAccounts']) {
                        const subAcctIds: string[] = res.data.SubAccounts;
                        // find the associated Corrections subaccount:
                        for (const id of subAcctIds) {
                            const subAccountObj = activeUser.Accounts.find(acctObj => acctObj.AccountID === id)
                            if (subAccountObj && subAccountObj.Type === 'Book') {
                                return subAccountObj.AccountName;
                            }
                        }
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            })

            return acctDisplayNameFetchAttempt || false;
        }
    }
    return false;
}

const AccountDataApi = {
    getAccount,
    getCorrectionsAcctDispNameByCombinedAcctId,
    getRolesetById
}

export default AccountDataApi;