import capitalize from 'lodash/capitalize';
import React, { useContext, useMemo } from 'react';
import { FaQuestion } from 'react-icons/fa';
import PageDataContext from './PageDataContext';

import styles from './InksUsed.module.scss';

function isCssColor(strColor: string) {
	const s = new Option().style;
	s.color = strColor;
	return s.color === strColor;
}

function ColorSwatch({ color }: { color?: string }) {
	return (
		<div className={styles.ColorSwatch} style={{ backgroundColor: color }}>
			{color === undefined && <FaQuestion />}
		</div>
	);
}

function Ink({ color }: { color: string }) {
	const isValidColor = useMemo(() => isCssColor(color), [color]);

	return (
		<li>
			<ColorSwatch color={isValidColor ? color : undefined} />{' '}
			{capitalize(color)}
		</li>
	);
}

export default function InksUsed() {
	const page = useContext(PageDataContext);

	const { InksUsed: Inks } = page.Tags || {};

	return (
		<div className={styles.InksUsed}>
			<h6>Colors Used</h6>
			<ul className={styles.List}>
				{Inks ? (
					Inks.map(ink => <li key={ink}>{ink}</li>)
				) : (
					<p>None Specified</p>
				)}
			</ul>
		</div>
	);
}
