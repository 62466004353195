import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { changePagePosition } from "../store/pagesSlice";
import { PagePart } from "../types/pages.types";


type PartPageThumbnailDropZoneType = {
    ordinal: number,
    partVal?: string
}

function PartPageThumbnailDropZone({ ordinal, partVal }: PartPageThumbnailDropZoneType) {

    const dispatch = useDispatch();
    const { part } = useParams();

    const partValue = useMemo(() => {
        return partVal || part
    }, [part, partVal])

    const onPosChange = useCallback((from: number, fromPart: PagePart) => {
        if (!partValue) return;
        dispatch(changePagePosition({ fromOrdinal: from, part: fromPart, destinationOrdinal: ordinal, destinationPart: partValue }))
    }, [ordinal])

    const [{ isOver, canDrop, isDragging }, drop] = useDrop<{ index: number, part: PagePart }, any, any>(
        () => ({
            accept: "PartPageThumbnail",
            drop: (item) => {
                onPosChange(item.index, item.part);
            },
            collect: monitor => {
                const draggedItem: any = monitor.getItem();
                return ({
                    isOver: !!monitor.isOver(),
                    isDragging: !!draggedItem,
                    canDrop: !!monitor.canDrop() && (draggedItem.part === partValue ? (draggedItem.index !== (ordinal - 1) && draggedItem.index !== (ordinal)) : true) // only prevent at neighboring drop zones if the drag is within the same part's pages
                });
            }
        }), [ordinal, partValue])

    const display = useMemo(() => {
        return isDragging ? "block" : "none";
    }, [isDragging])

    return <>
        <div ref={drop} className={classNames("cannot-currently-drop", {"can-drop": canDrop})} style={{ width: 40, marginRight: -20, marginLeft: -20, display }}>
        </div>
        <div className={classNames({ "has-background-grey-dark": isOver })} style={{ width: 40, marginRight: -20, marginLeft: -20, border: isOver ? "1px dashed #eee" : "none", display }}>
        </div>
    </>
}

export default PartPageThumbnailDropZone