import React, { useState } from "react"
import classNames from "classnames"
import { FaPlus, FaTrash } from "react-icons/fa"
import Swal from "sweetalert2"
import { userHasPermission } from "../utils/user"
import { UserPermission } from "../types/user.types"
import { customerReferenceValidator, validateIsbnFormInput } from "../utils/utils"
import { setActiveAccount, useAuthState } from "../store/authSlice"
import { useDispatch } from "react-redux"
import { useJob } from "../store/jobSlice"
import JobApi from "../lib/job"
import { JobCopyApiReqData } from "../types/jobApi.types"
import { JobCopyFormData } from "../types/job.types"
import { useForm } from "react-hook-form"
import { v4 } from "uuid"

type CopyTitleProps = {
    isOpen: boolean,
    toggleOpen: (value: boolean) => void,
}

const CopyTitle = (props: CopyTitleProps) => {

    // when false, the title will be copied to a new Customer Reference # instead of a new ISBN13/10
    const [copyToNewIsbn, setCopyToNewIsbn] = useState(true)

    // extra helper errors to identify hyphen input early and warn the user
    const [newIsbnErrorOnChange, setNewIsbnErrorOnChange] = useState('')
    const [isbnListEntryErrorOnChange, setIsbnListEntryErrorOnChange] = useState('')

    const { user, activeAccountId } = useAuthState()
    const { job } = useJob()

    const dispatch = useDispatch()

    const { register, formState: { errors, isDirty }, setError, clearErrors, handleSubmit, watch, resetField, setValue } = useForm<Omit<JobCopyFormData, "OtherCommonIsbnsList">>({
        mode: 'onBlur',
        defaultValues: {
            ISBN: '',
            CustomerReference: '',
            Title: '',
            Author: '',
            OtherCommonIsbnEntry: ''
        }
    })

    const [commonIsbnsList, setCommonIsbnsList] = useState<JobCopyFormData["OtherCommonIsbnsList"]>(job && job.AssociatedISBNs ? job.AssociatedISBNs : [])

    const toggleNewIdField = (event: React.ChangeEvent<HTMLInputElement>) => {

        resetField("ISBN")
        resetField("CustomerReference")
        setNewIsbnErrorOnChange('')
        
        const { id } = event.currentTarget

        if (id === 'use-new-isbn') {
            setCopyToNewIsbn(true)
        }
        if (id === 'use-new-cr') {
            setCopyToNewIsbn(false)
        }
    }

    const submitHandler = handleSubmit(async (data) => {

        let valid = true

        clearErrors()
        if (!userHasPermission(UserPermission.Job_Copy, user)) {
            await Swal.fire({          
                icon: "info",
                title: "Insufficient permissions",
                text: "You don't have the required permissions to copy a title."
            })
            return;
        }

        if (copyToNewIsbn) {
            if (!data.ISBN) {
                setError("ISBN", { type: 'custom', message: "ISBN is required."})
                return;
            }

            const newBookIsbnIsValid = await validateIsbnFormInput(data.ISBN, true)
            .then((result) => {
                if (typeof result === 'string') {
                    setError("ISBN", {message: result});
                    return false;
                }
                return result;
            })
            .catch(error => console.log(error))

            if (!newBookIsbnIsValid) {
                valid = false;
            }

            const newBookIsbnInItsOwnAssocList = commonIsbnsList.some(entry => entry === watch("ISBN")) ? true : false;

            if (newBookIsbnInItsOwnAssocList) {
                setError("ISBN", {message: 'Cannot copy to an ISBN that would be listed in its own common ISBNs list.'});
                valid = false;
            }
        }
        else {

            if (!data.CustomerReference.trim()) {
                setValue("CustomerReference", '')
                setError("CustomerReference", { type: 'custom', message: "Customer Reference is required."})
                valid = false;
            }

            if (data.CustomerReference && customerReferenceValidator(data.CustomerReference) !== true) {
                setError("CustomerReference", { message: "Customer Reference can only include letters, numbers, spaces, and hyphens."})
                valid = false;
            }

        }



        

        if (!valid) {
            return;
        }

        const { isConfirmed } = await Swal.fire({
            // icon: "warning",
            title: 'Please Confirm',
            text: "Title is being copied in its current state. Please verify that corrections aren't actively being performed.",
            showCancelButton: true,
            confirmButtonText: "Copy",
            cancelButtonText: "Cancel",
            allowOutsideClick: false,
            reverseButtons: true,
            focusConfirm: true
        })
        if (!isConfirmed) return;
        if (isConfirmed) {

            const jobCopyPostData: JobCopyApiReqData = {
                newid: copyToNewIsbn ? data.ISBN : data.CustomerReference,
                isbnlist: commonIsbnsList ? commonIsbnsList : undefined,
                author: data.Author ? data.Author : undefined,
                title: data.Title ? data.Title : undefined
            }

            if (job) {
                await JobApi.copyJob(job.JobID, jobCopyPostData)
                .then((res) => {
                    props.toggleOpen(false);
                    dispatch(setActiveAccount(activeAccountId))
                    Swal.fire({
                        icon: "success",
                        title: `Title copied successfully.`,
                        timer: 5000,
                        toast: true,
                        position: 'top',
                        showClass: {
                            popup: 'swal-popover-in-delayed'
                        },
                        hideClass: {
                            popup: 'swal-popover-out'
                        },
                        showCloseButton: true,
                        showConfirmButton: false,
                        grow: 'row'
                    })
                })
                .catch((err) => {

                    let errorMessage;

                    if (err.response) {
                        if (err.response.data["Error"] === "Destination Job must not exist") {
                            errorMessage = `The Customer Reference number '${ data.CustomerReference }' already exists on a title in the database. Please choose a different Customer Reference number.`
                        }
                        else if (err.response.data["Error"]) {
                            errorMessage = err.response.data["Error"]
                        }
                    }
                    Swal.fire({
                        icon: "error",
                        title: "Error copying title",
                        text: errorMessage || "An error occurred and the title was unable to be copied."
                    })
                })
            }
        }
    })

    const checkAndWarnOnHyphens = (event: React.ChangeEvent<HTMLInputElement>, fieldName: keyof Omit<JobCopyFormData, "OtherCommonIsbnsList">) => {
        
        const { value } = event.currentTarget

        let error = ''

        if (value && value.indexOf("-") >= 0) {
            error = 'Please do not include hyphens in the ISBN input.'
        }

        if (fieldName === 'ISBN') {
            setNewIsbnErrorOnChange(error)
        } else if (fieldName === 'OtherCommonIsbnEntry') 
        {
            setIsbnListEntryErrorOnChange(error) 
        }

    }

    const addCommonIsbnHandler = async () => {

        const validAdditionToList = await validateAddIsbnToList(watch("OtherCommonIsbnEntry")).then(isbnValidOnList => isbnValidOnList)

        if (!validAdditionToList) {
            return;
        }

        setCommonIsbnsList(oldVal => {
            const updatedList = [...oldVal];
            updatedList.push(watch("OtherCommonIsbnEntry"))
            return updatedList;
        })
    }

    const validateAddIsbnToList = async (value: string): Promise<boolean> => {

        const isbnIsValid = await validateIsbnFormInput(value, false)

        if (!isbnIsValid) {
            setError("OtherCommonIsbnEntry", { message: 'Value must be a valid ISBN-13.'})
            return false;
        }

        if (typeof isbnIsValid === 'string') {
            setError("OtherCommonIsbnEntry", { message: isbnIsValid })
            return false;
        }

        if (commonIsbnsList && commonIsbnsList.length === 10) {
            setError("OtherCommonIsbnEntry", { message: 'Max number of associated ISBNs reached. (10/10)' })
            return false;
        }

        if (watch("ISBN") !== "" && watch("ISBN") === watch("OtherCommonIsbnEntry")) {
            setError("OtherCommonIsbnEntry", { message: 'Cannot list the same ISBN as the book that will be created by this copy operation.' })
            return false;
        }

        const isbnIsAlreadyOnList = commonIsbnsList.some(entry => entry === watch("OtherCommonIsbnEntry")) ? true : false;

        if (isbnIsAlreadyOnList) {
            setError("OtherCommonIsbnEntry", { message: 'The entered value is already on the list.' })
            return false;
        }

        return true;

    }

    const cancelHandler = async () => {

        if (isDirty) {

            const { isConfirmed } = await Swal.fire({
                icon: "warning",
                title: "Unsaved changes",
                text: "Are you sure you want to close the Copy Title dialog with unsaved changes?",
                showCancelButton: true,
                focusCancel: true,
                confirmButtonText: "Yes, close anyways",
                cancelButtonText: "Cancel",
                reverseButtons: true
            })
            if (isConfirmed) {
                props.toggleOpen(false)
            }
        }
        else {
            props.toggleOpen(false)
        }

    }

    const deleteCommonIsbnHandler = (event: React.MouseEvent<HTMLButtonElement>) => {

        if (event && event.currentTarget && event.currentTarget.dataset && event.currentTarget.dataset.rowid) {
            
            clearErrors("OtherCommonIsbnEntry")

            const deletedItemId = event.currentTarget.dataset.rowid;

            const itemIndex = event.currentTarget.dataset.itemindex || undefined;
            if (itemIndex) {
                const indexVal = Number(itemIndex)
                setCommonIsbnsList(oldVal => {
                    const updatedList = oldVal ? [...oldVal] : [];
                    updatedList.splice(indexVal, 1)
                    return updatedList;
                })
            }
        }
    }

    return (
        <div className={classNames('modal has-text-light-cascading-no-important', {"is-active": props.isOpen})}>
            <div onClick={async () => await cancelHandler()} className='modal-background'></div>
            <div className={classNames('modal-content', 'box', 'copy-modal', 'p-5', 'has-background-dark')}>
                <header>
                    <p>Copy selected title {(job && job.Title) ? (<span className="title-to-copy-name">({job.Title.length > 100 ? job.Title.substring(0,100)+'...' : job.Title})</span>) : ''}</p>
                </header>
                <hr/>
                <form onSubmit={submitHandler} className="copy-modal-form columns is-flex is-flex-direction-row is-justify-content-space-around is-align-items-flex-start">
                    <div className='column'>
                        <div className="new-id-box is-flex is-flex-direction-column is-justify-content-space-around">
                            <p className="mb-3 pl-2 copy-title-radio-header">Copy title to a new:</p>
                            <div className={classNames('control is-new-id-radio-option is-flex is-flex-direction-row is-justify-content-space-around is-align-items-center', {"is-selected-newid-radio": copyToNewIsbn})}>
                                <input type='radio' id='use-new-isbn' onChange={toggleNewIdField} name='isbn-or-cr' value='isbn' defaultChecked className={classNames('radio ml-2')} />
                                <label className='is-unselectable' htmlFor='use-new-isbn'>ISBN</label>
                            </div>
                            <div className={classNames('control is-new-id-radio-option is-flex is-flex-direction-row is-justify-content-space-around is-align-items-center', {"is-selected-newid-radio": !copyToNewIsbn})}>
                                <input type='radio' id='use-new-cr' onChange={toggleNewIdField} name='isbn-or-cr' value='cr' className={classNames('radio ml-2')} />
                                <label className='is-unselectable' htmlFor='use-new-cr'>Customer Reference</label>
                            </div>
                            { copyToNewIsbn ? 
                            <>
                                <div className='mt-5 control is-text-control is-flex is-flex-direction-column end-of-column'>
                                    <label className='ml-2 is-text-input-label' htmlFor='new-isbn-entry-field'>ISBN</label>
                                    <input {...register("ISBN", { maxLength: 13})} id='new-isbn-entry-field' onChange={(e) => checkAndWarnOnHyphens(e, "ISBN")} maxLength={13} className={classNames('input', {"is-danger": newIsbnErrorOnChange || errors.ISBN})} type='text' />
                                </div>
                                <div className="error-field-text new-isbn-error-text">
                                    <p className="has-text-danger"> {newIsbnErrorOnChange ? newIsbnErrorOnChange : 
                                        errors.ISBN ? errors.ISBN.message : ''}
                                    </p>
                                </div>
                            </> :
                            <>
                                <div className='mt-5 control is-text-control is-flex is-flex-direction-column end-of-column'>
                                    <label className='ml-2 is-text-input-label' htmlFor='new-cr-entry-field'>Customer Reference</label>
                                    <input {...register("CustomerReference", { maxLength: 100})} maxLength={100} id='new-cr-entry-field' className={classNames('input', {"is-danger": errors.CustomerReference })} type='text' />
                                </div>
                                <div className="error-field-text new-isbn-error-text">
                                    <p className="has-text-danger">
                                        {errors.CustomerReference && errors.CustomerReference.message}
                                    </p>
                                </div>
                            </>
                            }
                        </div>

                        <div className='control is-text-control is-flex is-flex-direction-column'>
                            <label className='ml-2 is-text-input-label' htmlFor='new-book-title'>Title <span className="optionalText">(Optional)</span></label>
                            <input {...register("Title")} className='input' id="new-book-title" type='text' maxLength={350}/>
                        </div>
                        <div className='control is-text-control is-flex is-flex-direction-column'>
                            <label className='ml-2 is-text-input-label' htmlFor='new-book-author'>Author <span className="optionalText">(Optional)</span></label>
                            <input {...register("Author")} className='input' id="new-book-author" type='text' maxLength={350}/>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='control is-text-control is-flex is-flex-direction-column end-of-column'>
                            <label className="is-text-input-label" htmlFor='common-isbn-list-input'>Other common ISBNs <span className="optionalText">(Optional)</span></label>
                            <div className="add-other-isbn-field-wrapper field is-grouped has-addons">
                            <input {...register("OtherCommonIsbnEntry")} placeholder="Add a common ISBN" onChange={(e) => checkAndWarnOnHyphens(e, "OtherCommonIsbnEntry")} className={classNames('input', {"is-danger": isbnListEntryErrorOnChange || errors.OtherCommonIsbnEntry})} 
                                id="common-isbn-list-input" type='text' maxLength={13} />
                            <button onClick={addCommonIsbnHandler} type="button" className={"button is-info"}>
                                <span className="icon is-small"><FaPlus/></span>
                                <span>Add</span>
                            </button>
                            </div>
                        </div>
                        <div className="error-field-text isbn-list-entry-error-text">
                            <p className="has-text-danger"> {isbnListEntryErrorOnChange ? isbnListEntryErrorOnChange : 
                            errors.OtherCommonIsbnEntry ? errors.OtherCommonIsbnEntry.message : ''}
                            </p>
                        </div>
                        <div className={classNames("table-container-and-message-wrapper", {"show-no-isbns-message": (commonIsbnsList.length === 0)})} >
                            {(commonIsbnsList.length === 0) && <span className="no-isbns">{'(No common ISBNs)'}</span>}
                            <div className="table-container">
                                <table className='isbn-table table is-fullwidth is-bordered'>
                                    {/* is-striped is-hoverable */}
                                    <tbody>
                                        {commonIsbnsList.length > 0 && commonIsbnsList.map((isbn, index) => {
                                            const uniqueListItemId = v4();
                                            return (
                                            <tr key={uniqueListItemId} data-rowid={uniqueListItemId} data-itemindex={index} className="tr" >
                                                <td className="td">
                                                    <input value={isbn} maxLength={13} type="text" title={isbn} disabled />
                                                    {/* TODO *** Incorporate react-tooltip tooltips for truncated strings here (...or just keep the html "title" attr) */}
                                                    <div className="other-isbn-context-buttons field is-grouped">
                                                        <button type="button" data-itemindex={index} data-rowid={uniqueListItemId} onClick={deleteCommonIsbnHandler} className="button other-isbn other-isbn-delete">
                                                            <span>
                                                                <FaTrash size={20}/>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="field is-grouped modal-button-options px-6 is-flex is-flex-direction-row is-justify-content-space-around is-align-items-center">
                    <button type="button" onClick={() => props.toggleOpen(false)} className="button has-background-dark has-text-light">Cancel</button>
                    <button type="submit" onClick={submitHandler} className="button is-primary">Copy</button> 
                </div>
            </div>
        </div>
    )

}

export default CopyTitle