/* eslint-disable react/prop-types */
import moment from "moment"
import { useMemo, useState } from "react"
import Foco from "react-foco"
import { FaBell, FaCircle, FaEnvelopeOpen, FaTrash } from "react-icons/fa"
import { stringToRegExp } from "../../utils/utils"
import styles from "./Notification.module.css"

export interface NotificationGroup {
    name: string,
    data: NotificationItem[]
}

export interface NotificationItem {
    id: string,
    notiId?: string,
    title?: string,
    message: string,
    subtitle?: string,
    time?: string,
    read?: boolean,
    meta?: any
}

export type NotificationProp = {
    shine?: boolean,
    data: NotificationItem[]
    active?: boolean,
    setActive?: (isActive: boolean) => void,
    deleteHandler?: (notification: NotificationItem) => void,
    clickHandler?: (notification: NotificationItem) => void,
    clearAllHandler?: (allNotifications: NotificationItem[]) => void,
    readAllHandler?: (allNotifications: NotificationItem[]) => void
}

export const Notification: React.FC<NotificationProp> = (props) => {
    const [query, setQuery] = useState("");

    const matchedNoti = useMemo(() => {
        if (!query.trim()) return props.data;
        return props.data.filter(d => {
            const q = stringToRegExp(query);
            return `${d.message}`.search(q) > -1 ||
                `${d.title}`.search(q) > -1 ||
                `${d.subtitle}`.search(q) > -1
        })
    }, [query, props.data])

    return <Foco className="px-1 mt-1" style={{ position: "relative", display: "inline-block", alignSelf: "center", userSelect: "none" }} onClickOutside={() => props.setActive && props.setActive(false)} >
        {props.active ?
            <div className="card has-background-grey" style={{ position: "absolute", minWidth: 360, top: 0, left: "130%", maxHeight: 600, overflowY: "auto", border: "1px solid #969ea4" }}>
                <div className="is-flex is-justify-content-space-between p-2">
                    <input value={query} type="search" placeholder="Search" onChange={(e) => setQuery(e.target.value)} />
                    <FaEnvelopeOpen className="is-align-self-center is-clickable" color="#fff" onClick={() => props.readAllHandler && props.readAllHandler(matchedNoti)} title="Mark all as read" />
                </div>
                <div className="p-2 is-clickable bb-1-light" onClick={() => props.clearAllHandler && props.clearAllHandler(matchedNoti)}>
                    <FaTrash color="#fff" /> <span className="has-text-white is-underlined">Delete all notifications</span>
                </div>
                <div className="noti_container">
                    {
                        matchedNoti.map(noti => {
                            return <div key={noti.id} className={styles.noti} onClick={() => props.clickHandler && props.clickHandler(noti)}>
                                <div className={styles.noti_body}>
                                    <div style={{ fontSize: 11 }}>{noti.title}</div>
                                    <div className="has-text-weight-bold" style={{ fontSize: 13 }}>{noti.message}</div>
                                    <div className="noti_sub_content" style={{ fontSize: 12 }}>{noti.subtitle}</div>
                                </div>
                                <div className="noti_options has-text-right">
                                    <div>
                                        <FaCircle color={noti.read ? "#ffffff00" : "#fff"} />
                                    </div>
                                    <div>
                                        <FaTrash className={styles.delete_noti} onClick={(e) => { props.deleteHandler && props.deleteHandler(noti); e.stopPropagation() }} />
                                    </div>
                                    <div className="has-text-light" style={{ fontSize: 10, lineBreak: "strict" }}>{noti.time ? moment(new Date(noti.time)).utc(true).fromNow(true) : null}</div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            : null
        }
    </Foco>
}
