import parseBox from '../_utils/parseBox';
import { Page } from '../_types/LSCScout.type';

export default function getPageBoxes(page: Page) {
	const { MediaBox, TrimBox, CustomBox } = page;
	const media = parseBox(MediaBox);

	const trim = TrimBox ? parseBox(TrimBox) : null;

	const custom = CustomBox ? parseBox(CustomBox) : null;

	const current =
		page.SelectedBox === 'Custom' && !!CustomBox ? custom! : trim || media;

	return { current, media, trim, custom };
}
