import { Job, NewJob, JobIdData } from "../types/job.types"
import { JobIsbnOrCrPostBody } from "../types/jobApi.types"
import { PagePart } from "../types/pages.types"
import { IsbnTypes } from "../types/types"
import { JobCopyApiReqData, CommonTitlesPatchData } from "../types/jobApi.types"
import customFetch from "./axiosInstance"
import { tryInferIsbnOfType } from "../utils/utils"
import { PreflytProcessOnJobPostData } from "../types/jobApi.types"


const searchJobs = (searchFilter: Record<string, any>) => {
    return Promise.all([
        customFetch.post("/job/jobs", searchFilter),
        customFetch.post("/job/jobsinfo", searchFilter)
    ])
}

const isbnExists = async (isbn: string, isbnType: IsbnTypes) => {
    const filterQuery = { "conditions": { "$query": { [isbnType]: { "$eq": isbn } } } };
    const { data } = await customFetch.post("/job/jobsinfo", filterQuery);
    if (data.NumberOfJobs && data.NumberOfJobs > 0) return true;
    return false;
}

const getJob = (jobId: string, withToken?: boolean, keys?: (keyof Job)[], options?: { signal: AbortSignal }) => {
    return customFetch.get<Job>(`/job/job/${jobId}`,
        {
            params: {
                token: withToken || false,
                keys: keys?.join(",")
            },
            signal: options?.signal
        })
}

const requestJob = (isbn: string) => {
    return customFetch.post(`/job/autodeliver?isbn=${isbn}`)
}

const lookupIsbn = (isbn: string) => {
    return customFetch.get(`/job/isbn/${isbn}`)
}

const createJob = (jobData: NewJob) => {
    return customFetch.post(`/job/job`, jobData)
}

const editJob = (jobId: string, jobData: NewJob) => {
    return customFetch.patch(`/job/job/${jobId}`, jobData)
}

/** PATCH method that allows for specifically PATCHing only a job's AssociatedISBNs field.  
 * Both `editJob()` and this method use the same route.  
 * The whole job PATCH process will likely be changed when `@lsp-types` is adopted for this project.
 * */
const updateJobCommonTitles = (jobId: string, updatedCommonTitlesData: CommonTitlesPatchData) => {
    return customFetch.patch(`/job/job/${jobId}`, updatedCommonTitlesData);
}

const deleteJob = (jobId: string) => {
    return customFetch.delete(`/job/job/${jobId}`)
}

const inviteToJob = (jobId: string, email: string, message: string) => {
    return customFetch.post(`/job/job/${jobId}/invite`, { email, message })
}

const copyJob = (jobId: string, jobCopyReqBodyData: JobCopyApiReqData) => {
    return customFetch.post(`/job/job/${jobId}/copy`, jobCopyReqBodyData)
}

const downloadOriginal = (jobId: string, part?: PagePart | string) => {
    const partQuery = `?part=${part}`;
    return customFetch.get(`/job/job/${jobId}/download${part ? partQuery : ""}`, { responseType: "blob" })
        .then((res) => {
            const blob = new Blob([res.data], { type: 'application/octet-stream' });
            const blobUrl = URL.createObjectURL(blob);
            return blobUrl;
        })
}

const downloadTep = (jobId: string) => {
    return customFetch.get(`/job/job/${jobId}/download`, { responseType: "blob" })
        .then((res) => {
            const blob = new Blob([res.data], { type: 'application/octet-stream' });
            const blobUrl = URL.createObjectURL(blob);
            return blobUrl;
        })
}

const downloadPreflight = (jobId: string, part?: PagePart | string) => {
    const partQuery = `?part=${part}`;
    return customFetch.get(`job/job/${jobId}/preflytreport${part ? partQuery : ""}`)
}

const runPreflytProcessOnPartOfJob = (jobId: Job["JobID"], preflytJobPostData: PreflytProcessOnJobPostData) => {
    return customFetch.post(`job/job/${jobId}/preflyt`, preflytJobPostData);
}

const signOff = (jobId: string, data: any) => {
    return customFetch.patch(`/job/job/${jobId}`, { Workflow: data })
}

const addToOrRemoveFromMyJobs = (jobId: string, userCurrentlyHasJob: boolean) => {
    return customFetch.post(`job/job/${jobId}/myjob`, { remove: userCurrentlyHasJob });
}

/**
 * Method that accepts an identifying value and attempts to find job(s) that have the matching value.  
 * The value is inferred to be either an isbn, or a CR#.  
 * A query is then made for job(s) that have a matching value of the inferred 'type'.
 */
const getJobIdDataByIsbnOrCr = async (isbnOrCr: string): Promise<JobIdData> => {

    const inferredIsbnType: IsbnTypes | undefined = tryInferIsbnOfType(isbnOrCr)
    const postBody: JobIsbnOrCrPostBody = {
        conditions: {
            $query: {
                [inferredIsbnType || 'CustomerReference']: {
                    $eq: isbnOrCr
                }
            }
        }
    }
    return await customFetch.post(`/job/jobs`, postBody)
        .then(res => {
            if (res.data) {
                for (const job of res.data) {
                    if (job.Type && job.Type !== 'Book') {
                        continue;
                    }
                    if (job.JobID && typeof job.JobID === 'string') {
                        // return the job's JobID and its other existing 'unique IDs' - any and all of ISBN13, ISBN10, and CR# - as an object 
                        // The JobID is used to link the job, and the 'unique ID's are used to try and prevent adding the same job multiple times to the same common titles list
                        const { JobID, ISBN13, ISBN10, CustomerReference } = job;
                        return {
                            JobID: JobID,
                            ISBN13: ISBN13,
                            ISBN10: ISBN10,
                            CustomerReference: CustomerReference
                        }
                    }
                }
            }
            return {};
        })
        .catch(err => {
            console.log(err)
            return {};
        })
}

const patch = (jobId: string, patchData: any) => {
    return customFetch.patch(`/job/job/${jobId}`, patchData);
}

const JobApi = {
    searchJobs,
    getJobIdDataByIsbnOrCr,
    getJob,
    requestJob,
    createJob,
    lookupIsbn,
    isbnExists,
    editJob,
    updateJobCommonTitles,
    deleteJob,
    inviteToJob,
    copyJob,
    downloadOriginal,
    downloadPreflight,
    downloadTep,
    runPreflytProcessOnPartOfJob,
    signOff,
    addToOrRemoveFromMyJobs,
    patch
}

export default JobApi
