import { PayloadAction } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import { put, select } from "redux-saga/effects";
import Swal from "sweetalert2";
import PagesApi from "../lib/pages";
import { RootState } from "../store";
import { setJobData, setPartPages } from "../store/jobSlice";
import { setSelectedPages, stopLoadingPages } from "../store/pagesSlice";
import { Job } from "../types/job.types";
import { PagePart, StageStatus } from "../types/pages.types";
import { JobState, PagesState } from "../types/store.types";
import { JobStatus } from "../types/types";

const generateSelectedGroups = (selectedOrdinals: number[]) => {
    const ordinals = cloneDeep(selectedOrdinals).sort((a, b) => a - b);
    if (ordinals.length < 1) return [];
    let start = 0;
    const selectedGroups = [];
    for (let i = 0; i < ordinals.length; i++) {
        const next = i + 1;
        if (next >= ordinals.length || ordinals[next] - 1 !== ordinals[i]) {
            selectedGroups.push({
                ordinal: ordinals[start],
                count: next - start
            })
            start = next;
        }
    }
    return selectedGroups.reverse();
}

const displayAlert = () => {
    Swal.fire({
        title: "Please wait",
        text: "Performing requested operation...",
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading()
        }
    });
}

const isActionDisabled = (job?: Job, part?: PagePart | string) => {
    if (!job || !part || !job.Stage || !job.Stage[part]) return false;
    if (
        job?.Stage[part].Stage === JobStatus.Locked ||
        job.Stage[part].Status === StageStatus.COMPLETE || 
        job.Stage[part].Status === StageStatus.IN_PRODUCTION
    ) {
        Swal.fire({
            title: "Can't perform action",
            icon: "warning",
            text: `Action is disabled while part is ${job.Stage[part].Status}`,
            allowOutsideClick: true
        });
        return true;
    }
    return false;
}

export function* deleteSelectedPagesSaga({ payload }: PayloadAction<PagePart | string>) {
    if (!payload) return;
    const part = payload;
    const isShelf = part === PagePart.UnassignedCorrections;

    const pagesData: PagesState = yield select((state: RootState) => state.pages)
    const jobData: JobState = yield select((state: RootState) => state.job)
    if (isActionDisabled(jobData.job, part)) {
        yield put(stopLoadingPages());
        return;
    }

    const selectedPages = isShelf ? pagesData.selectedShelfPages : pagesData.selectedPages;
    const selectionGroups = generateSelectedGroups(selectedPages).map(group => ({ ...group, type: "delete", part }))
    if (!selectionGroups.length) return;
    displayAlert()
    // @ts-ignore
    const response = yield PagesApi.deletePage(jobData.jobId, selectionGroups)
    .catch((err?: any) => {
        console.error(err || 'An error occurred.')
    });
    if (response && response.status === 200) {
        yield put(setJobData(response.data))
        const pages = jobData.pages[part]
            .filter(page => !selectedPages.includes(page.Ordinal))
            .map((page, i) => ({ ...page, Ordinal: i + 1 }))
        yield put(setPartPages({ part, pages }));
    }
    yield put(stopLoadingPages())
    yield put(setSelectedPages({ ordinals: [] }))
    Swal.close();
}

export function* updateSelectedPagesApprovalSaga({ payload }: PayloadAction<{ part: PagePart | string, value: string }>) {
    if (!payload) return;
    const part = payload.part;
    const isShelf = part === PagePart.UnassignedCorrections;
    if (isShelf) return;
    const pagesData: PagesState = yield select((state: RootState) => state.pages)
    const jobData: JobState = yield select((state: RootState) => state.job)

    if (isActionDisabled(jobData.job, part)) return;

    const selectionGroups = generateSelectedGroups(pagesData.selectedPages).map(group => ({ ...group, type: "setvalue", value: payload.value, tag: "Tags.ReviewStatus", part }));
    if (!selectionGroups.length) return;
    displayAlert()

    //@ts-ignore
    const response = yield PagesApi.patch(jobData.jobId, selectionGroups)
    .catch((err?: any) => {
        console.error(err || 'An error occurred.')
    });
    // update pages with new state
    if (response && response.status === 200) {
        const updatedPages = jobData.pages[part].map(page => {
            if (!pagesData.selectedPages.includes(page.Ordinal)) return page;
            return { ...page, Tags: { ...page.Tags, ReviewStatus: payload.value } };
        })
        yield put(setPartPages({ part, pages: updatedPages }))
    }
    Swal.close();
}

export function* duplicateSelectedPagesSaga({ payload }: PayloadAction<PagePart | string>) {
    if (!payload) return;
    const part = payload;
    const isShelf = part === PagePart.UnassignedCorrections;
    const pagesData: PagesState = yield select((state: RootState) => state.pages)
    const selectedPages = isShelf ? pagesData.selectedShelfPages : pagesData.selectedPages;
    const jobData: JobState = yield select((state: RootState) => state.job)

    if (isActionDisabled(jobData.job, part)) return;

    const selectionGroups = generateSelectedGroups(selectedPages).map(group => ({
        ...group,
        type: "copy",
        part,
        destination: group.ordinal + group.count,
        destinationpart: part
    }));
    if (!selectionGroups.length) return;

    displayAlert()

    //@ts-ignore
    const response = yield PagesApi.patch(jobData.jobId, selectionGroups)
    .catch((err?: any) => {
        console.error(err || 'An error occurred.')
    });
    // update pages with new state
    if (response && response.status === 200) {
        // simple get par pages here
        //@ts-ignore
        const newPages = yield PagesApi.getPartPages(jobData.jobId, part);
        if (newPages.data && newPages.data.length) yield put(setPartPages({ part, pages: newPages.data }));
    }
    yield put(setSelectedPages({ ordinals: [] }))
    Swal.close();
}

export function* moveToPartSaga({ payload }: PayloadAction<{ from: PagePart | string, to: PagePart | string }>) {        
    const pagesData: PagesState = yield select((state: RootState) => state.pages)
    const jobData: JobState = yield select((state: RootState) => state.job)

    if (isActionDisabled(jobData.job, payload.from)) return;

    const isShelf = payload.from === PagePart.UnassignedCorrections;
    const selectedPages = isShelf ? pagesData.selectedShelfPages : pagesData.selectedPages;
    const destination = ((jobData.job?.PartPages && jobData.job.PartPages[payload.to]) ? jobData.job.PartPages[payload.to] : 0) + 1;
    const selectionGroups = generateSelectedGroups(selectedPages).map(group => ({
        ...group,
        part: payload.from,
        destination,
        destinationpart: payload.to,
        type: "move"
    }));
    if (!selectionGroups.length) return;

    displayAlert()

    //@ts-ignore
    const response = yield PagesApi.patch(jobData.jobId, selectionGroups)
    .catch((err?: any) => {
        console.error(err || 'An error occurred.')
        if (err.response) {
            return err.response;
        }        
    });    
    if (response && response.status === 200) {
        const remainingPages = cloneDeep(jobData.pages[payload.from]).filter(page => !selectedPages.includes(page.Ordinal)).map((page, i) => ({ ...page, Ordinal: i + 1 }))
        yield put(setPartPages({ part: payload.from, pages: remainingPages }))
        yield put(setJobData(response.data))

        //@ts-ignore
        const pagesResponse = yield PagesApi.getPartPages(jobData.jobId, payload.to)
        yield put(setPartPages({ part: payload.to, pages: pagesResponse.data }));
        Swal.close();
    }
    else {
        // if the PATCH response errors:
        Swal.fire({
            icon: "error",
            title: `Error moving page${selectedPages.length > 1 ? 's' : ''} to component`,
            text: response.data?.["Error"] || `Something went wrong while trying to move the selected page${selectedPages.length > 1 ? 's' : ''} to the component.`,
        })
    }
    yield put(setSelectedPages({ ordinals: [] }))
}

export function* changePagePositionSaga({ payload }: PayloadAction<{ part: PagePart | string, fromOrdinal: number, destinationOrdinal: number, destinationPart?: PagePart }>) {
    const { destinationOrdinal, part, fromOrdinal, destinationPart } = payload;
    const jobData: JobState = yield select((state: RootState) => state.job)

    if (isActionDisabled(jobData.job, part)) return;

    // reject page pos. change if state job is not active
    if (jobData.job?.Status !== 'active') {
        return;
    }
    const pagesData: PagesState = yield select((state: RootState) => state.pages)
    const isShelf = payload.part === PagePart.UnassignedCorrections;
    const selectedpagesData = isShelf ? pagesData.selectedShelfPages : pagesData.selectedPages;
    const selectedPages = (selectedpagesData.length && selectedpagesData.includes(fromOrdinal)) ? selectedpagesData : [fromOrdinal];
    const isNotSameLocation = (destinationPart && destinationPart !== part);
    const selectionGroups = generateSelectedGroups(selectedPages)
        .filter(group => (destinationOrdinal < group.ordinal || destinationOrdinal > group.ordinal + group.count) || isNotSameLocation)
        .map(group => ({
            ...group,
            part,
            destination: (!isNotSameLocation && (destinationOrdinal > group.ordinal)) ? destinationOrdinal - group.count : destinationOrdinal,
            destinationpart: destinationPart || part,
            type: "move"
        }))
    if (!selectionGroups.length) return;

    const { isConfirmed } = yield Swal.fire({
        icon: "warning",
        title: "Please Confirm",
        text: "Are you sure you want to move this page?",
        confirmButtonText: "Yes, move it!",
        focusConfirm: true,
        showCancelButton: true,
        cancelButtonText: "No",
        reverseButtons: true
    })
    if (!isConfirmed) return;

    displayAlert()

    //@ts-ignore
    const response = yield PagesApi.patch(jobData.jobId, selectionGroups)
    .catch((err?: any) => {
        console.error(err || 'An error occurred.')
    });
    if (response && response.status === 200) {
        yield put(setJobData(response.data))

        // can do client side prediction here

        //@ts-ignore
        const pagesResponse = yield Promise.all([
            PagesApi.getPartPages(jobData.jobId, part),
            isNotSameLocation ? PagesApi.getPartPages(jobData.jobId, destinationPart) : null,
        ])
        yield put(setPartPages({ part, pages: pagesResponse[0].data }));
        if (isNotSameLocation) yield put(setPartPages({ part: destinationPart, pages: pagesResponse[1].data }));
    }
    yield put(setSelectedPages({ ordinals: [] }))
    Swal.close();
}

export function* replacePageSaga({ payload }: PayloadAction<{ fromOrdinal: number, fromPart: PagePart, destinationOrdinal: number, destinationPart: PagePart }>) {
    const { destinationOrdinal, fromOrdinal, destinationPart, fromPart } = payload;
    const jobData: JobState = yield select((state: RootState) => state.job)
    const pagesData: PagesState = yield select((state: RootState) => state.pages)

    if (isActionDisabled(jobData.job, destinationPart)) return;

    const selectedPages = (pagesData.selectedShelfPages.length && pagesData.selectedShelfPages.includes(fromOrdinal)) ? pagesData.selectedShelfPages : [fromOrdinal];
    const isSamePart = fromPart === destinationPart;
    let selectionGroups: any = generateSelectedGroups(selectedPages)
        .map(group => ({
            ...group,
            part: fromPart,
            ordinal: (isSamePart && group.ordinal > destinationOrdinal) ? group.ordinal - 1 : group.ordinal,
            destination: (isSamePart && group.ordinal < destinationOrdinal) ? destinationOrdinal - 1 : destinationOrdinal,
            destinationpart: destinationPart,
            type: "move"
        }))
    if (!selectionGroups.length) return;

    selectionGroups = [{
        count: 1,
        ordinal: destinationOrdinal,
        part: destinationPart,
        type: "delete",
    }, ...selectionGroups]


    const { isConfirmed } = yield Swal.fire({
        icon: "warning",
        title: "Please Confirm",
        // TODO - do we want to say "page 3", etc., like the Angular FE does? 
        // Determine if ordinal or folio is used/belongs in the message.
        // It appears to be using Folio in the Angular FE.
        // And determine if this message should support plurals, if we do change it.
        text: "Are you sure you want to replace this page?",
        confirmButtonText: "Yes, replace it",
        showCancelButton: true,
        cancelButtonText: "No",
        reverseButtons: true
    })
    if (!isConfirmed) return;

    displayAlert()

    //@ts-ignore
    const response = yield PagesApi.patch(jobData.jobId, selectionGroups)
    .catch((err?: any) => {
        console.error(err || 'An error occurred.')
    });
    if (response && response.status === 200) {
        yield put(setJobData(response.data))

        // can do client side prediction here

        //@ts-ignore
        const pagesResponse = yield Promise.all([
            PagesApi.getPartPages(jobData.jobId, destinationPart),
            PagesApi.getPartPages(jobData.jobId, fromPart),
        ])
        yield put(setPartPages({ part: destinationPart, pages: pagesResponse[0].data }));
        yield put(setPartPages({ part: fromPart, pages: pagesResponse[1].data }));
    }
    yield put(setSelectedPages({ ordinals: [] }))
    Swal.close();
}
