import classNames from "classnames"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { FaArrowLeft } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import Swal from "sweetalert2"
import JobApi from "../../lib/job"
import { visitHome, visitJob } from "../../routes/visit"
import { setActiveAccount, useAuthState } from "../../store/authSlice"
import { setJobData, setJobId, useJob } from "../../store/jobSlice"
import { setQuery, setSortBy, setStatus, setTitleType } from "../../store/searchSlice"
import { JobTypes, NewJob } from "../../types/job.types"
import { IsbnTypes, SearchSortBy, SearchStatus, SearchTitleTypes } from "../../types/types"
import { AccountTypes, UserPermission } from "../../types/user.types"
import { userHasPermission } from "../../utils/user"
import { customerReferenceValidator, getReadableDate, isbnValidator } from "../../utils/utils"

type TitleviewProps = {
    isNewTitle?: boolean
}

function Titleview({ isNewTitle = true }: TitleviewProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { jobId } = useParams();

    const { user, activeAccountId } = useAuthState()
    const { job, jobId: activeJobId } = useJob()

    const { register, handleSubmit, formState: { errors, isDirty, isSubmitting }, setError, clearErrors, getValues, setValue, reset } = useForm<NewJob>({ mode: "onBlur" })

    const [displayData, setDisplayData] = useState({
        pageCount: "",
        jobNumber: "",
        account: "",
        owner: "",
        created: ""
    })

    useEffect(() => {
        if (!jobId || isNewTitle) return;
        const controller = new AbortController();
        JobApi.getJob(jobId, undefined, undefined, { signal: controller.signal })
            .then(res => {
                reset({
                    Author: res.data.Author,
                    Title: res.data.Title,
                    ISBN10: res.data.ISBN10,
                    ISBN13: res.data.ISBN13,
                    CustomerReference: res.data.CustomerReference,
                    Publisher: res.data.Publisher
                })
                setDisplayData({
                    account: res.data.Account.Name,
                    owner: res.data.Owner.Name,
                    created: getReadableDate(res.data.Created),
                    jobNumber: res.data.Tags?.SystemJobNumber || "",
                    pageCount: res.data.Tags?.Preflyt2?.text?.PageCount || "" //res.data.IntendedPageCount || ""
                    // TODO - update the text PageCount retrieval location if it changes in the API
                })
            })
        return () => controller.abort()
    }, [jobId, isNewTitle, reset])

    const submitHandler = handleSubmit((data) => {

        if (!data.ISBN10 && !data.ISBN13 && !data.CustomerReference) {
            const message = "One of: ISBN10, ISBN13, or Customer Reference is required";
            setError("ISBN10", { message })
            setError("ISBN13", { message })
            setError("CustomerReference", { message })
            return;
        }
        // TODO refactor permission checking with info display
        if (isNewTitle && !userHasPermission(UserPermission.Job_Create, user)) {
            Swal.fire({
                icon: "info",
                title: "Can't create title",
                text: "You don't have the required permission to create a title"
            })
            return;
        } else if (!isNewTitle && !userHasPermission(UserPermission.Job_Edit, user)) {
            Swal.fire({
                icon: "info",
                title: "Can't edit title",
                text: "You don't have the required permission to edit a title"
            })
            return;
        } else if (!isNewTitle && job?.Type === JobTypes.TEP) {
            Swal.fire({
                icon: "info",
                title: "Can't edit TEP title",
                text: "Cannot edit a TEP title."
            })
            return;
        }

        if (isNewTitle) return JobApi.createJob(data)
            .then(async res => {
                dispatch(setJobId(res.data.JobID))
                dispatch(setActiveAccount(activeAccountId)) // causes refetch of account's jobs, allowing new job to show in titles list
                // fire the four below dispatches to set the titles list to show the selected, newly created job
                dispatch(setQuery(''))
                dispatch(setSortBy(SearchSortBy.MODIFIED))
                dispatch(setStatus(SearchStatus.ACTIVE))
                dispatch(setTitleType(SearchTitleTypes.MY_TITLES))
                navigate(visitJob({ jobId: res.data.JobID }))
                await Swal.fire({
                    icon: "success",
                    title: "Successfully created title.",
                    timer: 5000,
                    toast: true,
                    position: 'top',
                    showClass: {
                        popup: 'swal-popover-in-delayed'
                    },
                    hideClass: {
                        popup: 'swal-popover-out'
                    },
                    showCloseButton: true,
                    showConfirmButton: false,
                    grow: 'row'
                })
            })
            .catch(err => {
                const errMessage = err.response?.data?.Error;
                Swal.fire({
                    icon: "error",
                    title: "Error creating title",
                    text: errMessage ? errMessage : null,
                    // confirmButtonColor: "#008b55"
                })
            })
        else if (jobId) return JobApi.editJob(jobId, data)
            .then(async res => {
                dispatch(setJobId(res.data.JobID))
                dispatch(setJobData(res.data))
                navigate(visitJob({ jobId: res.data.JobID }))
                await Swal.fire({
                    icon: "success",
                    title: "Successfully edited title.",
                    timer: 5000,
                    toast: true,
                    position: 'top',
                    showClass: {
                        popup: 'swal-popover-in-delayed'
                    },
                    hideClass: {
                        popup: 'swal-popover-out'
                    },
                    showCloseButton: true,
                    showConfirmButton: false,
                    grow: 'row'
                })
            })
            .catch(err => {
                const errMessage = err.response?.data?.Error;
                Swal.fire({
                    icon: "error",
                    // // TODO same concern as earlier todo
                    title: "Error editing title",
                    text: errMessage ? errMessage : null,
                    // confirmButtonColor: "#008b55"
                })
            })
    })

    const cancelHandler = async () => {
        if (isDirty) {
            const { isConfirmed } = await Swal.fire({
                icon: "warning",
                title: "You have unsaved changes",
                text: "Are you sure you want to discard your changes?",
                showCancelButton: true,
                confirmButtonText: "OK",
                reverseButtons: true
            })
            if (isConfirmed) {
                if (activeJobId) {
                    navigate(visitJob({ jobId: activeJobId }))
                }
                else {
                    navigate(visitHome())
                }
            }
            return;
        }
        if (activeJobId) {
            navigate(visitJob({ jobId: activeJobId }))
        }
        else {
            navigate(visitHome())
        }    
    }

    const clearRequiredErrors = () => clearErrors(["ISBN10", "ISBN13", "CustomerReference"])

    const isbnLookupHandler = (isbnType: IsbnTypes) => {
        return () => {
            const isbnValue = getValues(isbnType);
            Swal.fire({
                title: "Retrieving ISBN data...",
                didOpen: () => {
                    Swal.showLoading();
                }
            })
            JobApi.lookupIsbn(isbnValue)
                .then(res => {
                    Swal.close()
                    const { book } = res.data;
                    setValue("ISBN10", book.isbn);
                    setValue("ISBN13", book.isbn13);
                    setValue("Publisher", book.publisher);
                    setValue("Title", book.title);
                    const authors = Array.isArray(book.authors) ? book.authors.join(", ") : book.authors;
                    setValue("Author", authors);
                })
                .catch(err => {
                    Swal.close()
                    // TODO - do we want to be more descriptive?
                    // i.e. 
                    // -- "ISBN not found" vs. another kind of *unexpected* runtime error occurring and being caught here? 
                    // It's kind of expected for someone to sometimes search an invalid ISBN value, I feel like.
                    // We might have intended to be treating them all the same. I just wanted to check.
                    // -- Could even maybe disable lookup button if ISBN won't validate on front end.
                    Swal.fire({
                        icon: "error",
                        text: "There was an error looking up that ISBN"
                    })
                })
        }
    }

    return <section className="section mb-6 has-text-light-cascading-no-important">
        <div className="box has-background-grey">
            <div className="mb-3">
                <button onClick={cancelHandler} type="button" className="button is-link">
                    <span className="icon">
                        <FaArrowLeft />
                    </span>
                    <span>Back</span>
                </button>
            </div>
            <form onSubmit={submitHandler}>
                <table className="table mb-0 no-border is-fullwidth has-background-grey">
                    <thead>
                        <tr>
                            <td colSpan={2} className="is-size-6 has-background-dark has-text-light">Title Information</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width={200} className="has-text-right is-vcentered">Title</td>
                            <td>
                                <input {...register("Title", { required: "A title is required" })} type="text" className="input is-primary" />
                                <p className="help is-danger">{errors.Title?.message}</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="has-text-right is-vcentered">ISBN-10</td>
                            <td>
                                <div className="field has-addons">
                                    <p className="control is-flex-grow-1">
                                        <input {...register("ISBN10", { validate: (v) => !v || isbnValidator(v, IsbnTypes.ISBN10, isNewTitle), onChange: () => clearRequiredErrors() })} type="text" className="input is-primary" />
                                    </p>
                                    <p className="control">
                                        <button onClick={isbnLookupHandler(IsbnTypes.ISBN10)} type="button" className="button">
                                            Lookup ISBN-10
                                        </button>
                                    </p>
                                </div>
                                <p className="help is-danger">{errors.ISBN10?.message}</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="has-text-right is-vcentered">ISBN-13</td>
                            <td>
                                <div className="field has-addons">
                                    <p className="control is-flex-grow-1">
                                        <input {...register("ISBN13", { validate: (v) => !v || isbnValidator(v, IsbnTypes.ISBN13, isNewTitle), onChange: () => clearRequiredErrors() })} type="text" className="input is-primary" />
                                    </p>
                                    <p className="control">
                                        <button onClick={isbnLookupHandler(IsbnTypes.ISBN13)} type="button" className="button">
                                            Lookup ISBN-13
                                        </button>
                                    </p>
                                </div>
                                <p className="help is-danger">{errors.ISBN13?.message}</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="has-text-right is-vcentered">Customer Reference</td>
                            <td>
                                <input {...register("CustomerReference", { validate: (v) => !v || customerReferenceValidator(v), onChange: () => clearRequiredErrors() })} type="text" className="input is-primary" />
                                <p className="help is-danger">{errors.CustomerReference?.message}</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="has-text-right is-vcentered">Author(s)</td>
                            <td><input {...register("Author")} type="text" className="input is-primary" /></td>
                        </tr>
                        <tr>
                            <td className="has-text-right is-vcentered">Publisher(s)</td>
                            <td><input {...register("Publisher")} type="text" className="input is-primary" /></td>
                        </tr>
                    </tbody>
                </table>

                {
                    !isNewTitle &&
                    <>
                        <table className="table mb-0 no-border is-fullwidth has-background-grey">
                            <thead>
                                <tr>
                                    <td colSpan={2} className="is-size-6 has-background-dark has-text-light">Title Specifications</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width={200} className="has-text-right is-vcentered">Text page count</td>
                                    <td>{displayData.pageCount}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table mb-0 no-border is-fullwidth has-background-grey">
                            <thead>
                                <tr>
                                    <td colSpan={2} className="is-size-6 has-background-dark has-text-light">Lakeside Scout Information</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width={200} className="has-text-right is-vcentered">Lakeside Book job number</td>
                                    <td>{displayData.jobNumber}</td>
                                </tr>
                                <tr>
                                    <td className="has-text-right is-vcentered">Account</td>
                                    <td>{displayData.account}</td>
                                </tr>
                                <tr>
                                    <td className="has-text-right is-vcentered">Owner</td>
                                    <td>{displayData.owner}</td>
                                </tr>
                                <tr>
                                    <td className="has-text-right is-vcentered">Created</td>
                                    <td>{displayData.created}</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                }
                <div className="buttons is-centered mt-3">
                    <button type="submit" className={classNames("button is-primary", { "is-loading": isSubmitting })} disabled={isSubmitting || user?.AccountType === AccountTypes.TEP}>Save</button>
                    <button onClick={cancelHandler} type="button" className="button is-dark">Cancel</button>
                </div>
            </form>
        </div>
    </section>
}

export default Titleview
