import PageViewContext from '../_components/PageViewContext';
import { useContext, useMemo } from 'react';

import usePageGroupDimensions from './usePageGroupDimensions';

export default function useScaleAmt() {
	const { zoomAreaWidth, zoomAreaHeight, canvasScale } = useContext(
		PageViewContext
	);

	const { width, height } = usePageGroupDimensions();

	const scaleAmt = useMemo(() => {
		const widthRatio = zoomAreaWidth / (width * canvasScale);
		const heightRatio = zoomAreaHeight / (height * canvasScale);

		return Math.min(widthRatio, heightRatio) * 0.9;
	}, [canvasScale, height, width, zoomAreaHeight, zoomAreaWidth]);

	return scaleAmt;
}
