import { Box } from '../_types/LSCScout.type';

export default function drawAnnotationsOnCanvas(
	ctx: CanvasRenderingContext2D,
	box: Box,
	scale = 1,
	offset: { x: number; y: number } = { x: 0, y: 0 },
	color = 'red',
	verticalFlip = false
) {
	ctx.save();

	if (verticalFlip) ctx.transform(1, 0, 0, -1, 0, ctx.canvas.height);

	const rect: [number, number, number, number] = [
		(offset.x + box.x) * scale,
		(offset.y + box.y) * scale,
		box.width * scale,
		box.height * scale
	];

	ctx.globalAlpha = 0.7;
	ctx.strokeStyle = ctx.fillStyle = color;
	ctx.lineWidth = 1 * scale;

	ctx.strokeRect(...rect);

	ctx.globalAlpha = 0.1;

	ctx.fillRect(...rect);

	// reset all context properties
	ctx.restore();
}
