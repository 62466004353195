import { useCallback } from 'react';
import customFetch from '../../../lib/axiosInstance';
import { Page, PageUpdate } from '../_types/LSCScout.type';

import useResource, { ResourceDefinition } from './useResource';

export interface PageParams {
	API_HOST: string;
	jobId: string;
	pageId: string;
}

const pageDef: ResourceDefinition<Page, PageParams> = {
	name: 'page',
	get: params =>
		customFetch
			.get(
				`/page/page/${params.jobId}/${params.pageId}`
			)
			.then(res => res.data) as Promise<Page>,
	update: (patch, params) =>
		customFetch
			.patch(
				`/page/page/${params.jobId}/${params.pageId}`,
				patch
			)
			.then(res => res.data) as Promise<Page>,
	isProcessing: p => p.Status === 'processing'
};

export default function usePage(
	params: Omit<PageParams, 'API_HOST'>,
	seed: Page
) {
	const API_HOST = process.env.REACT_APP_API_HOST as string;

	const batchOp = useCallback(
		async (op: PageUpdate) => {
			await customFetch.patch(`/page/page/${params.jobId}`, op);
		},
		[params.jobId]
	);

	const resource = useResource(pageDef, { ...params, API_HOST }, seed);

	return { ...resource, batchOp };
}
