import PageDataContext from '../_components/PageDataContext';
import PageViewContext from '../_components/PageViewContext';
import { useContext, useMemo } from 'react';
import { countReports } from '../../../utils/hasBeenFixed';

export default function useReportCounts() {
	const page = useContext(PageDataContext);
	const { job, Part } = useContext(PageViewContext);

	return useMemo(() => {
		const unfixedWarnings = countReports(job, Part, page, 'Warnings');
		const errors = countReports(job, Part, page, 'Errors');

		return { unfixedWarnings, errors };
	}, [Part, job, page]);
}
