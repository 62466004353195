import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from ".";
import { Job } from "../types/job.types";
import { Page, PagePart } from "../types/pages.types";
import { JobState } from "../types/store.types";
import { JobStatus } from "../types/types";

const initialState: JobState = {
    changeId: "",
    jobId: "",
    loading: false,
    error: "",
    pages: {},
    jobIsActive: false
}

const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {
        setJobId: (state, { payload }: PayloadAction<string>) => {
            state.jobId = payload
            state.pages = {}
            state.loading = true
        },

        setJobIdIfJobHasChanged: (state, { payload }: PayloadAction<string>) => {
            // This reducer causes saga generator to run 
            return;
        },

        changeActiveJob: (state, { payload }: PayloadAction<string>) => {
            state.changeId = payload;
        },

        getJobById: (state, { payload }: PayloadAction<string>) => {
            return;
        },

        setJobData: (state, { payload }: PayloadAction<Job | undefined>) => {
            if (payload && state.jobId !== payload.JobID) return;
            state.job = payload
            state.jobIsActive = (state.job && state.job.Status === JobStatus.Active) || false
            state.loading = false
        },

        setJobDataError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload
            state.loading = false
        },

        setPartPages: (state, { payload }: PayloadAction<{ part: PagePart | string, pages: Page[] }>) => {
            state.loading = false;
            state.pages[payload.part] = payload.pages;
        },

        setPartPageAtOrdinal: (state, { payload }: PayloadAction<{ ordinal: number, part: PagePart | string, pages: Page[] }>) => {
            state.pages[payload.part].splice(payload.ordinal - 1, 0, ...payload.pages);
            // reassign ordinals
            state.pages[payload.part] = state.pages[payload.part].map((page, i) => ({ ...page, Ordinal: i + 1 }));
        },

        updatePartPageAtOrdinal: (state, { payload }: PayloadAction<{ ordinal: number, part: PagePart | string, page: Partial<Page> }>) => {
            state.pages[payload.part] = state.pages[payload.part].map(page => {
                if (page.Ordinal === payload.ordinal) return { ...page, ...payload.page }
                return page;
            });
        },

        setPages: (state, { payload }: PayloadAction<Record<PagePart, Page[]>>) => {
            state.loading = false
            state.pages = payload
        }



    }
})

export const { setJobId, getJobById, changeActiveJob, setJobData, setJobDataError, setPartPages, setPages, setPartPageAtOrdinal, updatePartPageAtOrdinal, setJobIdIfJobHasChanged } = jobSlice.actions

// Selectors
export const useJob = () => useSelector((state: RootState) => state.job)

export default jobSlice.reducer