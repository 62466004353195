import { Job } from "../types/job.types";
import { AccountTypes, User, UserPermission } from "../types/user.types";
import AccountDataApi from "../lib/accountdata";

export const userHasPermission = (permission: UserPermission, user?: User) => {
    if (!user || !user.Permissions) return false;
    return user.Permissions.includes(permission);
}

export const userHasAllPermissionsInList = (permissions: UserPermission[], user?: User) => {
    if (!user || !user.Permissions) return false;
    return permissions.every(permission => user.Permissions.includes(permission));
}

export const userOwnsJob = (user?: User, job?: Job) => {
    if (!job || !user) return false;
    return user.UserID === job.Owner?.UserID
}

export const userHasJob = async (user: User, job: Job, activeAcctId: string) => {

    const currentAccountType = user.AccountType

    const accountsToCheck = [activeAcctId]

    // If the user's account is a combined account type,
    // Have to also check in child accounts of parent umbrella/combined account for the job.
    if (currentAccountType === AccountTypes.Combined) {
        const subAccountsToCheck = await getSubAccountsForAccountById(activeAcctId)
        if (subAccountsToCheck) {
            accountsToCheck.push(...subAccountsToCheck)
        }
    }

    const usersJobs = user.JobsList

    for (const userAccount of accountsToCheck) {
        const usersJobsInAccount = usersJobs[userAccount]
        if (usersJobsInAccount) {
            const foundJob = usersJobsInAccount.some(usersJobId => usersJobId === job.JobID)
            if (foundJob) {
                return true;
            }
            continue;
        }
    }
    return false;
}

const getSubAccountsForAccountById = async (combinedAcctId: string) => {
    return await AccountDataApi.getAccount(combinedAcctId)
        .then(res => {
            if (res.data) {
                const subAccounts: string[] = res.data.SubAccounts || [];
                if (subAccounts.length > 0) {
                    return subAccounts;
                }
                throw new Error('No subaccounts defined on combined account data.')
            }
        })
        .catch(err => {
            console.log(err)
            return undefined;
        })
}

export const getAccountTypeName = (userAccountType?: AccountTypes) => {
    if (!userAccountType) return "";
    const accType = Object.entries(AccountTypes).find(([, value]) => userAccountType === value);
    return accType ? accType[0] : undefined;
}

export const userHasAccountType = (accountTypes: AccountTypes[] | AccountTypes, user?: User) => {
    if (!user) return false;
    if (Array.isArray(accountTypes)) return accountTypes.includes(user.AccountType);
    else return accountTypes === user.AccountType
}
