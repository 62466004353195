import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from ".";
import { AdminState } from "../types/store.types";
import { User } from "../types/user.types";

const initialState: AdminState = {
    editUserId: undefined,
    editUserDirty: false,
    userList: []
}

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setEditUserId: (state, { payload }: PayloadAction<string | undefined>) => {
            state.editUserId = payload;
            state.editUserDirty = false;
        },
        setUserList: (state, { payload }: PayloadAction<User[]>) => {
            state.userList = payload;
        },
        setEditUserDirty: (state, { payload }: PayloadAction<boolean>) => {
            state.editUserDirty = payload;
        }
    }
})

export const { setEditUserId, setEditUserDirty, setUserList } = adminSlice.actions

// Selectors
export const useAdmin = () => useSelector((state: RootState) => state.admin)

export default adminSlice.reducer