import { User } from "../types/user.types"
import customFetch from "./axiosInstance"

const login = (email: string, password: string) => {
    return customFetch.post<User>("/user/login?token=true", { email, password })
}

const getPreference = () => {
    return customFetch.get("/user/preferences?token=true");
}

const logout = () => {
    return customFetch.get("/user/logout")
}

const setAccount = (accountId: string, fallbackToDefault?: boolean) => {
    if (fallbackToDefault) {
        return customFetch.post(`/user/setaccount`, { account: accountId })
            .then(res => {
                return res;
            })
            .catch((error: any) => {
                if (error?.response?.status === 404) {
                    /* If /setaccount returns a 404, it will also return the user's JSON object.
                    We will return this user JSON response so that the user's default account ID can be
                    obtained and used as a fallback value .*/
                    return error.response;
                } else {
                    throw error;
                }
            })
    }
    return customFetch.post(`/user/setaccount`, { account: accountId })
}

const checkServerHealth = () => {
    return customFetch.get(`/admin/sysinfo`)
}

const keycloakLogin = () => {
    return customFetch.get(`/user/keycloaklogin`)
}

const AuthApi = {
    login,
    logout,
    setAccount,
    getPreference,
    keycloakLogin,
    checkServerHealth
}

export default AuthApi