import { useCallback, useMemo } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import EditUser from "../components/EditUser"
import OrganizerSidebar from "../components/OrganizerSidebar"
import Search from "../components/Search"
import SearchList from "../components/SearchList"
import UsersList from "../components/UsersList"
import ViewHistory from "../components/ViewHistory/ViewHistory"
import Dashboard from "../pages/Dashboard"
import PageOrganizer from "../pages/Dashboard/PageOrganizer"
import PreflightSummary from "../pages/Dashboard/PreflightSummary"
import Preview from "../pages/Dashboard/Preview"
import Titleview from "../pages/Dashboard/Titleview"
import Login from "../pages/Login"
import Notfound from "../pages/Notfound"
import PageView from "../pages/PageView"
import { useAuthState } from "../store/authSlice"
import { useJob } from "../store/jobSlice"
import PrivateRoute from "./PrivateRoute"
import PublicRoute from "./PublicRoute"
import Redirect from "./Redirect"

export const ScoutRoutes = () => {
    const { job } = useJob();

    const { activeAccountId } = useAuthState()

    const normalSidebarRender = useMemo(() => {
        return <>
            <Search />
            <SearchList />
        </>
    }, [])

    const emptyView = useCallback((text: string) => {
        return <>
            <div className="h-90vh has-text-light-cascading-no-important is-flex is-justify-content-center is-align-items-center">
                <p>{text}</p>
            </div>
        </>
    }, [])
    const pageViewRender = useMemo(() => {
        return <PrivateRoute>
            {job ? <PageView job={job} /> : <Redirect url="/" />}
        </PrivateRoute>
    }, [job])

    return (
        <Routes>
            <Route path='/'>
                <Route index element={<PublicRoute><Login /></PublicRoute>} />
                <Route path='admin-ui.html' element={<Redirect url={`${process.env.REACT_APP_API_HOST}/admin-ui.html`} />} />
                <Route path="dashboard">
                    {/* The below index route will navigate to the active account ID in the app's route, if no account ID is present in the route */}
                    <Route index element={<Navigate to={`/${activeAccountId}`} />} />
                    <Route path="users">
                        <Route index element={<Dashboard sidebar={<UsersList />} main={emptyView("Please select a user from the users list.")} />} />
                        <Route path="new" element={<Dashboard sidebar={<UsersList />} main={<EditUser isNew={true} />} />} />
                        <Route path=":userId" element={<Dashboard sidebar={<UsersList />} main={<EditUser />} />} />
                    </Route>
                    <Route path=":accountId">
                        <Route index element={<Dashboard sidebar={normalSidebarRender} main={emptyView("Please select a title from the titles list.")} />} />
                        <Route path="add-title" element={<Dashboard sidebar={normalSidebarRender} main={<Titleview />} />} />
                        <Route path=":jobId">
                            <Route index element={<Dashboard sidebar={normalSidebarRender} main={<Preview />} />} />
                            <Route path="edit" element={<Dashboard sidebar={normalSidebarRender} main={<Titleview isNewTitle={false} />} />} />
                            <Route path="preflight" element={<Dashboard sidebar={normalSidebarRender} main={<PreflightSummary />} />} />
                            <Route path=":part">
                                <Route index element={<Dashboard sidebar={<OrganizerSidebar />} main={<PageOrganizer />} />} />
                                <Route path="history" element={<Dashboard sidebar={normalSidebarRender} main={<ViewHistory />} />} />
                                <Route path=":ordinal" element={pageViewRender} />
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Route>
            <Route path="*" element={<Notfound />} />
        </Routes>
    )
}