import PageViewContext from '../_components/PageViewContext';
import convertUnits from '../_utils/convertUnits';
import getPageBoxes from '../_utils/getPageBoxes';
import { useContext, useMemo } from 'react';

export default function usePageGroupDimensions() {
	const { pages } = useContext(PageViewContext);

	const bleedSize = useMemo(
		() => convertUnits('inches', 'points', 0.125),
		[]
	);

	const width = pages.reduce((sum, page) => {
		if (!page) return sum;
		else return sum + getPageBoxes(page).current.width + bleedSize * 2;
	}, 0);

	const height = Math.max(
		...pages.map(p => {
			if (!p) return 1;
			else return getPageBoxes(p).current.height + bleedSize * 2;
		})
	);

	return { width, height };
}
