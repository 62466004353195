import { RefObject, useCallback, useLayoutEffect, useState } from 'react';

interface LineState {
	current: Line | null;
	final: Line | null;
	shiftKey: boolean;
}

export interface Coords {
	x: number;
	y: number;
}

export type Line = [Coords, Coords];

interface Scale {
	x: number;
	y: number;
}

function scaleCoords(input: Coords, scale: Scale = { x: 1, y: 1 }): Coords {
	return {
		x: input.x * scale.x,
		y: input.y * scale.y
	};
}

function scaleLine(input: Line, scale: Scale = { x: 1, y: 1 }): Line {
	return input.map(c => scaleCoords(c, scale)) as Line;
}

export default function useDraw(
	ref: RefObject<HTMLCanvasElement | HTMLDivElement>,
	scale: Scale = { x: 1, y: 1 }
): [LineState, () => void] {
	const [lineState, setLineState] = useState<LineState>({
		current: null,
		final: null,
		shiftKey: false
	});

	const clear = useCallback(
		() => setLineState({ current: null, final: null, shiftKey: false }),
		[]
	);

	useLayoutEffect(() => {
		const { current } = ref;

		if (!current) return;

		let mouseIsDown: boolean;

		let start: Coords = { x: 0, y: 0 };
		let end: Coords = { x: 0, y: 0 };

		const mouseEventHandlers = ((e: MouseEvent) => {
			const currentTarget = e.currentTarget as HTMLCanvasElement | null;
			if (!currentTarget) return;

			// idk why the types suck here
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const boundingRect: DOMRect = currentTarget.getBoundingClientRect();

			// ratio of screen size to canvas size
			let internalScale;
			if (current.tagName === 'CANVAS') {
				internalScale = boundingRect.width / currentTarget.width;
			} else {
				internalScale = 1;
			}

			const x = e.clientX - boundingRect.left;
			const y = e.clientY - boundingRect.top;
			const { shiftKey } = e;

			switch (e.type) {
				case 'mousedown':
					start = end = { x, y };
					mouseIsDown = true;

					// eslint-disable-next-line no-case-declarations
					const downLine = scaleLine([start, end], {
						x: 1 / internalScale / scale.x,
						y: 1 / internalScale / scale.y
					});
					setLineState({
						current: downLine,
						final: null,
						shiftKey
					});
					break;
				case 'mouseup':
					mouseIsDown = false;
					// eslint-disable-next-line no-case-declarations
					const upLine = scaleLine([start, end], {
						x: 1 / internalScale / scale.x,
						y: 1 / internalScale / scale.y
					});
					setLineState({
						current: upLine,
						final: upLine,
						shiftKey
					});
					break;
				case 'mousemove':
					end = {
						x: e.clientX - boundingRect.left,
						y: e.clientY - boundingRect.top
					};
					if (mouseIsDown) {
						const moveLine = scaleLine([start, end], {
							x: 1 / internalScale / scale.x,
							y: 1 / internalScale / scale.y
						});
						setLineState({
							current: moveLine,
							final: null,
							shiftKey
						});
					}
					break;
				default:
			}
		}) as EventListener;

		current.addEventListener('mousedown', mouseEventHandlers);
		current.addEventListener('mouseenter', mouseEventHandlers);
		current.addEventListener('mouseup', mouseEventHandlers);
		current.addEventListener('mousemove', mouseEventHandlers);

		return () => {
			current.removeEventListener('mousedown', mouseEventHandlers);
			current.removeEventListener('mouseenter', mouseEventHandlers);
			current.removeEventListener('mouseup', mouseEventHandlers);
			current.removeEventListener('mousemove', mouseEventHandlers);
		};
	}, [scale.x, scale.y, ref]);

	return [lineState, clear];
}
