import PageViewContext from '../_components/PageViewContext';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Job, PageUpdate, partShortname } from '../_types/LSCScout.type';
import PageDataContext from '../_components/PageDataContext';

import useJob from './useJob';
import customFetch from '../../../lib/axiosInstance';

/**
 * An attempt at encapsulating scale/crop logic in a hook.
 */
export default function useScaleCrop(jobSeed: Job, part: partShortname) {
	const page = useContext(PageDataContext);

	const ordinal = useMemo(() => page.Ordinal, [page.Ordinal]);

	const API_HOST = process.env.REACT_APP_API_HOST as string;

	const { refetch, bumpOffset, setBumpOffset, Part } = useContext(
		PageViewContext
	);

	const { data: job, mutate: mutateJob } = useJob(
		{ API_HOST, jobId: jobSeed.JobID },
		jobSeed
	);

	const [targetwidth, setTargetWidth] = useState(
		(
			job.Components?.[part]?.TrimBox?.width ||
			+(page.TrimBox?.width || page.MediaBox.width)
		).toString()
	);

	const [targetheight, setTargetHeight] = useState(
		(
			job.Components?.[part]?.TrimBox?.height ||
			+(page.TrimBox?.height || page.MediaBox.height)
		).toString()
	);

	const targetDimensionsValid = useMemo(
		() => (!isNaN(parseFloat(targetwidth)) && !isNaN(parseFloat(targetheight))),
		[targetheight, targetwidth]
	);

	const batchOp = useCallback(
		async (op: PageUpdate | PageUpdate[]) => {
			await customFetch.patch(`/page/page/${job.JobID}`, op);
		},
		[job.JobID]
	);

	const submitPatch = useCallback(
		(
			startOrdinal: number,
			type: 'center' | 'ascalecenter',
			count = 1,
			args: {
				targetpercent?: number;
				targetwidth?: number;
				targetheight?: number;
				settrim?: { width: number; height: number };
			}
		) =>
			batchOp({
				type,
				ordinal: startOrdinal,
				part,
				count,
				...args
			}),
		[batchOp, part]
	);

	const trimDirty =
		parseFloat(targetwidth) !== job.Components?.[part]?.TrimBox?.width ||
		parseFloat(targetheight) !== job.Components?.[part]?.TrimBox?.height;

	const applyCustomTrim = useCallback(
		(override?: { width: number; height: number }) =>
			mutateJob(() => {
				if (!targetwidth || !targetheight)
					throw new Error(`Component width or height is undefined`);
				else if (
					[parseFloat(targetwidth), parseFloat(targetheight)].some(
						isNaN
					)
				)
					throw new Error('Component width or height are NaN');

				const TrimBox = {
					width: override?.width || parseFloat(targetwidth),
					height: override?.height || parseFloat(targetheight)
				};

				const data = {
					Components: {
						[part]: {
							TrimBox
						}
					}
				};
				return data;
			}),
		[part, job, mutateJob, targetheight, targetwidth]
	);

	const autoCenter = useCallback(
		() =>
			submitPatch(ordinal, 'center', 1, {
				targetwidth: parseFloat(targetwidth),
				targetheight: parseFloat(targetheight)
			}),
		[ordinal, submitPatch, targetheight, targetwidth]
	);

	const autoScaleCenter = useCallback(
		() =>
			submitPatch(ordinal, 'ascalecenter', 1, {
				targetwidth: parseFloat(targetwidth),
				targetheight: parseFloat(targetheight)
			}),
		[ordinal, submitPatch, targetheight, targetwidth]
	);

	const scaleToPercent = useCallback(
		(scale: number, override?: { width: number; height: number }) =>
			submitPatch(ordinal, 'ascalecenter', 1, {
				targetpercent: scale * 100,
				settrim: override || {
					width: parseFloat(targetwidth),
					height: parseFloat(targetheight)
				}
			}),
		[ordinal, submitPatch, targetheight, targetwidth]
	);

	const applyTo = useCallback(
		async (pageSelector: {
			part: string;
			ordinal: number;
			count: number;
			skip?: number;
		}) => {
			const [bumpx, bumpy] = bumpOffset;

			const updates: PageUpdate[] = [];

			if (page.CustomBox?.op && page.CustomBox.op !== 'undoscale')
				updates.push({
					...pageSelector,
					type: page.CustomBox?.op as
						| 'center'
						| 'scalecenter'
						| 'ascalecenter',
					// yes, despite the name, HorizontalPercent/VericalPercent are actually proportions
					targetpercent: (page.Scale?.HorizontalPercent || 1) * 100,
					bumpx,
					bumpy
				});
			else
				updates.push({
					...pageSelector,
					type: 'bump',
					bumpx,
					bumpy
				});

			setBumpOffset([0, 0]);

			await applyCustomTrim();
			await batchOp(updates);
		},
		[
			page.CustomBox,
			page.Scale?.HorizontalPercent,
			bumpOffset,
			setBumpOffset,
			applyCustomTrim,
			batchOp
		]
	);

	const reset = useCallback(
		() =>
			batchOp({
				ordinal,
				part: Part,
				type: 'undoscale',
				count: 1
			} as any),
		[Part, batchOp, ordinal]
	);

	return {
		applyCustomTrim,
		autoCenter,
		autoScaleCenter,
		targetwidth,
		setTargetWidth,
		targetheight,
		setTargetHeight,
		refetch,
		applyTo,
		setBumpOffset,
		bumpOffset,
		reset,
		trimDirty,
		scaleToPercent,
		targetDimensionsValid
	};
}
