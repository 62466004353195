import { cloneDeep } from 'lodash';
import JobApi from '../../../lib/job';
import PagesApi from '../../../lib/pages';
import { Job, Page, partShortname, PreflytReport } from '../_types/LSCScout.type';
/**
 * Fix a warning in a part or in a job.
 *
 * @param job
 * @param api
 * @param ruleid
 * @param inJob
 * @param inPart
 * @param partKey
 */
async function autoFixWarning(
	job: Job,
	ruleid: string,
	inJob = false,
	inPart = false,
	partKey?: partShortname
): Promise<void> {
	if (inJob && inPart)
		throw new Error(
			`you can't fix a warning in both the entire job and also a single part`
		);
	else if (!inJob && !inPart)
		throw new Error(
			'fixing in a single page has been removed from this method, try fixInPage instead'
		);

	// clone the job's existing Workflow property, if it's defined on the job:
	const clonedJobWf = (job.Workflow ? cloneDeep(job.Workflow) : undefined)

	// record all Workflow updates to be PATCHED in the updatedJobWf field:
	const updatedJobWf = clonedJobWf || {}

	// Add the required properties if they are not defined,
	// because it does happen that job.Workflow.Preflyt1.Warnings is *not* defined initially.
	// So this will make sure that the workflow update obj sent to the API will have at least the minimum new data for the job.
	if (!updatedJobWf.Preflyt1) {
		updatedJobWf.Preflyt1 = {}
	}

	// if fixing/signing off at Job level (i.e. making changes at job.Workflow.Preflyt1.Warnings) -
	if (inJob) {

		if (!updatedJobWf.Preflyt1.Warnings) {
			updatedJobWf.Preflyt1.Warnings = []
		}

		let warningData = updatedJobWf.Preflyt1.Warnings // will be either the old warnings val, or the newly init'd value, []

		const warningInData = warningData.find(w => w.RuleID === ruleid);
		if (warningInData) {
			warningData = warningData.map(w => (w.RuleID === ruleid) ? { ...w, AutoFix: true } : w);
		}
		else {
			warningData.push({ RuleID: ruleid, AutoFix: true })
		}
		
		updatedJobWf.Preflyt1.Warnings = warningData;		
	} else {
		// else,
		// if fixing/signing-off warnings at Part level (i.e. making changes at job.Workflow.Preflyt1.[part].Warnings) -
		
		if (partKey === undefined) {
			throw new Error('partKey not defined')
		}
		if (typeof partKey !== 'string') {
			throw new Error ('invalid partKey type')
		}

		if (!updatedJobWf.Preflyt1) {
			updatedJobWf.Preflyt1 = {}
		}

		if (!updatedJobWf.Preflyt1[partKey]) {
			updatedJobWf.Preflyt1[partKey] = { Warnings: [] }
		}

		// if the part property was defined but doesn't have the Warnings array, define here:
		if (!Object.keys(updatedJobWf.Preflyt1[partKey]!).includes("Warnings")) {
			updatedJobWf.Preflyt1[partKey]!.Warnings = []
		}
		
		// tsc is not certain that Warnings will be defined at this point, so "|| []" is added to this assignment:
		let warningData = updatedJobWf.Preflyt1[partKey]!.Warnings || []

		const warningInData = warningData.find(w => w.RuleID === ruleid);
		if (warningInData) {
			warningData = warningData.map(w => (w.RuleID === ruleid) ? { ...w, AutoFix: true } : w);
		}
		else { 
			warningData.push({ RuleID: ruleid, AutoFix: true })
		}

		updatedJobWf.Preflyt1[partKey]!.Warnings = warningData

	}

	return JobApi.signOff(
		job.JobID,
		updatedJobWf
	) as unknown as Promise<void>;
}

/**
 * Fix a warning in a part.
 *
 * @param job
 * @param jobApi
 * @param part
 * @param warning
 */
export async function fixInPart(
	job: Job,
	part: any,
	warning: PreflytReport
): Promise<void> {

	return autoFixWarning(job, warning.RuleID, false, true, part);
}

/**
 * Fix a warning in entire job.
 *
 * @param job
 * @param jobApi
 * @param warning
 */
export async function fixInJob(
	job: Job,
	warning: PreflytReport
): Promise<void> {

	return autoFixWarning(job, warning.RuleID, true);
}

/**
 * Fix a warning in a page.
 *
 * @param job
 * @param page
 * @param pageApi
 * @param warning
 */
export async function fixInPage(
	job: Job,
	page: Page,
	warning: PreflytReport
): Promise<void> {
	const clonedPageWf = (page.Workflow ? cloneDeep(page.Workflow) : undefined)
	const updatedPageWf = clonedPageWf || {};

	const ruleId = warning.RuleID

	if (!updatedPageWf.Preflyt1) {
		updatedPageWf.Preflyt1 = {}
	}
	if (!updatedPageWf.Preflyt1.Warnings) {
		updatedPageWf.Preflyt1.Warnings = []
	}
	let warningData = updatedPageWf.Preflyt1.Warnings

	const warningIsInData = warningData.find(w => w.RuleID === ruleId);
	if (warningIsInData) {
		warningData = warningData.map(w => (w.RuleID === ruleId) ? { ...w, AutoFix: true } : w);
	}
	else { 
		throw new Error('Attempt was made to fix a warning not found on page.')
	}

	// update data to be PATCHED with
	updatedPageWf.Preflyt1.Warnings = warningData
	
	const data: Partial<Page> = {
		// TODO - do we need to/have to send Tags w/the PATCH request? I don't think they've been changed.
		Tags: page.Tags,
		Workflow: updatedPageWf
	}
	return PagesApi.patchPage(
		job.JobID,
		page.PageID,
		data
	) as unknown as Promise<void>;
}
