import { Line } from '@apagoinc/react-ruler';

import coordRoundToGrid from './coordRoundToGrid';

export default function lineRoundToGrid(
	line: Line,
	grid: [number, number],
	roundFunc: (i: number) => number = Math.round
): Line {
	const { start, end } = line;

	return {
		start: coordRoundToGrid(start, grid, roundFunc),
		end: coordRoundToGrid(end, grid, roundFunc)
	};
}
