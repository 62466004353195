import classNames from 'classnames';
import React, {
	Dispatch,
	ReactNode,
	SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';
import { FaCheck, FaCheckCircle, FaTimes } from 'react-icons/fa';
import extractRuleMetadata from '../_utils/RuleMetadataExtractor';
import { toggleHandler } from '../_utils/toggle';
import { PreflytReport } from '../_types/LSCScout.type';
import hasBeenFixed from '../../../utils/hasBeenFixed';
import { fixInJob, fixInPart, fixInPage } from '../_utils/fixWarnings';
import PageDataContext from '../_components/PageDataContext';
import ScaleCropContext from './PageViewContext';
import RuleIcon from './RuleIcon';
import styles from './PreflightTree.module.scss';
import { userHasPermission } from '../../../utils/user';
import { UserPermission } from '../../../types/user.types';
import { useAuthState } from '../../../store/authSlice';
import { JobStatus } from '../../../types/types';
import { checkLegacyJobTypePartLock } from "../../../utils/utils"

import SwalTwo from "sweetalert2"

interface PreflytReportDisplayProps {
	reportCount?: number;
	onClick: () => void;
	icon: ReactNode;
	name: string;
}

function PreflytReportDisplay({
	reportCount,
	onClick,
	children,
	icon,
	name
}: React.PropsWithChildren<PreflytReportDisplayProps>) {
	return (
		<div
			className={classNames(styles.PreflytRectangle, styles.RuleEntry)}
			onClick={onClick}
		>
			<div className={styles.InfoLine}>
				{icon}
				<span className={styles.NumberName}>{name}</span>
				<span className={styles.CheckOrCount}>
					{!reportCount && <FaCheck className={styles.FixedCheck} />}
					{!!reportCount && `${reportCount}`}
				</span>
			</div>
			{children}
		</div>
	);
}

interface PreflytWarningProps {
	warning: PreflytReport;
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
}

function PreflytWarningDisplay(props: PreflytWarningProps) {

	const { warning, open, setOpen } = props;

	const { category, number, fixable } = extractRuleMetadata(warning.RuleID);

	// This is the legacy job object, typed using the legacy "Job" type
	// The types share a name, but they are not the same
	const { job, Part, refetch } = useContext(ScaleCropContext);
	// const { job } = useJob()
	const { user } = useAuthState()
	// if (!job) return null;

	const contextJobIsActive: boolean | undefined = useMemo(() => (job && job.Status === JobStatus.Active), [job])


	const page = useContext(PageDataContext);
	// const { job } = useContext(PageViewContext);

	const partIsLocked = useMemo(() => {
		return checkLegacyJobTypePartLock(job, Part)
	}, [job, Part]);



	const fixedInPage = useMemo(
		() => hasBeenFixed.inPage(page, warning.RuleID),
		[page, warning.RuleID]
	);
	const fixedInPart = useMemo(
		() => hasBeenFixed.inPart(job, Part, warning.RuleID),
		[Part, job, warning.RuleID]
	);
	const fixedInJob = useMemo(() => hasBeenFixed.inJob(job, warning.RuleID), [
		job,
		warning.RuleID
	]);

	const fixed = fixedInPage || fixedInPart || fixedInJob;

	const inPageHandler = () => {
		if (!contextJobIsActive || partIsLocked) {
			return;
		}
		fixInPage(job as any, page, warning).then(refetch);
	}

	const inPartHandler = () => {
		if (!contextJobIsActive || partIsLocked) {
			return;
		}
		fixInPart(job as any, Part, warning).then(refetch)
	}

	const inJobHandler = () => {
		if (!contextJobIsActive || partIsLocked) {
			return;
		}
		fixInJob(job as any, warning).then(refetch)
	}

	const canFix = useMemo(() => userHasPermission(UserPermission.Preflight1_ApplyFix, user), [user]);
	const canSignoff = useMemo(() => userHasPermission(UserPermission.Preflight1_Signoff, user), [user]);

	const language = fixable ? 'Fix in' : 'Sign off';
	const pastTenseLanguage = fixable ? 'Fixed in' : 'Signed off';
	const enableActionBtn = fixable ? canFix : canSignoff;

	const warningCount = page.Tags?.Preflyt1?.Warnings?.[warning.RuleID];

	return (
		<PreflytReportDisplay
			reportCount={warningCount}
			onClick={toggleHandler(setOpen)}
			name={`${number} - ${warning.Name}`}
			icon={
				<RuleIcon
					className={classNames(styles.RuleIcon, styles.Warning)}
					category={category}
				/>
			}
		>
			{open && (
				<div className={styles.Description}>
					{warning.Description}
					<br />
					{fixed && (
						<span className={styles.FixedText}>
							<FaCheck className={styles.FixedCheck} />
							{fixedInJob
								? `${pastTenseLanguage} Job`
								: fixedInPart
									? `${pastTenseLanguage} Part`
									: fixedInPage
										? `${pastTenseLanguage} Page`
										: null}
						</span>
					)}
					{enableActionBtn && (
						<div className={styles.FixButtons}>
							{fixedInJob || fixedInPart || fixedInPage || (
								<button disabled={!contextJobIsActive || partIsLocked}
									className={styles.FixButton}
									type="button"
									onClick={inPageHandler}
								>
									{language} Page
								</button>
							)}
						</div>
					)}
				</div>
			)}
		</PreflytReportDisplay>
	);
}

interface PreflytErrorProps {
	error: PreflytReport;
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
}

function PreflytErrorDisplay(props: PreflytErrorProps) {
	const { error, open, setOpen } = props;

	return (
		<PreflytReportDisplay
			icon={
				<FaTimes
					className={classNames(styles.RuleIcon, styles.Error)}
				/>
			}
			onClick={toggleHandler(setOpen)}
			name={error.Name}
		>
			{open && (
				<div className={styles.Description}>{error.Description}</div>
			)}
		</PreflytReportDisplay>
	);
}

interface PreflightTreeProps {
	openWarnings: string[];
	openErrors: string[];
	setWarningOpen: (id: string, action: SetStateAction<boolean>) => void;
	setErrorOpen: (id: string, action: SetStateAction<boolean>) => void;
	// the following props have been added to lift the open state of the warnings and
	// error accordions up to the top level of the PageView component, so that the 
	// accordions stay open or closed between pages in Pageview:
	warningsExpanded: boolean;
	errorsExpanded: boolean;
	onToggleWarningsExpanded: React.Dispatch<React.SetStateAction<boolean>>
	onToggleErrorsExpanded: React.Dispatch<React.SetStateAction<boolean>>
}

interface AccordionProps {
	className?: string;
	count: number;
	click: () => void;
	label: string;
}

function Accordion({ count, className, click, label }: AccordionProps) {
	return (
		<div
			className={classNames(
				styles.PreflytRectangle,
				styles.Header,
				className
			)}
			onClick={click}
		>
			<FaCheckCircle
				className={classNames(styles.RuleIcon, styles.Icon)}
			/>{' '}
			<div className={styles.Label}>{label}</div>
			<div className={styles.CountOrCheck}>
				{count === 0 && <FaCheck className={styles.FixedCheck} />}
				{count > 0 && `${count}`}
			</div>
		</div>
	);
}

export default function PreflightTree(props: PreflightTreeProps) {


	const {
		openWarnings,
		setWarningOpen,
		openErrors,
		setErrorOpen,
		warningsExpanded,
		errorsExpanded,
		onToggleWarningsExpanded,
		onToggleErrorsExpanded
	} = props;


	const { job, Part } = useContext(ScaleCropContext);
	const page = useContext(PageDataContext);

	const warnings = page.Workflow?.Preflyt1?.Warnings || [];
	const errors = page.Workflow?.Preflyt1?.Errors || [];

	const pageWarnings = useMemo(
		() => hasBeenFixed.countReports(job, Part, page, 'Warnings'),
		[Part, job, page]
	);
	const pageErrors = useMemo(
		() => hasBeenFixed.countReports(job, Part, page, 'Errors'),
		[Part, job, page]
	);

	// utility type for use below to include an issue "type" for each detected bad rule:
	type PreflytReportDialogData = PreflytReport & {
		type: 'warning' | 'error'
	}

	const badRules: PreflytReportDialogData[] = useMemo(() => {
		const badRuleData: PreflytReportDialogData[] = [];

		for (const warning of warnings) {
			const { malformed } = extractRuleMetadata(warning.RuleID);
			if (malformed) {
				badRuleData.push({ ...warning, type: 'warning' })
			}
		}

		for (const error of errors) {
			const { malformed } = extractRuleMetadata(error.RuleID);
			if (malformed) {
				badRuleData.push({ ...error, type: 'error' })
			}
		}

		return badRuleData;
	}, [page])

	const buildTableAndMsgTemplate = (badRuleTableData: PreflytReportDialogData[]): string => {


		const msg = `Please consult your administrator for advice on how to proceed with this title.`;

		let tableRowData = ""

		// For testing large amount of bad rules in html template:
		// vvv
		// const artificiallyDuplicate = () => {
		// 	let i = 0;
		// 	while (i < 30) {
		// 		badRuleTableData.push(badRuleTableData[0])
		// 		i++;
		// 	}
		// }
		// artificiallyDuplicate();
		// ^^^

		const countInfo = `${badRuleTableData.length} incorrectly formatted rule${badRuleTableData.length > 1 ? 's' : ''} found:`


		for (const rule of badRuleTableData) {
			const { type, RuleID, Name } = rule; // , Description
			// build table row string
			tableRowData += `
			<tr>
			<td>${type || 'n/a'}</td>
			<td>${RuleID || 'n/a'}</td>
			<td>${Name || 'n/a'}</td>
			</tr>`
		}
		// <td>${Description || 'n/a'}</td>

		return `
		<p>${countInfo}</p>
		<div>
		<table>
		<tbody>
		<th>Type</th>
		<th>Rule ID</th>
		<th>Name</th>
		${tableRowData}
		</tbody>
		</table>
		</div>
		<div>
		<strong>
		${msg}
		</strong>
		</div>
		`;

		// <th>Description</th>
	}

	useEffect(() => {
		async function alertOnBadRules() {
			if (badRules.length > 0) {
				return SwalTwo.fire({
					icon: 'error',
					customClass: 'swal2-bad-rules-dialog',
					title: `Incorrectly formatted preflight rules detected for page`,
					// text: JSON.stringify(badRules)
					html: buildTableAndMsgTemplate(badRules)
				})
			}
		}
		alertOnBadRules()
	}, [badRules])


	return (
		<>
			<div>
				<Accordion
					label="Warnings"
					count={pageWarnings}
					className={styles.Warnings}
					click={() => onToggleWarningsExpanded(prev => !prev)}
				/>
				<div
					className={classNames(styles.Rules, {
						[styles.Hidden]: !warningsExpanded
					})}
				>
					{warnings.map(warning => (
						<PreflytWarningDisplay
							key={warning.RuleID}
							warning={warning}
							open={openWarnings.includes(warning.RuleID)}
							setOpen={action =>
								setWarningOpen(warning.RuleID, action)
							}
						/>
					))}
					{!warnings.length && (
						<span className={styles.None}>No Warnings</span>
					)}
				</div>
			</div>
			<div>
				<Accordion
					label="Errors"
					className={styles.Errors}
					click={() => onToggleErrorsExpanded(prev => !prev)}
					count={pageErrors}
				/>
				<div
					className={classNames(styles.Rules, {
						[styles.Hidden]: !errorsExpanded
					})}
				>
					{errors.map(error => (
						<PreflytErrorDisplay
							key={error.RuleID}
							error={error}
							open={openErrors.includes(error.RuleID)}
							setOpen={action =>
								setErrorOpen(error.RuleID, action)
							}
						/>
					))}
					{!errors.length && (
						<span className={styles.None}>No Errors</span>
					)}
				</div>
			</div>
		</>
	);
}
