import { Line } from '../_hooks/useDraw';
import { Area } from '../_types/LSCScout.type';

function numericSort(a: number, b: number) {
	return a - b;
}

export default function getAreaFromLine(line: Line): Area {
	const [a, b] = line;

	const [minx, maxx] = [a.x, b.x].sort(numericSort);
	const [miny, maxy] = [a.y, b.y].sort(numericSort);

	const x = minx;
	const y = miny;

	const width = maxx - minx;
	const height = maxy - miny;

	const area: Area = [x, y, width, height];

	return area;
}
