import { useCallback, useEffect, useRef, useState } from "react";

export const useViewPort = () => {
    const ref = useRef<HTMLElement>()
    const [inView, setInView] = useState(false);

    const setRef = useCallback((node) => {
        ref.current = node;
    }, [ref])

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.4
        }
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio >= 0.4) {
                    setInView(true);
                } else setInView(false)
            });
        }, options);

        if (ref.current) observer.observe(ref.current);
        return () => observer.disconnect()
    }, [])

    return { inView, setRef }
}