import PageViewContext from '../_components/PageViewContext';
import getPageBoxes from '../_utils/getPageBoxes';
import { useContext, useMemo } from 'react';

export default function useBoxes() {
	const { pages } = useContext(PageViewContext);

	const page = useMemo(() => pages.find(p => !!p), [pages]);

	return useMemo(() => getPageBoxes(page!), [page]);
}
