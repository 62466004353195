import { Box, Unparsed } from '../_types/LSCScout.type';

/**
 * Convert a box whose values may be string-encoded into numbers.
 *
 * @param box
 */
export default function parseBox(box: Partial<Unparsed<Box>>): Box {
    let height = box.height !== undefined ? box.height : 0;
    if (typeof height === 'string') height = parseFloat(height);

    let width = box.width !== undefined ? box.width : 0;
    if (typeof width === 'string') width = parseFloat(width);

    let x = box.x !== undefined ? box.x : 0;
    if (typeof x === 'string') x = parseFloat(x);

    let y = box.y !== undefined ? box.y : 0;
    if (typeof y === 'string') y = parseFloat(y);

    return { height, width, x, y };
}
