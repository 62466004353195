import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from ".";
import { AuthState, LoginPayload } from "../types/store.types";
import { User } from "../types/user.types";

export const initialState: AuthState = {
    isAuthenticated: false,
    loading: false,
    error: '',
    activeAccountId: '',
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        login: (state, { payload }: PayloadAction<Partial<LoginPayload>>) => {
            state.loading = true
            state.error = ""
        },

        loginSession: (state) => {
            state.loading = true;
        },

        loginSuccess: (state, { payload }: PayloadAction<User>) => {
            state.user = payload
            state.isAuthenticated = true
            state.loading = false
        },

        loginError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload
            state.isAuthenticated = false
            state.loading = false
        },

        setActiveAccount: (state, { payload }: PayloadAction<string>) => {
            state.activeAccountId = payload
        },

        logout: (state) => {
            state.loading = true
        },

        logoutSuccess: (state) => {
            state.isAuthenticated = false
            state.user = undefined
            state.error = ''
            state.loading = false
        },

        logoutError: (state) => {
            state.loading = false
        }
    }
})

export const { login, loginSession, loginError, loginSuccess, logout, logoutSuccess, logoutError, setActiveAccount } = authSlice.actions

export const useAuthState = () => useSelector((state: RootState) => state.auth)

export default authSlice.reducer