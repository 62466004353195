import { Preferences as IPrefs } from '../_types/LSCScout.type';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { SearchTitleTypes } from '../../../types/types';

const LAKESIDE_PREFERENCE = "Lakeside_preference";

export default function usePreferences(): [
	IPrefs,
	Dispatch<SetStateAction<IPrefs>>
] {

	const [internalPrefs, setInternalPrefs] = useState<IPrefs>({
		units: 'inches',
		selectedTab: 'scale/crop',
		cropSidebarWidth: 400,
		pageThumbnailZoom: 1,
		jobFilter: 0,
		userStatusFilter: 'All',
		accountStatusFilter: 'All',
		jobStatusFilter: 'All',
		shelfOpen: true,
		activeTitleType: SearchTitleTypes.ALL_TITLES
	});

	useEffect(() => {
		try {
			const data = localStorage.getItem(LAKESIDE_PREFERENCE);
			if (!data) return;
			const jsonData = JSON.parse(data);
			// if pageThumbnailZoom doesn't parse correctly:
			if (typeof jsonData.pageThumbnailZoom !== 'number') {
				// set it to the 'default' value:
				jsonData.pageThumbnailZoom = 1;
			}
			setInternalPrefs(jsonData);
		} catch (e) {
			console.error(e);
		}
	}, [])


	useEffect(() => {
		localStorage.setItem(LAKESIDE_PREFERENCE, JSON.stringify(internalPrefs));
	}, [internalPrefs]);

	return [internalPrefs, setInternalPrefs];
}
