import { Page, PagePart, PageReviewStatus } from "../types/pages.types"
import { Job } from "../types/job.types"
import hasBeenFixed from "../utils/hasBeenFixed";
import { Preflyt1_IssueTypes } from "../types/job.types";

const { atAll } = hasBeenFixed

export const isPageApproved = (page: Page) => {
    const reviewStatus: string = page.Tags.ReviewStatus;
    if (!reviewStatus) return false;
    else if (reviewStatus === PageReviewStatus.APPROVED) return true;
    return false;
}

export const pageHasErrors = (page?: Page): boolean => {
    if (page) {
        const errorsProperty = page?.Tags?.Preflyt1?.Errors
        if (errorsProperty) {
            return (Object.keys(page?.Tags?.Preflyt1?.Errors).length > 0);
        }
        return false;
    }
    console.error('A required variable is not defined in the state store/component tree.')
    return false;
}

export const pageHasWarnings = (job?: Job, part?: PagePart | string, page?: Page): boolean => {
    if (job && part && page) {
        const warningsProperty = page?.Tags?.Preflyt1?.Warnings
        if (warningsProperty) {
            for (const [ruleId, countOfWarning] of Object.entries(warningsProperty)) {
                if (!atAll(job, part, page, ruleId)) {
                    return true;
                }
            }
        }
        return false;
    }
    console.error('A required variable is not defined in the state store/component tree.')
    return false;
}

// TODO - rework part type with adoption of \@lsp-types - currently allowing all string values through for "part"
export const sumPreflytIssuesForPage = (job?: Job, part?: PagePart | string, page?: Page, issueType?: Preflyt1_IssueTypes): number => {
    let sum = 0
    if (job && part && page && issueType) {
        if (page.Tags?.Preflyt1?.[issueType]) {
            if (issueType === Preflyt1_IssueTypes.Errors) {
                const errors = page.Tags.Preflyt1[Preflyt1_IssueTypes.Errors]
                if (errors) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    for (const [ruleId, countOfError] of Object.entries(errors)) {
                        // ^^^ 'ruleId' is just a placeholder to use the "for ([key, value] of object)" ES destructuring syntax
                        sum += countOfError
                    }
                }
            }
            else if (issueType === Preflyt1_IssueTypes.Warnings) {
                const warnings = page.Tags.Preflyt1[Preflyt1_IssueTypes.Warnings]
                if (warnings) {
                    for (const [ruleId, countOfWarning] of Object.entries(warnings)) {
                        if (!atAll(job, part, page, ruleId)) {
                            sum += countOfWarning
                        }
                    }
                }
            }
        }
        return sum;
    }
    if (!issueType) {
        console.error('Method must be called with an issueType param')
    }
    if (!(job || part || page)) {
        console.error('A required variable is not defined in the state store/component tree.')
    }
    return sum;
}


export const pagesWithWarnings = (job: Job, part: PagePart | string, pages: Page[]) => {
    return pages.reduce((warningPages, page) => {
        return pageHasWarnings(job, part, page) ? warningPages + 1 : warningPages;
    }, 0)
}

export const pagesWithErrors = (pages: Page[]) => {
    return pages.reduce((errorPages, page) => {
        return pageHasErrors(page) ? errorPages + 1 : errorPages
    }, 0);
}

export const pagesUnapproved = (pages: Page[]) => {
    return pages.reduce((a, b) => {
        if (pageHasErrors(b)) {
            return a;
        }
        return !isPageApproved(b) ? a + 1 : a
    }, 0);
}