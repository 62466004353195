import { getNotificationText } from "@apagoinc/lsp-types"
import classNames from "classnames"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import Foco from "react-foco"
import GitInfo from "react-git-info/macro"
import { FaCog, FaInfoCircle, FaPowerOff } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { visitHome, visitJob, visitUsersAdmin } from "../routes/visit"
import { setEditUserId, useAdmin } from "../store/adminSlice"
import { logout, setActiveAccount, useAuthState } from "../store/authSlice"
import { UserPermission } from "../types/user.types"
import { flattenNotifications, getGetStreamTokens, getNotificationClient, getUserFeed } from "../utils/getstream"
import { getAccountTypeName, userHasPermission } from "../utils/user"
import { isKeyCloak } from "../utils/utils"
import { Notification, NotificationItem } from "./Notification/Notification"
import { swalButtonColorsPalette as palette } from "../utils/constants"

const branchString = `${GitInfo().branch}`.match(/.+(\/.+)$/);
const githubString = branchString ? branchString[0] : GitInfo().branch;


const Sidebar: React.FC = (props) => {
    const { user, activeAccountId } = useAuthState()
    const [isOptionsOn, setIsOptionsOn] = useState(false);
    const [showNoti, setShowNoti] = useState(false);
    const [allNoti, setAllNoti] = useState<any>([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { pathname } = useLocation()

    const { editUserDirty } = useAdmin() || {}

    const backToDashboardHandler = async () => {
        if (editUserDirty) {
            const { isConfirmed } = await confirmLeaveOnDirty()
            if (!isConfirmed) {
                return;
            }
        }
        navigate(visitHome())
    }

    const hasUnread = useMemo(() => allNoti ? allNoti.some((n: any) => n.read === false) : false, [allNoti])

    const notificationResultToItem = (data: any[]) => {
        return data.map((r) => {
            const obj = JSON.parse(r.object);
            const notiMessage = getNotificationText(obj as any, "notification");
            const subtitle = notiMessage.body && notiMessage.body.length ? notiMessage.body[notiMessage.body.length - 1] : undefined;
            return {
                id: r.id,
                notiId: r.notiId,
                message: notiMessage.header,
                title: notiMessage.jobIdentifier,
                subtitle: subtitle,
                meta: obj,
                read: r.is_read,
                time: r.time
            }
        }) as NotificationItem[]
    }

    useEffect(() => {
        const getStreamTokens = user?.getStreamTokens || getGetStreamTokens();
        const feed = getUserFeed(getStreamTokens);
        if (!user?.UserID || !getStreamTokens) return;
        async function getStreamData() {
            if (!feed) return;
            const data = await feed.get();
            if (!data?.results) return;
            const allNotifications = flattenNotifications(data);
            setAllNoti(notificationResultToItem(allNotifications));
            // subscribe to updates
            console.debug("getstream: data fetched!");
            feed.subscribe(async (m) => {
                console.debug("getstream: new message in", m);
                const data = await feed.get();
                if (!data?.results) return;
                const allNotifications = flattenNotifications(data);
                setAllNoti(notificationResultToItem(allNotifications));
            })
                .then(() => console.debug("getstream: subscribed for real-time updates")).catch(console.error)
        }
        getStreamData();
        return () => {
            feed?.unsubscribe();
        }
    }, [user?.UserID, user?.getStreamTokens])

    const notificationClickHandler = useCallback(async (noti: NotificationItem) => {
        navigate(visitJob({ accountId: noti.meta.accountInfo.id, jobId: noti.meta.jobInfo.id }))
        const getStreamTokens = user?.getStreamTokens || getGetStreamTokens();
        if (!getStreamTokens) return;
        const feedClient = getNotificationClient(getStreamTokens);
        if (noti.notiId) {
            await feedClient?.get({ mark_read: [noti.notiId] })
            setAllNoti((prev: NotificationItem[]) => prev.map(p => {
                return {
                    ...p,
                    read: p.notiId === noti.notiId ? true : p.read
                }
            }))
        }

    }, [navigate, user?.getStreamTokens])

    const readAllClickHandler = useCallback(async (items: NotificationItem[]) => {
        const getStreamTokens = user?.getStreamTokens || getGetStreamTokens();
        if (!getStreamTokens) return;
        const feedClient = getNotificationClient(getStreamTokens);
        if (items.length) {
            const notiIds = items.filter(i => !!i.notiId).map(i => i.notiId) as string[]
            await feedClient?.get({ mark_read: notiIds })
            setAllNoti((prev: NotificationItem[]) => prev.map(p => {
                return {
                    ...p,
                    read: p.notiId && notiIds.includes(p.notiId) ? true : p.read
                }
            }))

        }
    }, [user?.getStreamTokens])

    const notificationDeleteHandler = useCallback((noti: NotificationItem) => {
        const getStreamTokens = user?.getStreamTokens || getGetStreamTokens();
        if (!getStreamTokens) return;
        const feedClient = getNotificationClient(getStreamTokens);
        feedClient?.removeActivity(noti.id)
    }, [])

    const clearAllHandler = useCallback(async (notiData: NotificationItem[]) => {
        const { isConfirmed } = await Swal.fire({
            icon: "warning",
            title: "Delete All",
            text: "Are you sure you want to delete all notifications?",
            showCancelButton: true,
            cancelButtonText: "No",
            focusCancel: true,
            confirmButtonText: "Yes, Delete All",
            confirmButtonColor: palette.red
        })
        if (!isConfirmed) return;
        const getStreamTokens = user?.getStreamTokens || getGetStreamTokens();
        if (!getStreamTokens) return;
        const feedClient = getNotificationClient(getStreamTokens);
        notiData.forEach(async noti => {
            await feedClient?.removeActivity(noti.id);
        })
    }, [])

    const toTitlesHandler = async () => {
        setIsOptionsOn(false)
        if (editUserDirty) {
            const { isConfirmed } = await confirmLeaveOnDirty()
            if (!isConfirmed) {
                return;
            }
        }
        navigate(visitHome())
    }

    const toUsersHandler = () => {
        setIsOptionsOn(false)
        navigate(visitUsersAdmin())
    }

    const confirmLeaveOnDirty = async () => Swal.fire({
        icon: "warning",
        title: "Unsaved changes",
        text: "Are you sure you want to cancel with unsaved changes?",
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: "Yes, discard changes",
        cancelButtonText: "No, don't cancel",
        reverseButtons: true
    })

    const accountChangeHandler = async (accountId: string) => {
        if (editUserDirty) {
            const { isConfirmed } = await confirmLeaveOnDirty()
            if (!isConfirmed) {
                return;
            }
            dispatch(setEditUserId(''))
        }
        dispatch(setActiveAccount(accountId))

        // stay on the user admin view, if already on it:
        if (pathname.startsWith(`/dashboard/users`)) {
            navigate(visitUsersAdmin())
        }
        else {
            navigate(visitHome());
        }
    }

    const logoutHandler = () => {
        dispatch(logout())
    }

    return <>
        {/* TODO Figure out why the styling of this element seems to break the navigator. */}
        {/* vvv */}
        <div className="logo-and-controls pt-3 pb-3 pr-3 force-to-almost-front-of-ui is-flex is-justify-content-space-between">
            <img onClick={backToDashboardHandler} src="/LakesideScoutLogo.svg" title={githubString} className="is-align-self-center is-clickable" alt="" />
            <div className="is-align-self-center is-vcentered is-flex">
                <Foco onClickOutside={() => setIsOptionsOn(false)}>
                    <div className={classNames("dropdown", { "is-active": isOptionsOn })}>
                        <div className="dropdown-trigger is-flex">
                            <FaCog onClick={() => setIsOptionsOn(prev => !prev)} className="px-1 has-text-grey-light is-clickable" size={25} />
                        </div>
                        <div className="dropdown-menu">
                            <div className="dropdown-content">
                                <a onClick={toTitlesHandler} className="dropdown-item">Titles</a>
                                {
                                    userHasPermission(UserPermission.User_Edit, user) &&
                                    <a onClick={toUsersHandler} className="dropdown-item">Users</a>
                                }
                            </div>
                        </div>
                    </div>
                </Foco>
                <FaInfoCircle className="px-1 has-text-grey-light is-clickable" size={25} />
                {
                    !isKeyCloak() ? <FaPowerOff className="px-1 has-text-grey-light is-clickable" size={25} onClick={logoutHandler} /> : null
                }
                <Notification shine={hasUnread} setActive={setShowNoti} active={showNoti} deleteHandler={notificationDeleteHandler} data={allNoti} clickHandler={notificationClickHandler} readAllHandler={readAllClickHandler} clearAllHandler={clearAllHandler} />
            </div>
        </div >
        {/* ^^^ */}
        < section className="is-flex is-flex-direction-column h-full" style={{ borderTop: "1px solid #8e9aa3" }
        }>
            <div className="columns fix-padding px-2 pt-1 mb-0">
                <div className="column remove-unnecessary-padding is-three-fifths px-2">
                    <div>
                        <div className="help m-0 mr-2 has-text-white is-size-9">Account ({getAccountTypeName(user?.AccountType)})</div>
                        <div className="custom-select-dark control">
                            <div className="select is-small half-width">
                                <select data-testid="account-combobox" value={activeAccountId} onChange={(e) => accountChangeHandler(e.target.value)}>
                                    {
                                        user?.Accounts.map(Account => <option key={Account.AccountID} value={Account.AccountID}>{Account.AccountName}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column remove-unnecessary-padding px-2">
                    <div>
                        <div className="help m-0 mr-2 has-text-white is-size-9">User</div>
                        <div className="has-text-white">
                            <input className="input user-name-control-field is-size-10 is-small is-dark has-text-white has-background-dark" value={user?.DisplayName} multiple readOnly />
                        </div>
                    </div>
                </div>
            </div>
            {props.children}
        </section >
    </>
}

export default Sidebar
