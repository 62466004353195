import axios from "axios";
import { logout } from "../store/authSlice";
import { store } from "../store";

const customFetch = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    withCredentials: true
})

// response interceptor for responses outside of the 2xx range,
// to check 401 responses
customFetch.interceptors.response.use(undefined,
    function(error) {
        const responseObj = error?.response?.data // the response object/message from API
        const status = error?.toJSON()?.status
        if (status === 401 && responseObj) {
            if (responseObj["Error"] === "Invalid request, no login") {
                store.dispatch(logout());
                return;
            }
        }
        else {
            // pass the error object on like normal if it's not a no-login error 
            return Promise.reject(error);
        }
    })

export default customFetch