import { all, takeLatest, takeLeading } from "redux-saga/effects"
import { login, loginSession, logout, setActiveAccount } from "../store/authSlice"
import { getJobById, setJobData, setJobId, setJobIdIfJobHasChanged } from "../store/jobSlice";
import { changePagePosition, deleteSelectedPages, duplicateSelectedPages, moveToPart, replacePage, updateSelectedPagesApproval } from "../store/pagesSlice";
import { search, setPageNumber, setSortBy, setStatus, setTitleType } from "../store/searchSlice";
import { loginUser, loginUserSession, logoutUser, setUserAccount } from "./authSaga";
import { getJobData, getJobUpdate, getJobDataAndRefetchIfModified } from "./jobSaga";
import { changePagePositionSaga, deleteSelectedPagesSaga, duplicateSelectedPagesSaga, moveToPartSaga, replacePageSaga, updateSelectedPagesApprovalSaga } from "./pagesSaga";
import { searchTitles } from "./searchSaga";

function* rootSaga() {
    yield all([
        takeLatest(login.type, loginUser),
        takeLatest(loginSession.type, loginUserSession),
        takeLeading(logout.type, logoutUser),
        takeLatest(setActiveAccount.type, setUserAccount),
        takeLatest(setJobId.type, getJobData),
        takeLatest(getJobById.type, getJobData),
        takeLatest(setJobData.type, getJobUpdate),
        takeLatest(setJobIdIfJobHasChanged.type, getJobDataAndRefetchIfModified),
        takeLatest([
            search.type,
            setSortBy.type,
            setTitleType.type,
            setStatus.type,
            setPageNumber.type
        ], searchTitles),
        takeLatest(deleteSelectedPages.type, deleteSelectedPagesSaga),
        takeLatest(updateSelectedPagesApproval.type, updateSelectedPagesApprovalSaga),
        takeLatest(duplicateSelectedPages.type, duplicateSelectedPagesSaga),
        takeLatest(moveToPart.type, moveToPartSaga),
        takeLatest(changePagePosition.type, changePagePositionSaga),
        takeLatest(replacePage.type, replacePageSaga),
    ])
}

export default rootSaga