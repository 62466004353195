import { Area, Box } from '../_types/LSCScout.type';

const rectToBox = ([x, y, width, height]: Area): Box => ({
	x,
	y,
	width,
	height
});

export default rectToBox;
