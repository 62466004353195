import { omit } from 'lodash';
import React, {
	ChangeEvent,
	DetailedHTMLProps,
	InputHTMLAttributes,
	useCallback,
	useMemo,
	useState
} from 'react';
import convertUnits, { Unit } from '../_utils/convertUnits';

interface MultiUnitInputProps
	extends Omit<
		DetailedHTMLProps<
			InputHTMLAttributes<HTMLInputElement>,
			HTMLInputElement
		>,
		'type' | 'value' | 'onChange'
	> {
	modelUnits: Unit;
	displayUnits: Unit;
	value: number | string;
	onChange: (value: string) => void;
}

// RegExp to check if a user input in a number field has a trailing zero after a decimal point.
const trailingZeroRe = /^(0|^[1-9]\d*)\.\d*0$/

// A second RegExp to test a value with, if it passes an initial test with trailingZeroRe:
// this will indicate whether or not to append a string consisting of a decimal point followed by 
// all trailing zeroes to the input field string, as opposed to just appending the string of trailing zeroes 
const directlyAfterDecimalRe = /\.0+$/

// A third RegExp to get the full string section of all trailing zeroes,
// for a value that passes an initial test with trailingZeroRe.
const trailingZeroesSliceRe = /0*$/

// The <input> field's internal validation seems to already handle the case of only allowing one 
// decimal place character ('.') in the value

type TrailingZeroInputData = {
	trailingZeroesSlice: string,
	directlyAfterDecimal: boolean
} | undefined;

export default function MultiUnitInput(props: MultiUnitInputProps) {
	const { modelUnits, displayUnits, value, onChange } = props;

	const [trailingZeroInput, setTrailingZeroInput] = useState<TrailingZeroInputData>(undefined)

	const internalChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {

			// reset trailing zero detection state object
			setTrailingZeroInput(undefined)

			const v = e.target.value;
			
			if (v.trim() === '' || isNaN(parseInt(v))) { 
				onChange('');
			}
			else {
				if (trailingZeroRe.test(v)) {
					setTrailingZeroInput({
						trailingZeroesSlice: trailingZeroesSliceRe.exec(v)?.[0] || '',
						directlyAfterDecimal: directlyAfterDecimalRe.test(v)
					})
				}
				// console.log(`${displayUnits} -> ${modelUnits}`)
				onChange(convertUnits(displayUnits, modelUnits, v));
			}
		},
		[displayUnits, modelUnits, onChange]
	);

	const internalValue = useMemo(() => {
		
		if (
			value.toString().trim() === '' ||
			isNaN(parseFloat(value.toString())) 
		)
			return '';
		else {
			const convertedValue = convertUnits(modelUnits, displayUnits, value)

			if (modelUnits !== displayUnits && trailingZeroInput) {
				const { trailingZeroesSlice: trailingZeroes, directlyAfterDecimal } = trailingZeroInput	
				if (trailingZeroes.length > 0) {
					return (directlyAfterDecimal ? `${convertedValue}.${trailingZeroes}` : `${convertedValue}${trailingZeroes}`);
				}
			}
			return convertedValue;
		}
	}, [displayUnits, modelUnits, value, trailingZeroInput]);

	return (
		<input
			type="number"
			value={internalValue}
			onChange={internalChange}
			{...omit(props, [
				'modelUnits',
				'displayUnits',
				'value',
				'onChange'
			])}
		/>
	);
}
