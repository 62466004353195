import React from 'react';
import convertUnits, { Unit } from '../_utils/convertUnits';
import toStrictFractionDigitPrecision from "../_utils/toStrictFractionDigitPrecision"

interface QuantityProps {
	from: Unit;
	to: Unit;
	value: number;
	precision?: number;
}

export default function Quantity(props: QuantityProps) {
	const {
		value,
		from: sourceUnits = 'points',
		to: targetUnits = 'inches',
		precision
	} = props;

	const convertedValue = convertUnits(sourceUnits, targetUnits, value);
		
	if (precision !== undefined)
		return <>{toStrictFractionDigitPrecision(convertedValue, precision)}</>;
	else return <>{convertedValue.toString()}</>;
}
