import React from 'react';
import {
	FaExclamationTriangle,
	FaFile,
	FaFont,
	FaImage,
	FaPaintBrush,
	FaRegFile,
	FaVectorSquare
} from 'react-icons/fa';
import { IconType } from 'react-icons/lib';
// import { RuleCategory } from '../_utils/RuleMetadataExtractor';

interface RuleIconProps {
	category: any;
	className?: string;
}

const iconMap: Record<any, IconType> = {
	page: FaFile,
	image: FaImage,
	color: FaPaintBrush,
	text: FaFont,
	format: FaRegFile,
	vector: FaVectorSquare,
	undefined: FaExclamationTriangle
};

export default function RuleIcon({ category, className }: RuleIconProps) {
	const Icon = iconMap[category];
	return <Icon className={className} />;
}
