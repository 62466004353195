import classNames from "classnames"
import { MouseEvent, useEffect, useMemo, useRef, useState } from "react"
import Foco from "react-foco"
import { FaArrowCircleLeft, FaCopy, FaDownload, FaEdit, FaTrash, FaUserPlus } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import Swal from "sweetalert2"
import AccountDataApi from "../lib/accountdata"
import JobApi from "../lib/job"
import PagesApi from "../lib/pages"
import { visitHome, visitJob } from "../routes/visit"
import { setActiveAccount, useAuthState } from "../store/authSlice"
import { setJobId, useJob } from "../store/jobSlice"
import { JobTypes } from "../types/job.types"
import { PreflytProcessName } from "../types/jobApi.types"
import { JobStatus } from "../types/types"
import { AccountTypes, UserPermission } from "../types/user.types"
import { swalButtonColorsPalette as palette } from "../utils/constants"
import { hasComponents, jobHasType } from "../utils/job"
import { userHasAccountType, userHasJob, userHasPermission, userOwnsJob } from "../utils/user"
import CommonTitles from "./CommonTitles"
import CopyTitle from "./CopyTitle"
import InviteUser from "./InviteUser"

function PreviewOptions() {
    const { job, jobIsActive } = useJob()

    const { user, activeAccountId } = useAuthState()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [editMenuOpen, setEditMenuOpen] = useState(false);
    const [downloadMenuOpen, setDownloadMenuOpen] = useState(false);

    const [inviteUserOpen, setInviteUserOpen] = useState(false)
    const [inviteSending, setInviteSending] = useState(false)

    const toggleInviteUser = (wasOpened: boolean) => {
        if (wasOpened) {
            if (inviteUserOpen) return;
            if (!userHasPermission(UserPermission.Job_Invite, user)) {
                Swal.fire({
                    icon: "info",
                    title: "Insufficient permissions",
                    text: "You don't have the required permissions to invite users to a job.",
                })
                return;
            }
        }
        setInviteUserOpen(() => wasOpened)
    }

    const [copyTitleOpen, setCopyTitleOpen] = useState(false)
    const [commonTitlesOpen, setCommonTitlesOpen] = useState(false)

    const [userHasTitle, setUserHasTitle] = useState(false)

    const downloadTepTitleBtnRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        let cancelled = false;

        async function checkIfUserHasCurrentJob() {
            if (user && job) {
                const result = await userHasJob(user, job, activeAccountId)
                if (cancelled) {
                    return;
                }
                setUserHasTitle(result)
            }
        }

        checkIfUserHasCurrentJob()

        return (() => {
            cancelled = true;
        })
    }, [user, job, activeAccountId])

    const editTitleRecordHandler = (jobId: string) => (e: MouseEvent<HTMLAnchorElement>) => {
        if (jobIsActive) {
            navigate(visitJob({ jobId, edit: true }))
        }
    }

    const deleteTitlehandler = (jobId: string) => async (e: MouseEvent<HTMLDivElement>) => {
        if (
            userHasPermission(UserPermission.Job_DeleteAny, user) ||
            (userOwnsJob(user, job) && userHasPermission(UserPermission.Job_DeleteMine, user))
        ) {
            const { isConfirmed } = await Swal.fire({
                icon: "warning",
                title: "Are you sure?",
                text: "Do you really want to delete this title?",
                confirmButtonText: "Yes, delete it!",
                confirmButtonColor: palette.red,
                showCancelButton: true,
                reverseButtons: true,
                focusCancel: true
            })
            if (!isConfirmed) return;
            JobApi.deleteJob(jobId)
                .then(res => {
                    navigate(visitHome())
                    Swal.fire({
                        icon: "success",
                        title: "Successfully deleted title."
                    })
                    dispatch(setJobId(""))
                    dispatch(setActiveAccount(activeAccountId)) // causes refetch of account's jobs, keeping deleted job from showing in titles list under an outdated status filter
                })
                .catch(err => {
                    Swal.fire({
                        icon: "error",
                        title: "Failed to delete title!"
                    })
                })
        } else {
            Swal.fire({
                icon: "info",
                title: "Not enough permission",
                text: "You don't have permission to delete this title"
            })
        }
    }

    const downloadOriginalsHandler = async () => {
        if (!job?.JobID) return;
        const { isConfirmed } = await Swal.fire({
            title: "Please Confirm",
            text: "Download the originals for this title?",
            confirmButtonText: "Download",
            showCancelButton: true,
            reverseButtons: true,
            focusCancel: true
        })
        if (!isConfirmed) return;
        setDownloadMenuOpen(false);
        Swal.fire({
            title: "Please wait...",
            text: "Generating originals",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        })
        JobApi.downloadOriginal(job.JobID)
            .then((res) => {
                Swal.close();
                const link = document.createElement("a");
                link.href = res;
                link.setAttribute("download", `${job.Title} originals.zip`);
                link.click();
            })
            .catch((err) => {
                console.log(err);
                Swal.close();
                Swal.fire({
                    title: "Download Error",
                    icon: "error",
                    text: "There was an error downloading the original file"
                })
            })
    }

    const downloadTepHandler = async () => {
        if (!job?.JobID) return;
        if (downloadTepTitleBtnRef?.current) {
            // Blurs (removes focus from) the download title button element - because if focus is maintained, the tooltip will reappear after the download dialog is cancelled.
            downloadTepTitleBtnRef.current.blur();
        }
        const { isConfirmed } = await Swal.fire({
            title: "Please Confirm",
            text: "Download the files for this title?",
            confirmButtonText: "Download",
            showCancelButton: true,
            reverseButtons: true,
            focusCancel: true
        })
        if (!isConfirmed) return;
        setDownloadMenuOpen(false);
        Swal.fire({
            title: "Please wait...",
            text: "Generating title files download",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        })
        JobApi.downloadTep(job.JobID)
            .then((res) => {
                Swal.close();
                const link = document.createElement("a");
                link.href = res;
                link.setAttribute("download", `${job.Title}.zip`);
                link.click();
            })
            .catch((err) => {
                console.log(err);
                Swal.close();
                Swal.fire({
                    title: "Download Error",
                    icon: "error",
                    text: "There was an error downloading the title's files."
                })
            })
    }

    const requestProofHandler = () => {
        // block/disable if any part of job is locked, or not?
        if (!jobIsActive) {
            return;
        }
        setDownloadMenuOpen(false);
        if (!job?.JobID) return;
        PagesApi.requestProof(job.JobID)
            .then(() => {
                Swal.fire({
                    icon: "success",
                    title: "Proof Requested",
                    text: "Your proof has been requested. A link to the proof downloads will be sent via your email address when the proof is ready for download."
                })
            })
            .catch((err) => {
                console.error(err);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "There was an error requesting proof. Try again"
                })
            })
    }

    const addToOrRemoveFromMyTitlesHandler = async () => {
        if (jobIsActive) {
            setEditMenuOpen(false)
            if (job && job.JobID && user) {
                return await JobApi.addToOrRemoveFromMyJobs(job.JobID, userHasTitle)
                    .then(res => {
                        dispatch(setActiveAccount(activeAccountId))
                        return Swal.fire({
                            icon: "success",
                            title: `Title ${userHasTitle ? 'removed from ' : 'added to '}My Titles.`,
                            timer: 4000,
                            toast: true,
                            position: 'top',
                            showClass: {
                                popup: 'swal-popover-in'
                            },
                            hideClass: {
                                popup: 'swal-popover-out'
                            },
                            showCloseButton: true,
                            showConfirmButton: false,
                            grow: 'row'
                        })
                    })
                    .catch(err => {
                        console.log(err)
                        Swal.fire({
                            title: `Error ${userHasTitle ? 'removing title from ' : 'adding title to '}My Titles`,
                            icon: "error",
                            text: `Something went wrong while attempting to ${userHasTitle ? 'remove ' : 'add '}the selected title ${userHasTitle ? 'from ' : 'to '}the My Titles List.`
                        })
                    })
            }
        }
    }

    // TODO consider if anything should be changed about the fact that this event handler and the component card event handler (make corrections to single part)
    // are basically using the same code, except for 1-2 extra fields and UI display changes.
    // If it doesn't overcomplicate things, one way to 'consolidate' this code would be to:
    // - replace the handlers with just a single event handler 'above' both of these components, up at the Preview.tsx level.
    // 'make corrections' events fired at either level could just be passed up the component chain to the one event handler.
    // The limited diffs in the two handlers just made me think that doing the above could be a preferred option for maintainability/readability, etc.
    // I would like someone's opinion on this.

    const makeCorrectionsToJobHandler = async () => {
        // block if not in combined account
        if (!userHasAccountType(AccountTypes.Combined, user)) {
            return;
        }
        // TODO - Re: the below conditional that checks 'user', etc.:
        // Is there a uniform way that the app should handle the case of expected Redux state store values *not being found/not being defined*?
        // I am led to think that maybe if an expected Redux state variable like 'user' is not available when this handler runs -
        // it would be a good idea to trigger an app 'soft refresh' and try to 'refetch' the state store data.
        // --
        // I will defer this to a higher-level discussion outside of just myself, since this type of 'error' could occur almost everywhere in the front end. 
        if (job?.JobID && user && activeAccountId) {
            const correctionsAcctNameFetch: string | undefined = await AccountDataApi.getCorrectionsAcctDispNameByCombinedAcctId(activeAccountId, user)
                .then(result => {
                    if (typeof result === 'string') return result;
                    return undefined;
                })
                .catch(err => {
                    console.log(err);
                    return undefined;
                })

            return await JobApi.runPreflytProcessOnPartOfJob(job.JobID, {
                processname: PreflytProcessName.TEP2Scout
            })
                .then(() => {
                    return Swal.fire({
                        icon: "info",
                        text: `The selected Job is currently migrating to ${correctionsAcctNameFetch ? `account "${correctionsAcctNameFetch}" ` : 'its associated Corrections account '}where corrections can be made.`,
                    });
                })
                .catch(err => {
                    const errMsgFromBe: string | undefined = err.response?.data?.["Error"]

                    return Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: errMsgFromBe || "An error occurred and the job could not be migrated to its associated corrections account."
                    });
                })
        }
    }

    // Render different tooltip text depending on whether or not user's active account allows Make Corrections
    const tooltipText: string = useMemo(() => (!userHasAccountType(AccountTypes.Combined, user) ? "Select Combined account to enable Make Corrections." : "Check out"), [activeAccountId])

    return <>
        {
            job &&
            <div className="preview-option-buttons is-flex is-flex-wrap-no-wrap">
                {
                    (
                        // Make Corrections can only be accessed by a Combined/'umbrella' account.
                        userHasAccountType([AccountTypes.Combined, AccountTypes.TEP], user)
                        &&
                        userHasPermission(UserPermission.Job_Edit, user)
                        &&
                        jobHasType(JobTypes.TEP, job)
                        &&
                        jobIsActive
                    )
                    &&
                    <>
                        <div data-tip={tooltipText} data-for="make-corrections-job-tip" data-delay-show="250" onClick={makeCorrectionsToJobHandler} className={classNames("button is-small is-link is-rounded ml-2 no-not-allowed-icon", { "disabled-custom-control": !userHasAccountType(AccountTypes.Combined, user) })}>
                            <div className="icon fill-svg-warning "> <FaArrowCircleLeft size={20} style={{ backgroundColor: "white", borderRadius: "50%" }} /> </div>
                        </div>
                        <ReactTooltip id="make-corrections-job-tip" place="bottom" />
                    </>
                }
                {
                    job.Type === JobTypes.Book &&
                    <>
                        {
                            (userHasPermission(UserPermission.Job_Invite, user) &&
                                userHasAccountType([AccountTypes.Combined, AccountTypes.Book], user) &&
                                job.Status === JobStatus.Active) &&
                            <>
                                <Foco onClickOutside={() => toggleInviteUser(false)}>
                                    <div className={classNames("dropdown is-right", { "is-active": inviteUserOpen })}>

                                        <div id={'popover-anchor'} className="dropdown-trigger">

                                            <div data-tip="Invite users to title" data-for="invite-tooltip" data-delay-show="250" onClick={() => toggleInviteUser(true)} className={classNames("button is-small is-link is-rounded ml-2", { "is-loading": inviteSending })}>
                                                <FaUserPlus size={23} />
                                                {!inviteUserOpen && <ReactTooltip id="invite-tooltip" place="bottom" />}
                                            </div>
                                            {inviteSending && (<p className='invite-loading-text'>Sending...</p>)}

                                            {inviteUserOpen &&
                                                <InviteUser isOpen={inviteUserOpen} toggleOpen={(isOpen: boolean) => toggleInviteUser(isOpen)} toggleSending={(isSending) => setInviteSending(isSending)} />
                                            }

                                        </div>

                                    </div>
                                </Foco>
                            </>
                        }
                    </>
                }
                {

                    (
                        userHasAccountType(AccountTypes.Combined, user) &&
                        // jobHasType(JobTypes.TEP, job) && 
                        // TODO ^^^ why was the above ever added to disable the copy button for TEP jobs?
                        // It looks like the back end supports copying TEP jobs.
                        job.Status === JobStatus.Active &&
                        hasComponents(job) &&
                        userHasPermission(UserPermission.Job_Copy, user)
                    )
                    &&
                    <>
                        <div data-tip={"Copy title"} data-for="copy-tooltip" data-delay-show="250" onClick={() => setCopyTitleOpen(oldVal => !oldVal)} className="button is-small is-link is-rounded ml-2">
                            <FaCopy size={20} />
                        </div>
                        <ReactTooltip id="copy-tooltip" place="bottom" />
                        {copyTitleOpen && (<CopyTitle isOpen={copyTitleOpen} toggleOpen={(value) => setCopyTitleOpen((oldVal) => (value ? value : !oldVal))} />)}
                    </>
                }
                {
                    (
                        userHasAccountType([AccountTypes.Combined, AccountTypes.Book], user) &&
                        !jobHasType(JobTypes.TEP, job)
                    )
                    &&
                    <Foco onClickOutside={() => setEditMenuOpen(false)}>
                        <div className={classNames("dropdown is-right", { "is-active": editMenuOpen })}>
                            <div className="dropdown-trigger is-flex">
                                <>
                                    <div data-tip={"Edit title"} data-for="edit-menu-tooltip" data-delay-show="250" onClick={() => setEditMenuOpen(true)} className="button is-small is-link is-rounded ml-2">
                                        <FaEdit size={20} />
                                    </div>
                                    {!editMenuOpen && <ReactTooltip id="edit-menu-tooltip" place="bottom" />}
                                </>
                            </div>
                            <div className="dropdown-menu" role="menu">
                                <div className="dropdown-content">
                                    {
                                        userHasPermission(UserPermission.Job_Edit, user) &&
                                        <a href="#" onClick={editTitleRecordHandler(job.JobID)} className={classNames("dropdown-item", { "disabled-custom-control": !jobIsActive })}>
                                            Edit Title record
                                        </a>
                                    }
                                    <a href="#" onClick={() => {
                                        setCommonTitlesOpen(oldVal => !oldVal);
                                        setEditMenuOpen(false);
                                    }
                                    } className="dropdown-item">
                                        Common Titles
                                    </a>
                                    {
                                        user && userHasPermission(UserPermission.Job_List, user) &&
                                        <a href="#" onClick={async () => await addToOrRemoveFromMyTitlesHandler()} className={classNames("dropdown-item", { "disabled-custom-control": !jobIsActive })}>
                                            {userHasTitle ? 'Remove from ' : 'Add to '}My Titles
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                    </Foco>
                }
                {
                    userHasPermission(UserPermission.Job_Download, user) &&
                    job.Status === JobStatus.Active &&
                    hasComponents(job) &&
                    <>
                        {!jobHasType(JobTypes.TEP, job) &&
                            <Foco onClickOutside={() => setDownloadMenuOpen(false)}>
                                <div className={classNames("dropdown is-right", { "is-active": downloadMenuOpen })}>
                                    <div className="dropdown-trigger is-flex">
                                        <div data-tip="Download title files" data-for="download-tooltip" data-delay-show="250" onClick={() => setDownloadMenuOpen(true)} className="button is-small is-link is-rounded ml-2">
                                            <FaDownload size={20} />
                                            {!downloadMenuOpen && <ReactTooltip id="download-tooltip" place="bottom" />}
                                        </div>
                                    </div>
                                    <div className="dropdown-menu" role="menu">
                                        <div className="dropdown-content">
                                            <a href="#" onClick={downloadOriginalsHandler} className="dropdown-item">
                                                Download Originals
                                            </a>
                                            <a href="#" onClick={requestProofHandler} className="dropdown-item">
                                                Download Proof
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Foco>
                        }
                        {(jobHasType(JobTypes.TEP, job) && (job && jobIsActive)) &&
                            <button ref={downloadTepTitleBtnRef} data-tip="Download title files" data-for="tep-download-tooltip" data-delay-show="250" onClick={downloadTepHandler} className="button is-small is-link is-rounded ml-2">
                                <FaDownload size={20} />
                                <ReactTooltip id="tep-download-tooltip" place="bottom" />
                            </button>
                        }
                    </>
                }
                {
                    user &&
                    (
                        (
                            (job.Status !== JobStatus.Locked && job.Status !== JobStatus.Processing)
                            &&
                            (
                                (userOwnsJob(user, job) && userHasPermission(UserPermission.Job_DeleteMine, user))
                                ||
                                (userHasPermission(UserPermission.Job_DeleteAny, user))
                            )
                        )
                        ||
                        (
                            job.Status === JobStatus.Locked
                            &&
                            userHasPermission(UserPermission.Job_DeleteLocked, user)
                        )
                    )
                    &&
                    <div data-tip="Delete title" data-for="delete-tooltip" data-delay-show="250" onClick={deleteTitlehandler(job.JobID)} className="button is-small is-link is-rounded ml-2">
                        <FaTrash size={20} />
                        <ReactTooltip id="delete-tooltip" place="bottom" />
                    </div>
                }
                {commonTitlesOpen && (<CommonTitles isOpen={commonTitlesOpen} toggleOpen={(value) => setCommonTitlesOpen((oldVal) => (value ? value : !oldVal))} />)}
            </div>
        }
    </>
}

export default PreviewOptions
