import React, { CSSProperties, useMemo } from 'react';
import parseBox from '../_utils/parseBox';
import { Page as IPage } from '../_types/LSCScout.type';
import * as pt from 'prop-types';
import classNames from 'classnames';
import outset from '../_utils/outsetBox';
import convertUnits from '../_utils/convertUnits';

interface BaseProps {
	page: IPage;
	image?: 'Image' | 'Thumb';
	alt?: string;
	style?: CSSProperties;
	className?: string;
}

interface TargetDimensionsProps extends BaseProps {
	width: number;
	height: number;
}

interface ScaleProps extends BaseProps {
	scale: number;
}

type PagePreviewProps = TargetDimensionsProps | ScaleProps;

const propTypes = {
	page: pt.object.isRequired,
	image: pt.string,
	scale: pt.number,
	alt: pt.string,
	style: pt.object,
	className: pt.string,
	width: pt.number,
	height: pt.number
};

function isScaleMode(props: PagePreviewProps): props is ScaleProps {
	return 'scale' in props && props.scale !== undefined;
}

function isTargetMode(props: PagePreviewProps): props is TargetDimensionsProps {
	return (
		'width' in props &&
		props.width !== undefined &&
		'height' in props &&
		props.height !== undefined
	);
}

export default function PagePreview(props: PagePreviewProps) {
	const { page, image = 'Image', alt, style = {}, className } = props;

	const box =
		page.SelectedBox === 'Custom'
			? parseBox(page.CustomBox!)
			: parseBox(page.TrimBox || page.MediaBox);

	const bleedOffset = convertUnits('inches', 'points', 0.125);

	const bleedBox = outset(box, bleedOffset);

	const media = parseBox(page.MediaBox);

	const { HorizontalPercent = 1, VerticalPercent = 1 } = page.Scale || {};

	const scale = useMemo(() => {
		if (isScaleMode(props)) return props.scale;
		else if (isTargetMode(props)) {
			const widthRatio = box.width / props.width;
			const heightRatio = box.height / props.height;

			const max = Math.max(widthRatio, heightRatio);

			return 1 / max;
		} else return 1;
	}, [box.height, box.width, props]);

	return (
		<div
			style={{
				width: +bleedBox.width * scale,
				height: +bleedBox.height * scale,
				backgroundColor: 'white',
				position: 'relative',
				overflow: 'hidden',
				...style
			}}
			className={classNames('thumbv2', className)}
		>
			<img
				className="block-invasive-style-rule"
				alt={alt}
				src={page[image]}
				style={{
					width: media.width * scale * HorizontalPercent,
					height: media.height * scale * VerticalPercent,
					display: 'block',
					position: 'absolute',
					bottom: (-box.y + bleedOffset + media.y) * scale,
					left: (-box.x + bleedOffset + media.x) * scale
				}}
			/>
		</div>
	);
}

PagePreview.propTypes = propTypes;
