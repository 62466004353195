
export enum BatchPageOperationTypes {
    copy = "copy",
    delete = "delete",
    move = "move",
    refolio = "refolio",
    setvalue = "setvalue"
}

export interface BatchPageUpdateOptions {
    type: BatchPageOperationTypes,
    ordinal: number,
    count: number,
    part: string,
    destination?: number,
    destinationpart?: string,
    folio?: string,
    tag?: string,
    value?: {[k: string]: any} // TODO - either exclude this field if "setvalue" is never used, or assign a better type to it if "setvalue" is used 
}