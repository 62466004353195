import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UrlPattern from "url-pattern";
import { visitJob } from "../../../routes/visit";
import { useAuthState } from "../../../store/authSlice";
import { useJob } from "../../../store/jobSlice";
import useLocation from "../_components/LocationProvider";


interface Params {
  AccountID: string;
  JobID: string;
  ComponentName: string;
  PageOrdinals: number[];
}


export default function usePageViewNavigation() {
  const [location, setLocation] = useLocation();

  const { user } = useAuthState();
  const { job, pages } = useJob();
  const { part, ordinal } = useParams();
  const navigate = useNavigate();

  const params = useMemo(() => {
    const p: Params = {
      AccountID: user?.Account || "",
      JobID: job?.JobID || "",
      ComponentName: part || "",
      PageOrdinals: ordinal
        ? ordinal.split(",").map((o) => parseInt(o, 10))
        : [],
    };

    return p;
  }, [location]);

  const navigateToPages = useCallback(
    (pageOrdinals: number[]) => {
      navigate(visitJob({ jobId: job?.JobID, part, ordinal: pageOrdinals }));
    },
    [job?.JobID, part, navigate]
  );

  return { params, navigateToPages };
}
