import { Box } from '../_types/LSCScout.type';

export default function inset(box: Box, amount: number) {
	return {
		x: box.x + amount,
		y: box.y + amount,
		width: box.width - amount * 2,
		height: box.height - amount * 2
	};
}
