import { useCallback, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useDispatch } from 'react-redux';
import { actorNameRecord, populateNameRecord } from './components/ViewHistory/historyEntryTransforms';
import { ScoutRoutes } from './routes/ScoutRoutes';
import { useAuthState } from "./store/authSlice";
import { setJobIdIfJobHasChanged, useJob } from './store/jobSlice';
import { initPartTree } from './utils/parts';

function App() {

  const { user, isAuthenticated } = useAuthState()

  const { job, jobId } = useJob()

  const dispatch = useDispatch()

  useEffect(() => {
    initPartTree();
  }, [])

  useEffect(() => {
    if (user && Object.keys(actorNameRecord).length === 0) {
      populateNameRecord()
    }
  }, [user])

  // Show and hide groove widget;
  useEffect(() => {
    if (!window.groove) return;
    const loadedWidgets = Object.values(window.groove.loadedWidgets || {});
    if (isAuthenticated) {
      if (loadedWidgets.length) return;
      const grooveWidgetId: string | undefined = process.env.REACT_APP_GROOVE_WIDGET_ID as string;
      if (!grooveWidgetId) return;
      window.groove.widget = window.groove.createWidget();
      window.groove.widget.init(grooveWidgetId, {});
    } else {
      loadedWidgets.forEach(widget => {
        widget.destroy();
      })
    }
  }, [isAuthenticated])

  const handleFocus = useCallback(() => {
    if (job && job.JobID) {
      // Only refetch the job on focus if the Modified timestamp on the fetched job is different from the current state job's Modified timestamp
      dispatch(setJobIdIfJobHasChanged(job.JobID))
    }
  }, [job, jobId])

  useEffect(() => {
    window.addEventListener('focus', handleFocus)        
    return () => {
      window.removeEventListener('focus', handleFocus)
    }
  }, [job, jobId])

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ScoutRoutes />
    </QueryClientProvider>
  );
}

export default App;
