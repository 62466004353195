import {
  connect,
  FeedAPIResponse,
  NotificationActivityEnriched,
  UnknownRecord,
} from "getstream";
import { User } from "../types/user.types";

let streamClient: ReturnType<typeof connect>;

const getStreamTokensKey = "getstreams-tokens";
export const setGetStreamTokens = (
  getGetStreamTokens: User["getStreamTokens"]
) => {
  localStorage.setItem(getStreamTokensKey, JSON.stringify(getGetStreamTokens));
};

export const getGetStreamTokens = () => {
  const data = localStorage.getItem(getStreamTokensKey);
  if (!data) return null;
  try {
    const obj = JSON.parse(data);
    return obj;
  } catch (e) {
    return null;
  }
};

export const initializeGetstreamClient = () => {
  if (streamClient) return streamClient;
  if (
    !process.env.REACT_APP_GETSTREAM_API_KEY ||
    !process.env.REACT_APP_GETSTREAM_APP_ID
  ) {
    throw new Error("envs not defined");
  }
  streamClient = connect(
    process.env.REACT_APP_GETSTREAM_API_KEY,
    null,
    process.env.REACT_APP_GETSTREAM_APP_ID
  );
  return streamClient;
};

export const getNotificationClient = (
  getStreamTokens: User["getStreamTokens"]
) => {
  const client = initializeGetstreamClient();
  if (!client || !getStreamTokens) return null;
  const notiToken = getStreamTokens.feeds.notification;
  return client.feed(notiToken.feedSlug, notiToken.userId, notiToken.token);
};

export const getUserFeed = (getStreamTokens: User["getStreamTokens"]) => {
  const feed = getNotificationClient(getStreamTokens);
  if (!feed) return null;
  return feed;
};

type FeedResponse = FeedAPIResponse<
  UnknownRecord,
  UnknownRecord,
  UnknownRecord,
  UnknownRecord,
  UnknownRecord
>;

export const flattenNotifications = (data: FeedResponse) => {
  let allNotifications: any[] = [];
  if (!data.results) return allNotifications;
  const resultData = data.results as NotificationActivityEnriched[];
  for (const result of resultData) {
    const notiId = result.id;
    const is_read = result.is_read;
    if (!result.activities) continue;
    const notiData = [result.activities]
      .flat()
      .map((a: any) => ({ ...a, notiId, is_read }));
    allNotifications = allNotifications.concat(notiData);
  }
  return allNotifications;
};
