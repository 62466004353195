import classNames from "classnames"
import debounce from "lodash/debounce"
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { AiOutlineDownCircle, AiOutlineUpCircle } from "react-icons/ai"
import { FaArrowLeft } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import HeaderBanner from "../../components/HeaderBanner"
import JobProcessingSpinner from "../../components/JobProcessingSpinner"
import usePreferences from "../../components/PageView/_hooks/usePreferences"
import PartPageThumbnail from "../../components/PartPageThumbnail"
import PartPageThumbnailDropZone from "../../components/PartPageThumbnailDropZone"
import { visitJob } from "../../routes/visit"
import { setJobId, useJob } from "../../store/jobSlice"
import { selectPage, setSelectedPages, usePages } from "../../store/pagesSlice"
import { HoldingKeys, PagePart, PageReviewStatus, PartPageThumbnailType } from "../../types/pages.types"
import { JobStatus } from "../../types/types"
import { getThumbSizeFromZoom } from "../../utils/constants"
import { pageHasErrors, pageHasWarnings } from "../../utils/page"
import { getPart } from "../../utils/parts"
import { sortObjectByDeepProp } from "../../utils/utils"
import { useAuthState } from "../../store/authSlice"

function PageOrganizer() {

    const [searchResults, setSearchResults] = useState<PartPageThumbnailType[]>([]);

    // calculated using the 'default' thumb size
    const initialThumbSize = getThumbSizeFromZoom(1)

    const [size, setSize] = useState(initialThumbSize);
    const [shelfOpen, setShelfOpen] = useState(true);

    const { job, pages } = useJob()
    const { part, jobId } = useParams()
    const { selectedPages, selectedShelfPages } = usePages()

    const dispatch = useDispatch()
    const [pref, setPref] = usePreferences()
    const ref = useRef<HTMLInputElement>(null)

    useEffect(() => {

        const newSize = getThumbSizeFromZoom(pref.pageThumbnailZoom)

        setSize(newSize);

        if (ref.current) ref.current.value = `${pref.pageThumbnailZoom}`;
    }, [pref.pageThumbnailZoom])

    useEffect(() => {
        setShelfOpen(pref.shelfOpen);
    }, [pref.shelfOpen])

    useEffect(() => {
        if (!job && jobId) dispatch(setJobId(jobId))
    }, [job, jobId, dispatch])

    useEffect(() => {
        if (!part) return;
        dispatch(setSelectedPages({ ordinals: [] }));
    }, [part, dispatch])

    const [searchData, setSearchData] = useState({
        query: '',
        warnings: false,
        errors: false,
        notApproved: false
    })

    const searchQueryChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchData(prev => ({ ...prev, query: e.target.value }))
    }

    const selectOptionChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchData(prev => ({ ...prev, [e.target.name]: e.target.checked }))
    }

    useEffect(() => {
        const partPages = (part && pages[part]) ? pages[part] : [];
        const results: PartPageThumbnailType[] = partPages
            .map(page => ({ page, visible: true }))
            .map(page => {
                const visible = page.page.Folio.search(searchData.query) > -1
                return { page: page.page, visible }
            })
            .map(page => {
                if (!searchData.warnings) return page;
                const visible = pageHasWarnings(job, part, page.page)
                return { page: page.page, visible: page.visible ? visible : page.visible }
            })
            .map(page => {
                if (!searchData.errors) return page;
                const visible = pageHasErrors(page.page)
                return { page: page.page, visible: page.visible ? visible : page.visible }
            })
            .map(page => {
                if (!searchData.notApproved) return page;
                const visible = ((!pageHasErrors(page.page)) && `${page.page.Tags?.ReviewStatus}` !== PageReviewStatus.APPROVED)
                return { page: page.page, visible: page.visible ? visible : page.visible }
            })
        setSearchResults(results);
    }, [searchData.query, searchData.warnings, searchData.errors, searchData.notApproved, pages, part, job])

    const navigate = useNavigate()

    const backClickHandler = () => {
        // manual refresh on moving back to Preview
        if (job && job.JobID) {
            dispatch(setJobId(job.JobID))
        }
        navigate(visitJob({ jobId }))
    }

    const pageClickHandler = useCallback((pageOrdinal: number, held?: HoldingKeys) => {
        dispatch(selectPage({ ordinal: pageOrdinal, held: held }));
    }, [dispatch])

    const shelfPageClickHandler = useCallback((pageOrdinal: number, held?: HoldingKeys) => {
        dispatch(selectPage({ ordinal: pageOrdinal, held: held, isShelf: true }));
    }, [dispatch])

    const outsideClickHandler = () => {
        dispatch(setSelectedPages({ ordinals: [] }));
    }

    const shelfOutsideClickHandler = () => {
        dispatch(setSelectedPages({ ordinals: [], isShelf: true }))
    }

    const sizeChangeHandler = debounce((val: string) => {
        const zoomVal = Number(val);
        setPref(prev => ({ ...prev, pageThumbnailZoom: zoomVal }));
    }, 100, { trailing: true, leading: false })

    const shelfStateChangeHandler = () => {
        setPref(prev => ({ ...prev, shelfOpen: !shelfOpen }))
        setShelfOpen(!shelfOpen)
    }

    const isShelfAvailable = useMemo(() => {
        return (pages[PagePart.UnassignedCorrections] && pages[PagePart.UnassignedCorrections].length)
    }, [pages]);

    if (!part) return null;


    return <div className="has-background-dark is-flex is-flex-direction-column has-text-light-cascading-no-important" style={{ height: "100%" }}>
        {
            job && <HeaderBanner>
                <div className="job-banner has-background-link is-flex">
                    <div onClick={backClickHandler} className="px-3 is-flex p-2 has-text-white is-clickable" style={{ backgroundColor: "#38678f", border: "1px solid #315a7d" }}>
                        <FaArrowLeft size={25} className="is-align-self-center" />
                    </div>
                    <div className="py-3 pr-3 is-flex-grow-1">
                        <div className="has-text-white ml-2 is-flex is-justify-content-space-between">
                            <div className="is-size-6 has-text-weight-bold">
                                {job.Title}
                                {job.Status === JobStatus.Processing && <JobProcessingSpinner addedClassNames="bump-spinner-down-4" />}
                            </div>
                            <div className="is-flex is-flex-direction-column is-flex-wrap-wrap">
                                <div>
                                    <input value={searchData.query} onChange={searchQueryChangeHandler} type="text" className="m-1 page-org-search input is-small" placeholder="Search" />
                                </div>
                                <div className="is-flex is-flex-direction-row is-flex-wrap-wrap">
                                    <label className="m-1 page-org-checkbox-filter-control checkbox button is-small is-warning is-flex">
                                        <input checked={searchData.warnings} name="warnings" onChange={selectOptionChangeHandler} type="checkbox" className="is-align-self-center mr-1" />
                                        <span className="is-align-self-center">Warnings</span>
                                    </label>
                                    <label className="m-1 page-org-checkbox-filter-control checkbox button is-small is-danger is-flex">
                                        <input checked={searchData.errors} name="errors" onChange={selectOptionChangeHandler} type="checkbox" className="is-align-self-center mr-1" />
                                        <span className="is-align-self-center">Errors</span>
                                    </label>
                                    <label className="m-1 page-org-checkbox-filter-control checkbox button is-small is-success is-flex">
                                        <input checked={searchData.notApproved} name="notApproved" onChange={selectOptionChangeHandler} type="checkbox" className="is-align-self-center mr-1" />
                                        <span className="is-align-self-center">Not Approved</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="columns">
                                <div className="column is-half">
                                    <nav className="level">
                                        <div className="level-left">
                                            <div className="has-text-white mr-0 px-2">
                                                <div>
                                                    <p className="help has-text-grey-light">JOB #</p>
                                                    <p className="is-size-11">{job.Tags?.SystemJobNumber || <i className="is-size-11">No Job Number</i>}</p>
                                                </div>
                                            </div>
                                            <div className="has-text-white mr-0 px-2">
                                                <div>
                                                    <p className="help has-text-grey-light">
                                                        {
                                                            (job.ISBN13 || job.ISBN10) ? 'ISBN' : job.CustomerReference ? 'CR #' : 'ISBN/CR #'
                                                        }
                                                    </p>
                                                    <p className="is-size-11">{job.ISBN13 || job.ISBN10 || job.CustomerReference || <i className="is-size-11">No ISBN or CR #</i>}</p>
                                                </div>
                                            </div>
                                            <div className="has-text-white mr-0 px-2">
                                                <div>
                                                    <p className="help has-text-grey-light">AUTHOR</p>
                                                    <p className="is-size-11">{job.Author || <i className="is-size-11">No Author</i>}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </HeaderBanner>
        }
        {
            job &&
            <>
                {
                    (job && job.Status === JobStatus.Processing) &&
                    <div className="notification is-warning m-0 mt-6 mx-5">
                        <span className="has-text-black has-text-weight-bold">Read-only while pages are being processed.</span>
                    </div>
                }
                {
                    (job && job.Status === JobStatus.Deleted) &&
                    <div className="notification is-warning m-0 mt-6 mx-5">
                        <span className="has-text-black has-text-weight-bold">Read-only because job has been deleted.</span>
                    </div>
                }
                {
                    (job.Status === JobStatus.Error && job.ErrorReason) &&
                    <div className="notification is-danger m-0 mt-6 mx-5">
                        Error: {job.ErrorReason}
                    </div>
                }
            </>
        }
        <section className="section organizer-window" onClick={outsideClickHandler}>
            <div className="title is-size-5">{getPart(part)?.name}</div>
            <div className="is-flex is-flex-wrap-wrap">
                {
                    searchResults.sort(sortObjectByDeepProp("page.Ordinal")).map(partPage => <React.Fragment key={partPage.page.PageID}><PartPageThumbnailDropZone ordinal={partPage.page.Ordinal} /> <PartPageThumbnail size={size} selected={selectedPages.includes(partPage.page.Ordinal)} onClick={pageClickHandler} key={partPage.page.PageID} visible={partPage.visible} page={partPage.page} /> </React.Fragment>)
                }
                <PartPageThumbnailDropZone ordinal={searchResults.length + 1} />
            </div>
        </section>
        {
            isShelfAvailable ?
                <section className="p-3 has-background-dark" style={{ position: "absolute", minWidth: '165px', width: 'calc(100vw - 300px)', bottom: 0, left: 301, right: 0, height: "auto", zIndex: 99999 }} onClick={shelfOutsideClickHandler}>
                    <div onClick={shelfStateChangeHandler} className="is-flex pl-1 is-clickable ml-3">
                        <div className="title has-text-light is-size-6 mb-0 mr-2 is-align-self-center">Unassigned Corrections</div>
                        {shelfOpen ? <AiOutlineDownCircle size={20} /> : <AiOutlineUpCircle size={20} />}
                    </div>
                    <div className={classNames("is-flex is-flex-wrap-wrap ml-3", { "mt-2": shelfOpen })} style={{ overflowY: "auto", maxHeight: shelfOpen ? "350px" : "0px" }}>
                        {
                            pages[PagePart.UnassignedCorrections] && pages[PagePart.UnassignedCorrections].map(page => <React.Fragment key={page.PageID}><PartPageThumbnailDropZone partVal={PagePart.UnassignedCorrections} ordinal={page.Ordinal} /> <PartPageThumbnail size={size} selected={selectedShelfPages.includes(page.Ordinal)} partVal={PagePart.UnassignedCorrections} onClick={shelfPageClickHandler} visible={true} key={page.PageID} page={page} /> </React.Fragment>)
                        }
                        <PartPageThumbnailDropZone partVal={PagePart.UnassignedCorrections} ordinal={pages[PagePart.UnassignedCorrections].length + 1} />
                    </div>
                </section>
                : <div></div>
        }
        <div className="is-flex is-flex-direction-column is-justify-content-center p-1 is-clickable" style={{ position: "fixed", bottom: 100, left: 211, transform: "rotate(270deg)", zIndex: 99999, cursor: "pointer", backgroundColor: "#33333354", borderRadius: 5 }}>
            <input defaultValue={1} style={{ width: 198 }} ref={ref} min={0.8} max={3} step={0.1} type="range" onChange={(e) => sizeChangeHandler(e.target.value)} />
        </div>
    </div >


}

export default PageOrganizer
