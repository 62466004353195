import { Coords } from '../_hooks/useDraw';

import toNearest from './toNearest';

export default function coordRoundToGrid(
	coord: Coords,
	grid: [number, number],
	roundFunc: (i: number) => number = Math.round
): Coords {
	const { x, y } = coord;

	return {
		x: toNearest(x, grid[0], roundFunc),
		y: toNearest(y, grid[1], roundFunc)
	};
}
