import classNames from "classnames";
import { MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { FaCaretRight } from "react-icons/fa";
import { useJob } from "../store/jobSlice";
import { useParams } from "react-router-dom";

export type ContextMenuItemType = {
    title: string,
    id: string
    disabled?: boolean,
    hidden?: boolean,
    children?: ContextMenuItemType[]
    menuItemClick?: (contextId: string) => void
    width?: number
    divider?: boolean
}

function ContextMenuItem({ id, title, children, disabled, menuItemClick, width, divider }: ContextMenuItemType) {

    // It feels a bit heavy-handed to pull the job state all the way down to the menu item level.
    // but, if it doesn't cause any performance issues, then -- it should be fine.
    // In theory, if it's ever desired to leave *certain* page operations available during job processing
    // (e.g. keep menu page select opts enabled since cursor select remains enabled), 
    // then having the job state at the 'menu item' level supports that. 
    const { job, jobIsActive } = useJob()

    const { part } = useParams(); // undefined (safely) in the UploadModal context menu; defined in page thumbnail context menus

    const partIsLocked = useMemo(() => (part && job?.Stage?.[part]?.Stage === "Locked"), [job, part]);

    const [pos, setPos] = useState({ x: 0, y: 0 });
    const [isOn, setIsOn] = useState(false);
    const ref = useRef<HTMLLIElement>(null);

    const widthVal = useMemo(() => {
        return width || 250
    }, [width])

    const mouseEnter = () => {
        if (!jobIsActive || partIsLocked) {
            return;
        }
        setIsOn(true);
        if (!ref.current) return;
        const bounds = ref.current.getBoundingClientRect();
        let rightX = bounds.x + bounds.width;
        if (rightX + widthVal >= window.document.body.clientWidth) rightX = bounds.x - bounds.width;
        setPos({
            x: rightX,
            y: bounds.y
        })
    }

    const hasChildren = useMemo(() => {
        return (children && children.length)
    }, [children])

    const itemClickHandler = (contextId: string) => (e: MouseEvent<HTMLLIElement>) => {
        e.stopPropagation();
        if (menuItemClick && !hasChildren && !disabled) {
            menuItemClick(contextId);
        }
    }

    if (divider) return <li onClick={(e) => e.stopPropagation()} className="part-menu-list-item has-text-weight-bold" style={{ pointerEvents: "none" }}>
        {title}
    </li>

    return <li onMouseOver={mouseEnter} onMouseLeave={() => setIsOn(false)} ref={ref} onClick={itemClickHandler(id)} className={classNames({'disabled-custom-control': !jobIsActive || partIsLocked}, "part-menu-list-item")} style={{ cursor: disabled ? "not-allowed" : undefined }}>
        {title} {hasChildren && <FaCaretRight className="has-text-dark is-align-self-center" />}
        {
            (children && isOn) && <ContextMenu pos={pos} items={children} contextMenuClick={menuItemClick} />
        }
    </li>
}

type ContextMenuType = {
    pos: { x: number, y: number },
    items: ContextMenuItemType[]
    contextMenuClick?: (contextId: string) => void
    width?: number
}

function ContextMenu({ pos, items, contextMenuClick, width }: ContextMenuType) {

    const ref = useRef<HTMLDivElement>(null);
    const [posY, setPosY] = useState(pos.y);

    useEffect(() => setPosY(pos.y), [pos.y])

    const widthVal = useMemo(() => {
        return width || 250
    }, [width])

    const posX = useMemo(() => {
        if (pos.x + widthVal >= document.body.clientWidth) return document.body.clientWidth - widthVal - 10;
        return pos.x;
    }, [pos.x, widthVal])

    useEffect(() => {
        if (!ref.current) return;
        const height = ref.current?.getBoundingClientRect().height;
        if (posY + height >= window.innerHeight) setPosY(window.innerHeight - height - 10);
    }, [ref.current, posY, window.innerHeight]);

    return <div ref={ref} style={{ position: "fixed", left: posX, top: posY, zIndex: 999999 }}>
        <div className="part-menu-list" style={{ position: "relative", width: widthVal }}>
            <ul style={{ overflowY: "auto" }}>
                {
                    items.filter(item => !item.hidden).map((item, i) => <ContextMenuItem key={i} {...item} menuItemClick={contextMenuClick} width={widthVal} />)
                }
            </ul>
        </div>
    </div>
}

export default ContextMenu
