import classNames from "classnames"
import React, { MouseEvent, useMemo, useRef, useState } from "react"
import Foco from "react-foco"
import { BiHistory, BiSpreadsheet, BiUpload } from "react-icons/bi"
import { FaArrowCircleLeft, FaDownload, FaLock, FaLockOpen, FaTrash } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import Swal from "sweetalert2"
import AccountDataApi from "../lib/accountdata"
import JobApi from "../lib/job"
import PagesApi from "../lib/pages"
import { visitJob } from "../routes/visit"
import { useAuthState } from "../store/authSlice"
import { setJobId, useJob } from "../store/jobSlice"
import { Job, JobTypes } from "../types/job.types"
import { PreflytProcessName } from "../types/jobApi.types"
import { PagePart, StageStatus } from "../types/pages.types"
import { BatchPageOperationTypes } from "../types/pagesApi.types"
import { JobStatus } from "../types/types"
import { AccountTypes, UserPermission } from "../types/user.types"
import { swalButtonColorsPalette as palette, swalHtmlTemplates } from "../utils/constants"
import { jobHasType } from "../utils/job"
import { pagesUnapproved, pagesWithErrors, pagesWithWarnings } from "../utils/page"
import { validPartNameTypeGuard_STUB } from "../utils/pagetypes.utils"
import { getPart, getPartTagsDataByCurrentStage } from "../utils/parts"
import { userHasAccountType, userHasPermission } from "../utils/user"
import { dispatchCustomEvent } from "../utils/utils"


function ComponentCard({ part }: { part: string }): JSX.Element {

    const { user, activeAccountId } = useAuthState()
    const { job, pages, jobId, jobIsActive } = useJob()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [downloadMenuOpen, setDownloadMenuOpen] = useState(false);

    const [termsAccepted, setTermsAccepted] = useState(false)

    const partDisplayName = getPart(part)?.name || part.charAt(0).toUpperCase() + part.substring(1)

    const partIsLocked = useMemo(() => job?.Stage?.[part]?.Stage === "Locked", [job]);

    const deletePartBtnRef = useRef<HTMLButtonElement>(null)

    const approveToPrintHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (termsAccepted) {
            try {
                if (job && jobId && pages && part && user) {

                    const partPageCountOnJob = pages[part].length

                    if (partPageCountOnJob) {
                        // TODO
                        // -- the text on all of the Sweetalert popups for the approve to print cases is a little bit
                        // large - 
                        // consider restyling these,
                        // either at the level of every Swal popup - or just here.

                        // Check if the component is text or not - if it is not text, then the pages must match exactly with no exceptions.
                        const isTextComponent: boolean = (part === 'text');
                        // TODO ^^^ Consider if this check should be more sophisticated (especially when new typing is incorporated)

                        const udbPgCount: number | undefined = getUdbPageCount(job, part)

                        let ignorePageCount = false;

                        // If a udb page count is obtained:
                        if (typeof udbPgCount === 'number') {
                            if (partPageCountOnJob > udbPgCount) {

                                const accountAllowsMorePages = user.AllowMorePages
                                if (typeof accountAllowsMorePages === undefined) {
                                    throw Error('The user\'s account must specify whether to allow more pages or not.');
                                }
                                const morePagesAlertTitle = `Page count of ${partPageCountOnJob} does not match the required manufacturing spec of ${udbPgCount} pages`
                                if (isTextComponent && accountAllowsMorePages) {
                                    const { isConfirmed } = await Swal.fire({
                                        title: morePagesAlertTitle,
                                        text: 'If there are more pages than intended, please remove pages to match the expected page count. ' +
                                            'Additional costs may be required if the total page count exceeds the supplied manufacturing order.',
                                        showCancelButton: true,
                                        focusCancel: true,
                                        reverseButtons: true,
                                        confirmButtonText: 'Proceed without changes'
                                    });
                                    if (!isConfirmed) {
                                        setTermsAccepted(false);
                                        return await Swal.fire({
                                            text: 'If there are more pages than intended, please remove pages to match the expected page count.\n\n' +
                                                'Additional costs may be required if the total page count exceeds the supplied manufacturing order.'
                                        });
                                    }
                                    if (isConfirmed) {
                                        // If the mismatch is confirmed for a text component, this function will later call the API with the "ignorepagecount" param = true
                                        ignorePageCount = true;
                                    }
                                }
                                else {
                                    // if the user's account does not allow more pages, or if the component is not text:
                                    return await Swal.fire({
                                        title: morePagesAlertTitle,
                                        text: `Please remove pages or supply new spec data with correct page count by contacting your CSR.`,
                                    });
                                }
                            }
                            else if (partPageCountOnJob < udbPgCount) {

                                const accountAllowsFewerPages = user.AllowFewerPages
                                if (typeof accountAllowsFewerPages === undefined) {
                                    throw Error('The user\'s account must specify whether to allow fewer pages or not.');
                                }
                                const fewerPagesAlertTitle = `The supplied page count (${partPageCountOnJob} pages) does not match the required manufacturing spec (${udbPgCount} pages)`
                                if (accountAllowsFewerPages) {
                                    const { isConfirmed } = await Swal.fire({
                                        title: fewerPagesAlertTitle,
                                        text: `Blanks will be added to the end of the ${partDisplayName} component at the local manufacturing location to account for less content pages supplied.`,
                                        showCancelButton: true,
                                        focusCancel: true,
                                        reverseButtons: true,
                                        confirmButtonText: 'Proceed without changes'
                                    });
                                    if (!isConfirmed) {
                                        setTermsAccepted(false);
                                        return await Swal.fire({
                                            text: 'If content pages are missing, please add them or supply new specification data by contacting your CSR.'
                                        });
                                    }
                                    if (isConfirmed) {
                                        // If the mismatch is confirmed for a text component, call the API with the "ignorepagecount" param = true
                                        ignorePageCount = true;
                                    }
                                }
                                else {
                                    // if the user's account does not allow fewer pages, or if the component is not text:
                                    return await Swal.fire({
                                        title: fewerPagesAlertTitle,
                                        text: 'If content pages are missing, please add them or supply new specification data by contacting your CSR.',
                                    });
                                }
                            }
                        }
                        else {
                            // if the udb data is not yet available:
                            const { isConfirmed } = await Swal.fire({
                                title: 'Order information not currently available',
                                html: swalHtmlTemplates.approveToPrintConfirm,
                                showCancelButton: true,
                                focusCancel: true,
                                reverseButtons: true,
                                target: document.getElementById('approve-to-print-container') || 'body'
                            })
                            if (!isConfirmed) {
                                setTermsAccepted(false);
                                return;
                            }
                        }
                        // Note: the ideal case where the job part's page count is equal to the udb page count will fall through to here and execute. 
                        // It will skip almost all of the above branches.

                        return await approvePartToPrint(jobId, part, ignorePageCount);
                    }
                }
                throw new Error('A required state variable or property is not defined in the approve to print handler.')
            }
            catch (error) {
                console.log(error)
                return await Swal.fire({
                    title: 'Error while approving component to print',
                    icon: 'error',
                    text: 'Something went wrong while trying to approve the component to print.'
                });
            }
        }
    }

    // TODO update 'part' parameter type along with new types integration
    // the potential to return 'never' is due to the possibility that the function will throw an error if the [part] or any earlier properties are not defined
    const getUdbPageCount = (job: Job, part: string): number | undefined | never => {
        // TODO - the below data access is likely to eventually be reworked, as I understand it.
        // the back end may be changed to supply this info from a different location.
        // like job.Components[part].PageCount, or something/somewhere similar.
        // I have seen a 'UDBPageCount' property sometimes(?) included at that level.
        // Figure out if the data access pattern is different, once any changes to the back end logic are final.
        if (job.Tags?.Preflyt2?.[part]) {
            return job.Tags.Preflyt2[part].PageCount;
        }
        throw new Error('The job is missing required information to determine the udb page count.');
    }


    // TODO -
    // is it by design that more than one part cannot be approved to print at a time?
    // if one tries to approve another component on a job that just had a component approved to print, 
    // the back end returns status 409 and an error message "Cannot execute process Preflyt2 for job in state processing"
    // Could add a tooltip or an alert that mentions that the job has to finish processing before another part can be approved to print.
    // Adding UI state that indicates a job is 'processing' will likely be done as part of development, eventually.
    // I think this took the form of a loading spinner next to the job title on the angular FE.

    const approvePartToPrint = async (jobId: Job["JobID"], part: string, ignorePageCount: boolean) => {
        return await JobApi.runPreflytProcessOnPartOfJob(jobId, {
            part: part,
            processname: PreflytProcessName.Preflyt2,
            ignorepagecount: ignorePageCount
        })
            .then(res => {
                if (res.data) {
                    dispatch(setJobId(jobId)); // 'soft' refresh to pull new job data after starting preflyt2 for the part
                    Swal.fire({
                        icon: "success",
                        title: `${partDisplayName} component approved to print.`,
                        timer: 4000,
                        toast: true,
                        position: 'top',
                        showClass: {
                            popup: 'swal-popover-in'
                        },
                        hideClass: {
                            popup: 'swal-popover-out'
                        },
                        showCloseButton: true,
                        showConfirmButton: false,
                        grow: 'row'
                    })
                }
            })
            .catch(err => {
                let errMsgFromBe: string | undefined;
                if (err?.response?.data?.["Error"]) {
                    errMsgFromBe = err.response.data["Error"];
                }
                console.log(err)
                return Swal.fire({
                    icon: 'error',
                    text: errMsgFromBe || `Something went wrong while trying to approve the ${partDisplayName} component to print.`
                })
            })
    }

    const awaitingSpecsMessageHandler = () => {
        return Swal.fire({
            title: 'Lakeside Scout is awaiting specifications',
            html: swalHtmlTemplates.awaitingSpecs,
            target: document.getElementById('awaiting-specs-container') || 'body'
        })
    }

    const viewHistoryHandler = () => {
        navigate(visitJob({ part, history: true }))
    }

    const lockClickHandler = () => {
        Swal.fire({
            icon: "info",
            title: "This component has been approved to print and is in manufacturing production.",
            text: "Please contact your CSR if you have an immediate need to halt production of this component."
        })
    }

    const deletePartHandler = async (event: React.MouseEvent<HTMLButtonElement>, partUpForDelete: string) => {
        if (!jobIsActive) {
            return;
        }
        if (deletePartBtnRef?.current) {
            // Blurs (removes focus from) the delete button element - because if focus is maintained, the tooltip will reappear after the delete dialog is cancelled.
            deletePartBtnRef.current.blur();
        }
        if (userHasPermission(UserPermission.Job_Edit, user)) {
            const { isConfirmed } = await Swal.fire({
                icon: "warning",
                title: "Are you sure?",
                text: `Do you really want to remove the ${partDisplayName} component?`,
                confirmButtonColor: palette.red,
                confirmButtonText: "Yes, delete it!",
                showCancelButton: true,
                cancelButtonText: "Cancel",
                focusCancel: true,
                reverseButtons: true
            });

            if (!isConfirmed) return;

            if (job && job.PartPages) {

                const partEnum = validPartNameTypeGuard_STUB(partUpForDelete) || partUpForDelete

                if (partEnum) {
                    return await PagesApi.batchUpdatePages(jobId, {
                        type: BatchPageOperationTypes.delete,
                        ordinal: 1,
                        count: job?.PartPages?.[partEnum],
                        part: partUpForDelete,
                    })
                        .then((res) => {
                            if (job?.JobID) dispatch(setJobId(job.JobID));
                            Swal.fire({
                                icon: "success",
                                title: "Successfully deleted component.",
                                timer: 4000,
                                toast: true,
                                position: 'top',
                                showClass: {
                                    popup: 'swal-popover-in'
                                },
                                hideClass: {
                                    popup: 'swal-popover-out'
                                },
                                showCloseButton: true,
                                showConfirmButton: false,
                                grow: 'row'
                            })
                        })
                        .catch((err) => {
                            console.log(err)
                            if (err.response && err.response.data["Error"]) {
                                Swal.fire({
                                    icon: "error",
                                    title: "Error",
                                    text: err.response.data["Error"]
                                })
                            }
                            else {
                                Swal.fire({
                                    icon: "error",
                                    title: "Failed to delete component from job!",
                                })
                            }
                        })
                }
            }
            Swal.fire({
                icon: "error",
                title: "Failed to delete component from job!",
            })
        } else {
            Swal.fire({
                icon: "info",
                title: "Insufficient permissions",
                text: "You don't have the required permission to delete this component.",
            })
        }

    }

    const downloadOriginalsHandler = (part: PagePart | string) => (e: MouseEvent<HTMLAnchorElement>) => {
        if (!job?.JobID) return;
        Swal.fire({
            title: "Please wait...",
            text: `Generating ${partDisplayName} originals`,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        })
        JobApi.downloadOriginal(job.JobID, part)
            .then((res) => {
                Swal.close();
                const link = document.createElement("a");
                link.href = res;
                // TODO - is it ok to change this zip filename to use the part display name?
                // I would think it's not a problem. (Just making sure this filename doesn't get parsed somewhere or something.
                // I think it just serves to tell the user what they downloaded -
                // in which case, this change is ok.)
                // TODO - *** One reason to potentially not change it - do the display names ever have any forbidden filename characters?
                // For that matter - does the job Title not risk having 'illegal' filename characters? 
                // That would have been an issue by now, I would think. Maybe the 'illegal' chars are stripped out silently.
                link.setAttribute("download", `${job.Title} ${partDisplayName}-originals.zip`);
                link.click();
            })
            .catch((err) => {
                console.log(err);
                Swal.close();
                Swal.fire({
                    title: "Download Error",
                    icon: "error",
                    text: `There was an error downloading the ${partDisplayName} original file.`
                })
            })
    }

    const requestProofHandler = (part: PagePart | string, highRes = false) => (e: MouseEvent<HTMLAnchorElement>) => {
        if (!jobIsActive || partIsLocked) {
            return;
        }
        setDownloadMenuOpen(false);
        if (!job?.JobID) return;
        PagesApi.requestProof(job.JobID, part, highRes)
            .then(() => {
                Swal.fire({
                    icon: "success",
                    title: `${highRes ? "Hi-res " : ""}Proof Requested`,
                    text: `Your ${highRes ? "hi-res" : ""} proof has been requested. A link to the proof downloads will be sent via your email address when the proof is ready for download.`
                })
            })
            .catch((err) => {
                console.error(err);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `There was an error requesting ${highRes ? "High-res of" : ""} ${partDisplayName} proof. Try again`
                })
            })
    }

    const downloadPreflightHandler = async (part: PagePart | string) => {
        if (!jobIsActive || partIsLocked) {
            return;
        }
        setDownloadMenuOpen(false);
        if (!job?.JobID) return;
        Swal.fire({
            title: "Please wait...",
            text: `Generating ${partDisplayName} preflight`,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        })
        JobApi.downloadPreflight(job.JobID, part)
            .then(() => {
                Swal.close();
                Swal.fire({
                    title: `${partDisplayName} preflight requested`,
                    text: `A generated ${partDisplayName} preflight will sent to your email!`
                })
            })
            .catch(() => {
                Swal.close();
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: `There was an error requesting for the ${partDisplayName} preflight file`
                })
            })
    }

    const viewPageClickHandler = (part: PagePart | string) => () => {
        if (!jobHasType(JobTypes.TEP, job)) navigate(visitJob({ part: part }));
    }

    const makeCorrectionsToPartHandler = async (part: string) => {
        // block if not in combined account
        if (!userHasAccountType(AccountTypes.Combined, user)) {
            return;
        }
        if (job?.JobID && user && activeAccountId) {
            const correctionsAcctNameFetch: string | undefined = await AccountDataApi.getCorrectionsAcctDispNameByCombinedAcctId(activeAccountId, user)
                .then(result => {
                    if (typeof result === 'string') return result;
                    return undefined;
                })
                .catch(err => {
                    console.log(err);
                    return undefined;
                })
            return await JobApi.runPreflytProcessOnPartOfJob(job.JobID, {
                part: part,
                processname: PreflytProcessName.TEP2Scout
            })
                .then(() => {
                    return Swal.fire({
                        icon: "info",
                        text: `The selected component is currently migrating to ${correctionsAcctNameFetch ? `account "${correctionsAcctNameFetch}" ` : 'its associated Corrections account '}where corrections can be made.`,
                    });
                })
                .catch(err => {
                    const errMsgFromBe: string | undefined = err.response?.data?.["Error"]

                    return Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: errMsgFromBe || "An error occurred and the component could not be migrated to its associated corrections account."
                    });
                })
        }
    }

    const uploadFilesClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        if (jobIsActive) dispatchCustomEvent("upload-modal-open", { part })
    }

    // Render different tooltip text depending on whether or not user's active account allows Make Corrections
    const tooltipText: string = useMemo(() => (!userHasAccountType(AccountTypes.Combined, user) ? "Select Combined account to enable Make Corrections." : "Check out"), [activeAccountId])

    if (!job) return <></>;

    // data for part in its current stage:
    const partData = getPartTagsDataByCurrentStage(job, part)

    const pagesOfPart = pages[part];
    const status = job.Stage[part]?.Status;
    const partStage = job.Stage[part]?.Stage;
    const displayMsg = job.Stage && job.Stage[part]?.Display;
    let reasonMsg = job.Stage[part]?.Reason;
    if (displayMsg === reasonMsg)
        reasonMsg = ""; // avoid duplicate messages in UI
    const page = pagesOfPart[0];
    const isNew = page?.Status === JobStatus.New;

    return <>
        <div key={part} className="is-flex is-flex-direction-column has-text-light-cascading-no-important px-1">
            <div className="has-text-light title is-size-6 ml-1 mb-3 mt-5 is-capitalized">{partDisplayName}</div>
            <div className="component-card-fixed-dims rounded-1 has-pos-relative has-background-grey mr-4 mb-2 is-flex is-flex-direction-column p-4 is-flex-grow-1">
                <div className="img-and-controls-row is-flex is-justify-content-space-between mb-4">
                    <img onClick={viewPageClickHandler(part)} src={isNew ? "/placeholder.png" : page?.Thumb} className="component-thumb" alt="no thumbnail" />
                    {/* can add to src value to render placeholder if thumbnail errors out --> */} {/* ? page.Thumb : "/placeholder.png" */}
                    <div className="has-text-right mb-3">
                        {
                            userHasPermission(UserPermission.Job_Edit, user) &&
                            job.Status !== JobStatus.Locked &&
                            !jobHasType(JobTypes.TEP, job) &&
                            !partIsLocked &&
                            (<>
                                <button ref={deletePartBtnRef} data-tip="Delete component" data-for={`delete-component-tip-${part}`} data-delay-show="250" onClick={(event) => deletePartHandler(event, part)} className={classNames("custom-button is-clickable mx-1", { "disabled-custom-control": !jobIsActive })} disabled={!jobIsActive}>
                                    <FaTrash size={24} />
                                </button>
                                <ReactTooltip id={`delete-component-tip-${part}`} place="bottom" />
                            </>)
                        }
                        {
                            userHasPermission(UserPermission.Job_Edit, user) &&
                            jobHasType(JobTypes.TEP, job) &&
                            jobIsActive &&
                            userHasAccountType([AccountTypes.Combined, AccountTypes.TEP], user) &&
                            // userHasAccountType(AccountTypes.Combined, user) &&
                            (
                                <>
                                    <button data-tip={tooltipText} data-for={`make-corrections-part-tip-${part}`} data-delay-show="250" onClick={() => makeCorrectionsToPartHandler(part)} className={classNames("custom-button is-clickable mx-1 no-not-allowed-icon", { "disabled-custom-control": !userHasAccountType(AccountTypes.Combined, user) })}>
                                        <div className="icon fill-svg-warning"> <FaArrowCircleLeft size={24} style={{ backgroundColor: "white", borderRadius: "50%" }} /> </div>
                                    </button>
                                    <ReactTooltip id={`make-corrections-part-tip-${part}`} place="bottom" />
                                </>
                            )
                        }
                        {
                            userHasPermission(UserPermission.Job_Download, user) &&
                            !jobHasType(JobTypes.TEP, job) &&
                            !(job.Status === JobStatus.Locked) &&
                            <Foco onClickOutside={() => setDownloadMenuOpen(false)}>
                                <div className={classNames("dropdown is-left has-text-left", { "is-active": downloadMenuOpen })}>
                                    <div className="dropdown-trigger is-flex">
                                        {/* The tooltip component will not reliably keep from rendering when the download menu is open.
                                        To account for this and keep the tooltip from showing, 
                                        the data-tip attribute is being set to an empty string while the menu is open. */}
                                        <div data-tip={(downloadMenuOpen ? "" : "Download component files")} data-for={`download-component-tip-${part}`} data-delay-show="250" onClick={() => {
                                            setDownloadMenuOpen(true);
                                            ReactTooltip.hide();
                                        }
                                        } className="mx-1 is-clickable">
                                            <FaDownload size={24} />
                                            {!downloadMenuOpen && <ReactTooltip id={`download-component-tip-${part}`} place="bottom" />}
                                        </div>
                                    </div>
                                    <div className="dropdown-menu" role="menu">
                                        <div className="dropdown-content">
                                            <a href="#" onClick={downloadOriginalsHandler(part)} className="dropdown-item">
                                                Download Originals
                                            </a>
                                            <a href="#" onClick={requestProofHandler(part)} className={classNames({ "disabled-custom-control": !jobIsActive || partIsLocked }, "dropdown-item")}>
                                                Download Proof
                                            </a>
                                            {
                                                (pagesWithWarnings(job, part, pagesOfPart) + pagesWithErrors(pagesOfPart)) <= 0 ?
                                                    <a href="#" onClick={requestProofHandler(part, true)} className={classNames({ "disabled-custom-control": !jobIsActive || partIsLocked }, "dropdown-item")}>
                                                        Download Hi-res
                                                    </a>
                                                    : null
                                            }
                                            <a href="#" onClick={() => downloadPreflightHandler(part)} className={classNames({ "disabled-custom-control": !jobIsActive || partIsLocked }, "dropdown-item")}>
                                                Download preflight report
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Foco>
                        }
                    </div>
                </div>
                {
                    ((status === StageStatus.PROCESSING || status === StageStatus.IN_PROGRESS) &&
                        partData?.Progress?.stage !== "Complete" &&
                        partData?.Progress?.percent &&
                        partData?.Progress?.percent !== 100) ?
                        <div className="progress-bar-and-label-wrapper">
                            <progress className="progress is-small is-success" style={{ backgroundColor: "#008b55", border: "1px solid #ffffffa1" }} value={partData.Progress.percent} max="100"></progress>
                            <span>
                                {partData.Progress.stage && `${partData.Progress.stage}: `}
                                {`${partData.Progress.percent}%`}
                            </span>
                        </div> : <div></div>
                }
                {
                    (
                        job.Type !== JobTypes.TEP &&
                        job.Status === JobStatus.Active &&
                        !partIsLocked &&
                        partStage === PreflytProcessName.Preflyt1 &&
                        status === StageStatus.WAITING_APPROVAL &&
                        pagesOfPart && pagesUnapproved(pagesOfPart) === 0 &&
                        userHasPermission(UserPermission.Job_Edit, user) &&
                        userHasPermission(UserPermission.Preflight2_Signoff, user)
                    ) &&
                    <div id="approve-to-print-container" className="approve-to-print-controls mb-2">
                        <button disabled={(!termsAccepted || !jobIsActive)} onClick={approveToPrintHandler} className="button is-primary approve-to-print">Approve to Print</button>
                        <div className='terms-and-checkbox-wrapper'>
                            <input type="checkbox" onChange={(e) => setTermsAccepted(e.target.checked)} checked={termsAccepted} />
                            {/* TODO - refactor style tag attributes in CSS restyle */}
                            <label style={{ fontSize: '14px' }} className="checkbox">
                                <span onClick={() => setTermsAccepted(oldVal => !oldVal)}>Accept{' '}</span><a className="termsLink has-text-white is-underlined" onClick={(event) => {
                                    event.preventDefault();
                                    // TODO - update/refine terms popup styling in CSS restyle branch
                                    Swal.fire({
                                        icon: "info",
                                        title: "Terms and Conditions",
                                        html: swalHtmlTemplates.terms,
                                        focusConfirm: true,
                                        target: document.getElementById('approve-to-print-container') || 'body'
                                    })
                                }
                                }>Terms and Conditions</a>
                            </label>
                        </div>
                    </div>
                }
                <div className="is-flex-grow-1">
                    <div>{pagesOfPart.length} page{pagesOfPart.length > 1 ? "s" : ""}</div>
                    {!jobHasType(JobTypes.TEP, job) &&
                        <>
                            {
                                pagesWithWarnings(job, part, pagesOfPart) > 0 &&
                                <div>{pagesWithWarnings(job, part, pagesOfPart)} page{pagesWithWarnings(job, part, pagesOfPart) > 1 ? "s" : ""} with warnings</div>
                            }
                            {
                                pagesWithErrors(pagesOfPart) > 0 &&
                                <div>{pagesWithErrors(pagesOfPart)} page{pagesWithErrors(pagesOfPart) > 1 ? "s" : ""} with errors</div>
                            }
                            {
                                pagesUnapproved(pagesOfPart) > 0 &&
                                <div>{pagesUnapproved(pagesOfPart)} page{pagesUnapproved(pagesOfPart) > 1 ? "s" : ""} not approved</div>
                            }
                        </>
                    }
                    {
                        (displayMsg) &&
                        (displayMsg !== 'Begin Spec Check')
                        &&
                        <div className="rounded-2 has-text-black has-background-warning p-1 help">
                            {
                                displayMsg
                            }
                        </div>
                    }
                    {
                        (status === 'Failed') &&
                        (reasonMsg && !/is awaiting specifications/.test(reasonMsg))
			&&
                        <div className="rounded-2 has-text-black has-background-danger p-1 help">
                            {
                                reasonMsg
                            }
                        </div>
                    }
                    {/* TODO ^^^ the above and the below conditionally rendered blocks should likely
                    be mutually exclusive.
                    they may already be exclusive just by way of the render logic.
                    Consider refactoring the below into the displayMsg render variable -
                    if it makes sense to do so. */}
                    {
                        (job.Stage?.[part]?.Reason === "Failed to find order info data for job" ||
                            /is awaiting specifications/.test(job.Stage?.[part]?.Reason || "")) &&
                        // TODO css restyle, styling to be refactored/applied
                        <div id='awaiting-specs-container' className='has-text-dark awaiting-specs-notif rounded-2 has-background-warning mt-2 p-1'>
                            <a className="has-text-black" onClick={awaitingSpecsMessageHandler}>Lakeside Scout is awaiting specifications. Click here for more details...</a>
                        </div>
                    }
                </div>
                <div className="view-pages-upload-files-view-history-controls mt-2">
                    {
                        (!jobHasType(JobTypes.TEP, job)) && (
                            <>
                                <div onClick={viewPageClickHandler(part)} className="is-clickable is-size-6 mt-1 is-flex">
                                    <BiSpreadsheet className="is-self-centered mr-2" size={20} />
                                    <span className="is-self-centered">View Pages</span>
                                </div>
                                {
                                    !partIsLocked &&
                                    <div onClick={uploadFilesClickHandler} className={classNames({ "disabled-custom-control": !jobIsActive }, "is-clickable is-size-6 mt-1 is-flex")}>
                                        <BiUpload className="is-self-centered mr-2" size={20} />
                                        <span className="is-self-centered">Upload files</span>
                                    </div>
                                }
                            </>
                        )
                    }
                    <div onClick={viewHistoryHandler} className="is-clickable is-size-6 mt-1 is-flex">
                        <BiHistory className="is-self-centered mr-2" size={20} />
                        <span className="is-self-centered">View history</span>
                    </div>
                </div>
                <div className="component-card-footer is-flex is-justify-content-flex-end">
                    <div className="lock-icon-wrapper">
                        {
                            (job.Type === JobTypes.TEP || partIsLocked) ?
                                <FaLock onClick={lockClickHandler} size={24} /> :
                                <FaLockOpen size={24} />
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ComponentCard
