import classNames from "classnames";
import { GlobalWorkerOptions, PDFDocumentProxy, PDFWorker } from "pdfjs-dist";
import React, { DragEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FaCaretDown, FaCaretUp, FaCheckCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import PagesApi from "../../lib/pages";
import { useAuthState } from "../../store/authSlice";
import { useJob } from "../../store/jobSlice";
import { PagePart } from "../../types/pages.types";
import { JobStatus } from "../../types/types";
import { JobTypes } from "../../types/job.types";
import { UserPermission } from "../../types/user.types";
import { userHasPermission } from "../../utils/user";
import { sortByOrdinal, sortObjectByDeepProp } from "../../utils/utils";
import UploadItem from "./UploadItem";
import UploadItemDropZone from "./UploadItemDropZone";
import * as pdfLib from "pdf-lib";
import { getPart } from "../../utils/parts";

GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js"
const worker = new PDFWorker()
worker.promise.catch(console.error);

export type PdfFileType = {
    file: File,
    part: PagePart | string,
    ordinal: number,
    // `doc` is no longer being passed down to each UploadItem to render thumbnails and previews;
    // instead, the worker is being passed down to each UploadItem, thumbnails are no longer shown,
    // and previews are generated when requested, using the reference to the worker
    doc?: PDFDocumentProxy,
    numPages?: number,
    id: number,
    // pass down reference to worker declared in this file, to allow rendering each pdf preview if requested
    pdfWorker?: PDFWorker,
    // if the pdf cannot be loaded, i.e. if it is corrupt or encrypted
    failedToLoad: boolean,
    // if the file is above the large file threshold
    aboveSizeThreshold: boolean
}

interface UploadState {
    jobId: string,
    progress: number,
    isUploading: boolean,
    total: number,
    done: number,
    jobTitle?: string
}

const largeFileThreshold = 1000000000;

function UploadModal() {
    const { jobId, job } = useJob();
    const { user } = useAuthState();

    const isTepJob = useMemo(() => (job && job.Type === JobTypes.TEP), [job])

    const ref = useRef(0)

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [pdfFiles, setPdfFiles] = useState<PdfFileType[]>([]);
    const [uploadStates, setUploadStates] = useState<UploadState[]>([]);
    const [loadingPages, setLoadingPages] = useState({ count: 0, total: 0 });

    const [isOpen, setIsOpen] = useState(false);
    const [part, setPart] = useState(PagePart.Text);

    const [initialPart, setInitialPart] = useState(PagePart.Text);

    // manyFilesMode will be enabled whenever the number of files staged for upload is above a set threshold.
    // This mode skips pdf-lib checks, .pdf file type checks, etc.
    // It also locks the part type for all files to the part the UploadModal was originally opened for.
    const [manyFilesMode, setManyFilesMode] = useState(false)

    // The number of files staged for upload above which to switch to manyFilesMode
    const manyFilesModeThreshold = 30

    const modalOpenEventHandler = useCallback((e: any) => {
        if (job?.Status === JobStatus.Error) {
            Swal.fire({
                icon: "info",
                title: "Upload disabled",
                text: "Upload is currently disabled for this job"
            })
            return;
        }
        setPdfFiles([]);
        setIsOpen(true);
        setPart(e.detail.part);

        setInitialPart(e.detail.part);
        setManyFilesMode(false);
    }, [job?.Status, setPart, setIsOpen, setPdfFiles])

    useEffect(() => {
        window.addEventListener("upload-modal-open", modalOpenEventHandler);
        return () => window.removeEventListener("upload-modal-open", modalOpenEventHandler);
    }, [modalOpenEventHandler])


    // disables manyFilesMode if pdfFiles drops below 30
    useEffect(() => {
        if (pdfFiles.length < manyFilesModeThreshold && manyFilesMode) {            
            setManyFilesMode(false)
        }
    }, [pdfFiles.length, manyFilesMode])

    const droppedFilesLoading = useMemo(() => {
        if (loadingPages.total > loadingPages.count) return true;
        else return false;
    }, [loadingPages.count, loadingPages.total])

    const fileProcessor = async (files: FileList) => {

        // don't run this function when there are no files to process
        if (files.length < 1) {
            return;
        } 
        
        // checks to block upload of more than one file via browse or dragging files
        if (isTepJob && (files.length + pdfFiles.length > 1)) {
            Swal.fire({
                title: "Cannot upload multiple files",
                text: "Only one file can be added to a new component for a TEP job."
            })
            return;
        }

        let isLargeAmtOfFiles = false;
        // enable mode for handling large amounts of files if at or above manyFilesModeThreshold
        if (files.length + pdfFiles.length >= manyFilesModeThreshold) {
            // manyFilesMode now also needs to be set to true; it will be set at the end of this method run.
            isLargeAmtOfFiles = true;            
        }
                
        setLoadingPages({ count: 0, total: files.length });
        if (!userHasPermission(UserPermission.Job_Edit, user) || !userHasPermission(UserPermission.Page_Add, user)) {
            Swal.fire({
                icon: "info",
                title: "Can't upload",
                text: "You don't have the required permissions to upload pages"
            })
            return;
        }


        // const fileProcessingBatchStart = Date.now()

        // TODO - decide whether to leave the sort by name removed or not.
        const filesArray = Array.from(files) 
        // TODO - even though it's removed for testing, do NOT yet fully remove this sort -
        // because it could change the orig. implementation's behavior.
        // .sort(sortObjectByDeepProp("file.name"));
        
        if (isLargeAmtOfFiles) {
            // just stage the files for upload with no checks
            
            // set the ordinals for the new files based off of pdfFiles's length at the time this function runs:
            let newOrd = pdfFiles.length - 1;

            const pdfFilesToAppend: PdfFileType[] = filesArray.map(file => {
                setLoadingPages(prev => ({ ...prev, count: prev.count + 1 }))
                newOrd = newOrd + 1;
                return { 
                    file, 
                    part: part, 
                    ordinal: newOrd, 
                    numPages: -2, 
                    id: ref.current++,
                    failedToLoad: false, 
                    aboveSizeThreshold: false 
                }
            })


            // Combine the value of the existing pdfFiles array with the new files to add,
            // and sort them by file name
            const pdfFilesSortedByFileName = pdfFiles
            .concat(pdfFilesToAppend)
            .sort(sortObjectByDeepProp("file.name"));

            // Once combined and sorted, map through the new array and set all ordinals appropriately, from 0 to pdfFiles.length - 1
            const sortedPdfFilesReOrdinaled = pdfFilesSortedByFileName.map((file, index) => {
                return {
                    ...file, ordinal: index
                }
            })

            // and now set pdfFiles state with the newly updated array
            setPdfFiles(sortedPdfFilesReOrdinaled)

            // after pdfFiles has been updated, set manyFilesMode
            setManyFilesMode(true)
            return;
        }

        // If files are being staged for upload but there are still less files than the manyFilesThreshold:
        // Instead of setting pdfFiles state incrementally w/ each parsed pdf, we are going to gather its state in
        // an array and then set state just one time at the end - after sorting the array by file name and re-ordinaling its elements.
        // Start with any existing pdfFiles:
        const updatedPdfFilesData = [...pdfFiles];

        for (let i = 0; i < filesArray.length; i++) {
            const file = filesArray[i];
            if (file.type !== "application/pdf") continue;
            if (!file.size) continue;
            if (file.size > largeFileThreshold) {
                // since the large file was not loaded in this case, it is not actually known if it is encrypted or corrupt                
                updatedPdfFilesData.push({
                    file, part: part, ordinal: pdfFiles.length + 1, doc: undefined, numPages: -1, id: ref.current++,
                    failedToLoad: false, aboveSizeThreshold: true
                })
                
                setLoadingPages(prev => ({ ...prev, count: prev.count + 1 }))
                continue;
            }
            
            const arrayBufferFromFile = await file.arrayBuffer();

            try {
                const pdfDoc = await pdfLib.PDFDocument.load(arrayBufferFromFile);
                
                const totalPages = pdfDoc.getPageCount();           
                const ordinal = pdfFiles.length + i;
                
                updatedPdfFilesData.push({
                    file, part: part, ordinal, numPages: totalPages, id: ref.current++, pdfWorker: worker,
                    failedToLoad: false, aboveSizeThreshold: false
                })
            } catch (err) {
                console.log('error thrown while trying to load pdf file:')
                console.log(err)
                
                const ordinal = pdfFiles.length + i;
                updatedPdfFilesData.push({
                    file, part: part, ordinal, numPages: -1, id: ref.current++, pdfWorker: worker,
                    failedToLoad: true, aboveSizeThreshold: false
                })
            } finally {
                setLoadingPages(prev => ({ ...prev, count: prev.count + 1 }))                
            }
        }

        // Before setting the new pdfFiles state,
        // we need to sort the updatedPdfFiles array by file name and re-ordinal it.        
        const pdfFilesSortedByFileName = updatedPdfFilesData
        .sort(sortObjectByDeepProp("file.name"));

        // Once sorted, map through the new array and set all ordinals appropriately from 0 to pdfFiles.length - 1
        const sortedPdfFilesReOrdinaled = pdfFilesSortedByFileName.map((file, index) => {
            return {
                ...file, ordinal: index
            }
        })
        
        setPdfFiles(sortedPdfFilesReOrdinaled)

        // const timeTakenProcessingFiles = Date.now() - fileProcessingBatchStart
        // console.log(`time taken to stage batch of ${filesArray.length} files - `)
        // console.log(`${timeTakenProcessingFiles/1000}s`)
    }

    const fileDropHandler = async (e: DragEvent<HTMLDivElement>) => {

        e.preventDefault();
        fileProcessor(e.dataTransfer.files);
    }

    const fileClickHandler = () => {

        const fileElement = document.createElement("input");
        fileElement.type = "file";
        fileElement.accept = "application/pdf";

        fileElement.multiple = (isTepJob ? false : true);
        fileElement.click();
        fileElement.onchange = () => {            
            fileProcessor(fileElement.files || new FileList())
            fileElement.remove();
        }
    }

    const isFilesEmpty = useMemo(() => {
        return pdfFiles.length < 1;
    }, [pdfFiles])

    const UploadItemDeleteHandler = (index: number) => {
        setPdfFiles(prev => prev.filter((f, i) => i !== index).map((f, i) => ({ ...f, ordinal: i })));
    }

    const UploadItemPartChangeHandler = (index: number, part: PagePart | string) => {
        setPdfFiles(prev => prev.map((f, i) => {
            if (i !== index) return f;
            return { ...f, part }
        }))
    }

    const UploadItemPositionChangeHandler = (from: number, to: number) => {
        // Drag and Drop item moving logic
        const wentUp = to < from ? true : false;
        setPdfFiles(prevFiles => prevFiles.map(item => {
            if (wentUp) {
                if (item.ordinal === from) return { ...item, ordinal: to };
                if (item.ordinal >= to && item.ordinal < from) return { ...item, ordinal: item.ordinal + 1 }
            } else {
                if (item.ordinal === from) return { ...item, ordinal: to - 1 }
                if (item.ordinal > from && item.ordinal < to) return { ...item, ordinal: item.ordinal - 1 }
            }
            return item;
        }))
    }

    const submitUploadHandler = async () => {
        // block upload of more than one file if adding a new component to a TEP job:
        if (isTepJob && pdfFiles.length > 1) {
            return;
        }
        if (userHasPermission(UserPermission.Job_Edit, user) && userHasPermission(UserPermission.Page_Add, user)) {
            setIsOpen(false);
            try {
                const base = { isUploading: true, done: 0, progress: 0, total: pdfFiles.length, jobId: jobId, jobTitle: job?.Title };
                // re: looking complex - updates or creates new uploadItem                
                setUploadStates(prev => prev.some(item => item.jobId === jobId) ? prev.map(item => item.jobId === jobId ? { ...item, ...base } : item) : [...prev, base]);

                if (manyFilesMode) {
                    // console.log('uploading using multi upload API - ')

                    // in manyFilesMode, all uploads will be uploaded to the `initialPart` that was selected to open the UploadModal with.
                    const partShortName = getPart(initialPart)?.part || 'text';

                    await PagesApi.multiUploadSinglePages(jobId, partShortName, pdfFiles, (progress) => {
                        setUploadStates(prev => {
                            return prev.map(item => {
                                if (item.jobId === jobId) {
                                    return {
                                        ...item,
                                        isUploading: true,
                                        progress: progress.percent,
                                        total: progress.total,
                                        done: progress.loaded >= progress.total ? progress.total : progress.loaded
                                    }
                                }
                                return item;
                            })
                        })
                    })
                }
                else {
                    // console.log('uploading using normal pages API - ')
                    await PagesApi.uploadPages(jobId, pdfFiles, (progress) => {
                        setUploadStates(prev => {
                            return prev.map(item => {
                                if (item.jobId === jobId) return { ...item, isUploading: true, progress: progress.percent, total: progress.total, done: progress.loaded };
                                return item;
                            })
                        })
                    })
                }
                setUploadStates(prev => prev.map(item => {
                    if (item.jobId === jobId) return { ...item, isUploading: false }
                    return item;
                }));
            } catch (e) {
                setUploadStates(prev => prev.filter(item => item.jobId !== jobId));
                Swal.fire({
                    icon: "error",
                    title: "Error Uploading file",
                    text: "There was an error uploading your files. Please try again"
                })
                console.error(e);
            }
        } else {
            Swal.fire({
                icon: "info",
                title: "Can't upload",
                text: "You don't have the required permissions to upload pages"
            })
        }
    }

    const confirmCancelHandler = async () => {
        if (pdfFiles.length > 0) {
            const { isConfirmed } = await Swal.fire({
                icon: "warning",
                title: "Are you sure?",
                text: "Files are staged for upload. Do you still want to close the Upload dialog?",
                showCancelButton: true,
                focusCancel: true,
                confirmButtonText: "Yes, close anyways",
                cancelButtonText: "Cancel",
                reverseButtons: true
            })
            if (isConfirmed) {
                setIsOpen(false)
            }
        }
        else {
            setIsOpen(false)
        }
    }

    const inProgressUploads = useMemo(() => {
        return uploadStates.filter(item => item.isUploading);
    }, [uploadStates])
    const doneUploads = useMemo(() => {
        return uploadStates.filter(item => !item.isUploading);
    }, [uploadStates])

    const isUploading = useMemo(() => {
        return inProgressUploads.some(item => item.jobId === jobId);
    }, [jobId, inProgressUploads])

    const collapsedData = useMemo(() => ({
        done: inProgressUploads.reduce((a, b) => a + b.done, 0),
        progress: inProgressUploads.reduce((a, b) => a + b.progress, 0) / inProgressUploads.length,
        total: inProgressUploads.reduce((a, b) => a + b.total, 0)
    }), [inProgressUploads])

    const canCollapse = useMemo(() => inProgressUploads.length > 1, [inProgressUploads])


    // if any pdfs failed to load, i.e. any are corrupt or encrypted:
    const someFailedToLoad = useMemo(() => {
        for (const file of pdfFiles) {
            if (file.failedToLoad) {
                return true;
            }
        }
        return false;
    }, [pdfFiles])

    return <>
        <div style={{ position: "fixed", bottom: 90, right: 16, width: 350, zIndex: 999, display: "flex", flexDirection: "column", gap: 2 }}>
            {
                doneUploads.map(uploadItem => {
                    return <div key={uploadItem.jobId} className="is-flex p-2 card progress_bg">
                        <div style={{ width: 30 }} className="is-flex">
                            <FaCheckCircle color="#008b55" size={30} className="is-align-self-center" />
                        </div>
                        <div key={uploadItem.jobId} className="is-flex-grow-1">
                            {
                                uploadItem.jobTitle ?
                                    <div className="has-text-dark title is-size-6 m-0 px-2" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: 300 }}>{uploadItem.jobTitle}</div>
                                    : null
                            }
                            <div className="px-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="has-text-grey subtitle is-size-7 has-text-centered m-0">
                                    <div className="has-text-success">Done uploading</div>
                                </div>
                                <div className="is-help is-underlined has-text-link is-clickable" onClick={() => setUploadStates(prev => prev.filter(item => item.jobId !== uploadItem.jobId))}>Dismiss</div>

                            </div>
                        </div>
                    </div>
                })
            }
            {
                canCollapse ?
                    <div className="has-text-centered">
                        {
                            isCollapsed ?
                                <FaCaretUp className="is-clickable" color="white" onClick={() => setIsCollapsed(prev => !prev)} />
                                : <FaCaretDown className="is-clickable" color="white" onClick={() => setIsCollapsed(prev => !prev)} />
                        }
                    </div> : null
            }
            {
                (isCollapsed && canCollapse) ?
                    <div className="progress_bg p-2 card">
                        {
                            <div className="has-text-dark title is-size-6 m-0 px-2" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{inProgressUploads.length} jobs</div>
                        }
                        <div className="px-2" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className="has-text-grey subtitle is-size-7 has-text-centered m-0"><span className="has-text-weight-bold is-family-monospace">{collapsedData.done}/{collapsedData.total}</span> uploaded</div>
                            {
                                collapsedData.done === collapsedData.total ?
                                    <div className="has-text-weight-bold">finalizing...</div>
                                    :
                                    <div className="has-text-weight-bold">{collapsedData.progress.toFixed(2)}%</div>
                            }
                        </div>
                        <progress className="progress is-small" style={{ height: 8 }} value={collapsedData.progress} max="100">{collapsedData.progress}%</progress>
                    </div>
                    :
                    inProgressUploads.map(uploadItem => {
                        const isDone = !uploadItem.isUploading && uploadItem.done === uploadItem.total;
                        return <div key={uploadItem.jobId} className="is-flex p-2 card progress_bg">
                            <div key={uploadItem.jobId} className="is-flex-grow-1">
                                {
                                    uploadItem.jobTitle ?
                                        <div className="has-text-dark title is-size-6 m-0 px-2" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: 300 }}>{uploadItem.jobTitle}</div>
                                        : null
                                }
                                <div className="px-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="has-text-grey subtitle is-size-7 has-text-centered m-0"><span className="has-text-weight-bold is-family-monospace">{uploadItem.done}/{uploadItem.total}</span> uploaded</div>
                                    {
                                        uploadItem.done === uploadItem.total ?
                                            <div className="has-text-weight-bold">finalizing...</div>
                                            :
                                            <div className="has-text-weight-bold">{uploadItem.progress.toFixed(2)}%</div>
                                    }
                                </div>
                                <progress className="progress is-small" style={{ height: 8 }} value={uploadItem.progress} max="100">{uploadItem.progress}%</progress>
                            </div>
                        </div>
                    })
            }
        </div>
        <div className={classNames("modal force-to-front-of-ui has-text-light-cascading-no-important", { "is-active": isOpen })}>
            <div className="modal-background"></div>
            <div className="modal-content upload-modal box pt-0" style={{ minWidth: "55%" }}>
                <div className="level is-sticky has-background-dark py-5" style={{ position: "sticky", top: 0, zIndex: 99 }}>
                    <div className="level-left">
                        <div className="title has-text-light is-size-5 m-0">Upload Pages</div>
                        <button className={classNames("button is-link ml-3", { "is-loading": droppedFilesLoading })} onClick={fileClickHandler} disabled={isUploading || (isTepJob && pdfFiles.length >= 1)}>Browse</button>
                    </div>
                    <div className="level-right">
                        <button className="button has-background-dark has-text-light" onClick={confirmCancelHandler} disabled={isUploading}>Cancel</button>
                        <button className={classNames("button ml-2 is-primary", { "is-loading": isUploading })} onClick={submitUploadHandler} disabled={someFailedToLoad || isUploading || !pdfFiles.length || (isTepJob && pdfFiles.length > 1)}>Upload</button>
                    </div>
                </div>
                <div className="is-sticky has-background-dark pb-1" style={{ position: "sticky", top: 88, zIndex: 99 }}>
                    {
                            someFailedToLoad && <div className="has-text-danger has-text-centered mb-5">
                                Some of the selected files are encrypted or corrupt.
                            </div>
                    }
                    {
                        manyFilesMode && <div className="mb-3">{pdfFiles.length} files staged for upload to <strong>{getPart(initialPart)?.name}</strong></div>
                    }
                </div>

                <div onClick={fileClickHandler} className={classNames("is-flex is-flex-direction-column", {"is-clickable": !(isTepJob && pdfFiles.length >= 1), "is-justify-content-center": isFilesEmpty })} style={{ border: isFilesEmpty ? "1px dashed #f3f3f4" : undefined, minHeight: 300 }} onDragOver={(e) => { e.preventDefault(); e.stopPropagation() }} onDrop={fileDropHandler}>
                    {
                        isFilesEmpty &&
                        <div className="has-text-centered">
                            Click OR <br />
                            Drag &amp; Drop {isTepJob ? 'a file' : 'files'} here
                        </div>
                    }
                    {
                        !isFilesEmpty &&
                        <table className="table upload-modal-files-table">
                            <thead className="has-background-dark">
                                <tr className="has-text-centered">                                    
                                    {/* <th>Thumbnail</th> */}
                                    <th>Filename</th>
                                    <th>Pages</th>
                                    <th>Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    pdfFiles.sort(sortByOrdinal).map((file, index) => <React.Fragment key={file.id}>
                                        <UploadItemDropZone onPosChange={UploadItemPositionChangeHandler} ordinal={index} />
                                        <UploadItem key={file.id} index={index} initialPart={initialPart} multipleFilesMode={manyFilesMode} onDelete={UploadItemDeleteHandler} onPartChange={UploadItemPartChangeHandler} {...file} />
                                    </React.Fragment>)
                                }
                                <UploadItemDropZone onPosChange={UploadItemPositionChangeHandler} ordinal={pdfFiles.length} />
                            </tbody>
                        </table>
                    }
                </div>
            </div>
            {
                !isUploading &&
                <button className="modal-close is-large" aria-label="close" onClick={() => setIsOpen(false)}></button>
            }
        </div >
    </>
}

export default UploadModal
