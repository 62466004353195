import userDataApi from "../../lib/userdata";
import { HistoryEntry } from "./ViewHistory";
import { PagePart } from "../../types/pages.types"
import { Job } from "../../types/job.types"
import JobApi from "../../lib/job";
import { partNameDictionary } from "../../types/parts.types";

// Record to be populated with all user IDs and their respective user display names on user login:
export const actorNameRecord: Record<string, string> = {
    "Scout": "System", // whenever activities performed by the 'actor' with user ID 'Scout' are processed, displays the actor name as "System"
    "LSC UDB": "System"
}

export const transformPartName = (part: string) => {
    if (part in partNameDictionary) {
        return partNameDictionary[part];
    }
    // if (part === 'shelf') return 'Shelf'; // Do not render 'shelf' as an available option on the UI, nor render any entries related to 'shelf'.
    return part;
}

let _namesLoaded: undefined | true;

export const namesAreLoaded = (): boolean => _namesLoaded ? _namesLoaded : false;

const setNamesLoaded = (areLoaded: true): void => {
    _namesLoaded = areLoaded;
}

export const populateNameRecord = async () => {
    const allUsersList = await userDataApi.getAllUsers()
    for (const { UserID, DisplayName } of allUsersList) {
        if (UserID && DisplayName) {
            const id = UserID as string;
            const name = DisplayName as string;
            actorNameRecord[id] = name;
        }
    }
    if (Object.keys(actorNameRecord).length > 0) {
        setNamesLoaded(true)
    }
}

export const transformActor = async (actor: HistoryEntry["actor"]): Promise<string> => {
    // branch if static record was populated:
    if (actor && actor as keyof typeof actorNameRecord in actorNameRecord) {
        return actorNameRecord[actor]
    }
    // if, for some reason, the name record is not populated or the name was not found in the record -
    // --> branch if displayName needs to be fetched from API (and added to actorNameRecord)
    else {
        const userDisplayNameFetch = await userDataApi.getUserNameById(actor as string)
        if (typeof userDisplayNameFetch === 'string') {
            // add displayName to current record if it is not already on there
            if (!actorNameRecord[actor as string]) {
                actorNameRecord[actor as string] = userDisplayNameFetch
            }
            return userDisplayNameFetch;
        }
    }
    return `user ID '${actor}'`
}

/**
 * @description 
 * Attempts to parse and localize a datetime string obtained from the `time` property of an 'activity' retrieved from the GetStream API.  
 * @param {string} ambiguousGsIsoStr - A datetime string in standard ISO date time string format from the GetStream API that is *ambiguous* in the sense that it lacks an appended `Z` to indicate that it is a UTC datetime. 
 * @returns {string} Either a localized datetime string, or the original datetime string upon a failed Date parse attempt.  
 * 
 * For more background on this method and the issue it is meant to handle, see **Details** below.
 * 
 * -----
 * ### Details
 * ---
 * 
 * #### Why this method does not just directly parse the received time strings:
 * The GS API returns ISO datetime strings with no timezone data, even though the time strings are *(quietly)* asserted by GS docs to be UTC times  
 * {@link https://getstream.io/activity-feeds/docs/other-rest/adding_activities/ [1]}.  
 * 
 * Any attempt to parse a Date value from one of the GS API `time` strings will always risk parsing an incorrect time value unless the string is corrected to account for the ambiguity introduced by omitting a timezone identifier.   
 * 
 * Without a `Z` appended to the end of the string, A new Date object created using `new Date(${date_string_to_parse})`* will almost always have an incorrect internal date value. 
 *  
 * When this happens, the `Date.parse()` method is working as intended - the results are skewed due to the incomplete data passed in the date time string.  
 * 
 * When parsing a standard ISO format date time string with no time zone identified, `Date.parse()` will interpret the time value received as *local* time.  
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#date_time_string_format [2]}
 * ##### *Note: `new Date(${date_string_to_parse})` is an implicit call to `Date.parse(${date_string_to_parse})`
 * 
 * When an ISO date time string is passed over the Internet with no time zone identifier, the risk is almost inevitable that the date will be passed across time zones and therefore not be valid. 
 * 
 * #### Final note on parsing dates:
 * Parsing of date time strings is known to be risky, given that a string to be parsed may be of an invalid format and/or may not contain enough information to reliably parse the intended date time.
 * 
 * In the specific case of the strings this method is meant to parse, it is known that the strings received from GetStream are of the standard ISO date time string format (they are just missing a time zone.)
 * 
 * For ISO date time strings of the *standard format*, the MDN docs seem to indicate that `Date.parse()` should return **consistent** results. `Date.parse()` expects a string in an ISO format.  
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#parameters [6]}
 * 
 * The aberrant implementation-dependent fallback behavior appears to be confined to non-ISO date time strings.  
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#fall-back_to_implementation-specific_date_formats [7]}
 * 
 * All this is to say that this method should provide a reliable parse for the time strings that are passed from the GetStream API.
 * @see  
 * [1] {@link https://getstream.io/activity-feeds/docs/other-rest/adding_activities/}  
 * 
 * GetStream API Docs
 * - Note on GetStream Activity Feeds REST documentation page that reads "...*time doesn't have any timezone information but it's always in UTC.*"  
 * 
 * [2] {@link https://tc39.es/ecma262/#sec-date-time-string-format}  
 * 
 * ECMAScript Standard 21.4.1.15 - Date Time String Format
 * - expected format for ISO date time strings    
 * 
 * [3] {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#date_time_string_format}  
 * 
 * MDN - Date Time String Format
 * - description of Date.parse() behavior when no time zone is included  
 * 
 * [4] {@link https://stackoverflow.com/a/39531633}  
 * 
 * StackOverflow Answer  
 * - from GetStream co-founder on a question where a user was asking how to handle the GS datetime strings returning without any timezone identifier  
 * 
 * [5] {@link https://stackoverflow.com/a/58323576}  
 * 
 * Another StackOverflow Answer
 * - from GetStream co-founder on a question from a different user asking about this same behavior  
 * 
 * [6] {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#parameters}  
 * 
 * MDN - Date.parse() parameters
 * 
 * [7] {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#fall-back_to_implementation-specific_date_formats}  
 * 
 * MDN - Fall-back to implementation-specific date formats  
 * - On `Date.parse()` behavior when parsing date time strings that do not conform to the ECMAScript Standard (as linked in [2])
 * */
export const transformGsIsoTimeToLocale = (ambiguousGsIsoStr: string): string => {

    // Note: as mentioned, this will only work for the date time strings that are passed under the 'time' property of an activity from the GetStream Activity Stream API
    const correctedGsIsoStr = ambiguousGsIsoStr + 'Z'
    const dateObjFromDateTimeStr = new Date(correctedGsIsoStr)

    // confirm date string is valid
    const validDateParsed: boolean = (dateObjFromDateTimeStr instanceof Date && !isNaN(dateObjFromDateTimeStr.getTime()))

    if (validDateParsed) {
        const transformedDateStr = dateObjFromDateTimeStr.toLocaleString(Intl.DateTimeFormat().resolvedOptions().locale, { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })

        // TODO - it could be helpful(?) to also have the time zone abbreviation shown on these times -
        // i.e., EST||EDT||ET at the end of a time string shown in a user's browser that is running "in" the 'America/New_York' time zone.
        return transformedDateStr;
    }
    // if the attempt to transform the datetime results in a malformed/invalid date, just return the original string instead:
    return ambiguousGsIsoStr;
}

export enum HistoryFilters {
    part = 'part',
    deleted = 'deleted',
    all = 'all',
    // allactive = 'allactive',
    // joblevel = 'joblevel'
}
// | HistoryFilters.allactive

// currently only supports one filter predicate at a time -
// could apply filters in succession, if multiple ones are needed - multiple filters may not be needed.
// TODO - might consider reworking the type narrowing/parameter structure for this method in the future, as it feels 'overbuilt' for its limited amount of different uses
export const filterJobLevelHistoryEntries = (entries: HistoryEntry[],
    filterPredicate: { filter: HistoryFilters.deleted, job: Job }): HistoryEntry[] => {
    switch (filterPredicate.filter) {
        case HistoryFilters.deleted:
            return entries.filter((entry) => ((entry.part && entry.part as PagePart) && (entry.part !== 'all') && !filterPredicate.job.PartsList.includes(entry.part as PagePart)))
        // case HistoryFilters.allactive:
        //     return entries.filter((entry) => ((entry.part && entry.part as PagePart) && (entry.part !== 'all') && filterPredicate.job.PartsList.includes(entry.part as PagePart)))
        default:
            throw new Error('Method only accepts valid filter predicates')
    }
}


/* 
history entry verbs by case
-----------------------------

// case 0: unique, ends in 'ed'
----------------------------
downloadfailed -> download failed  // apply space

// case I: ends in 'ed' -> no change
----------------------------
created
approved
rejected
changed
delivered
scaled
replaced
migrated
// // // // // moved to case 0 --> this verb must be handled earlier than case I -> downloadfailed

// case II: ends in 'e' -> append 'd'
----------------------------
approve
move
rename
delete
update
create

// case III: ends in 'd', but not 'ed' (handled by case II) -> append 'ed'
----------------------------
upload

// case IV: special case set
----------------------------
upload-pdf
insert
insert_blank
copy
submit
not reviewed
reset
jobcopy
scalecenter
checkout
*/
export const verbDictionary = {
    move: 'moved',
    rename: 'renamed',
    delete: 'deleted',
    update: 'updated',
    create: 'created', // for parent Job creation event
    created: 'created', // for part creation event(s)
    upload: 'uploaded',
    'upload-pdf': 'uploaded',
    insert: 'inserted',
    insert_blank: 'inserted',
    approved: 'approved',
    rejected: 'rejected',
    approve: 'approved',
    changed: 'changed',
    delivered: 'delivered',
    scaled: 'scaled',
    replaced: 'replaced',
    copy: 'copied', // ...
    downloadfailed: 'download failed', // 
    migrated: 'checked out', // 'checked out' - per lsp
    checkout: 'checked out',
    submit: 'submitted',
    'not reviewed': 'unapproved', // Note - while the angular FE includes this key-value pair,
    // the back end does not seem to ever actually send it.
    // It is thought to be a typo with the intended key being 'not-reviewed' - the back end does set that as a getstream activity verb.
    // See LSCPortalFE PR 1287:
    // https://github.com/ApagoInc/LSCPortalFE/pull/1287/
    'Not Reviewed': 'unapproved', // The back end does send this as a verb type, whenever a page is moved from approved to pending.
    reset: 'reset',
    jobcopy: 'copied job', // old job ID is added if sent in activity data from getstream
    scalecenter: 'scaled',
    shift: 'shifted',
    transmit: 'transmitted',
    // The below is added to avoid 'approved to printed' being rendered. We can revisit the syntax of 'approve to print' history entries if desired.
    'approved to print': 'approved to print',
    // Same for this one below; both of these appear to be later changes to the FE and/or BE.
    'checkin': 'checked in'
};

export const transformVerb = (verb: string) => {
    const verbIsInDictionary = verb as keyof typeof verbDictionary in verbDictionary
    if (verbIsInDictionary) {
        const verbAsDictKey = verb as keyof typeof verbDictionary
        return verbDictionary[verbAsDictKey];
    }
    else {
        // to have ended up here could predict unexpected results for how an entry is rendered:
        // it indicates that an unrecognized verb has been passed - and likely, an unrecognized entry.
        return `${verb}ed`;
    }
};

export enum partStreamActivityVerbs {
    rename,
    update,
    created, // for part creation event(s)
    upload,
    'upload-pdf',
    insert,
    insert_blank,
    delivered,
    replaced,
    copy,
    downloadfailed,
    migrated,
    submit,
    reset,
    download,
    // approved // seems to sometimes happen at part level,
    transmit
}

export enum objectStreamActivityVerbs {
    create,
    jobcopy,
    upload,
    'upload-pdf',
    update,
    reset, // can be 'hard' or '<partname>' - for a job; can also be 'preflyt1' or 'preflyt2' for a job -- ? double check
    download,
    delete
}

export enum tepStreamActivityVerbs {
    resubmit
}

export enum pageStreamActivityVerbs {
    pageproof,
    approve,
    shift,
    delete,
    changed,
    move,
    'not-reviewed',
    scalecenter,
    scaled, // maybe this one appears some
    rejected,
    approved,
    downloadfailed,
    'not reviewed',
    'Not Reviewed'
}

export enum approvalStreamActivities {
    approved,
    'not-reviewed',
    // 'not reviewed' 
    // ^^^ see above comments in this file on 'not reviewed'
}

// No current intersect between page & part verb lists

// rename,
// update, 
// created, // for part creation event(s)
// upload,
// 'upload-pdf',
// insert,
// insert_blank,
// delivered, 
// replaced,  
// copy, 
// downloadfailed, 
// migrated, 
// submit, 
// reset,
// download,

export const transformObject = (objectStr: string) => {
    return objectStr.replace(/^(Job|PDF):/i, '')
}

export const getJobTitleById = async (jobId: string, activeHistoryJob?: Job): Promise<string | false> => {
    if (activeHistoryJob && activeHistoryJob.JobID === jobId) {
        if (activeHistoryJob.Title) return activeHistoryJob.Title;
    }
    else {
        // TODO ensure there's no "lighter" call that can do this - there may not be.
        return await JobApi.getJob(jobId)
            .then(res => {
                if (res.data && res.data.Title) {
                    return res.data.Title;
                }
                return false;
            })
            .catch(err => {
                console.log(err)
                return false;
            })
    }
    return false;
}

/**
 * @description Checks to see if the value of a GS API stream activity's `object` property is of a certain "type".
 * @param {string} objStr - string value of `object` property to be checked 
 * @param {GsObjectTypes} gsObj - enum value that indicates which object "type" to check for in the string 
 * @returns {boolean} truth value of whether or not the object string is of the specified "type"
 */
const gsObjStrIs = (objStr: string, gsObj: GsObjectTypes): boolean => gsDataRe[gsObj].test(objStr);


export const gsShelfPartRe = /shelf/i



export const gsDataRe = {
    // validator: /^.*:.*$/, // TODO - if going this route -
    // // have to choose whether to fail on unexpected tokens in the 'property' or 'value' - or silently 'accept' them.
    job: /^Job:/i,
    part: /^Component:/i,
    pdf: /^PDF:/i,
    page: /^Page:/i,
    objectStrValue: /=:(.+)$/i // regex to obtain the 'value' of the gs object string
}

export const applyPreRenderTransformsForEntry = async (entry: HistoryEntry, activeHistoryJob?: Job): Promise<HistoryEntry> => {

    // this method is for operations that may require async fetches:
    // 1. get actor display name (async if not already in name record)
    // 2. get related job name (async if not the job whose history is being viewed)

    const transformedEntry: HistoryEntry = { ...entry }
    const { actor, verb, object, target, count } = transformedEntry
    if (actor) {
        transformedEntry.actor = await transformActor(actor)
    }
    if (verb === 'jobcopy') {
        if (target && gsDataRe.job.test(target)) {
            const copiedJobId = target.replace(gsDataRe.job, '')
            const copiedJobTitleById = await getJobTitleById(copiedJobId)
            transformedEntry.renderData = { ...transformedEntry.renderData, origCopiedJobTitle: copiedJobTitleById ? copiedJobTitleById : '' }
        }
    }
    if (object && gsDataRe.job.test(object)) {
        const referencedJobId = object.replace(gsDataRe.job, '')
        const jobTitleById = await getJobTitleById(referencedJobId, activeHistoryJob)
        transformedEntry.renderData = { ...transformedEntry.renderData, jobTitle: jobTitleById ? jobTitleById : '' } // TODO <-- safe to spread the renderData prop even if it's undefined? I would think so. --> Sandbox the process to check
    }
    // remove properties with trivial values that can cause incorrect renders if present
    if (count === 0) delete transformedEntry.count;

    // 
    applyRenderCriteria(entry)
    // 

    return transformedEntry;
}

export enum GsObjectTypes {
    pdf = 'pdf',
    job = 'job',
    part = 'part', // prefixed with 'Component:' in the `object` field
    page = 'page'
}


export type GsObject = {
    objType: GsObjectTypes,
    objValue: string
}

export const parseGsObject = (entry: HistoryEntry): GsObject | false => {
    if (entry.object) {
        const inferredTypeResult = inferGsObjType(entry)
        if (inferredTypeResult) {
            // get 'value' of object
            const objValue = gsDataRe.objectStrValue.exec(entry.object)?.[0] || ''
            const parsedGsObj: GsObject = {
                objType: inferredTypeResult,
                objValue: objValue
            }
            return parsedGsObj;
        }
        console.log(`Could not parse getstream API 'object' field.`)
        return false;
    }
    return false;
}


export const gsObjectIs = (entry: HistoryEntry, gsObjType: GsObjectTypes) => {
    // TODO - undef. check here or at a higher level?
    if (entry.object) {
        // if (gsObjStrIs(entry.object, gsObjType)) {
        // const typedGsObj: GsObjectValue = `${gsObjType}:${}`
        // }
        return gsObjStrIs(entry.object, gsObjType)
    }
    return false;
}

// TODO - this method might theoretically exhibit unexpected behavior if 
//  - a CR# that has the value of a valid or dummy ISBN is passed from the history entry.
// The method currently cannot "tell" the difference in the two in this case -
// thus the attempted inference is actually wrong.

// *** Inputting an ISBN for a CR feels like a possible user 'mistake' or behavior - 
// as a result, this method might need updated moving forward to not make a falsifiable assumption in some cases.
export const inferGsObjType = (entry: HistoryEntry): GsObjectTypes | false => {
    if (entry.object) {
        const gsObjTypesArr = Object.keys(GsObjectTypes)
        let inferredAsType = false;
        for (const gsObjTypeKey of gsObjTypesArr) {
            if (gsObjTypeKey in GsObjectTypes) {
                inferredAsType = gsObjStrIs(entry.object, GsObjectTypes[gsObjTypeKey as keyof typeof GsObjectTypes])
                if (inferredAsType) {
                    return GsObjectTypes[gsObjTypeKey as keyof typeof GsObjectTypes]
                }
            }
        }
        console.log(`The entry has an object property, but its 'type' was not recognized. (entry: '${entry.object}')`)
    }
    return false;
}


// export const getActivityTypeByVerb = (verb: string): GsActivityTypes => {

// if (verb )
// return GsActivityTypes.pageActivity;

// } 

export const verbIs = (entry: HistoryEntry, verb: string): boolean => (entry.verb === verb)

// TODO - these would have to be entirely mutually exclusive - and I am not sure that's guaranteed.
// export type GsActivityTypes = Record<string, T> {
//     pageActivity,
//     partActivity,
//     tepStreamActivity,
//     objectStreamActivity
// } 

// | pageStreamActivityVerbs
// | partStreamActivityVerbs 
// | tepStreamActivityVerbs
// | objectStreamActivityVerbs

export type DerivedRenderConditions = {
    part?: string,
    object?: GsObject,
    // activityTypeFromVerb?: GsActivityTypes,
    [otherKey: string]: any // TODO - probably don't want to actually support rendering anything that would go here.
}


// TODO - when revisiting, review this method and consider whether or not it would be useful to implement.
const applyRenderCriteria = (entry: HistoryEntry) => {

    entry.renderOn = {}
    const { renderOn } = entry

    const { part, verb, object: gsObject, target } = entry;

    if (part && part !== 'all') {
        renderOn.part = part
    }

    if (gsObject) {
        const validatedObj = parseGsObject(entry)
        if (validatedObj) {
            renderOn.object = validatedObj
        }
    }

    // TODO
    // -- can activity 'type' be definitively determined by just the verb 'type'?
    // I am led to think not.
    if (verb) {

        // if (verb in pageStreamActivityVerbs) {
        //     // renderOn.verb = {}
        //     const { count, folio } = entry
        //     if (count && count > 0) {
        //         renderOn.count = count;
        //     }
        //     if (folio) {
        //         renderOn.folio = folio;
        //     }

        //     return;
        // }
        // if (verb in partStreamActivityVerbs) {
        //     return;
        // }
        // if (verb in tepStreamActivityVerbs) {
        //     // ???
        //     return;
        // }
    }

    if (target) {
        // currently, target has only been observed as holding the job id for the job copied from on a 'jobcopy' event
        // console.log(target)
    }

    // and once all useful properties are checked, add them all as kv pairs to the entry.renderOn property; -- !!! (if they are not already)
    // entry.renderOn = renderOn;

    // and then, in the render() method - 
    // destructure the more descriptively named, abstracted conditions there and use them for the conditional rendering.

};


// const parseGsObjTest = () => {
//     const fakeObjArr = [
//         'Part:dfdfds', // TODO - can '^/Part:/' ever appear for entry.object?
//         'Component:adsfdfdf',
//         'Pdf:asdfpldf',
//         'PDF:afwhiocfw',
//         'PBF:dfopidh',
//         'Job:adfs',
//         'job:aefadef',
//         'Port:afedf',
//         'Chicken:aefdhio',
//         'comPONent:adfpoidhjf'
//     ]

//     for (const testVal of fakeObjArr) {
//         const entry = {object: testVal}
//         console.log(`test value: ${testVal}`)
//         // console.log(`infer result: ${inferGsObjType(entry)}`)
//         const parsedGsObj = parseGsObject(entry)
//         if (parsedGsObj) {
//             console.log(`parsed:`)
//             console.log(parsedGsObj)
//         }
//         else {
//             console.log(`failed to parse gs object string '${entry.object}'`)
//         }
//     }

// }

// parseGsObjTest();
