// source for regex: https://github.com/colinhacks/zod/blob/a6e13ccf9f5656e386bef63e13db3561b692aec9/src/types.ts#L484
// TODO could just import this from the 'zod' module, once zod is included as a dep
export const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line no-useless-escape

export const dummyIsbn10_RegEx = /^[9]{3}\d{6}(\d|X){1}$/
export const dummyIsbn13_RegEx = /^[9]{3}\d{10}$/

// TODO -- ***
// -- make sure, with input from others,
// that the use of these static strings for html templates is *not* somehow a security risk.
// I think since there are no dynamic(user input-determined) parts, it shouldn't be.
const termsHtmlTemplate = `
<p>
Selecting “Approve to Print” you are assuming responsibility for the quality of the file preparation and its content.
<br>
“Approve to Print” will allow an order to be placed and release of the job into print production as well as releasing Lakeside Book Company of any responsibility for file preparation and content.
</p>
<br>
<p>
Lakeside Book Company will not conduct any additional checking of file, including but not limited to: Image resolution, color space, font inclusion, trim size, adherence to bleed and safety margin guidelines, overprints and knockouts, blank pages, page position, page offsets, folio placement and content backup, and general printability of content provided.
</p>
`;
const approveToPrintConfirmHtmlTemplate = `
<p>
No further action is required on your part as Lakeside Scout will begin its automatic checks once specifications have been provided.
</p>
<br>
<p>
If a specification mismatch is detected, the user that Approved to Print will be emailed to reconcile the issue.
</p>
<br>
<p>
If you know specifications have been provided, contact the CSR to address this issue.
</p>
`; 
const awaitingSpecsHtmlTemplate = `
<p>
This component has been approved to print.
</p>
<br>
<p>
No further action is required on your part as Lakeside Scout will begin its automatic checks once specifications have been provided.
</p>
<br>
<p>
If a specification mismatch is detected, the user that Approved to Print will be emailed to reconcile the issue.
</p>
<br>
<p>
If you know specifications have been provided, contact the CSR to address this issue.
</p>
`;

export const swalHtmlTemplates = {
    terms: termsHtmlTemplate,
    approveToPrintConfirm: approveToPrintConfirmHtmlTemplate,
    awaitingSpecs: awaitingSpecsHtmlTemplate
}

export const swalButtonColorsPalette = {
                        // use cases:
    red: '#e64942',     // confirm significant change/operation (delete, etc.)
    blue: 'steelblue',  // confirm regular (*** currently instead applied by CSS style rule from .scss sheet)
    grey: '#6e7881'     // cancel (this appears to still be the Swal2 default 'cancel' button color, so should not be necessary to set this for a normal cancel button.)
}

// thumbnail zoom helpers and data
export const thumbZoomConfig = {
    min: 0.8,
    max: 3,
    // 8.5 and 10 are legacy constants used to scale the page width by.
    legacyScaleFactor: 10 * 8.5
}
const clampZoomVal = (zoomVal: number) => {
    if (zoomVal < thumbZoomConfig.min) {
        zoomVal = thumbZoomConfig.min
    }
    if (zoomVal > thumbZoomConfig.max) {
        zoomVal = thumbZoomConfig.max
    }
    return zoomVal;
}
// the 10s inside and outside of round() are included to set up rounding to the nearest tenth.
export const getThumbSizeFromZoom = (zoomVal: number) => {
    const constrainedZoomVal = clampZoomVal(zoomVal)
    return Math.round((thumbZoomConfig.legacyScaleFactor * 10 * constrainedZoomVal))/10;
}


export const adminRoleNames: string[] = ['LSCAdmin', 'SuperAdmin'];

export const excludedRoleNames: string[] = ['LSCAdmin', 'LSCViewOnly', 'SuperAdmin'];

export const excludedEmailDomains = ['@lakesidebook.com', '@lsccom.com', '@apago.com']

export const emailInExcludedDomain = (email: string) => {
    for (const domain of excludedEmailDomains) {
        if (email.endsWith(domain)) {
            return true;
        }
    }
    return false;
}
