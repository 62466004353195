type UTCArray = Parameters<DateConstructor['UTC']>;

export default function parseLefDate(date: string | Date) {
	if (date instanceof Date) return date;
	else {
		const split = date.split(':').map(i => parseInt(i)) as UTCArray;
		split[1]--;
		const parsed = new Date(Date.UTC(...split));
		return parsed;
	}
}