import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from ".";
import { HoldingKeys, PagePart } from "../types/pages.types";
import { PagesState } from "../types/store.types";

const initialState: PagesState = {
    selectedPages: [],
    selectedShelfPages: [],
    loading: false,
    shelfLoading: false
}

const pagesSlice = createSlice({
    name: 'pages',
    initialState,
    reducers: {
        selectPage: (state, { payload }: PayloadAction<{ ordinal: number, held?: HoldingKeys, isShelf?: boolean }>) => {
            const prev = payload.isShelf ? state.selectedShelfPages : state.selectedPages;
            if (payload.held === HoldingKeys.Shift) {
                const lastOrdinal = prev.length ? prev[prev.length - 1] : 0;
                const minVal = Math.min(lastOrdinal, payload.ordinal);
                const maxVal = Math.max(lastOrdinal, payload.ordinal);
                const newOrdinals = new Array<number>(maxVal - minVal + 1).fill(0).map((o, i) => i + minVal);
                const data = Array.from(new Set([...prev, ...newOrdinals]));
                if (payload.isShelf) state.selectedShelfPages = data;
                else state.selectedPages = data;
            }
            else if (payload.held === HoldingKeys.Ctrl) {
                const isSelected = prev.includes(payload.ordinal);
                let newPages = [];
                if (isSelected) newPages = prev.filter(ordinal => ordinal !== payload.ordinal);
                else newPages = [...prev, payload.ordinal]
                if (payload.isShelf) state.selectedShelfPages = newPages;
                else state.selectedPages = newPages;
            }
            else if (prev.includes(payload.ordinal) && prev.length === 1) {
                if (payload.isShelf) state.selectedShelfPages = []
                else state.selectedPages = []
            }
            else {
                if (payload.isShelf) state.selectedShelfPages = [payload.ordinal]
                else state.selectedPages = [payload.ordinal]
            }
        },

        setSelectedPages: (state, { payload }: PayloadAction<{ ordinals: number[], isShelf?: boolean }>) => {
            if (payload.isShelf) state.selectedShelfPages = payload.ordinals;
            else state.selectedPages = payload.ordinals;
        },

        deleteSelectedPages: (state, { payload }: PayloadAction<PagePart | string>) => {
            if (payload === PagePart.UnassignedCorrections) state.shelfLoading = true;
            else state.loading = true;
        },

        updateSelectedPagesApproval: (state, { payload }: PayloadAction<{ part: PagePart | string, value: string }>) => {
            // do nothing here. data is for saga
        },

        duplicateSelectedPages: (state, { payload }: PayloadAction<PagePart | string>) => {
            // do nothing here. data is for saga
        },

        moveToPart: (state, { payload }: PayloadAction<{ from: PagePart | string, to: PagePart | string }>) => {
            // do nothing here. data is for saga
        },

        changePagePosition: (state, { payload }: PayloadAction<{ part: PagePart | string, fromOrdinal?: number, destinationOrdinal: number, destinationPart?: PagePart | string }>) => {
            // do nothing here. data is for sage
        },

        replacePage: (state, { payload }: PayloadAction<{ fromOrdinal: number, fromPart: PagePart | string, destinationOrdinal: number, destinationPart: PagePart | string }>) => {
            // do nothing here. data is for saga
        },

        stopLoadingPages: (state) => {
            state.loading = false;
            state.shelfLoading = false;
        }


    }
})

export const { selectPage, setSelectedPages, deleteSelectedPages, stopLoadingPages, updateSelectedPagesApproval, duplicateSelectedPages, moveToPart, changePagePosition, replacePage } = pagesSlice.actions;

export const usePages = () => useSelector((state: RootState) => state.pages)

export default pagesSlice.reducer;