import classNames from 'classnames';
import { isEqual } from 'lodash';
import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useContext,
	useMemo,
	useState
} from 'react';
import ReactDom from 'react-dom';
import {
	FaCheck,
	FaCompress,
	FaCompressArrowsAlt,
	FaCropAlt,
	FaExpand,
	FaExpandArrowsAlt,
	FaEye,
	FaEyeSlash,
	FaInfoCircle,
	FaPlus,
	FaRuler,
	FaTrash
} from 'react-icons/fa';
import PageDataContext from '../_components/PageDataContext';
import useScaleCrop from '../_hooks/useScaleCrop';
import { Unit, units } from '../_utils/convertUnits';
import parseBox from '../_utils/parseBox';

import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert';
import { visitJob } from '../../../routes/visit';
import { checkLegacyJobTypePartLock } from '../../../utils/utils';
import { useAuthState } from '../../../store/authSlice';
import Compass, { Direction } from './Compass';
import MultiUnitInput from './MultiUnitInput';
import ScaleCropContext from './PageViewContext';
import Quantity from './Quantity';
import ScaleCenterPopupContent from './ScaleCenterPopupContent';
import styles from './ScaleCrop.module.scss';

interface ScaleCropPaneProps {
	selectedUnit: Unit;
	setSelectedUnit: Dispatch<SetStateAction<Unit>>;
}

export default function ScaleCropPane(props: ScaleCropPaneProps) {
	const { selectedUnit, setSelectedUnit } = props;

	const navigate = useNavigate()
	const { part } = useParams()

	const {
		job,
		processing,
		bumpOffset,
		clearMeasurements,
		lines,
		addRulerMode,
		setAddRulerMode,
		showMeasurements,
		setShowMeasurements,
		Part
	} = useContext(ScaleCropContext);

	const contextJobIsActive: boolean | undefined = React.useMemo(() => (job && job.Status === 'active'), [job])

	const partIsLocked = useMemo(() => {
		return checkLegacyJobTypePartLock(job, Part)
	}, [job, Part]);

	const page = useContext(PageDataContext);

	const ordinal = useMemo(() => page.Ordinal, [page.Ordinal]);

	const {
		targetwidth,
		targetheight,
		setTargetWidth,
		setTargetHeight,
		applyCustomTrim,
		autoCenter,
		autoScaleCenter,
		setBumpOffset,
		applyTo,
		reset,
		trimDirty,
		scaleToPercent,
		targetDimensionsValid
	} = useScaleCrop(job, Part);

	const compassClick = useCallback(
		(direction: Direction, e: React.MouseEvent<HTMLButtonElement>) => {
			// reject compass input if job not active or part is currently locked:
			if (!contextJobIsActive || partIsLocked) {
				return;
			}
			const amount = e.shiftKey ? 5 : 1;
			const [relX, relY] = {
				[Direction.North]: [0, amount],
				[Direction.West]: [-amount, 0],
				[Direction.East]: [amount, 0],
				[Direction.South]: [0, -amount]
			}[direction];
			setBumpOffset(([x, y]) => [x + relX, y + relY]);
		},
		[setBumpOffset]
	);

	const backHandler = useCallback(() => {
		navigate(visitJob({ jobId: job.JobID, part }))
	}, [job.JobID, part, navigate])

	const [scaleToPercentageEnabled, setScaleToPercentageEnabled] = useState(
		false
	);
	const [scaleToPercentageAmount, setScaleToPercentageAmount] = useState(100);

	const confirmTrim = useCallback(
		async (confirmText: string, title?: string) => {
			const element = document.createElement('div');
			ReactDom.render(
				<ScaleCenterPopupContent
					title={title}
					width={+targetwidth}
					height={+targetheight}
					units={selectedUnit}
					scale={scaleToPercentageAmount / 100}
				/>,
				element
			);
			const correctedTargetDims = await Swal({
				content: {
					element
				},
				buttons: ['Cancel', confirmText]
			});

			if (correctedTargetDims) {
				const [strWidth, strHeight] = `${correctedTargetDims}`.split(' ');
				return {
					width: parseFloat(strWidth),
					height: parseFloat(strHeight)
				};
			}
		},
		[scaleToPercentageAmount, selectedUnit, targetheight, targetwidth]
	);

	const autoCenterClickHandler = useCallback(async () => {
		const targetDimensions = await confirmTrim('Crop');
		if (targetDimensions) {
			await Promise.all([
				applyCustomTrim(targetDimensions),
				autoCenter()
			]);
			backHandler();
		}
	}, [applyCustomTrim, autoCenter, backHandler, confirmTrim]);

	const autoScaleCenterClickHandler = useCallback(async () => {
		const targetDimensions = await confirmTrim('Scale to Trim');
		if (targetDimensions) {
			await Promise.all([
				applyCustomTrim(targetDimensions),
				autoScaleCenter()
			]);
			backHandler();
		}
	}, [applyCustomTrim, autoScaleCenter, backHandler, confirmTrim]);

	const scalePercentClickHandler = useCallback(async () => {
		const targetDimensions = await confirmTrim(
			'Scale & Crop',
			`Current page will be scaled to ${parseFloat(
				scaleToPercentageAmount.toFixed(2)
			)}%`
		);
		if (targetDimensions) {
			await applyCustomTrim(targetDimensions);
			await scaleToPercent(
				scaleToPercentageAmount / 100,
				targetDimensions
			);
			backHandler();
		}
	}, [
		applyCustomTrim,
		backHandler,
		confirmTrim,
		scaleToPercent,
		scaleToPercentageAmount
	]);

	const warnUser: () => Promise<boolean> = useCallback(
		() => Swal({
			text:
				'Applying these changes will cause the component to check for content too close to the new Trim box.',
			buttons: [true, true]
		}).then((confirmed?: boolean) => !!confirmed),
		[Swal]
	);

	const applyToCurrentHandler = useCallback(async () => {
		const part = Part;
		const count = 1;

		const confirmed = await warnUser();

		if (confirmed) {

			await applyTo({
				part,
				ordinal,
				count
			});
			backHandler();
		}
	}, [Part, applyTo, backHandler, ordinal, warnUser]);

	const applyToAllHandler = useCallback(async () => {
		const part = Part;
		const count = job.PartPages[part];

		const confirmed = await warnUser();

		if (confirmed) {
			await applyTo({
				ordinal: 1,
				part,
				count
			});
			backHandler();
		}
	}, [Part, applyTo, backHandler, job.PartPages, warnUser]);

	const applyToEvenHandler = useCallback(async () => {
		const part = Part;
		const skip = 1;
		const count = job.PartPages[part];

		const confirmed = await warnUser();

		if (confirmed) {
			await applyTo({
				part,
				ordinal: 2,
				count,
				skip
			});
			backHandler();
		}
	}, [Part, applyTo, backHandler, job.PartPages, warnUser]);

	const applyToOddHandler = useCallback(async () => {
		const part = Part;
		const skip = 1;
		const count = job.PartPages[part];

		const confirmed = await warnUser();

		if (confirmed) {
			await applyTo({
				part,
				ordinal: 1,
				count,
				skip
			});
			backHandler();
		}
	}, [Part, applyTo, backHandler, job.PartPages, warnUser]);

	const undoHandler = useCallback(() => setBumpOffset([0, 0]), [
		setBumpOffset
	]);

	const isBumped = useMemo(() => !isEqual(bumpOffset, [0, 0]), [bumpOffset]);

	const resetHandler = useCallback(async () => {
		await reset();
		backHandler();
	}, [backHandler, reset]);

	const adjustedBumpOffset = useMemo(() => {
		const { bumpx = 0, bumpy = 0 } = page.CustomBox || {};
		return [bumpOffset[0] + bumpx, bumpOffset[1] + bumpy];
	}, [bumpOffset, page.CustomBox]);

	const originalTrim = useMemo(
		() => parseBox(page.TrimBox || page.MediaBox),
		[page.MediaBox, page.TrimBox]
	);

	const customBox = useMemo(() => {
		if (page.SelectedBox !== 'Custom') return null;
		else return parseBox(page.CustomBox!);
	}, [page.CustomBox, page.SelectedBox]);

	const showScale = useMemo(
		() =>
			customBox &&
			(page.Scale?.HorizontalPercent !== 1 ||
				page.Scale?.HorizontalPercent !== 1),
		[customBox, page.Scale?.HorizontalPercent]
	);

	return (
		<div className={styles.ScaleCrop}>
			<div className={styles.Help}>
				<a
					target="_blank"
					href="https://lscscout.groovehq.com/help/how-to-scale-adjust-trim-or-crop"
					rel="noreferrer"
				>
					<FaInfoCircle /> How do I scale?
				</a>
			</div>
			<div className={styles.InputGroup}>
				<label>Units</label>
				<div>
					<select
						onChange={e => setSelectedUnit(e.target.value as Unit)}
						className="form-control"
						defaultValue={selectedUnit}
					>
						{units.map(u => (
							<option key={u} value={u}>
								{u}
							</option>
						))}
					</select>
				</div>
			</div>
			<hr />
			<h3>
				<FaCropAlt /> Trim
			</h3>
			<div className={styles.TrimArea}>
				<Compass className={classNames(styles.Compass, { "disabled-custom-control": !contextJobIsActive || partIsLocked })} onClick={compassClick} />
				<div className={styles.Stats}>
					<table>
						<tbody>
							<tr>
								<td>Nudge</td>
								<td>
									<Quantity
										from="points"
										to={selectedUnit}
										value={adjustedBumpOffset[0]}
										precision={4}
									/>
									,{' '}
									<Quantity
										from="points"
										to={selectedUnit}
										value={adjustedBumpOffset[1]}
										precision={4}
									/>
								</td>
							</tr>
							<tr>
								<td>Media Offset</td>
								<td>
									<Quantity
										from="points"
										to={selectedUnit}
										value={
											originalTrim.x -
											+page.MediaBox.x +
											adjustedBumpOffset[0]
										}
										precision={4}
									/>
									,{' '}
									<Quantity
										from="points"
										to={selectedUnit}
										value={
											originalTrim.y -
											+page.MediaBox.y +
											adjustedBumpOffset[1]
										}
										precision={4}
									/>
								</td>
							</tr>
							<tr>
								<td>Original</td>
								<td>
									<Quantity
										from="points"
										to={selectedUnit}
										value={originalTrim.width}
										precision={4}
									/>
									,{' '}
									<Quantity
										from="points"
										to={selectedUnit}
										value={originalTrim.height}
										precision={4}
									/>
								</td>
							</tr>
							{(customBox && (originalTrim.width !== customBox.width || originalTrim.height !== customBox.height)) && (
								<tr>
									<td>Current</td>
									<td>
										<Quantity
											from="points"
											to={selectedUnit}
											value={customBox.width}
											precision={4}
										/>
										,{' '}
										<Quantity
											from="points"
											to={selectedUnit}
											value={customBox.height}
											precision={4}
										/>
									</td>
								</tr>
							)}
							{showScale && (
								<tr>
									<td>Scaling</td>
									<td>
										{(
											page.Scale!.HorizontalPercent * 100
										).toPrecision(4)}
										{page.Scale?.HorizontalPercent !==
											page.Scale?.VerticalPercent && (
												<>
													{' '}
													x{' '}
													{(
														page.Scale!
															.VerticalPercent * 100
													).toPrecision(4)}
												</>
											)}{' '}
										%
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
			<div className={styles.ComponentDimensionControls}>
				<div className={styles.InputGroup}>
					<label>Component Width</label>
					<div>
						<MultiUnitInput
							disabled={!contextJobIsActive || partIsLocked || processing || targetwidth === undefined}
							value={targetwidth}
							onChange={setTargetWidth}
							modelUnits="points"
							displayUnits={selectedUnit}
						/>
					</div>
				</div>
				<div className={styles.InputGroup}>
					<label>Component Height</label>
					<div>
						<MultiUnitInput
							disabled={!contextJobIsActive || partIsLocked || processing || targetheight === undefined}
							value={targetheight}
							onChange={setTargetHeight}
							modelUnits="points"
							displayUnits={selectedUnit}
						/>
					</div>
				</div>
				<div className={`${styles.InputGroup} ${styles.SaveGroup}`}>
					<label></label>
					<div>
						<button
							className={`${styles.CustomButton} ${styles.Success} ${styles.Save}`}
							type="button"
							onClick={() => applyCustomTrim()}
							disabled={!contextJobIsActive || partIsLocked || !trimDirty || !targetDimensionsValid}
						>
							Save
						</button>
					</div>
				</div>
			</div>
			{page.SelectedBox !== 'Custom' && (
				<div className={styles.InputGroup}>
					<label>
						<input disabled={!contextJobIsActive || partIsLocked}
							type="checkbox"
							checked={scaleToPercentageEnabled}
							onChange={e =>
								setScaleToPercentageEnabled(
									e.currentTarget.checked
								)
							}
						/>{' '}
						Scale to Percentage (optional)
					</label>
					{scaleToPercentageEnabled && (
						<div>
							<input
								type="number"
								step="1"
								disabled={!scaleToPercentageEnabled}
								value={scaleToPercentageAmount}
								onChange={e =>
									setScaleToPercentageAmount(+e.target.value)
								}
							/>
						</div>
					)}
				</div>
			)}
			{page.SelectedBox !== 'Custom' && (
				<div className={`${styles.ButtonGroup} ${styles.Flex}`}>
					{!scaleToPercentageEnabled && (
						<>
							<button
								className={classNames(
									styles.CustomButton,
									styles.Success
								)}
								onClick={autoCenterClickHandler}
								disabled={!contextJobIsActive || partIsLocked || processing || !targetDimensionsValid}
							>
								<FaCompress /> Center
							</button>
							<button
								className={classNames(
									styles.CustomButton,
									styles.Success
								)}
								onClick={autoScaleCenterClickHandler}
								disabled={!contextJobIsActive || partIsLocked || processing || !targetDimensionsValid}
							>
								<FaCompressArrowsAlt /> Scale{' & '}Center
							</button>
						</>
					)}
					{scaleToPercentageEnabled && (
						<button
							className={classNames(
								styles.CustomButton,
								styles.Success
							)}
							onClick={scalePercentClickHandler}
							disabled={processing || !targetDimensionsValid}
						>
							{scaleToPercentageAmount > 100 ? (
								<FaExpandArrowsAlt />
							) : scaleToPercentageAmount === 100 ? (
								<FaExpand />
							) : (
								<FaCompressArrowsAlt />
							)}{' '}
							Scale to {scaleToPercentageAmount}% and Center
						</button>
					)}
				</div>
			)}
			<div className={`${styles.ButtonGroup} ${styles.Flex}`}>
				{isBumped && (
					<button
						className={classNames(
							styles.CustomButton,
							styles.Danger
						)}
						onClick={undoHandler}
						type="button"
					>
						<FaTrash /> Undo Local Changes
					</button>
				)}
				{page.SelectedBox === 'Custom' && (
					<button
						className={classNames(
							styles.CustomButton,
							styles.Danger
						)}
						onClick={resetHandler}
						type="button"
					>
						<FaTrash /> Reset Trim
					</button>
				)}
			</div>
			<hr />
			<h3>
				<FaRuler /> Measurements
			</h3>
			<div className={`${styles.ButtonGroup} ${styles.Flex}`}>
				<button
					className={styles.CustomButton}
					type="button"
					onClick={() => setShowMeasurements(s => !s)}
				>
					{showMeasurements ? (
						<>
							<FaEyeSlash /> Hide
						</>
					) : (
						<>
							<FaEye /> Show
						</>
					)}
				</button>
				{showMeasurements && (
					<>
						<button
							className={classNames(
								styles.CustomButton,
								styles.Info
							)}
							disabled={addRulerMode}
							type="button"
							onClick={() => setAddRulerMode(true)}
						>
							<FaPlus /> Add
						</button>
						{lines.length > 0 && (
							<button
								className={classNames(
									styles.CustomButton,
									styles.Danger
								)}
								type="button"
								onClick={() => clearMeasurements()}
							>
								<FaTrash /> Clear
							</button>
						)}
					</>
				)}
			</div>
			<div className={styles.Apply}>
				<h3>
					<FaCheck /> Apply Changes
				</h3>
				<p>Apply these settings to:</p>
				<div className={`${styles.ButtonGroup} ${styles.Flex}`}>
					<button
						className={classNames(
							styles.CustomButton,
							styles.Success
						)}
						onClick={applyToCurrentHandler}
						disabled={!contextJobIsActive || partIsLocked || processing || !targetDimensionsValid}
					>
						Current
					</button>
					<button
						className={classNames(styles.CustomButton, styles.Info)}
						onClick={applyToAllHandler}
						disabled={!contextJobIsActive || partIsLocked || processing || !targetDimensionsValid}
					>
						All
					</button>
					<button
						className={classNames(
							styles.CustomButton,
							styles.Warning
						)}
						onClick={applyToEvenHandler}
						disabled={!contextJobIsActive || partIsLocked || processing || !targetDimensionsValid}
					>
						Even
					</button>
					<button
						className={classNames(
							styles.CustomButton,
							styles.Warning
						)}
						onClick={applyToOddHandler}
						disabled={!contextJobIsActive || partIsLocked || processing || !targetDimensionsValid}
					>
						Odd
					</button>
				</div>
			</div>
		</div>
	);
}
