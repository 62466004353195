import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from ".";
import { SearchJob } from "../types/job.types";
import { SearchState } from "../types/store.types";
import { SearchSortBy, SearchStatus, SearchTitleTypes } from "../types/types";

const initialState: SearchState = {
    query: "",
    sortBy: SearchSortBy.MODIFIED,
    titleType: SearchTitleTypes.ALL_TITLES,
    status: SearchStatus.ACTIVE,
    loading: false,
    results: [],
    error: "",
    pageSize: 20,
    pageNumber: 1,
    numberOfPages: 1,
    numberOfJobs: 0
}

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setQuery: (state, { payload }: PayloadAction<string>) => {
            state.query = payload
        },

        setSortBy: (state, { payload }: PayloadAction<string>) => {
            state.sortBy = payload
            state.loading = true
            state.pageNumber = 1
        },

        setTitleType: (state, { payload }: PayloadAction<SearchTitleTypes>) => {
            state.titleType = payload
            state.loading = true
            state.pageNumber = 1
        },

        setStatus: (state, { payload }: PayloadAction<string>) => {
            state.status = payload
            state.loading = true
            state.pageNumber = 1
        },

        search: (state) => {
            state.loading = true;
        },

        searchSuccess: (state, { payload }: PayloadAction<SearchJob[]>) => {
            state.loading = false;
            state.results = payload
            state.error = ""
        },

        searchError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload
        },

        setNumberOfJobs: (state, { payload }: PayloadAction<number>) => {
            state.numberOfJobs = payload
            state.numberOfPages = Math.ceil(payload / state.pageSize);
        },

        setPageNumber: (state, { payload }: PayloadAction<number>) => {
            state.pageNumber = payload
            state.loading = true;
        },
    }
})

export const { setQuery, setSortBy, setStatus, setTitleType, search, searchError, searchSuccess, setNumberOfJobs, setPageNumber } = searchSlice.actions

export const useSearch = () => useSelector((state: RootState) => state.search)

export default searchSlice.reducer
