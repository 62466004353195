import React, { useEffect } from "react"
import Sidebar from "../components/Sidebar"
import UploadModal from "../components/Upload/UploadModal"
import { mqttActions, useMqttState } from "../mqtt"
import { FaExclamationTriangle } from "react-icons/fa"
import Swal from "sweetalert2"
import { useDispatch } from "react-redux"
import PrivateRoute from "../routes/PrivateRoute"

interface DashboardProps {
    sidebar?: React.ReactNode,
    main?: React.ReactNode
}

const Dashboard: React.FC<DashboardProps> = (props) => {

    const { sessionTakenOver, userNotified } = useMqttState() || {}

    const dispatch = useDispatch()

    // Notify user when connection is first lost to being taken over by another new connection under
    // the same client ID
    useEffect(() => {
        if (sessionTakenOver && !userNotified) {
            mqttConnectionLostAlert();
            dispatch(mqttActions.setUserNotifiedOfConnectionChange(true))
        }
    })

    const mqttConnectionLostAlert = () => {
        return Swal.fire({
            "icon": "warning",
            "title": "Warning - Connection To Updates Lost In This Window",
            "text": "Your user account is now connected to updates in a different instance of the app. This current app window is no longer receiving updates on title changes and progress."
        });
    }


    return <PrivateRoute>
        <>
            <UploadModal />
            <section className="dashboard-view has-background-dark" data-testid="dashboard-page">
                {
                    sessionTakenOver &&
                    <div onClick={mqttConnectionLostAlert} className="mqtt-connection-down-icon force-to-front-of-ui rounded-2 is-clickable">
                        <span className="is-flex is-justify-content-center is-align-items-center">
                            <FaExclamationTriangle size={25} />
                        </span>
                    </div>
                }
                <div className="columns h-full mx-0 has-min-width-465">
                    <div className="column fixcolumn sidebar has-min-width-300 has-background-dark is-flex-grow-0 p-0">
                        <Sidebar>
                            {props.sidebar}
                        </Sidebar>
                    </div>
                    <div className="column p-0 dashboard-main scrollable">
                        <div>
                            {props.main}
                        </div>
                    </div>
                </div>
            </section>
        </>
    </PrivateRoute>
}

export default Dashboard
