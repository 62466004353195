import getAreaFromLine from '../_utils/getAreaFromLine';
import { RefObject, useEffect, useState } from 'react';
import { Area } from '../_types/LSCScout.type';

import useDraw from './useDraw';

export default function useBoxDraw(
	ref: RefObject<HTMLCanvasElement>,
	canvasScale = 1
) {
	const [selectedArea, setSelectedArea] = useState<Area | undefined>();

	const [{ current, shiftKey }] = useDraw(ref, {
		x: canvasScale,
		y: canvasScale
	});

	useEffect(() => {
		if (current) {
			const [start, end] = current;
			if (shiftKey) {
				const relativeX = end.x - start.x;
				const relativeY = end.y - start.y;

				const relXSign = Math.sign(relativeX);
				const relYSign = Math.sign(relativeY);

				const length = (Math.abs(relativeX) + Math.abs(relativeY)) / 2;

				const newEnd = {
					x: start.x + length * relXSign,
					y: start.y + length * relYSign
				};

				setSelectedArea(getAreaFromLine([start, newEnd]));
			} else {
				setSelectedArea(getAreaFromLine(current));
			}
		} else setSelectedArea(undefined);
	}, [current, shiftKey]);

	return selectedArea;
}
