import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react';
import { useQuery } from 'react-query';

function ssaIsFunction<T>(ssa: SetStateAction<T>): ssa is (prevState: T) => T {
	return typeof ssa === 'function';
}

export interface ResourceDefinition<T, P> {
	name: string;
	get: (params: P) => Promise<T>;
	update: (patch: Partial<T>, params: P) => Promise<T | null>;
	isProcessing: (data: T) => boolean;
}

function useResource<T, P>(d: ResourceDefinition<T, P>, p: P, seed: T) {
	const [refetchInterval, setRefetchInterval] = useState(5000);

	const queryResponse = useQuery([d.name, p], () => d.get(p), {
		initialData: seed,
		refetchInterval
	});
	const { isLoading, refetch } = queryResponse;
	const data = queryResponse.data as T;

	const mutate = useCallback(
		async (action: SetStateAction<Partial<T>>) => {
			let newData: Partial<T>;
			if (ssaIsFunction(action)) {
				if (!data)
					throw new Error('Cannot mutate without existing data');
				else newData = action(data);
			} else newData = action;

			await d.update(newData, p).then(() => refetch());
		},
		[d, data, p, refetch]
	);

	const processing = useMemo(() => (data ? d.isProcessing(data) : false), [
		data,
		d
	]);

	useEffect(() => {
		if (processing) setRefetchInterval(500);
		else setRefetchInterval(5000);
	}, [processing]);

	return { data, loading: isLoading, processing, mutate, refetch };
}

export default useResource;
