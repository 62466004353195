import jobApi from "../lib/job"
import { Job } from "../types/job.types"
import { connect, StreamClient, FeedAPIResponse } from 'getstream'
import { PagePart } from "../types/pages.types";
import { HistoryEntry } from "../components/ViewHistory/ViewHistory";

let gsClient: StreamClient|null;

const initializeGetstreamClient = async (attemptNumber?: number): Promise<StreamClient|void> => {
    if (process.env.REACT_APP_GETSTREAM_API_KEY && process.env.REACT_APP_GETSTREAM_APP_ID) {
        let numberOfTries = attemptNumber || 1;
        try {
            gsClient = connect(process.env.REACT_APP_GETSTREAM_API_KEY, null, process.env.REACT_APP_GETSTREAM_APP_ID) 
            return gsClient;
        }
        catch (error) {
            if (numberOfTries >= 3) {
                throw new Error('The app was unable to connect to the getstream feed for the job.')
            }
            numberOfTries += 1;
            console.log(`Something went wrong while connecting to the getstream feed for the job. Trying again in a moment... (attempt ${numberOfTries} of 3)`)
            return new Promise((resolve) => setTimeout(resolve, 1000)).then(() => initializeGetstreamClient(numberOfTries))
        }
    }
    else {
        throw new Error('The app is missing required environment variables to obtain and show Job history.')
    }
}

const getGsClient = async () => (gsClient ? gsClient : await initializeGetstreamClient())

// This requires that the current state Job is the Job selected.
// I think that will always be the case. -> it is enforced by the UI's current flow. 
const getFeedForJob = async (currentJob: Job, opts?: {id_lt?: string}) => {

    const jobFeedToken = await jobApi.getJob(currentJob.JobID, true).then((res) => {
        if (res.data.GetstreamToken) {
            return res.data.GetstreamToken;
        }
        else {
            throw new Error('No getstream token was obtained from the Scout API for the job.');
        }
    }).catch(err => console.log(err))
    
    const client = await getGsClient() as StreamClient
    if (client && jobFeedToken) {
        const jobFeed = client.feed('job', currentJob.JobID, jobFeedToken)
        const feedOpts: {
            limit: number,
            enrich: boolean,
            id_lt?: string
        } = {
            limit: 100,
            enrich: false
        }
        if (opts?.id_lt) {
            feedOpts.id_lt = opts.id_lt
        }
        return await jobFeed.get(feedOpts)
        .then((res: FeedAPIResponse) => {
            // console.log('feed res:')
            // console.log(res)
            return res;
        })
        .catch((error: Error) => console.log(error))
    }
}

const getFeedEntriesForPart = async (currentJob: Job, part?: PagePart | string) => {

    const feedEntriesRetrieved: HistoryEntry[] = []

    /**
     * Retrieves the Getstream feed recursively until either the feed's end is reached, or the desired amount of part-specific records is obtained.  
     *   
     * If the `part` param is supplied, the method will run and recursively `GET` and filter history entries specific to the `part` until either:
     * - 100 records specific to the `part` are obtained, or
     * - the feed's end is reached.  
     *  
     * If the `part` param is not passed, the method will fetch all available records from the Getstream API, up to the API's record max of 1,000.  
     * 
     * ---
     * **Note** - *Currently, this method is only used with a `part` param included.*
     * 
     * ---
     *  
     * Getstream feeds can contain an absolute max of 1,000 records.  
     * Up to 100 records can be fetched in a single GET request to the feed.  
     * 
     * @param part - The part to fetch and filter records for from the Getstream API.
     * @param id_lt - The "id less than" id string, obtained each time that the Getstream API still has records available for fetching. This value sets the record fetch position at the next portion of records each time a recursive `GET` call is made. It is extracted from the `next` field on the Getstream API response.
     */
    const retrieveFeedRecursively = async (part?: string, id_lt?: string ) => {        

        // console.log('calling the feed...')
        const feedResponse = await getFeedForJob(currentJob, {id_lt: id_lt || undefined})
        .then(feedRes => {
            if (feedRes?.results) {
                const entries: HistoryEntry[] = feedRes.results as HistoryEntry[]
                 
                // if a part param is passed, filter out all entries for the part from the feed response,
                // and only add those to the entry collection
                if (part) {
                    const entriesForPart = entries.filter(gsEntry => gsEntry.part === part)
                    // console.log(`adding results collection filtered for part, to feed...(${entriesForPart.length} entries)`)   
                    // Limit the feedEntries array to accumulate to a max length of 100
                    if ((entriesForPart.length + feedEntriesRetrieved.length) > 100) {
                        // console.log(`Instead of adding all ${entriesForPart.length}- truncating the amt of feed entries to add, to keep list at a max of 100...`)
                        // if this happens, only add N records from the *start*
                        // of the response - where N is the amount that that will bring the feedEntries to a total of exactly 100 records.
                        // Note: 100 is only the limit here because the GS API only allows a max fetch of 100 entries -
                        // this 'limit' could be lower if needed, but not any higher.
                        const amountToAdd = 100 - feedEntriesRetrieved.length

                        // console.log(`restricting last addition to ${amountToAdd} records... (entriesForPart: ${entriesForPart.length} records, feedEntriesRetrieved: ${feedEntriesRetrieved.length} records)`)
                        // slice of N records from the start of the response entries
                        const sectionToAdd = entriesForPart.slice(undefined, amountToAdd)
                        
                        // console.log('adding the following slice from the start of the feed res as the "last" entries...')
                        // console.log(sectionToAdd)

                        feedEntriesRetrieved.push(...sectionToAdd)
                    }
                    else {
                        // add the entries like normal, since adding them will not make the arr > 100
                        feedEntriesRetrieved.push(...entriesForPart)
                    }
                }
                else {
                    // if no part is passed and *all* of the job's history entries are just being recursively obtained:
                    // console.log('adding results collection to feed...')
                    // adds the results array's elements to the feedEntries array
                    feedEntriesRetrieved.push(...entries)
                }
                
                // if 100 entries have been obtained for a part, stop recursing and exit
                if (part && feedEntriesRetrieved.length >= 100) {
                    // console.log(`Stopping recursion; >= 100 entries have already been obtained for the part (${part})`)
                    // console.log(`(stopping with feedEntries at ${feedEntriesRetrieved.length} elements long)`)
                    return;
                }

                if (feedRes.next) {

                    // console.log('getting feedres.next...')
                    const nextIdLessThanParam: string | null = new URLSearchParams(feedRes.next).get("id_lt")
                    if (nextIdLessThanParam) {
                        // console.log('recursively calling feed retrieval with new id_lt:')
                        // console.log(nextIdLessThanParam)
                        // console.log(`Making recursive call with part value of: ${part}`)
                        return retrieveFeedRecursively(part, nextIdLessThanParam);
                    }
                }
                // console.log("after making every possible call using the next field from the API - the end of records has been reached.")
                // console.log('final feed entries obtained:')
                // console.log(feedEntriesRetrieved)
            }
        })
        .catch(err => {
            // Throw any error to outer try/catch below:
            throw err;
        })
    }

    try {
        // console.log('Making initial call to retrieve feed recursively with part value of:', part)
        return await retrieveFeedRecursively(part)
        .then(() => {
            // console.log('running end then() callback:')
            // console.log('returning final entries collection !!! ->')
            if (part) {
                // console.log(`collected and filtered for part "${part}" -`)
            }
            // console.log(feedEntriesRetrieved)
            return feedEntriesRetrieved;
        });
    }
    catch (error) {
        console.log('Error retrieving recursive feed for part:')
        console.log(error)
        return undefined;
    }
}

const historyApi = {
    getGsClient,
    getFeedForJob,
    getFeedEntriesForPart
}

export default historyApi;